import { Injectable} from '@angular/core';
import { NavbarService } from '../../../layout/header/navbar.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  
  constructor(private navbar: NavbarService) { }
  
  showSuccess(message: string): void {
    // this.snackBar.open(message);
    this.navbar.set_notification('Success','Success', message);

  }
  
  showError(message: string): void {
    // The second parameter is the text in the button. 
    // In the third, we send in the css class for the snack bar.
    // this.snackBar.open(message, 'X', {panelClass: ['error']});
    this.navbar.set_notification('Error','Error', message);
  }
}
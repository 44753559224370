import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router} from'@angular/router';
import { AuthenticationService} from '../../../core/authentication/authentication.service'

import {NavbarService} from '../../../layout/header/navbar.service'
@Component({
  selector: 'app-authorize-gmail-add-on',
  templateUrl: './authorize-gmail-add-on.component.html',
  styleUrls: ['./authorize-gmail-add-on.component.scss']
})
export class AuthorizeGmailAddOnComponent implements OnInit {
	gmail_verified=false;
	login_hint=''
  constructor(
  	
    private authService: AuthenticationService,
    private router: Router,
    private navbar: NavbarService
  	) { }

  ngOnInit(): void {
    localStorage.setItem('oauth_params', this.router.url.split('?')[1]);
    localStorage.removeItem('access_token')
    localStorage.removeItem('expiry');
    this.navbar.set_navbar_visible(false);
    this.navbar.set_is_logged_in(false);
    this.login_hint= this.router.url.split('login_hint=')[1]
    this.gmail_verified=(this.router.url.split('?')[1]!=undefined)
  }
      auth_gmail(){
        this.authService.http_get('/api/auth_gmail_add_on',{login_hint:this.login_hint}).subscribe(
            res => {
                console.log(res);
                window.location.assign(res['url']);
            },
            err=> console.log(err)
        )
    }

}

import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl} from'@angular/forms';

@Component({
  selector: '[app-jobs-for-vendor]',
  templateUrl: './jobs-for-vendor.component.html',
  styleUrls: ['./jobs-for-vendor.component.scss']
})
export class JobsForVendorComponent implements OnInit {

  constructor(
  	private authService: AuthenticationService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private fb: FormBuilder
  	) { }

  ngOnInit() {
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ImageCropperComponent, CropperSettings, Bounds } from 'ngx-img-cropper';
// import { FileUploader , FileUploaderOptions} from 'ng2-file-upload';
import { AuthenticationService} from '../../../core/authentication/authentication.service';
import { NavbarService } from '../../../layout/header/navbar.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FormGroup, FormBuilder, FormControl, Validators, NgForm } from '@angular/forms';
import {SharedService} from '../../../core/shared/shared.service'
import {Router} from '@angular/router';

declare var $: any;
let Quill: any = null;
// import * as QuillNamespace from 'quill';
// let Quill: any = QuillNamespace;
// import ImageResize from 'quill-image-resize-module';
// Quill.register('modules/imageResize', ImageResize);
@Component({
  selector: '[app-edit-about-company]',
  templateUrl: './edit-about-company.component.html',
  styleUrls: ['./edit-about-company.component.scss']
})
export class EditAboutCompanyComponent implements OnInit {
  
  contents='';
  editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '15rem',
        minHeight: '5rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        defaultFontName: 'Arial',
        // uploadUrl: '/api/upload_email_images',
        // uploadWithCredentials: false,
        toolbarHiddenButtons: [
          ['bold']
          ],
        customClasses: [
          {
            name: "quote",
            class: "quote",
          },
          {
            name: 'redText',
            class: 'redText'
          },
          {
            name: "titleText",
            class: "titleText",
            tag: "h1",
          },
        ]
      }
      editor_modules = {
      // toolbar: {
      //   container: [
      //     [{ 'font': [] }],
      //     [{ 'size': ['small', false, 'large', 'huge'] }],
      //     ['bold', 'italic', 'underline', 'strike'],
      //     [{ 'header': 1 }, { 'header': 2 }],
      //     [{ 'color': [] }, { 'background': [] }],
      //     [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      //     [{ 'align': [] }],
      //     ['link', 'image']
      //   ]
      // },
      // imageResize: true
    };
      about_company_form= this.fb.group({
          about_company: [''],
          });
  constructor(
  		private authService: AuthenticationService,
      private navbar: NavbarService,
      private fb: FormBuilder,
      private shared: SharedService,
      private router: Router
        ) { 
    if (this.shared.isBrowser()) {
      Quill = require('quill');
      const ImageResize = require('quill-image-resize').default;
      Quill.register('modules/imageResize', ImageResize);
    }
    this.editor_modules = {
      toolbar: {
        container: [
          [{ 'font': [] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'align': [] }],
          ['link', 'image']
        ]
      },
      imageResize: true
    };
  }

  ngOnInit() {
      this.authService.get('/api/get_about_company').subscribe(
        res=> {
          this.contents=res['about_company'];
          // $('#summernote_container').html('<div id="summernote" class="summernote"><span>'+this.contents+'</span></div>');
          // this.load_summernote();
          this.about_company_form.patchValue({
            'about_company': res['about_company']
          })
        },
        err=> console.log(err)
        )
        // this.load_summernote();
    }
    // load_summernote(){
    //     $('#summernote').summernote({
    //         height: 230,
    //         dialogsInBody: true,
    //         callbacks: {
    //             onChange: (contents, $editable) => {
    //                 this.contents = contents;
    //                 // console.log(contents);
    //             }
    //         }
    //     });
    //     $('.note-popover').css({'display': 'none'});
    // }
  save_about_company(_val){
        this.authService.post(
          '/api/save_about_company', _val
          ).subscribe(
          res => {
              
              console.log(res);
              this.navbar.set_notification('Success', res['notice'], 'Page will refresh now');;
              // setTimeout(function(){ location.reload(); }, 3000);
              this.shared.refresh(this.router.url);

              
          }, error => {
              console.log(error);
          }
        )
    }
}

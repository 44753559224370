import { Component, OnInit } from '@angular/core';
import { AuthenticationService} from '../../../core/authentication/authentication.service'
import { Router, ActivatedRoute, Params} from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ModalsService } from '../../../layout/modals/modals.service'
import { Observable} from 'rxjs';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service';


@Component({
  selector: '[app-saved-search]',
  templateUrl: './saved-search.component.html',
  styleUrls: ['./saved-search.component.scss']
})
export class SavedSearchComponent implements OnInit {
  saved_searches:any;
  constructor(
  	private authService: AuthenticationService,
    private route: ActivatedRoute, 
  	private router: Router,
    private modal: ModalsService,
    private shared: SharedService,
    private fb: FormBuilder,
    private navbar: NavbarService
  	) { }

  ngOnInit() {
  	this.load_saved_searches();
  }
  delete_saved_search(saved_search_id){
    console.log(saved_search_id);
    this.authService.post('/api/delete_saved_search', {'saved_search_id': saved_search_id}).subscribe(
      res=>{
        console.log(res);
        this.navbar.set_notification('Success','Notice' , res['Notice']);
        this.load_saved_searches();
      },
      err => {
        console.log(err)
      }
      )
  }
  load_saved_searches(){
    this.authService.get('/api/load_saved_searches')
    .subscribe(
      res=>{
        console.log(res);
        this.saved_searches=res['saved_searches'];
      },
      err => {
        console.log(err)
      }
    )
  }

}

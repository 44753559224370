import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { AuthenticationService} from '../../../core/authentication/authentication.service';
import { NavbarService } from '../../../layout/header/navbar.service';
import { ModalsService } from '../../../layout/modals/modals.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { SharedService } from '../../../core/shared/shared.service';

@Component({
  selector: '[app-career-page-settings]',
  templateUrl: './career-page-settings.component.html',
  styleUrls: ['./career-page-settings.component.scss']
})
export class CareerPageSettingsComponent implements OnInit {
  can_save_slug =false;
  updateCareerSlugForm = this.fb.group({
    // email_candidate_templates: [''],
    slug: ['', Validators.required]
    });
  update_url_selected = true;
  update_mandatory_fields = true;
  update_career_sections_display=true;
  updateMandatoryFieldsForm = this.fb.group({
    full_name: [''],
    date_of_birth: [''],
    email: [''],
    phone_no: [''],
    gender: [''],
    years_of_experience: [''],
    current_employer: [''],
    current_job_position: [''],
    current_location: [''],
    preferred_location: [''],
    salary: [''],
    expected_salary: [''],
    educational_qualification: [''],
    notice_period: [''],
    willingness_to_relocate: [''],
    reason_for_job_change: [''],
    interview_availability: [''],
    marital_status: [''],
    college_attended: [''],
    additional_info: [''],
    });
  updateCareerPageDisplayForm = this.fb.group({
    is_career_page_logo_enabled: [''],
    is_career_page_banner_enabled: [''],
    is_career_page_about_us_enabled: [''],
    font: [''],
    font_size: [''],
    font_colour: [''],
    layout: ['']
    
    });
  updateCareePageDiplayForm = this.fb.group({
    colour: [''],
    font_size: [''],
    font: [''],
    layout: [''],
    
    });
  career_page_display = {};
  update_display_settings = true
  mandatory_fields ={}
  slug_available:any; 
  constructor(
  	private authService: AuthenticationService,
  	private router: Router,
  	private route: ActivatedRoute,
    private navbar: NavbarService,
    private fb: FormBuilder,
    private shared: SharedService,
    private modal: ModalsService
  	) { }

  ngOnInit() {
  	this.can_save_slug=true; //temp
    this.load_slug();
    this.updateCareerSlugForm.controls.slug.valueChanges.subscribe(
      value => {
        this.check_availability(value)
      }
    );
    this.load_mandatory_fields();
    this.load_career_page_display();
  }
  load_mandatory_fields(){
      this.authService.get('/api/load_mandatory_fields').subscribe(
      res=> {
        console.log(res);
        this.mandatory_fields = res
        this.updateMandatoryFieldsForm.patchValue({
          full_name: res['is_full_name_mandatory'],
          date_of_birth: res['is_date_of_birth_mandatory'],
          email: res['is_email_mandatory'],
          phone_no: res['is_phone_no_mandatory'],
          gender: res['is_gender_mandatory'],
          years_of_experience: res['is_years_of_experience_mandatory'],
          current_employer: res['is_current_employer_mandatory'],
          current_job_position: res['is_current_job_position_mandatory'],
          current_location: res['is_current_location_mandatory'],
          preferred_location: res['is_preferred_location_mandatory'],
          salary: res['is_salary_mandatory'],
          expected_salary: res['is_expected_salary_mandatory'],
          educational_qualification: res['is_educational_qualification_mandatory'],
          notice_period: res['is_notice_period_mandatory'],
          willingness_to_relocate: res['is_willingness_to_relocate_mandatory'],
          reason_for_job_change: res['is_reason_for_job_change_mandatory'],
          interview_availability: res['is_interview_availability_mandatory'],
          marital_status: res['is_marital_status_mandatory'],
          college_attended: res['is_college_attended_mandatory'],
          additional_info: res['is_additional_info_mandatory'],
        })
      },
      err=> console.log(err)
    )
  }
   load_career_page_display(){
    this.authService.get('/api/load_career_page_display').subscribe(
      res=> {
        console.log(res);
        this.career_page_display = res;
        this.updateCareerPageDisplayForm.patchValue({
          'is_career_page_banner_enabled': res['is_career_page_banner_enabled'] ,
          'is_career_page_logo_enabled': res['is_career_page_logo_enabled'] ,
          'is_career_page_about_us_enabled': res['is_career_page_about_us_enabled'] ,
          'font': res['font'],
          'font_size': res['font_size'],
          'font_colour': res['font_colour'],
          'layout': res['layout']
        })
      },
      err=> console.log(err)
    )
  }
  select_mandatory_field($event, field){
    console.log(this.updateMandatoryFieldsForm.value)
    // this.shared.onCheckChange($event, this.updateMandatoryFieldsForm ,field);
  }
  save_mandatory_fields($event, _value){
    console.log(_value)
    this.authService.post('/api/save_mandatory_fields', _value).subscribe(
      res=> {
        console.log(res);
        // this.mandatory_fields = res
      },
      err=> console.log(err)
    )
  }
  save_career_page_display($event, _value){
    console.log(_value)
    this.authService.post('/api/save_career_page_display', _value).subscribe(
      res=> {
        console.log(res);
        // this.mandatory_fields = res
      },
      err=> console.log(err)
    )
  }
  update_diplay_settings($event, _value){
    console.log(_value)
    this.authService.post('/api/save_career_page_display_settings', _value).subscribe(
      res=> {
        console.log(res);
        // this.mandatory_fields = res
      },
      err=> console.log(err)
    )
  }
  save_slug($ev, _val){
  	this.authService.post('/api/save_slug', {slug: _val['slug']}).subscribe(
  		res=> {
  			console.log(res);
        this.navbar.set_notification('Success','Notice' , res['notice']);
        this.router.navigate(['/career']);
        this.modal.hide_modal_by_name('career_settings')
  		},
  		err=> console.log(err)
  	)
  }
  load_slug(){
    this.authService.get('/api/load_slug').subscribe(
      res=> {
        console.log(res);
        this.updateCareerSlugForm.patchValue({
              'slug': res['slug']
            })
      },
      err=> console.log(err)
      )
  }
  check_availability(_val) {
    this.authService.post('/api/check_career_url_availability', {slug: _val}).subscribe(
      res=> {
        console.log(res);
        this.slug_available = res['available']
      },
      err=> console.log(err)
      )
  }
}

import { Component, OnDestroy , ViewChild, ElementRef} from '@angular/core';
import { Params, Router, ActivatedRoute,NavigationEnd } from "@angular/router";
import { AuthenticationService} from '../../../../core/authentication/authentication.service'
import { Subscription, Observable }   from 'rxjs';
import { NavbarService} from '../../../../layout/header/navbar.service'
import { SharedService } from '../../../../core/shared/shared.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CandidateService } from '../candidate.service';
import { filter } from 'rxjs/operators';
import { ModalsService} from '../../../../layout/modals/modals.service';
import { FileUploader , FileUploaderOptions} from 'ng2-file-upload';
import {saveAs} from "file-saver";
import {TokenService} from '../../../../core/authentication/token.service';

import { environment } from '../../../../../environments/environment'
const URL = environment.baseUrl+'/api/create_comment';
let maxFileSize = 5*1024*1024;
@Component({
  selector: '[app-comments]',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnDestroy {
  @ViewChild('save_without_attachment', {static: false}) save_without_attachment;
  plo: any ;
  rcar:any;
  uar:any;
  comments: any;
  comments_form = this.fb.group({
    resume_comment: ['', Validators.required],
    is_shared_with_company: [''],
    is_shared_with_agency: ['']
  }); 
  hiring_company_name = '';
  was_sent_by_recruitment_agency = false;
  recruitment_company_name =''
  generated_params ={};
  subscription: Subscription;
  sub1: Subscription;
  sub2: Subscription;
  clicked_save =false;
  fileToUpload: File = null;
  is_attached = false;
  public uploader: FileUploader = new FileUploader({maxFileSize:maxFileSize, allowedMimeType: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'application/rtf', 'text/plain', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'image/jpeg', 'image/png' ], url: URL, itemAlias: 'candidate_attachment', autoUpload: false});
    public hasBaseDropZoneOver: boolean = false;
    // public hasAnotherDropZoneOver: boolean = false;

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }
   //uploadForm is not used to submit form, only added for validation of source input
  uploadForm=this.fb.group({
    sources: ['']
  });
  constructor(
  	private authService: AuthenticationService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private navbar: NavbarService,
    private shared: SharedService,
    private candidate: CandidateService,
    private tokenService2: TokenService,
    private fb: FormBuilder,
    private modal: ModalsService
    ) { }

  ngOnInit() {
  	this.get_restrictions()
  	this.get_plo();
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.upload_builder();

    // this.modal.load_modal_by_name('candidate', {});
  }

  ngOnDestroy(){
  	this.subscription.unsubscribe;
    this.sub1.unsubscribe;
    this.sub2.unsubscribe;
    // this.modal.hide_modal_by_name('candidate');

  }
  // handleFileInput(files: FileList) {
  //   this.fileToUpload = files.item(0);
  // }
  // uploadFileToActivity() {
  //   this.postFile(this.fileToUpload).subscribe(data => {
  //     // do something, if upload success
  //     }, error => {
  //       console.log(error);
  //     });
  // }
  // postFile(fileToUpload: File): Observable<boolean> {
  //   const endpoint = 'your-destination-url';
  //   const formData: FormData = new FormData();
  //   formData.append('fileKey', fileToUpload, fileToUpload.name);
  //   return this.httpClient
  //     .post(endpoint, formData, { headers: yourHeadersConfig })
  //     .map(() => { return true; })
  //     .catch((e) => this.handleError(e));
  // }
  upload_builder(){
    this.update_upload_parameters();
    // var date:Date = new Date();
    // var baseDate: Date = new Date(Date.UTC(1970, 0, 1));
    // var timestamp = _current_user_id + (date.getTime() - baseDate.getTime()).toString();
    var resume_comment = this.comments_form.get('resume_comment').value;
    console.log(resume_comment)
    var is_shared_with_agency = this.comments_form.get('is_shared_with_agency').value;
    console.log(is_shared_with_agency)
    var is_shared_with_company = this.comments_form.get('is_shared_with_company').value;
    console.log(is_shared_with_company)
    var generated_params = this.generated_params
    // console.log(this.cv_sources)
    //this.set_timestamp();
    this.uploader.onBuildItemForm = function(fileItem, form)
    { 
      form.append('resume_comment', resume_comment); 
      form.append('is_shared_with_agency', is_shared_with_agency);
      form.append('is_shared_with_company', is_shared_with_company);
      if(generated_params['search_result_id'] !=undefined){
        form.append('search_result_id', generated_params['search_result_id']);
      } 
      if(generated_params['resume_email_id'] !=undefined){
        form.append('resume_email_id', generated_params['resume_email_id']);
      } 
      if(generated_params['resume_report_id'] !=undefined){
        form.append('resume_report_id', generated_params['resume_report_id']);
      }
      return {fileItem, form}
    };
    this.uploader.onCompleteItem = (item:any, response:any, status:any, headers:any)=> {
      console.log( item, status,response, headers);
      var resp=JSON.parse(response);
      console.log(response);
      console.log(headers);
      if (headers['access-token']!== undefined && headers['access-token']!== ''){
        localStorage.setItem('accessToken', headers['access-token']);
        localStorage.setItem("expiry", headers['expiry']);
        this.update_upload_parameters()
      }
      
      // console.log(resp);  
    };
    this.uploader.onCompleteAll =()=> {
      this.load_comments();
      // this.navbar.set_notification('Success','Uploaded Resumes Completed', 'Please look for the bell icon on top right corner.');
      // this.modal.hide_modal_by_name('upload');
      // setTimeout(function(){ location.reload(); }, 3000);
      this.navbar.set_notification('Success','Comment Added', 'Comment Successfully Added, page will refresh now');
      this.comments_form.patchValue({
          'resume_comment': ''
        });
      this.clicked_save = false;
      // this.uploader.cancelAll()
      // setTimeout(function(){ location.reload(); }, 3000);
      this.shared.refresh(this.router.url);
    }
  }

  update_upload_parameters(){
      var uo: FileUploaderOptions = {};
      uo.headers = 
     [
       // {name: 'access-token', value : localStorage.getItem("accessToken")}, 
       // {name: 'client', value : localStorage.getItem("client")},
       // {name: 'expiry', value : localStorage.getItem("expiry")},
       // {name: 'token-type', value : localStorage.getItem("tokenType")},
       // {name: 'uid', value : localStorage.getItem("uid")}
       {name: 'access-token', value : localStorage.getItem("access_token")},
       {name: 'Authorization', value : 'Bearer '+ this.tokenService2.getToken()}, 
     ];
      // console.log(uo);
      
      this.uploader.setOptions(uo);
      // console.log(this.uploader);
  //    this.upload_builder(this.job_role.id , this.create_mass_upload_tags, "1" , this.create_mass_upload_cv_source)

    }
    submit_comment(){
      if(this.uploader.queue.length>0){
        this.upload_builder();
        this.uploader.uploadAll()
      }
      else{
        this.save_without_attachment.nativeElement.click();
      }
    }
  get_plo(){
  	// var url_split = this.router.url.split('/');
  	// var candidate_id = url_split[url_split.length-2];
  	// console.log(candidate_id);
    // this.generated_params = this.candidate.get_params_from_url(this.router.url);
    // if(this.router.url.includes('inbox')){
  	 //  this.authService.post('/api/get_resume_comments_for_resume', this.generated_params).subscribe(
    //     res => {
    //       this.plo =  res
    //       this.comments = this.plo.comments;
    //       console.log(res);
    //     }, error => {
    //       console.log(error);
    //     }
    //   )
    // }
    // else{
    //   this.authService.post('/api/get_resume_comments_for_resume_report', this.generated_params).subscribe(
    //     res => {
    //       this.plo =  res
    //       this.comments = this.plo.comments;
    //       console.log(res);
    //     }, error => {
    //       console.log(error);
    //     }
    //   )
    // }
    // this.load_comments();
    // this.sub1 = this.router.events.pipe(
    // filter(event => event instanceof NavigationEnd)  
    // ).subscribe((event: NavigationEnd) => {
    //   this.load_comments();
    //   console.log(event.url);
    // });    
    this.sub1 = this.route.params.subscribe(
      res=> {
        this.load_comments();
      }
    )
  }

  load_comments(){
    this.sub2=this.fetch_comments()
      .subscribe(
        res => {
          if(res['notice']==='Access Denied')
          {
            this.router.navigate(['/candidate_inbox'])
          }
          // this.plo =  res
          this.comments = res['comments'];
          this.hiring_company_name = res['hiring_company_name'];
          this.was_sent_by_recruitment_agency = res['was_sent_by_recruitment_agency'];
          this.recruitment_company_name = res['recruitment_company_name'];
          console.log(res);
        }, error => {
          // console.log(error);
        }
      )
  }
  fetch_comments(){
    this.generated_params = this.candidate.get_params_from_url(this.router.url);
    console.log(this.generated_params)
    if(this.router.url.includes('inbox')){
      return this.authService.post('/api/get_resume_comments_for_resume', this.generated_params)
    }
    else
    {
      return this.authService.post('/api/get_resume_comments_for_resume_report', this.generated_params)
    }
  }
  
  get_restrictions(){
    this.subscription= this.shared.current_restrictions$.subscribe(
        res => {
          this.rcar = res["rcar"];
          this.uar = res["uar"]
        }
    );
  }
  submitCommentsForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.comments_form.controls) {
            this.comments_form.controls[c].markAsTouched();
        }
    // if (this.submitCommentsForm.dirty && this.submitCommentsForm.valid)
    if (this.comments_form.valid) 
    {
      this.clicked_save=true;
      // console.warn(value);
      // console.log(this.generated_params);
      // console.log(this.shared.convert_date_to_query_string(value.start_date));
      // console.log(this.shared.convert_date_time_to_string(value.end_date, value.end_time));
      // console.log(value.start_date);
      // console.log(value.start_time);
      this.generated_params = this.shared.merge_options(this.generated_params, 
      {
        'resume_comment': value.resume_comment,
        'is_shared_with_agency': value.is_shared_with_agency,
        'is_shared_with_company': value.is_shared_with_company,
      })
       this.authService.post('/api/create_comment', this.generated_params
      //   'interview_end': this.shared.convert_date_time_to_string(value.end_date, value.end_time),
      //   'interview_start': this.shared.convert_date_time_to_string(value.start_date, value.start_time),
      //   'send_mail_to_candidate': value.send_mail_to_candidate,
      //   'appointment_message': value.appointment_message,,
      //   'interview_location': value.interview_location,
      //   'interviewers': value.interviewers,
      //   'stage_id': value.stage_id,
      //   'interview_reminder_unit': value.interview_reminder_unit,
      //   'interview_reminder': value.interview_reminder,
      //   'uploaded_resume_id': this.params['uploaded_resume_id']
      ).subscribe(
        res => {
          // console.log(res);
          this.navbar.set_notification('Success','Comment Added', 'Comment Successfully Added');
          this.comments = [res['comment']].concat(this.comments)
          this.comments_form.patchValue({
              'resume_comment': ''
            });
          this.clicked_save = false;
          // this.comments.push(res['comment']);
        },
        // err=> console.log(err)
      )
    }
    // this.modal.hide_modal('big');
  }
  is_called_from_job(){
     return this.router.url.includes('listview')
  }
  download_attachment(_id, _file_name){
    // console.log('downloading');
    this.authService.downloadFile('/api/download_attachment', {'id': _id}).subscribe(blob => {
            // console.log(blob)
            saveAs(blob, _file_name);
        }, error => {
              // console.log(error);
        }
    )
  }
}

import { Injectable } from '@angular/core';
//import { BehaviorSubject } from 'rxjs';
import { AuthenticationService} from '../../core/authentication/authentication.service';
import { Observable } from 'rxjs';
import { Params, Router, ActivatedRoute } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  resumereports_plo: any;
  constructor(
  	public authService: AuthenticationService, 
    private router: Router, 
    private route: ActivatedRoute
    ) { }

  get_resumereports_plo(queryparams, params){
    this.route.queryParams.subscribe((queryparams: Params) => {
      this.route.params.subscribe((params: Params) => {
        return this.authService.post('/api/index_resume_ranking_api', this.merge_options(params, queryparams)).pipe
        (

        )
        // .subscribe(
        //   res => {
        //       //var ploSource=new BehaviorSubject(res);
        //       this.resumereports_plo = res;
        //       console.log(this.resumereports_plo);
        //   }, error => {
        //   console.log(error);
        // });
      })
    })
  }
  merge_options=function(obj1, obj2) {
    var obj3 = {};
    for (var attrname in obj1) { obj3[attrname] = obj1[attrname]; }
    for (var attrname in obj2) { obj3[attrname] = obj2[attrname]; }
    return obj3;
  }
  get_params_from_url(_url){
    var url_split = _url.split('?')[0].split('/');
    // in case loaded from job list index
    if(url_split[url_split.length -1]==='list'){
      return {'job_role_id' : '0'}
    } 
    else{
      // console.log('here')
    var i = url_split.findIndex(function(element) {
         return element === 'listview';
       })
    return {'job_role_id' : url_split[i-1]};
    }
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from '../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl} from'@angular/forms';
import { FileUploader , FileUploaderOptions} from 'ng2-file-upload';
import { NavbarService } from '../../layout/header/navbar.service';
import {SharedService} from '../../core/shared/shared.service'

import {ModalsService} from '../../layout/modals/modals.service'
import { HttpHeaders,HttpClient} from '@angular/common/http';
import {TokenService} from '../../core/authentication/token.service';

import { Observable} from 'rxjs';
// import { AngularTokenService } from 'angular-token';
import { environment } from '../../../environments/environment'

const URL = environment.baseUrl+'/api/create_mass_upload_to_excel';
let maxFileSize = 5*1024*1024;

@Component({
  selector: 'app-parser',
  templateUrl: './parser.component.html',
  styleUrls: ['./parser.component.scss']
})
export class ParserComponent implements OnInit {

   public uploader: FileUploader = new FileUploader({maxFileSize:maxFileSize, allowedMimeType: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'application/rtf', 'text/plain'], url: URL, itemAlias: 'resume_source', autoUpload: true});
    public hasBaseDropZoneOver: boolean = false;
    // public hasAnotherDropZoneOver: boolean = false;

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }
    auto_id_resumes:any;
    email ='';
    company_id:any;
    current_user_id:any;
    resume_count:any;
    //uploadForm is not used to submit form, only added for validation of source input
    uploadForm=this.fb.group({
      email: ['']
    });
    can_access_parser = true;
    notifications = []
  constructor(
  	private authService: AuthenticationService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private fb: FormBuilder,
  	private navbar: NavbarService,
  	private http: HttpClient,
    private tokenService2: TokenService,
  	// private tokenService: AngularTokenService,
    private shared: SharedService,
    private modal: ModalsService
  	) { }


  ngOnInit() {
    this.navbar.set_current_page('extracts', {});
  	// console.log(URL)
    this.get_extract_data()
  	if (this.is_logged_in()){
  		this.get_plo();
  	}
  	else{
  		this.upload_builder( '', '');
  		this.get_stats();
  		this.current_user_id=0
      this.uploadForm.controls.email.valueChanges.subscribe(value => {
        // console.log(value);
        this.set_email(value)
      });
  	}
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    // this.cv_sources = [{
    //   'display': 'Database', 'value': 'Database'
    // }]
  	

  }
  get_plo(){
    
  	this.authService.post('/api/upload_resumes_modal', null).subscribe(
  		res => {
  			this.company_id =  res['company_id'];
        	this.current_user_id= res['current_user_id'];
        	// this.update_upload_parameters();
        	this.upload_builder( this.current_user_id, this.email);
  			}
  		)
    this.authService.get('/api/can_access_parser').subscribe(
      res=> {
        console.log(res);
        this.can_access_parser = res['can_access_parser'];
        console.log(this.can_access_parser)
      },
      err => console.log(err)
      )
  }
  update_upload_parameters(){
      var uo: FileUploaderOptions = {};
      uo.headers = 
     [
       // {name: 'access-token', value : localStorage.getItem("accessToken")}, 
       // {name: 'client', value : localStorage.getItem("client")},
       // {name: 'expiry', value : localStorage.getItem("expiry")},
       // {name: 'token-type', value : localStorage.getItem("tokenType")},
       // {name: 'uid', value : localStorage.getItem("uid")}
       {name: 'access-token', value : localStorage.getItem("access_token")},
       {name: 'Authorization', value : 'Bearer '+ this.tokenService2.getToken()}, 
     ];
      // console.log(uo);
      
      this.uploader.setOptions(uo);
      // console.log(this.uploader);
  //    this.upload_builder(this.job_role.id , this.create_mass_upload_tags, "1" , this.create_mass_upload_cv_source)

    }
  upload_builder(_current_user_id, _email){
    this.update_upload_parameters();
    var date:Date = new Date();
    var baseDate: Date = new Date(Date.UTC(1970, 0, 1));
    var timestamp = _current_user_id + (date.getTime() - baseDate.getTime()).toString();
    var cv_sources = ['Database']
    var auto_id_resumes = this.auto_id_resumes;
    // var email = this.email
    // console.log(this.email)
    //this.set_timestamp();
    this.uploader.onBuildItemForm = function(fileItem, form)
    { 
      //var timestamp ='9871239761'
      // form.append('company_id', _company_id); 
      form.append('user_upload_timestamp', timestamp);
      form.append('file_count', this.queue.length);
      form.append('cv_source', cv_sources);
      form.append('email', _email);
      form.append('auto_id_resumes', "on");
      return {fileItem, form} 
    };
	  this.uploader.onCompleteItem = (item:any, response:any, status:any, headers:any)=> {
      // console.log( item, status,response, headers);
      // var resp=JSON.parse(response);
      // console.log(response);
      // console.log(headers);
      if (headers['access-token']!== undefined && headers['access-token']!== ''){
      	localStorage.setItem('accessToken', headers['access-token']);
    	localStorage.setItem("expiry", headers['expiry']);
        this.update_upload_parameters()
    	}
      
      // console.log(resp);  
    };
    this.uploader.onCompleteAll =()=> {
      this.navbar.set_notification('Success','Uploaded Resumes Completed', 'You will receive an Email shortly. This page will refresh now');
      // setTimeout(function(){ location.reload(); }, 5000);
      this.shared.refresh(this.router.url);
    }
  }
  is_logged_in(){
  	return this.authService.isLoggedIn()
  }
  get_stats(){
  	let httpHeaders = new HttpHeaders({
        'Content-Type' : 'application/json'
      });
      this.http.get(environment.baseUrl +'/api/get_homepage_stats').subscribe(
        // this.http.get(this.tokenService.tokenOptions.apiBase +'/api/get_homepage_stats').subscribe(
        res => {
          // console.log(res);
          this.resume_count = res['resume_count'];
        }
      )
  }
  set_email(_val){
  	this.email=_val;
  	// console.log(this.email)
  	this.upload_builder( this.current_user_id, this.email);
  }
  get_extract_data(){
    this.authService.get('/api/get_extract_data').subscribe(
      res=> {
        console.log(res);
        this.notifications=res['notifications']
      }) 
  }
  download_extract(n){
    this.authService.post('/api/download_tracker_from_notification', {notification_id: n}).subscribe(
      res => {
        //saveAs(res, 'tracker.csv');
        window.open(res['link'])
        // window.location.assign(res['link']);
      },
      err=> console.log(err)
      )
  }
  open_notification_settings(){
    this.router.navigate(['/settings/notifications'])
  }
  open_video_help(){
    this.modal.load_modal_by_name('help_video', {})
  }
  
}

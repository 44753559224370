import { LayoutComponent } from '../layout/layout.component';
import { PrivacyComponent } from './static-pages/privacy/privacy.component';
import { TermsComponent } from './static-pages/terms/terms.component';
import { PricingComponent } from './static-pages/pricing/pricing.component';
import { AboutComponent } from './static-pages/about/about.component';
import { DbaComponent } from './static-pages/dba/dba.component';
import { LatestTobuVersionComponent } from './static-pages/latest-tobu-version/latest-tobu-version.component';
import { SecurityCertificateComponent } from './static-pages/security-certificate/security-certificate.component';
import { SitemapComponent } from './static-pages/sitemap/sitemap.component';
import { TobuJksComponent } from './static-pages/tobu-jks/tobu-jks.component';
import { CookiePolicyComponent } from './static-pages/cookie-policy/cookie-policy.component';
import { DownloadDriveComponent } from './static-pages/download-drive/download-drive.component';
import {ApplicationSuccessfulComponent} from './career/application-successful/application-successful.component';
// import {PostComponent} from './blog/post/post.component'

import { MicrosoftComponent } from './configure/microsoft/microsoft.component';
import { ZohoComponent } from './configure/zoho/zoho.component';
import { GmailComponent } from './configure/gmail/gmail.component';
import { GreenhouseComponent } from './configure/greenhouse/greenhouse.component';
import { GdriveComponent } from './configure/gdrive/gdrive.component';
import { OneDriveComponent } from './configure/one-drive/one-drive.component';
import { AuthGuard} from "../core/authentication/auth.guard";
import { ParserComponent } from './parser/parser.component';
import { VulnerabilityNoticeComponent } from './static-pages/vulnerability-notice/vulnerability-notice.component';
import { AffiliateComponent } from './static-pages/affiliate/affiliate.component';
import { AffiliateTermsComponent } from './static-pages/affiliate-terms/affiliate-terms.component';
import { GoogleApiDisclosureComponent } from './static-pages/google-api-disclosure/google-api-disclosure.component';
import { UnsubscribeComponent } from './static-pages/unsubscribe/unsubscribe.component';
// import { ListviewComponent } from './blog/listview/listview.component';
import { HelpVideosComponent } from './static-pages/help-videos/help-videos.component';
import { DemoComponent } from './static-pages/demo/demo.component';
import { GmailCallbackAddOnComponent } from './shared/gmail-callback-add-on/gmail-callback-add-on.component';
import { GmailCallbackLoginComponent } from './shared/gmail-callback-login/gmail-callback-login.component';
import { MicrosoftCallbackLoginComponent } from './shared/microsoft-callback-login/microsoft-callback-login.component';
import { AuthorizeGmailAddOnComponent } from './shared/authorize-gmail-add-on/authorize-gmail-add-on.component';
import { MonetizeComponent } from './monetize/monetize.component';
import { LoadingComponent } from './static-pages/loading/loading.component'
import {RedirectGuard} from '../core/authentication/redirect.guard'
import {TrendsComponent} from './static-pages/trends/trends.component'

export const routes = [

    {
        path: '',
        component: LayoutComponent,
        children: [
            // { path: '', redirectTo: 'candidates/inbox/view', pathMatch: 'full', canActivate: [AuthGuard]},
            { path: '', redirectTo: 'account/login', pathMatch: 'full'},
            // { path: 'home', loadChildren: './home/home.module#HomeModule' },
            // { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
            { path: 'privacy', component: PrivacyComponent ,
                data: {
                    title: 'Tobu - Privacy Policy',
                    description:'Tobu Privacy Policy',
                    ogUrl: 'https://www.tobu.ai/privacy'
                } 
            },
            { path: 'terms', component: TermsComponent ,
                data: {
                    title: 'Tobu - Terms and Conditions',
                    description:'Tobu Terms and Conditions',
                    ogUrl: 'https://www.tobu.ai/terms'
                }  
            },
            { path: 'pricing', component: PricingComponent ,
                data: {
                  title: 'Tobu Pricing',
                  description:'Pricing Details of Tobu',
                  ogUrl: 'https://tobu.ai/pricing'
                } 
            },
            { path: 'help-videos', component: HelpVideosComponent ,
                data: {
                  title: 'Tobu Video Tutorials',
                  description:'help videos on Tobu',
                  ogUrl: 'https://tobu.ai/help-videos'
                } 
            },
            { path: 'help-videos/:id', component: HelpVideosComponent ,
                data: {
                  title: 'Tobu Video Tutorials',
                  description:'help videos on Tobu',
                  ogUrl: 'https://tobu.ai/help-videos'
                } 
            },
            // { path: 'demo', component: DemoComponent ,
            //     data: {
            //       title: 'Tobu - Sign up',
            //       description:'request for Demo on Tobu',
            //       ogUrl: 'https://tobu.ai/demo'
            //     } 
            // },
            { path: 'demo', redirectTo: 'account/signup', pathMatch: 'full'},
            // { path: 'appsumo', component: DemoComponent ,
            //     data: {
            //       title: 'Tobu - Sign up',
            //       description:'request for Demo on Tobu',
            //       ogUrl: 'https://tobu.ai/appsumo'
            //     } 
            // },
            { path: 'appsumo', redirectTo: 'account/demo/referral/appsumo', pathMatch: 'full'},
            { path: 'affiliate-program', component: AffiliateComponent ,
                data: {
                    title: 'Tobu Affiliate Program',
                    description:'Tobu Affiliate Program',
                    ogUrl: 'https://www.tobu.ai/affiliate-program'
                } 
            },
            { path: 'affiliate-terms', component: AffiliateTermsComponent ,
                data: {
                    title: 'Tobu Affiliate Program Terms and Conditions',
                    description:'Tobu Affiliate Program Terms',
                    ogUrl: 'https://www.tobu.ai/affiliate-terms'
                } 
            },
            { path: 'partner/:id', redirectTo: 'account/demo/referral/:id', pathMatch: 'full'},
            { path: 'suman', redirectTo: 'account/demo/referral/suman', pathMatch: 'full'},
            { path: 'jamesmoran', redirectTo: 'account/demo/referral/jamesmoran', pathMatch: 'full'},
            { path: 'vir', redirectTo: 'account/demo/referral/vir', pathMatch: 'full'},
            { path: 'tamara', redirectTo: 'account/demo/referral/tamara', pathMatch: 'full'},
            { path: 'clinton', redirectTo: 'account/demo/referral/clinton', pathMatch: 'full'},
            { path: 'about', component: AboutComponent },
            { path: 'download', component: DownloadDriveComponent ,
                data: {
                    title: 'Tobu Drive Download',
                    description:'Tobu Drive Desktop Download',
                    ogUrl: 'https://www.tobu.ai/download'
                } 
            },
            { path: 'success', component: ApplicationSuccessfulComponent,
            data: {
                    title: 'Tobu - Successfully Submitted Resume',
                    description:'Tobu Successfully Submitted Resume',
                    ogUrl: 'https://www.tobu.ai/success'
                } 
            },
            { path: 'unsubscribe/:id', component: UnsubscribeComponent },
            { path: 'security-vulnerability-notification', component: VulnerabilityNoticeComponent},
            { path: 'google-api-disclosure', component: GoogleApiDisclosureComponent},
            { path: 'cookie_policy', component: CookiePolicyComponent ,
                data: {
                    title: 'Tobu - Cookie Policy',
                    description:'Tobu Cookie Policy',
                    ogUrl: 'https://www.tobu.ai/cookie_policy'
                } 
            },
            { path: 'configure_email/zoho/callback', component: ZohoComponent },
            { path: 'configure_email/microsoft/callback', component: MicrosoftComponent },
            { path: 'configure_email/gmail/oauth2callback', component: GmailComponent },
            { path: 'configure_cloud_drive/google_drive/oauth2callback', component: GdriveComponent },
            { path: 'configure_cloud_drive/one_drive/callback', component: OneDriveComponent },
            { path: 'configure_email/greenhouse/oauth2callback', component: GreenhouseComponent },
            { path: 'extracts', component: ParserComponent ,
                canActivate: [AuthGuard]
            },
            { path: 'users/sign_in', redirectTo: 'account/login', pathMatch: 'full' ,
                data: {
                    title: 'Tobu - Sign In',
                    description:'Tobu Sign In, Login',
                    ogUrl: 'https://www.tobu.ai/users/sign_in'
                } 
            },
            { path: 'users/sign_up', redirectTo: 'account/signup', pathMatch: 'full' },
            // { path: 'home', component: DownloadDriveComponent },
            { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule)},
            { 
                path: 'jobs', 
                loadChildren: () => import('./jobs/jobs.module').then(m => m.JobsModule), 
                canActivate: [AuthGuard]
            },
            { path: 'career-page', loadChildren: () => import('./career/career.module').then(m => m.CareerModule)},
            { path: 'referral', loadChildren: () => import('./career/career.module').then(m => m.CareerModule)},
            { path: 'candidates', 
                loadChildren: () => import('./candidates/candidates.module').then(m => m.CandidatesModule), 
                canActivate: [AuthGuard]
            },
            { path: 'calendar', 
                loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule), 
                canActivate: [AuthGuard]
            },
            { path: 'admin', 
                loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), 
                canActivate: [AuthGuard]
            },
            // {
            //    path: 'trends/:id', component: TrendsComponent 
            // },
            // { path: 'blogs', 
            //     // loadChildren: './blog/blog.module#BlogModule', 
            //     data: {
            //       title: 'Tobu Blog',
            //       description:'Tobu Blog contents',
            //       ogUrl: 'https://www.tobu.ai/blogs'
            //     } ,
            //     children : [
            //         {   
            //         path: ':id', component: PostComponent,
            //             data: {
            //               title: 'Tobu Blog Article',
            //               description:'Tobu Blog contents',
            //               ogUrl: 'https://www.tobu.ai/blog'
            //             }  
            //         }
            //     ]
            //     // canActivate: [AuthGuard]
            // },
            // { path: 'blog', component: ListviewComponent ,
            //     data: {
            //         title: 'Tobu - Blog',
            //         description:'Tobu Blog',
            //         ogUrl: 'https://www.tobu.ai/blog'
            //     } 
            // },
            // { path: 'blog', redirectTo: 'https://blog.tobu.ai' },
            {
                 path: 'blog',
                 canActivate: [RedirectGuard],
                 component: RedirectGuard,
                 data: {
                   externalUrl: 'https://blog.tobu.ai'
                 }
             },
             {
                 path: 'home',
                 canActivate: [RedirectGuard],
                 component: RedirectGuard,
                 data: {
                   externalUrl: 'https://tobu.ai'
                 }
             },
             {
                 path: 'blogs',
                 canActivate: [RedirectGuard],
                 component: RedirectGuard,
                 data: {
                   externalUrl: 'https://blog.tobu.ai'
                 }
             },
            { path: 'settings', 
                loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule), 
                canActivate: [AuthGuard]
            },
            { path: 'email-campaign', 
                loadChildren: () => import('./email-campaign/email-campaign.module').then(m => m.EmailCampaignModule), 
                canActivate: [AuthGuard]
            },
            { path: 'reports', 
                loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule), 
                canActivate: [AuthGuard]
            },
            { path: 'dba.pdf', component: DbaComponent ,
                data: {
                  title: 'Tobu DBA'
                } 
            },
            { path: 'tobu.jks', component: TobuJksComponent ,
                data: {
                  title: 'Tobu jks'
                } 
            },
            { path: 'latest_tobu_drive_version.txt', component: LatestTobuVersionComponent ,
                data: {
                  title: 'Tobu Drive Version',
                } 
            },
            { path: 'bishop_fox_security_certificate.pdf', component: SecurityCertificateComponent ,
                data: {
                  title: 'Tobu Security Certificate'
                } 
            },
            { path: 'sitemap.xml', component: SitemapComponent ,
                data: {
                  title: 'Sitemap'
                } 
            },
            { path: 'loading', component: LoadingComponent ,
            },
            //{ path: 'calendar', loadChildren: './calendar/calendar.module#CalendarModule', canActivate: [AuthGuard] }
            
            { path: 'gmail_callback_add_on', component: GmailCallbackAddOnComponent ,
                data: {
                  title: 'Gmail callback'
                } 
            }
            ,
            { path: 'gmail_callback_login', component: GmailCallbackLoginComponent ,
                data: {
                  title: 'Gmail callback'
                } 
            }
            ,
            { path: 'microsoft_callback_login', component: MicrosoftCallbackLoginComponent ,
                data: {
                  title: 'Microsoft callback'
                } 
            }
            ,
            { path: 'gmail_add_on', component: AuthorizeGmailAddOnComponent ,
                data: {
                  title: 'Authorize add on'
                } 
            },
            { path: 'monetize', component: MonetizeComponent ,
                data: {
                  title: 'Monetize DB'
                } 
            }
        ]
    },
    


    // Not found
    { path: '**', redirectTo: 'candidates/inbox/view' }

];

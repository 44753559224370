import { Component, OnInit } from '@angular/core';
import { Subscription }   from 'rxjs';
import {NavbarService} from '../../../layout/header/navbar.service'
import { ModalsService} from '../../../layout/modals/modals.service'
import {Location} from '@angular/common';
import {Router, ActivatedRoute} from '@angular/router';

import {AuthenticationService } from '../../../core/authentication/authentication.service';
import {ViewChild, Input} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from '../../../core/shared/shared.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from'@angular/forms';

@Component({
  selector: '[app-standardized-template]',
  templateUrl: './standardized-template.component.html',
  styleUrls: ['./standardized-template.component.scss']
})
export class StandardizedTemplateComponent implements OnInit {
  excluded_fields: Array<string> = [];//['Sugar Ray Robinson', 'Muhammad Ali', 'George Foreman', 'Joe Frazier', 'Jake LaMotta', 'Joe Louis', 'Jack Dempsey', 'Rocky Marciano', 'Mike Tyson', 'Oscar De La Hoya'];
  selected_fields: Array<string> = [];
  clicked_save=false
  valForm=this.fb.group({
    template_name: ['',[Validators.required]]
  });
  constructor(
  	private modal: ModalsService,
    private navbar: NavbarService,
    private router:Router,
    private authService: AuthenticationService,
    private shared: SharedService,
    private route: ActivatedRoute,
    private fb: FormBuilder 
    ) { }

  ngOnInit() {
  	this.authService.post('/api/get_all_standardized_template_fields', {template_id: ''}).subscribe(
  		res=>{
  			console.log(res);
  			this.selected_fields=res['selected_fields']
  			this.excluded_fields=res['excluded_fields']
  		},
  		err=> console.log(err)
  		)
  }
  //added for edit tracker later
  edit_template(_val){
  	this.authService.post('/api/get_all_standardized_template_fields', {template_id: {_val}}).subscribe(
  		res=>{
  			console.log(res);
  			this.selected_fields=res['selected_fields']
  			this.excluded_fields=res['excluded_fields']
  		},
  		err=> console.log(err)
  		)
  }
  submitValForm($ev, _val){
  	this.clicked_save=true;
  	this.authService.post('/api/create_standardized_template', this.shared.merge_options(_val, {template_field_ids: this.selected_fields.map((x,i,a)=> {return x['id']})})).subscribe(
  		res=> {
  			console.log(res); 
  			this.clicked_save=false;
  			this.modal.hide_modal_by_name('standardized_template');
  			this.navbar.set_notification('Success','Done', res['notice']);
  			// setTimeout(function(){ location.reload(); }, 3000);
  		},
  		err=> console.log(err)
  	)
  }
}

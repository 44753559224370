import { Component, OnInit , OnDestroy} from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from'@angular/forms';
import { ModalsService } from '../../../layout/modals/modals.service';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service'
import { Subscription } from 'rxjs';
import 'rxjs/add/operator/switchMap'; 
import 'rxjs/add/operator/map';
import { CandidateService} from '../../../routes/shared/candidate/candidate.service';
const swal = require('sweetalert');
@Component({
  selector: '[app-manage-tags]',
  templateUrl: './manage-tags.component.html',
  styleUrls: ['./manage-tags.component.scss']
})
export class ManageTagsComponent implements OnInit {
  tagForm = new FormGroup({
      old_tag_colour: new FormControl('#4285F4'), // Initial color
      old_tag: new FormControl('', Validators.required), // Initial value for tag_name
      new_tag_colour: new FormControl('#4285F4'), // Initial color
      new_tag: new FormControl('', Validators.required), // Initial value for tag_name
    });
  clicked_save = false
  edit_selected=false;
  constructor(
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private modal: ModalsService,
    private shared: SharedService,
    private navbar: NavbarService,
    private candidate: CandidateService
    ) { }
  all_tags: any ={};

  ngOnInit() {
    this.get_plo();
  }
  ngOnDestroy(){
    // this.sub.unsubscribe;
  }

  get_plo(){
    this.authService.get('/api/get_tag_stats').subscribe(
        res => {
            console.log(res);
         this.all_tags=res['tags']
         // this.edit_tags.patchValue({
         //  tags: res['tags']
         // })
         // console.log(this.edit_tags)

        }, error => {
            // console.log(error);
        }
      );
  }
  // submitEditTagsForm($ev, value: any) {
  //    // console.warn($ev);
  //    //from candidate view
  //    this.clicked_save =true;
     
  //     this.authService.post('/api/edit_tags', this.edit_tags.value)
  //     .subscribe(
  //       res => {
  //         // console.log(res);
  //         this.clicked_save =false;
  //         this.navbar.set_notification('Success','Tagging Initiated', 'The tagging has started. It might take a minute');
  //       },
  //       // err => console.log(err)
  //     )
  //    // console.warn(value);
  //    // this.modal.hide_modal('small');
  //    this.modal.hide_modal_by_name('manage_tags');
  //     // this.navbar.resumes_selected$
  //     // .switchMap(params => 
  //     //   this.authService.post('/api/transfer_resume_to_job_role', this.shared.merge_options({'uploaded_resume_ids': params}, value)))
  //     // .subscribe(
  //     //  res => console.log(res),
  //     //  err => console.log(err)
  //     //  )
  //    //.subscribe(x=>console.warn(x));
     
    
  //   };
    editTag(i: number) {
      this.edit_selected = true
    // Implement edit logic here
    // console.log(`Edit tag at index ${index}`);
      console.log(this.all_tags[i][0])
      this.tagForm.patchValue({
        old_tag: this.all_tags[i][0],
        old_tag_colour: this.all_tags[i][2],
        new_tag: this.all_tags[i][0],
        new_tag_colour: this.all_tags[i][2]
      })
    }
    updateTag(){
    this.clicked_save=true;
    console.log('Form Value:', this.tagForm.value);
    // Handle form submission
    this.authService.post('/api/edit_tag', this.tagForm.value)
      .subscribe(
        res => {
          // console.log(res);
          this.clicked_save =false;
          this.navbar.set_notification('Success','Updated Tag', 'The tag was successfully updated');
          // this.modal.hide_modal_by_name('create_tag')
          this.get_plo()
          this.edit_selected = false
        },
        // err => console.log(err)
      )
    
  }

    deleteTag(i: number) {
      // Implement delete logic here
      // console.log(`Delete tag at index ${index}`);
      // this.all_tags.splice(i, 1);
      swal({
            title: 'Are you sure?',
            text: 'This will delete the tag and will remove tags from any resume tagged with it.',
            icon: 'warning',
            buttons: {
                cancel: {
                    text: 'No, cancel',
                    value: null,
                    visible: true,
                    className: "",
                    closeModal: false
                },
                confirm: {
                    text: 'Yes, delete tag',
                    value: true,
                    visible: true,
                    className: "bg-danger",
                    closeModal: false
                }
            }
        }).then((isConfirm) => {
            if (isConfirm) {
              this.authService.post('/api/delete_tags', {tags: [this.all_tags[i][0]]})
            .subscribe(
              res => {
                // console.log(res);
                this.clicked_save =false;
                this.all_tags.splice(i, 1); 
                // this.navbar.set_notification('Success','Deleted Tag', 'The tag was successfully deleted');
                // this.modal.hide_modal_by_name('create_tag')
                // this.get_plo()
                // this.edit_selected = false
              },
              // err => console.log(err)
            )
                swal('Deleted!', 'The tag was successfully deleted.', 'success');
            } else {
                swal('Cancelled', 'Tag not deleted', 'error');
            }
        });
      
    }
    create_tag(){
      this.modal.hide_modal_by_name('manage_tags');
      this.modal.load_modal_by_name('create_tag', {});
    }

}

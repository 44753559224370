import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl} from'@angular/forms';
import { ModalsService } from '../../../layout/modals/modals.service';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service'
import 'rxjs/add/operator/switchMap'; 
import 'rxjs/add/operator/map';

@Component({
  selector: '[app-lead-card]',
  templateUrl: './lead-card.component.html',
  styleUrls: ['./lead-card.component.scss']
})
export class LeadCardComponent implements OnInit {

  constructor(
  	private authService: AuthenticationService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private fb: FormBuilder,
    private modal: ModalsService,
    private shared: SharedService,
    private navbar: NavbarService
  	) { }
  recruiter_lead:any;

  ngOnInit() {
  	// this.navbar.current_page$.switchMap(
   //    params=> {return params}
   //  )
    this.navbar.current_params$
      .switchMap(params => {
        console.log(params);
        return this.authService.post('/api/load_lead_card', params);
      }).subscribe(
        res => {
            console.log(res);
         this.recruiter_lead=res

        }, error => {
            // console.log(error);
        }
      );
  }
  convert_lead(_lead_id){
  	this.authService.post("/api/convert_lead_to_user", {lead_id:_lead_id}).subscribe(
        res=> console.log(res),
        err=> console.log(err)
      )
  }

}

import {Injectable, Inject} from '@angular/core';
// import {AngularTokenService} from 'angular-token';
import {Router} from '@angular/router';
import { HttpHeaders, HttpClient} from '@angular/common/http' 
import { environment } from '../../../environments/environment';
import { ElectronService } from 'ngx-electron';
//import { Http, ResponseContentType , RequestOptions} from '@angular/http';
// import { SharedService} from '../shared/shared.service'
import 'rxjs/Rx';
// import { RequestOptions, ResponseContentType } from '@angular/http';
import { Observable, Subject } from 'rxjs';
// import { InterceptorService } from 'ng2-interceptors';
import { PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
import {TokenService} from './token.service';
let win = undefined;
@Injectable()
export class AuthenticationService{
	redirectUrl: string;
	userSignedIn$:Subject<boolean> = new Subject();
	baseUrl=environment.baseUrl;
	constructor(
		// private tokenService: AngularTokenService,
		private router: Router,
		private http: HttpClient,
		private electronService: ElectronService,
		// private shared: SharedService,
		@Inject(PLATFORM_ID) private platformId: any,
		private tokenService2: TokenService
		// private shared: SharedService
	) {
		
		if(isPlatformBrowser(platformId))
		{ win = (<any>window)}
		
		//this.tokenService.init(); 
		// this.tokenService.validateToken().subscribe(
	 //        res => {
	 //        	console.log(res);
	 //        	res.status == 200 ? this.userSignedIn$.next(res.success) : this.userSignedIn$.next(false)
	 //        },
	 //        err => console.log(err)
  //   	)
  //   	this.userSignedIn$.subscribe(res=> console.log(res))
	}

	// login(email:string, password:string): Observable<Response>{
	// 	return this.tokenService.signIn({
	// 		login:email,
	// 		password:password
	// 	});
	// }
	// login(email:string, password:string){
	// 	return this.tokenService.signIn({
	// 		login:email,
	// 		password:password
	// 	})
	// 	.map(
	// 		res => {
 //          	this.userSignedIn$.next(true);
 //          	return res
 //        	}
	// 	);
	// }
	// signUp(email:string, password:string, passwordConfirmation: string): Observable<Response>{
	// 	return this.tokenService.registerAccount({
	// 		login:email,
	// 		password:password,
	// 		passwordConfirmation: passwordConfirmation
	// 	});
	// }
	// signUp(email:string, password:string, passwordConfirmation: string){
	// 	return this.tokenService.registerAccount({
	// 		login:email,
	// 		password:password,
	// 		passwordConfirmation: passwordConfirmation
	// 	})
	// 	.map(
	// 		res => {
 //          	this.userSignedIn$.next(true);
 //          	return res
 //        	}
	// 	);
	// }
	// recover(email:string){
	// 	return this.tokenService.resetPassword({
 //            login: email
 //        });
	// }
	// logOut(): void{
	// 	console.log('logging out')
	// 	this.redirectUrl = undefined;
	// 	this.tokenService.signOut().subscribe(
 //          res =>      console.log(res),
 //          error =>    console.log(error)
 //        );
	// 	//this.router.navigate(['/home']);
	// 	window.location.href = '/home'

	// }
	// logOut(): void{
	// 	console.log('logging out')
	// 	window.localStorage.removeItem('accessToken');
 //        window.localStorage.removeItem('client');
 //        window.localStorage.removeItem('expiry');
 //        window.localStorage.removeItem('uid');
 //        window.localStorage.removeItem('tokenType');
	// 	this.get('/api/sign_out').subscribe(
 //            res => {
 //                console.log(res);
 //                // window.localStorage.removeItem('accessToken');
 //                // window.localStorage.removeItem('client');
 //                // window.localStorage.removeItem('expiry');
 //                // window.localStorage.removeItem('uid');
 //                // window.localStorage.removeItem('tokenType');
 //                window.location.href = '/account/login'
 //            },
 //            err => console.log(err)
 //        )
 	logOut(){
 		// this.router.navigate(['account/login'])
 	// 	this.tokenService.signOut().subscribe(
		//   res =>      {
		//   	if(isPlatformBrowser(this.platformId))
		//   	// console.log(res);
		//   	// this.router.navigate(['account/login'])
		//   	{
		//   		if(this.electronService.isElectronApp)
		//   			{win.api.send("refreshApp")}
		//   		else
		//   			{window.location.href = '/account/login';}
		//   		// this.router.navigate(['/account/login'])
		//   		// this.userSignedIn$.next(false);
		//   	}
		//   },
		//   error =>    {
		//   	console.log(error)
		//   }
		// );
		this.tokenService2.removeToken();
    	this.tokenService2.removeRefreshToken();
    	this.tokenService2.removeExpiry();
    	if(isPlatformBrowser(this.platformId))
		  	// console.log(res);
		  	// this.router.navigate(['account/login'])
		  	{
		  		if(this.electronService.isElectronApp)
		  			{win.api.send("refreshApp")}
		  		else
		  			{window.location.href = '/account/login';}
		  		this.router.navigate(['/account/login'])
		  		this.userSignedIn$.next(false);
		  	}
 	// }
		// this.redirectUrl = undefined;
		// this.tokenService.signOut().subscribe(
  //         res =>      {
  //         	console.log(res);
  //         	this.userSignedIn$.next(false);
  //         	this.router.navigate(['/account/login']);
  //         },
  //         error =>    console.log(error)
  //       );
		//this.router.navigate(['/home']);
		//window.location.href = '/home'

	}
	isLoggedIn(): boolean{
		// if(this.shared.isBrowser())
		if(isPlatformBrowser(this.platformId))
		{
			let now= new Date().getTime()/1000
			let expiry= parseInt(window.localStorage.getItem('expiry'))
			if(window.localStorage.getItem('access_token')===null||window.localStorage.getItem('access_token')===''||(expiry<now)){return false}
				else
			// {
			// 	this.http.get(environment.baseUrl+'/api/is_logged_in', {}).subscribe(
			// 		res=> {
			// 			console.log(res)
			// 			let login=res['is_logged_in']
			// 			console.log(login)
			// 			return login;
			// 		}
			// 	)
			// }
			// return (this.tokenService.userSignedIn()&&window.localStorage.getItem('access_token')!=null&&window.localStorage.getItem('access_token')!='');
			
			{return true;}
		}
		else {return false}
	}

	redirectAfterLogin(): void{
		let redirectTo = this.redirectUrl ? this.redirectUrl : '/'
		this.redirectUrl = undefined;
		this.router.navigate([redirectTo]);
	}
	// post(route:string, options: any): Observable<Object>{
	//   if(isPlatformBrowser(this.platformId))
	// 	{
	// 		if(!this.isLoggedIn()){
	// 				console.log(route);
	// 				this.router.navigate(['/account/login']);
	// 				return Observable.empty();;
	// 			}
	// 			else{
	// 				console.log(this.tokenService.tokenOptions.apiBase);
	// 				return this.http.post(this.tokenService.tokenOptions.apiBase + route, 
	// 				// return this.http.post(this.baseUrl + route, 
	// 			   	options, 
	// 	           	//{headers:  new HttpHeaders({'Content-Type' : 'application/json'})}
	// 	        	)
	// 			}
	// 	}
	// }
	post_pre_login(route:string, options: any): Observable<Object>{
		 if(isPlatformBrowser(this.platformId))
			{let httpHeaders = new HttpHeaders({
				           'Content-Type' : 'application/json',
				           'Access-Control-Allow-Origin':'*',
				           'Accept' : 'application/json'
				        });
						return this.http.post(environment.baseUrl + route, 
						//return this.http.post(this.tokenService.tokenOptions.apiBase + route, 
					   	options, 
					   	{headers:httpHeaders}
			           	//{headers:  new HttpHeaders({'Content-Type' : 'application/json'})}
			        	)}
	}
	put_pre_login(route:string, params: any): Observable<Object>{
			let httpHeaders = new HttpHeaders({
	           'Content-Type' : 'application/json',
	           'Access-Control-Allow-Origin':'*',
	           'Accept' : 'application/json'
	        });
			return this.http.post(environment.baseUrl + route, 
			// return this.http.put(this.tokenService.tokenOptions.apiBase + route, 
		   	params, 
           	{headers:httpHeaders}
        	)
	}
	// get(route:string, params?: any): Observable<Object>{
	// 	if(!this.isLoggedIn()){
	// 		console.log(route);
	// 		this.router.navigate(['/account/login']);
	// 		return Observable.empty();
	// 		}
	// 	else{
	// 		console.log(this.tokenService.tokenOptions.apiBase);
	// 		return this.http.get(this.tokenService.tokenOptions.apiBase + route, params
	// 		// return this.http.get(this.baseUrl + route, params
 //           //{headers:  new HttpHeaders({'Content-Type' : 'application/json'})}
 //        	)
	// 	}
	// }
	post(_link, _params){
		 if(isPlatformBrowser(this.platformId))
        {let httpHeaders = new HttpHeaders({
                   'Content-Type' : 'application/json',
                   'Access-Control-Allow-Origin':'*',
                   'Accept' : 'application/json',
                   'Authorization': 'Bearer ' + this.tokenService2.getToken()
                });
                return this.http.post(this.baseUrl+_link, _params, {headers:httpHeaders})}
    }
    put(_link, _params){
    	 if(isPlatformBrowser(this.platformId))
        {let httpHeaders = new HttpHeaders({
                   'Content-Type' : 'application/json',
                   'Access-Control-Allow-Origin':'*',
                   'Accept' : 'application/json',
                   'Authorization': 'Bearer ' + this.tokenService2.getToken()
                });
                return this.http.put(this.baseUrl+_link, _params, {headers:httpHeaders})}
    }
    post_html(_link, _params){
    	 if(isPlatformBrowser(this.platformId))
        {let httpHeaders = new HttpHeaders({
                   'Access-Control-Allow-Origin':'*',
                   'Authorization': 'Bearer ' + this.tokenService2.getToken()
                });
                return this.http.post(this.baseUrl+_link, _params, {headers:httpHeaders})}
    }
    get_html(_link, _params){
    	 // if(isPlatformBrowser(this.platformId))
        // {
        	let httpHeaders = new HttpHeaders({
                   'Access-Control-Allow-Origin':'*',
                   'Authorization': 'Bearer ' + this.tokenService2.getToken()
                });
                return this.http.get(this.baseUrl+_link,  {params: _params, headers:httpHeaders})
            // }
    }
    get(_link, _params?){
        let httpHeaders = new HttpHeaders({
           'Content-Type' : 'application/json',
           'Access-Control-Allow-Origin':'*',
           'Accept' : 'application/json',
           'Authorization': 'Bearer ' + this.tokenService2.getToken()
        });
        return this.http.get(this.baseUrl+_link, {params: _params, headers:httpHeaders})
    }
	http_post(_link, _params){
		 if(isPlatformBrowser(this.platformId))
        {let httpHeaders = new HttpHeaders({
                   'Content-Type' : 'application/json',
                   'Access-Control-Allow-Origin':'*',
                   'Accept' : 'application/json',
                   'Authorization': 'Bearer ' + this.tokenService2.getToken()
                });
                return this.http.post(this.baseUrl+_link, _params, {headers:httpHeaders})}
    }
    http_get(_link, _params){
        let httpHeaders = new HttpHeaders({
           'Content-Type' : 'application/json',
           'Access-Control-Allow-Origin':'*',
           'Accept' : 'application/json',
           'Authorization': 'Bearer ' + this.tokenService2.getToken()
        });
        return this.http.get(this.baseUrl+_link, {params: _params, headers:httpHeaders})
    }
	//download data
    downloadFile(route:string, options: any):Observable<any> {
    	console.log('options below');
    	console.log(this.merge_options({responseType: 'blob'}, options));
    	let httpHeaders = new HttpHeaders({
           'Content-Type' : 'application/json',
           'Access-Control-Allow-Origin':'*',
           'Accept' : 'application/json',
           'Authorization': 'Bearer ' + this.tokenService2.getToken()
        });
        //let options = new RequestOptions({responseType: ResponseContentType.Blob });
        // console.log(this.tokenService.tokenOptions.apiBase);
        // return this.http.post(this.tokenService.tokenOptions.apiBase + route,  options, {responseType: 'blob'})
        return this.http.post(environment.baseUrl + route,  options, {responseType: 'blob', headers:httpHeaders})
            //.map(res => res.blob())
            //.catch(this.handleError)
    }

    merge_options=function(obj1, obj2) {
    var obj3 = {};
    for (var attrname in obj1) { obj3[attrname] = obj1[attrname]; }
    for (var attrname in obj2) { obj3[attrname] = obj2[attrname]; }
    return obj3;
  }
  	currentAuthData(){
  		console.log('currentAuthData')
  		// return this.tokenService.currentAuthData;
  	}
  	currentUserData(){
  		console.log('currentUserData')
  		// return this.tokenService.currentUserData;
  	}
}
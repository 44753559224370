import { Component, OnInit } from '@angular/core';
import { ModalsService} from '../../modals/modals.service';
import { NavbarService }     from '../navbar.service';
import { Subscription } from'rxjs';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import {Router, ActivatedRoute, Params} from '@angular/router';
import { SharedService } from '../../../core/shared/shared.service';
@Component({
  selector: '[app-navoptions-jobs]',
  templateUrl: './navoptions-jobs.component.html',
  styleUrls: ['./navoptions-jobs.component.scss']
})
export class NavoptionsJobsComponent implements OnInit {
  jobs_selected = false;
  sub: Subscription;
  sub2: Subscription;
  rcar:any;
  // uar:any;
  resume_ids=[];
  constructor(
    private modal: ModalsService,
    private navbar: NavbarService,
    private authService: AuthenticationService,
    private shared: SharedService,
    private router: Router
    ) { }

  ngOnInit() {
    this.get_rcar();
    // this.get_uar();
    this.sub = this.navbar.jobs_selected$.subscribe(
      res => {
        console.log(res);
        this.jobs_selected=(res !== undefined && res!== null && res !== '' && res.length>0);
        console.log(this.jobs_selected);
       }
      )
    this.sub2=this.navbar.jobs_selected$.subscribe(
      res=> {
        console.log(res);
        this.resume_ids=res;
      },
      err=> console.log(err)
      )
  }
  ngOnDestroy(){
    this.sub.unsubscribe;
    this.sub2.unsubscribe;
  }
  load_modal(_model_name, _size){
  	// this.modal.load_modal(_model_name, _size, {});
    this.modal.load_modal_by_name(_model_name, {})
  }
  get_rcar(){
    this.authService.post('/api/get_recruitment_company_access_rights_api', null).subscribe(
      res => {
          this.rcar =  res;
          console.log(res);
      }, error => {
          // console.log(error);
      }
    )
  }
  // get_uar(){
  //   this.authService.post('/api/get_user_access_rights_api', null).subscribe(
  //     res => {
  //         this.uar =  res;
  //         console.log(res);
  //     }, error => {
  //         // console.log(error);
  //     }
  //   )
  // }
  close_jobs(){
    console.log('reached here')

    // console.log(this.jobs_selected)
    this.authService.post('/api/mass_close_jobs', {job_role_ids: this.resume_ids}).subscribe(
      res=> {
        console.log(res);
        this.navbar.set_notification('Success','Done, Job Roles Closed', 'This page will refresh now');
        // setTimeout(function(){ location.reload(); }, 3000);
        this.shared.refresh(this.router.url);
    },
      err=> console.log(err))
  }
  open_jobs(){
    this.authService.post('/api/mass_open_jobs', {job_role_ids: this.resume_ids}).subscribe(
      res=> {
        console.log(res);
        this.navbar.set_notification('Success','Done, Job Roles Opened', 'This page will refresh now');
        // setTimeout(function(){ location.reload(); }, 3000);
        this.shared.refresh(this.router.url);
    },
      err=> console.log(err))
  }

}

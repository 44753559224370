import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { AuthenticationService} from '../../../core/authentication/authentication.service';
import { NavbarService } from '../../../layout/header/navbar.service';
import { ModalsService } from '../../../layout/modals/modals.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { SharedService } from '../../../core/shared/shared.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
declare var $: any;
let Quill: any = null;
// import * as QuillNamespace from 'quill';
// let Quill: any = QuillNamespace;
// import ImageResize from 'quill-image-resize-module';
// Quill.register('modules/imageResize', ImageResize);
//temp fix summernote
// const origToString = Object.prototype.toString;
// Object.prototype.toString = function() {
//     'use strict';
//     if (this === null) return '[object Null]';
//     return origToString.call(this);
// };
//end temp fix
@Component({
  selector: '[app-candidate-auto-response]',
  templateUrl: './candidate-auto-response.component.html',
  styleUrls: ['./candidate-auto-response.component.scss']
})
export class CandidateAutoResponseComponent implements OnInit {
  autoResponseForm=this.fb.group({
    mark_auto_response: [''],
    subject: [''],
    content: ['']
  });
  contents: any;
  editorConfig: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: '15rem',
      minHeight: '5rem',
      placeholder: 'Enter text here...',
      translate: 'no',
      defaultParagraphSeparator: 'p',
      defaultFontName: 'Arial',
      // uploadUrl: '/api/upload_email_images',
      // uploadWithCredentials: false,
      toolbarHiddenButtons: [
        ['bold']
        ],
      customClasses: [
        {
          name: "quote",
          class: "quote",
        },
        {
          name: 'redText',
          class: 'redText'
        },
        {
          name: "titleText",
          class: "titleText",
          tag: "h1",
        },
      ]
    }
    editor_modules = {
      // toolbar: {
      //   container: [
      //     [{ 'font': [] }],
      //     [{ 'size': ['small', false, 'large', 'huge'] }],
      //     ['bold', 'italic', 'underline', 'strike'],
      //     [{ 'header': 1 }, { 'header': 2 }],
      //     [{ 'color': [] }, { 'background': [] }],
      //     [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      //     [{ 'align': [] }],
      //     ['link', 'image']
      //   ]
      // },
      // imageResize: true
    };
  constructor(
  	private authService: AuthenticationService,
  	private router: Router,
  	private route: ActivatedRoute,
    private navbar: NavbarService,
    private fb: FormBuilder,
    private shared: SharedService,
    private modal: ModalsService
  ) { 
  if (this.shared.isBrowser()) {
      Quill = require('quill');
      const ImageResize = require('quill-image-resize').default;
      Quill.register('modules/imageResize', ImageResize);
    }
    this.editor_modules = {
      toolbar: {
        container: [
          [{ 'font': [] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'align': [] }],
          ['link', 'image']
        ]
      },
      imageResize: true
    };
  }

  ngOnInit() {
  	// this.load_summernote();
  	this.load_email_contents()
  }
  mark_auto_response($ev){
  	
  }
  submitAutoResponseForm($ev, value: any) {
    console.log(value);
    $ev.preventDefault();
    if (this.autoResponseForm.valid) {
      this.authService.post('/api/mark_auto_response', this.shared.merge_options(value, {'body': this.contents})).subscribe(
  		res=> {
  			console.log(res);
  			this.navbar.set_notification('Success','Notice', res['notice']);
  			this.modal.hide_modal_by_name('auto_response')
  		},
  		error => console.log(error)
  		)
    }
  }
  // load_summernote(){
  //   $('#summernote').summernote({
  //       height: 230,
  //       dialogsInBody: true,
  //       callbacks: {
  //           onChange: (contents, $editable) => {
  //               this.contents = contents;
  //               // console.log(contents);
  //           }
  //       }
  //   });
  //   $('.note-popover').css({'display': 'none'});
  // }
  load_email_contents(){
  	console.log('reached here 1')
  	this.authService.get('/api/get_company_details_for_auto_response').subscribe(
  		res=> {
  			console.log('reached here')
        console.log(res)
  			// this.contents = res['body']
  			// $('#summernote_container').html('<div id="summernote" class="summernote"><span>'+this.contents+'</span></div>');
        // this.load_summernote();
        // $('#summernote').summernote('pasteHTML', this.contents);
        this.autoResponseForm.patchValue({
          'subject': res['subject'],
          'mark_auto_response': res['is_auto_response_integration_enabled'],
          // 'mark_auto_response': true,
          'content': res['body']
        });
  		},
  		err => {}
  		)
  }

}

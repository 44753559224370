import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient} from '@angular/common/http' 

@Component({
  selector: 'app-trends',
  templateUrl: './trends.component.html',
  styleUrls: ['./trends.component.scss']
})
export class TrendsComponent implements OnInit {
  someObject:any
  constructor(private httpClient: HttpClient) { 
    this.httpClient = httpClient}

  ngOnInit(): void {
    this.httpClient.get('assets/sam.json', { responseType: 'json' })
      .subscribe(data => this.someObject = data);
    
  }

}

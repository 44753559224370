import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router} from'@angular/router';
import { AuthenticationService} from '../../../core/authentication/authentication.service'

import { FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';

import { SharedService } from '../../../core/shared/shared.service';
import { TokenService} from '../../../core/authentication/token.service'
import { environment} from '../../../../environments/environment.prod'
import { HttpHeaders,HttpClient} from '@angular/common/http';
import { NavbarService } from '../../../layout/header/navbar.service'
@Component({
  selector: 'app-gmail-callback-add-on',
  templateUrl: './gmail-callback-add-on.component.html',
  styleUrls: ['./gmail-callback-add-on.component.scss']
})
export class GmailCallbackAddOnComponent implements OnInit {
	password='';
	set_pwd=false
	email=''
  clicked_save=false
	countries=[];
  	route_url_params='';
  	gmail_access_token=''
  	gmail_refresh_token=''
  	state=''
    channels = ['Referral from a Friend', 'Quora', 'Capterra', 'Conference', 'Google', 'Email from Tobu', 'Blogs', 'Wikipedia', 'Linkedin', 'Facebook', 'Twitter', 'App Sumo' ,'Other'];
  	valForm = new FormGroup({
    	// authentication_token: new FormControl(),
    	passwordGroup: new FormGroup({
    		password: new FormControl(null, Validators.compose([Validators.required, Validators.minLength(8)])),
    		confirmPassword: new FormControl(null, Validators.required)
    	}),
		phone:  new FormControl(null, Validators.compose([Validators.required])), 
    	first_name:  new FormControl(null, Validators.compose([Validators.required])), 
    	company_type: new FormControl('Recruitment Agency', Validators.compose([Validators.required])), 
    	last_name:  new FormControl(null, Validators.compose([Validators.required])),
    	recruitment_company_name:  new FormControl(null, Validators.compose([Validators.required])),
      	cntry: new FormControl(),
        channel: new FormControl(null, Validators.compose([Validators.required])),
    });
  constructor(
  		private route: ActivatedRoute,
      private router: Router,
      private tokenService2: TokenService,
      private authService: AuthenticationService,
      private http: HttpClient,
      private shared: SharedService,
      private navbar: NavbarService

  	) { }

  ngOnInit(): void {
  	let params=this.route.snapshot.queryParams
    this.route_url_params=localStorage.getItem("oauth_params")
    console.log(this.route_url_params)
    this.state=this.route_url_params.split('state=')[1].split('&')[0]
  	console.log(params)
  	this.authService.http_get('/api/gmail_callback_add_on', this.shared.merge_options( params, {state: this.state})).subscribe(
            res => {
                console.log(res)
                this.email=res['email']
                if(res['new_user'])
                	{
                		this.set_pwd=true; 
                		this.get_countries();
                		this.gmail_access_token=res['gmail_access_token']
                		this.gmail_refresh_token=res['gmail_refresh_token']
                	}
                else 
                	{
                    // window.location.assign('https://script.google.com/macros/d/1fqgfd88KEekdkwf8_1MHAnSAEEFqlVAm_NIjrecgyiplMi3mnXc9QJt1/usercallback')
                    localStorage.setItem('access_token', res['access_token']);
                  	localStorage.setItem('expiry', res['expiry']);
                    this.get_sync_status_stats();
                    // window.location.assign('https://tobu.cloud/oauth/authorize?'+ this.route_url_params)
                    window.location.assign(res['return_url'])
                  }
                
                
            },
            err=> console.log(err)
        )
   this.set_pwd=true
   this.get_countries();
  }
  set_pass(){
  	console.log(this.valForm.value)
  	// this.authService.http_post('/api/set_password_gmail_add_on', this.valForm.value).subscribe(
  	// 	res=> {
  	// 		console.log(res);
  	// 		//window.location.assign('https://script.google.com/macros/d/1fqgfd88KEekdkwf8_1MHAnSAEEFqlVAm_NIjrecgyiplMi3mnXc9QJt1/usercallback')
   //      this.tokenService2.saveToken(res['access_token'])
   //      this.tokenService2.saveExpiry(res['created_at'], res['expires_in'])
   //      window.location.assign('https://tobu.ai/oauth/authorize?'+ this.route_url_params)
  	// 	},
  	// 	err=> {}
  	// 	)
  }
  submitForm($ev, value: any) {
        // console.log("submitted");
        $ev.preventDefault();
        for (let c in this.valForm.controls) {
            this.valForm.controls[c].markAsTouched();
        }
        let httpHeaders = new HttpHeaders({
           'Content-Type' : 'application/json'
        });
        //if (this.valForm.valid) {
            //this._tokenService.put('/auth/invitation', {
        if(this.valForm.valid){
          this.clicked_save=true;
        var params ={}
                 params = {
                 	'email': this.email,
                'first_name': value.first_name,
                'last_name': value.last_name,
                'recruitment_company_name': value.recruitment_company_name,
                'phone': value.phone,
                'country': value.cntry,
                'password': value.passwordGroup.password,
                // 'password_confirmation': value.passwordGroup.confirmPassword,
                'oauth_params': this.route.snapshot.queryParams,
                'gmail_access_token': this.gmail_access_token,
                'gmail_refresh_token': this.gmail_refresh_token,
                'state': this.state,
                'channel': value.channel
            }
            console.log(params)
             this.http.post(environment.baseUrl + '/api/set_password_gmail_add_on', params, {headers:httpHeaders}).subscribe(
	              res => {
	                  console.log(res);
			  			//window.location.assign('https://script.google.com/macros/d/1fqgfd88KEekdkwf8_1MHAnSAEEFqlVAm_NIjrecgyiplMi3mnXc9QJt1/usercallback')
			        // this.tokenService2.saveToken(res['access_token'])
			        // this.tokenService2.saveExpiry(res['created_at'], res['expires_in'])
			        localStorage.setItem('access_token', res['access_token']);
                  	localStorage.setItem('expiry', res['expiry']);
                    this.get_sync_status_stats();
			        // window.location.assign('https://tobu.cloud/oauth/authorize?'+ this.route_url_params)
			        window.location.assign(res['return_url'])
	              }, error => {
	                  console.log(error);
	              }
	            )
          }
          else{
            console.log(this.valForm.errors)
            // this.navbar.set_notification('Warning', 'Please complete form', 'Please ensure all fields are filled')
          }
    
        //}
    }
  get_countries(){
  	let httpHeaders = new HttpHeaders({
           'Content-Type' : 'application/json'
        });
  	this.http.get(environment.baseUrl + '/api/get_countries', {headers:httpHeaders}).subscribe(
      // this.http.get(this._tokenService.tokenOptions.apiBase + '/api/get_countries', {headers:httpHeaders}).subscribe(
            res => {
              this.countries = res['countries'].map((x,i,a)=> {return {name: x['name'], country_code: x['country_code']}}).sort(function(a, b){
                  if(a.label < b.label) { return -1; }
                  if(a.label > b.label) { return 1; }
                  return 0;
              });
              console.log(this.countries)
              },
            err => console.log(err)
            )
  }
  get_sync_status_stats(){
        console.log("syncing stats on ligin")
        this.authService.get('/api/get_sync_status_stats', {}).subscribe(
            res=> {
                console.log(res);
                this.navbar.set_sync_stats(res['stats']);
            },
            err=> {console.log(err)}
            )
        }

}

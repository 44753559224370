import { Component, OnInit } from '@angular/core';
import { AuthenticationService} from '../../../core/authentication/authentication.service';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { SharedService } from '../../../core/shared/shared.service';
@Component({
  selector: 'app-gmail',
  templateUrl: './gmail.component.html',
  styleUrls: ['./gmail.component.scss']
})
export class GmailComponent implements OnInit {

  constructor(
  	private authService: AuthenticationService,
  	private router: Router,
  	private route: ActivatedRoute,
    private shared: SharedService
  ) { }

  ngOnInit() {
  	// console.log('got here');
  	// console.log(this.route.snapshot.queryParams['code'])
    var code = this.route.snapshot.queryParams['code']
    var error= this.route.snapshot.queryParams['error']
  	this.authService.post('/api/gmail_callback', {'code': code, 'error': error})
  	.subscribe(
  		res=> {
  			console.log(res);
  			// this.router.navigate(['/settings/settings/configureemail'])
        
        setTimeout(()=>{ 
          //window.location.href = '/'; 
          console.log('rerouting')
          // this.router.navigate['/settings/configureemail'];
          this.shared.refresh('/settings/configureemail');
        }, 2000);
  		},
  		err=> {
        console.log(err)
      }
  	)
  }

}

import { Component, OnInit } from '@angular/core';
import { Subscription }   from 'rxjs';
import {NavbarService} from '../../../layout/header/navbar.service'
import { ModalsService} from '../../../layout/modals/modals.service'
import {Location} from '@angular/common';
import {Router, ActivatedRoute} from '@angular/router';

import {AuthenticationService } from '../../../core/authentication/authentication.service';
import {ViewChild, Input} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from '../../../core/shared/shared.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from'@angular/forms';


@Component({
  selector: '[app-customize-column-order]',
  templateUrl: './customize-column-order.component.html',
  styleUrls: ['./customize-column-order.component.scss']
})
export class CustomizeColumnOrderComponent implements OnInit {

  excluded_fields: Array<string> = [];//['Sugar Ray Robinson', 'Muhammad Ali', 'George Foreman', 'Joe Frazier', 'Jake LaMotta', 'Joe Louis', 'Jack Dempsey', 'Rocky Marciano', 'Mike Tyson', 'Oscar De La Hoya'];
  selected_fields: Array<string> = [];
  clicked_save=false
  valForm=this.fb.group({
    // tracker_name: ['',[Validators.required]]
  });
  constructor(
    private modal: ModalsService,
    private navbar: NavbarService,
    private router:Router,
    private authService: AuthenticationService,
    private shared: SharedService,
    private route: ActivatedRoute,
    private fb: FormBuilder 
    ) { }

  ngOnInit() {
    this.authService.get('/api/get_custom_search_column_display').subscribe(
      res=>{
        console.log(res);
        this.selected_fields=res['selected_fields']
        this.excluded_fields=res['excluded_fields']
      },
      err=> console.log(err)
      )
  }
  //added for edit tracker later
  // edit_tracker(_val){
  //   console.log(_val)
  //   // this.authService.post('/api/update_custom_search_column_display', {custom_search_column_display: {_val}}).subscribe(
  //   //   res=>{
  //   //     console.log(res);
  //   //     this.selected_fields=res['selected_fields']
  //   //     this.excluded_fields=res['excluded_fields']
  //   //   },
  //   //   err=> console.log(err)
  //   //   )
  // }
  save_order(){
    this.clicked_save=true;
    this.authService.post('/api/update_custom_search_column_display', {custom_search_column_display: this.selected_fields}).subscribe(
      res=> {
        console.log(res); 
        this.clicked_save=false;
        this.modal.hide_modal_by_name('customize_column_order');
        this.navbar.set_notification('Success','Done', res['notice']);
      },
      err=> console.log(err)
    )
  }
  add_field(_field, i){
        this.selected_fields.push(_field);
        this.excluded_fields.splice(i, 1)
    }
  remove_field(_field, i){
        this.excluded_fields.push(_field);
        this.selected_fields.splice(i, 1)
    }
    reset(){
      this.clicked_save=true;
      this.authService.post('/api/update_custom_search_column_display', {custom_search_column_display: []}).subscribe(
      res=> {
        console.log(res); 
        this.clicked_save=false;
        this.modal.hide_modal_by_name('customize_column_order');
        this.navbar.set_notification('Success','Done', res['notice']);
      },
      err=> console.log(err)
    )
    }
}

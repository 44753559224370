import { Injectable }     from '@angular/core';
import {CanActivate, Router} from "@angular/router";
// import { AngularTokenService} from 'angular-token';
import {SharedService} from '../shared/shared.service'

import {AuthenticationService} from './authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
              // private authTokenService:AngularTokenService,
              private router:Router,
              private shared: SharedService,
              private authService: AuthenticationService){}

  canActivate() {
    // console.warn("auth guard activated")
    // console.log(this.authTokenService.userSignedIn());
    // if(this.authTokenService.userSignedIn()&&window.localStorage.getItem('access_token')!=null&&window.localStorage.getItem('access_token')!=''){
      console.log('canActivate')
    if(this.authService.isLoggedIn()){

      // console.warn(this.authTokenService.currentUserData)
      // console.warn("logged in")
      return true;
    }else{
      console.warn("not so logged in")
      // if (this.authTokenService.validateToken()){console.warn("Destroying session");this.force_log_out();}
      // this.router.navigate(['/account/login']);
      this.shared.refresh('/account/login');
      return false;
    }
  }
  force_log_out(){
    if(this.shared.isBrowser())
     {window.localStorage.removeItem('access-token');
          window.localStorage.removeItem('client');
          window.localStorage.removeItem('expiry');
          window.localStorage.removeItem('uid');
          window.localStorage.removeItem('tokenType');}
  }

}
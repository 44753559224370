import { Component, OnInit } from '@angular/core';
import { AuthenticationService} from '../../../core/authentication/authentication.service';
import { Params, Router, ActivatedRoute } from "@angular/router";
import { HttpHeaders,HttpClient} from '@angular/common/http';
// import { AngularTokenService } from 'angular-token';
import { environment } from '../../../../environments/environment'

import {saveAs} from "file-saver";
@Component({
  selector: 'app-dba',
  templateUrl: './dba.component.html',
  styleUrls: ['./dba.component.scss']
})
export class DbaComponent implements OnInit {

  constructor(
    	private authService: AuthenticationService,
    	private router: Router,
      private http: HttpClient,
      // private tokenService: AngularTokenService,
      private route: ActivatedRoute
	  ) { }

  ngOnInit() {
  	
      
	     this.http.get(environment.baseUrl + '/api/get_dba', {responseType: 'blob'}).subscribe(
         // this.http.get(this.tokenService.tokenOptions.apiBase + '/api/get_dba', {responseType: 'blob'}).subscribe(
	  		blob => {
	            console.log(blob)
	            saveAs(blob, 'dba.pdf');
	            this.router.navigate['/']
	        }, error => {
	              console.log(error);
	        } 
	  	)

  }

}
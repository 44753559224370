import { Component, OnInit } from '@angular/core';
import { AuthenticationService} from '../../../core/authentication/authentication.service'
import { Router, ActivatedRoute, Params} from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ModalsService } from '../../../layout/modals/modals.service'
import { Observable} from 'rxjs';
import { SharedService } from '../../../core/shared/shared.service';
import { HttpHeaders, HttpClient, HttpErrorResponse} from '@angular/common/http' ;
import { NavbarService } from '../../../layout/header/navbar.service';
import { WindowRef } from '../../../core/shared/window-ref.service';
import { PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
import { Inject } from '@angular/core';
declare var Stripe;
declare var $;
import 'rxjs/add/operator/switchMap'; 
import 'rxjs/add/operator/map';
@Component({
  selector: '[app-top-up]',
  templateUrl: './top-up.component.html',
  styleUrls: ['./top-up.component.scss']
})
export class TopUpComponent implements OnInit {
  valForm=this.fb.group({
    duration: ['monthly', Validators.required],
    pricing_plan: ['', Validators.required],
    no_of_users: ['', Validators.required],
    coupon_code: [''],
    line1: ['', Validators.required],
    line2: [''],
    city: ['', Validators.required],
    state: ['', Validators.required],
    postal_code: ['', Validators.required],
    country: ['', Validators.required]
    
  });
  currency='USD';
  pricing_location:any;
  pricing_plan_id:any;
  no_of_users:any;
  pricing_plan_options:any;
  pricing_plan_mapping:any;
  discount=0;
  duration_discount = 0;
  discounts={
  	'monthly': 0,
  	'quarterly': 0,
  	'annually': 0
  }
  pre_tax_amount=0
  tax_amount=0;
  total_amount=0
  price = 0;
  duration="monthly";
  product_id='';
  loadAPI: Promise<any>;
  discount_amount=0;
  base_amount = 0;
  current_user:any;
  active_subscription = false;
  countries =[]
  // duration: any;
  // top_up_options: any;
  users=Array.from({length: 30}, (v, k) => k+1);
  

  constructor(
  	private authService: AuthenticationService,
    private route: ActivatedRoute, 
  	private router: Router,
    private modal: ModalsService,
    private shared: SharedService,
    private fb: FormBuilder,
    private http: HttpClient,
    private navbar: NavbarService,
    private winRef: WindowRef,
    @Inject(PLATFORM_ID) private platformId: any,
    ) {
      this.loadAPI = new Promise((resolve) => {
        this.loadScript();
        resolve(true);
      });
    }

  ngOnInit() {
  	// console.log(this.users);
    // this.loadStripe();
  	this.get_region()
      //.subscribe(
  		// res => {
  			// console.log(res);
  			// this.pricing_location = res['countryCode'];
  			
  		// },
  		// err => {
  		// 	console.log(err)
  		// }
    // );
    this.authService.get('/api/load_current_user_details')
    .subscribe(
      res=> {
        // console.log(res)
        this.current_user=res['current_user_data']
        // console.log(this.current_user)
      },
      err=>{
        console.log(err)
      }
    )
    // this.route.queryParams.map(params=> {
    // 	return params
    // 	}
    // 	).switchMap(a=> {
    // 		return this.authService.post('/api/load_top_up', a)
    // 	})
    // .subscribe(res=>{
    // 	this.pricing_plan_id=res['pricing_plan_id'];
    // 	this.no_of_users=res['no_of_users'];
    // 	this.duration=res['duration'];
    // 	}
    // )
    
  }
  load_billing_address(){
    this.authService.get('/api/get_billing_address').subscribe(

      )
  }
  load_pricing_options(){
    console.log(this.currency)
  	this.authService.post('/api/load_top_up', {'currency': this.currency})
    .subscribe(
    	res=> {
    		// console.log(res);
    		this.no_of_users=res['no_of_users'].toString();
    		this.pricing_plan_id=res['pricing_plan_id'];
    		this.valForm.patchValue({
    			no_of_users: this.no_of_users,
    		})
    		this.pricing_plan_options=res['pricing_plan_options'];
        this.pricing_plan_mapping=res['pricing_plan_mapping']
    		if (this.pricing_plan_options.map((x,i,a)=>{return x['id']}).includes(this.pricing_plan_id)){
    			this.valForm.patchValue({
    				pricing_plan: this.pricing_plan_id
    			})
    		}
        this.calculate_total();
    	},
    	err=> {
    		console.log(err)
    	}
    )
  }
  get_region(){
    // return  this.http.get('https://ipapi.co/8.8.8.8/json/')
    //options here https://stackoverflow.com/questions/391979/how-to-get-clients-ip-address-using-javascript
    // $.getJSON('https://api.ipgeolocation.io/ipgeo?apiKey=', (data) => {
    //   // console.log(JSON.stringify(data, null, 2));
    //   console.log(data);
    //   this.pricing_location=data['country_code2'];
    //   this.set_currency(this.pricing_location);
    //   this.load_pricing_options();
    //   this.load_billing_address();
    //   this.check_subscription_status();
    //   // this.apply_coupon('AllTheWay');
    //   // this.currency= (data['country_code']==="IN") ? 'INR' : 'USD';
    // });
    this.authService.get('/api/get_time_zone').subscribe(
    res=>{ 
      // console.log(res)  
      // if(res['timezone_set']!="true"){
      $.getJSON("https://api.ipgeolocation.io/ipgeo?apiKey="+ res['key'], (data) => {
          // console.log(JSON.stringify(data, null, 2));
           console.log(data);
          this.pricing_location=data['country_code2'];
          this.set_currency(this.pricing_location);
          this.load_pricing_options();
          this.load_billing_address();
          this.check_subscription_status();
          // this.assign_timezone(data['time_zone']['name'])
        });
      
    }, err=> console.log(err))
    // return this.http.get('https://ip-api.com/json')
  }

  private handleError(error: HttpErrorResponse):
      Observable<any> {
        //Log error in the browser console
        console.error('observable error: ', error);
        return Observable.throw(error);
  }
  set_currency(_location){
   	  if(_location==="IN"){
   	  	this.currency = 'INR';
   	  }
   	  else{
   	  	this.currency ='USD';
   	  }
   }
   apply_coupon(_code){
   	this.authService.post('/api/apply_coupon', {coupon_code: _code})
   	.subscribe(
   		res=> {
   			// console.log(res);
   			this.discounts=res['discounts'];
   			this.navbar.set_notification('Success', 'Notice' , res['coupon_status']);
   			this.discount=this.discounts[this.valForm.controls['duration'].value];
   		},
   		err=>{
   			console.log(err);
   		}
   	)
   }
   selectDuration($ev){
   	 this.duration = $ev.target.value;
   	 this.discount=this.discounts[this.duration];
      this.calculate_total()
   }
   selectPricingPlan($ev){
      this.pricing_plan_id = parseInt($ev.target.value);
      // console.log(this.pricing_plan_id)
      // console.log(this.pricing_plan_options)
      this.calculate_total()
   }
   selectUsers($ev){
      this.no_of_users = $ev.target.value;
      this.calculate_total()
   }
   calculate_total(){
     if ((this.pricing_plan_options.map((x,i,a)=>{return x['id']}).includes(this.pricing_plan_id)) && this.no_of_users!=0)
       {  
          this.price=this.pricing_plan_mapping[this.pricing_plan_id.toString()]
          // console.log(this.price)
          // console.log(this.price);
          // console.log(this.discount)
          
          
          if(this.duration==="monthly"){
            var multiple = 1
          }
          else if(this.duration === "quarterly"){
            var multiple=3;
            this.duration_discount = 10
          }
          else if (this.duration==="annually"){
            var multiple = 12
            this.duration_discount = 20
          }
          this.base_amount= Math.round(this.price*multiple*this.no_of_users)
          
          this.pre_tax_amount= Math.round(this.base_amount*(1-this.discount/100-this.duration_discount/100))
          this.discount_amount=this.base_amount*this.discount/100
          if (this.currency==='INR'){
            this.tax_amount=Math.round(0.18*this.pre_tax_amount)
          }
          else{
            this.tax_amount=0
          }
          this.total_amount=Math.round(this.pre_tax_amount+this.tax_amount)
          // console.log(this.pre_tax_amount)
          this.product_id=this.pricing_plan_id.toString() + '_' + this.total_amount.toString() + '_' + 'Users' + '_' + this.no_of_users.toString()+'_Discount_Credits_'+this.discount_amount.toString()
        }
   }
  public loadScript() {        
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
        if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src').includes("loader")) {
            isFound = true;
        }
    }

    if (!isFound) {
        var dynamicScripts = ["https://checkout.razorpay.com/v1/checkout.js"];

        for (var i = 0; i < dynamicScripts.length; i++) {
            let node = document.createElement('script');
            node.src = dynamicScripts [i];
            node.type = 'text/javascript';
            node.async = false;
            node.charset = 'utf-8';
            document.getElementsByTagName('head')[0].appendChild(node);
        }

    }
}
//deprecated rzp
  // submitForm($ev, _val){
  //   if(this.total_amount!=0){
  //     var options = {
  //       "key": "rzp_live_Xb7jDvNTEo3174",
  //       // "key": 'rzp_test_uhe25No4n9WdIu',
  //       "amount": this.total_amount*100, 
  //       "name": "Edwave India Private Limited",
  //       "description": "Subscription Package",
  //       "image": "/assets/img/tobu/ToBu_Logo_Square.png",
  //       "currency": this.currency,
  //       "handler": (response) => {
  //           // console.log(response);
  //           this.handle_callback(response);
  //       },
  //       "prefill": {
  //           "name": this.current_user['full_name'],
  //           "email": this.current_user['email']
  //       },
  //       "theme": {
  //           "color": "#F37254"
  //       }
  //     };
  //     var rzp1 = new this.winRef.nativeWindow.Razorpay(options);
  //     rzp1.open();
  //   // }
  //   }

  // }
  // handle_callback(value){
  //   // console.log(value)
  //   var params={
  //     razorpay_payment_id: value.razorpay_payment_id,
  //     code: (new Date).toString(),
  //     total: this.pre_tax_amount,
  //     product_id: this.product_id
  //   }
  //   this.authService.post('/api/complete_order_razorpay', params)
  //   .subscribe(
  //     res=> {
  //       // console.log(res)
  //       this.navbar.set_notification('Success', 'Successfully Topped up', 'This page will refresh now')
  //       setTimeout(function(){ location.reload(); }, 3000);
  //     },
  //     err=> {
  //       console.log(err)
  //     }
  //   )
  // }
  //stripe
  loadStripe() {
     
    // if(!window.document.getElementById('stripe-script')) {
    //   var s = window.document.createElement("script");
    //   s.id = "stripe-script";
    //   s.type = "text/javascript";
    //   s.src = "https://js.stripe.com/v3";
    //   window.document.body.appendChild(s);
    // }
    this.authService.get('/api/get_countries').subscribe(
      res=>{
        console.log('countroes');
        console.log(res);
        this.countries = res['countries'];
      },
      err=> {console.log(err)})
    var stripe = Stripe('pk_test_GHJA5lM3H3CCS9ULNmJiN76V00z9TXIxQR');
    // var stripe = Stripe('pk_live_aC7N3igeiQ8fP8mVO6u4mGDL00uuSS8W3X');
    var elements = stripe.elements();
    var style = {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };
    if(!this.active_subscription)
    {
        var cardElement = elements.create("card", { style: style });
        cardElement.mount("#card-element");
        cardElement.addEventListener('change', function(event) {
          var displayError = document.getElementById('card-errors');
          if (event.error) {
            displayError.textContent = event.error.message;
          } else {
            displayError.textContent = '';
          }
        });
    
        //save card details
        var form = document.getElementById('subscription-form');
    
        form.addEventListener('submit', function(event) {
          // We don't want to let default form submission happen here,
          // which would refresh the page.
          event.preventDefault();
    
          stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
              email: this.current_user['email'],
            },
          }).then((res)=> {this.stripePaymentMethodHandler(res, this.current_user['email'])});
        }.bind(this));
    }
    // var checkoutButton = document.querySelector('#checkout-button');
    // checkoutButton.addEventListener('click', function () {
    //   stripe.redirectToCheckout({
    //     items: [{
    //       sku: 'plan_HAIRdPEzqJnXGy',
    //       quantity: 1
    //     },
    //     {sku: 'plan_HAIRCZ44x60bqR',
    //       quantity: 1
    //     },
    //     ],
    //     successUrl: 'https://www.example.com/success',
    //     cancelUrl: 'https://www.example.com/cancel'
    //   });
    // });
  }
  set_address(){
    this.authService.post('/api/set_address', 
    {
        line1: this.valForm.get('line1').value,
        line2: this.valForm.get('line2').value,
        city: this.valForm.get('city').value,
        state: this.valForm.get('state').value,
        postal_code: this.valForm.get('postal_code').value,
        country: this.valForm.get('country').value
    }).subscribe(
    res => console.log(res),
    err => console.log(err)
    )
    
    //set address on server
    //set address on strip customer
  }
  stripePaymentMethodHandler(_result, _email) {
    if (_result.error) {
      console.log(_result.error)
      // Show error in payment form
      console.log('Error in payment Form');
      this.navbar.set_notification('Error', 'Check Card details', 'Please check the card details you entererd')
    } else {
      console.log(_result)
      this.authService.post('/api/create_stripe_customer', {
        email: _email,
        payment_method: _result.paymentMethod.id,
        no_of_users: this.no_of_users,
        pricing_plan_id: this.pricing_plan_id,
        duration: this.duration,
        total_amount: this.total_amount,
        line1: this.valForm.get('line1').value,
        line2: this.valForm.get('line2').value,
        city: this.valForm.get('city').value,
        state: this.valForm.get('state').value,
        postal_code: this.valForm.get('postal_code').value,
        country: this.valForm.get('country').value

      }).subscribe(
        res=> {
          console.log(res);
          let latest_invoice = res['subscription'];
          let payment_intent = res['subscription']['latest_invoice'];

          if (payment_intent) {
            const { client_secret, status } = payment_intent;

            if (status === 'requires_action') {
              var stripe = Stripe('pk_test_GHJA5lM3H3CCS9ULNmJiN76V00z9TXIxQR');
              // var stripe = Stripe('pk_live_aC7N3igeiQ8fP8mVO6u4mGDL00uuSS8W3X');
              stripe.confirmCardPayment(client_secret).then(function(result) {
                if (result.error) {
                  console.log(result.error)
                  this.navbar.set_notification('Error', 'Error While Paying', result.error)
                  // Display error message in your UI.
                  // The card was declined (i.e. insufficient funds, card has expired, etc)
                } else {
                  // Show a success message to your customer
                  // if(payment_intent.next_action.redirect_to_url != null)
                  //   {
                  //     var iframe = $('#3d_iframe').createElement('iframe');
                  //     iframe.src = payment_intent.next_action.redirect_to_url.url;
                  //     // iframe.width = 600;
                  //     // iframe.height = 400;
                  //     document.body.appendChild(iframe);
                  //   }


                  this.navbar.set_notification('Success', 'Successfully Subscribed', 'This page will refresh now')
                  // setTimeout(function(){ location.reload(); }, 3000);
                  this.shared.refresh(this.router.url);
                }
              });
            } else {
              // No additional information was needed
              // Show a success message to your customer
            }
          }
        }
      )
      // Otherwise send paymentMethod.id to your server
      // fetch('/create_stripe_customer', {
      //   method: 'post',
      //   headers: {'Content-Type': 'application/json'},
      //   body: JSON.stringify({
      //     email: _email,
      //     payment_method: _result.paymentMethod.id
      //   }),
      // }).then(function(result) {
      //  return result.json();
      // }).then(function(customer) {
      //   // The customer has been created
      // });
    }
  }
  // pay(amount) {    
 
  //   var handler = (<any>window).StripeCheckout.configure({
  //     key: 'pk_test_GHJA5lM3H3CCS9ULNmJiN76V00z9TXIxQR',
  //     locale: 'auto',
  //     token: function (token: any) {
  //       // You can access the token ID with `token.id`.
  //       // Get the token ID to your server-side code for use.
  //       console.log(token)
  //       alert('Token Created!!');
  //     }
  //   });
 
  //   handler.open({
  //     name: 'Demo Site',
  //     description: '2 widgets',
  //     amount: amount * 100
  //   });
 
  // }
  check_subscription_status(){
    this.authService.post('/api/check_stripe_subscription', {}).subscribe(
      res=> {
        console.log(res);
        this.active_subscription = res['active_subscription']
      }, 
      err=> console.log(err))
  }
  cancel_subscription(){
    this.authService.post('/api/cancel_stripe_subscription', {}).subscribe(
      res=> {
        console.log(res);
        this.active_subscription = res['active_subscription']
      }, 
      err=> console.log(err))
  }
  select_test_product(_val){
    if(_val===1)
      {this.pricing_plan_id="99"}
    else if (_val===2)
      {this.pricing_plan_id="100"}
    console.log(this.pricing_plan_id)
  }
  on3DSComplete() {
    // Hide the 3DS UI
    $('#3d_iframe').remove();
    var stripe = Stripe('pk_test_GHJA5lM3H3CCS9ULNmJiN76V00z9TXIxQR');
    // Check the PaymentIntent
    stripe.retrievePaymentIntent('{{PAYMENT_INTENT_CLIENT_SECRET}}')
      .then(function(result) {
        if (result.error) {
          // PaymentIntent client secret was invalid
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            // Show your customer that the payment has succeeded
          } else if (result.paymentIntent.status === 'requires_payment_method') {
            // Authentication failed, prompt the customer to enter another payment method
          }
        }
      });
  }

  // window.addEventListener('message', function(ev) {
  //   if (ev.data === '3DS-authentication-complete') {
  //     on3DSComplete();
  //   }
  // }, false);
}

import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  

  constructor(
  	private authService: AuthenticationService,
  	) { }

  ngOnInit() {
  	
  }
  is_logged_in(){
  	return this.authService.isLoggedIn()
  }
  
}

import { Component, OnInit , OnDestroy} from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from'@angular/forms';
import { ModalsService } from '../../../layout/modals/modals.service';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service'
import { Subscription } from 'rxjs';
import 'rxjs/add/operator/switchMap'; 
import 'rxjs/add/operator/map';
import { CandidateService} from '../../../routes/shared/candidate/candidate.service';

@Component({
  selector: '[app-add-tags]',
  templateUrl: './add-tags.component.html',
  styleUrls: ['./add-tags.component.scss']
})
export class AddTagsComponent implements OnDestroy {
  open_positions:any;
  tag_candidates = this.fb.group({
    tags: this.fb.array([], Validators.required)
  });
  params ={};
  called_from ='';
  sub:Subscription; 
  clicked_save =false;
  select1000=0;
  clients =[]
  constructor(
  	private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private modal: ModalsService,
    private shared: SharedService,
    private navbar: NavbarService,
    private candidate: CandidateService
  	) { }
  all_tags: any ={};

  ngOnInit() {
    this.get_plo();
    this.sub = this.navbar.current_page$.subscribe(
      res => {
        this.called_from= res['name'];
          console.log(this.called_from)
      }
    );
  }
  ngOnDestroy(){
    this.sub.unsubscribe;
  }

  get_plo(){
    this.authService.get('/api/get_tag_stats').subscribe(
        res => {
            console.log(res);
         this.all_tags=res['tags']

        }, error => {
            // console.log(error);
        }
      );
  }
  onCheckChange($event, array_name){
    this.shared.onCheckChange($event, this.tag_candidates ,array_name);
    // console.log(this.transfer_candidates.controls['job_role_ids'])
    // this.indexResumeRankingForm($event, this.transfer_candidates.value);
  }
  submitTagCandidateForm($ev, value: any) {
     // console.warn($ev);
     //from candidate view
     this.clicked_save =true;
     if(this.called_from === 'analytics' || this.called_from === 'resume' || this.called_from === 'emailhistory' || this.called_from === 'comments' || this.called_from === 'activity' || this.called_from === 'interviews'){
     // this.modal.small_modal_selected$
     //  .subscribe(params=>
     //    this.params = this.shared.merge_options(params['params'], value)
     //  )
     this.params= this.shared.merge_options(this.candidate.get_params_from_url(this.router.url), value);
     console.log(this.params);
      }
      else{
     //from inbox/resumereports
     this.navbar.resumes_selected$
       .subscribe(params => 
         this.params =this.shared.merge_options({'uploaded_resume_ids': params, 'no_of_resumes_to_be_tagged': this.select1000['count'], 'tobu_url': this.router.url}, value)
       );
      }
      console.log(this.params);

        this.authService.post('/api/bulk_update_tags', this.params)
      .subscribe(
        res => {
          // console.log(res);
          this.clicked_save =false;
          this.navbar.set_notification('Success','Tagging Initiated', 'Refresh in a few seconds to see tags updated');
        },
        // err => console.log(err)
      )
     // console.warn(value);
     // this.modal.hide_modal('small');
     this.modal.hide_modal_by_name('add_tags');
      // this.navbar.resumes_selected$
      // .switchMap(params => 
      //   this.authService.post('/api/transfer_resume_to_job_role', this.shared.merge_options({'uploaded_resume_ids': params}, value)))
      // .subscribe(
      //  res => console.log(res),
      //  err => console.log(err)
      //  )
     //.subscribe(x=>console.warn(x));
     
    
    };
    create_tag(){
      this.modal.hide_modal_by_name('add_tags');
      this.modal.load_modal_by_name('create_tag', {});
    }
    manage_tags(){
      this.modal.hide_modal_by_name('add_tags');
      this.modal.load_modal_by_name('manage_tags', {});
    }

}

import { Component, OnInit , ViewChild, OnDestroy, Inject, Injectable} from '@angular/core';
// import {AngularTokenService} from 'angular-token';
import {Router} from '@angular/router';
import { NavbarService } from '../../../layout/header/navbar.service';
import { HttpHeaders, HttpClient} from '@angular/common/http' 
import { ModalsService } from '../../../layout/modals/modals.service';
import {saveAs} from "file-saver";
import { ElectronService } from 'ngx-electron';
import { Subscription } from'rxjs';
// import { FileUploader , FileUploaderOptions} from 'ng2-file-upload';
import { FileService } from '../../../core/shared/file.service';
import { AuthenticationService} from '../../../core/authentication/authentication.service';
import {environment} from '../../../../environments/environment.prod'

// const electron = (<any>window).require('electron');
// const win = (<any>window)
import { PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
let win = undefined;
const URL = environment.baseUrl+'/api/upload_resume_to_database_from_desktop';
const swal = require('sweetalert');
@Component({
  selector: 'app-download-drive',
  templateUrl: './download-drive.component.html',
  styleUrls: ['./download-drive.component.scss']
})
export class DownloadDriveComponent implements OnDestroy {
  // fs:any;
  // entries = []
  // public uploader: FileUploader = new FileUploader({ url: URL, itemAlias: 'resume_source', autoUpload:true});
  isElectron=false
  folders_to_upload = []
  sizeLimit = '';
  mime_types = []
  extensions = []
  selected_folders=[];
  computer_id ='';
  uploads_pending = [];
  uploads_processing = []
  is_backup_done = true;
  total_uploads_pending = 0;
  total_uploads_processing_pending = 0;
  is_select_folder_count_reset = false;
  linked_folders_ever=[];
  hit_tobu_drive_limit = false;
  uploading=false;
  first_folder_last_sync_time = -2209013725000
  sub: Subscription;
  sub1: Subscription;
  sub2: Subscription;
  sub3: Subscription;
  sub4: Subscription;
  sub5: Subscription;
  do_full_sync_folders=[]
  mbox_progress =0
  @ViewChild("selectFolder") selectFolder;
  @ViewChild("doNothing") doNothing;
  constructor(
  	private navbar: NavbarService,
  	// private tokenService: AngularTokenService,
	  private router: Router,
	  private http: HttpClient,
    private modal: ModalsService,
    private _electronService: ElectronService,
    private fileService: FileService,
    private authService: AuthenticationService,
  	@Inject(PLATFORM_ID) private platformId: any
        ) {
    
    if(isPlatformBrowser(platformId))
    { win = (<any>window)}
    //this.fs = (window as any).fs//this._electronService.remote.require('fs');
    // if (this._electronService.isElectronApp) {
    //         this._electronService.ipcRenderer.on('pong', (event, arg) => {
    //             console.log(
    //                 'RECEIVED RESPONSE FROM ELECTRON TO ANGULAR APP',
    //                 event,
    //                 arg
    //             );
    //         });
    //                 this._electronService.ipcRenderer.send(
    //         'ping',
    //         'wow wow wow'
    //     );
    //     }
    // this.fs = electron.remote.require('fs');
    }

  ngOnInit() {
    // this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    
  	this.navbar.set_current_page('download', {});
    if (this._electronService.isElectronApp)
    {
      this.initialize_electron();
      // this.initialize_upload();
      // this.sub = this.navbar.is_electron_initialized$.subscribe(
      //   res => {
      //     if(!res) {this.initialize_electron()}
      //   },
      // err=> console.log(err)
      // )
     
      this.isElectron=true;
      
    };
  }
  ngOnDestroy(){
    // this.sub.unsubscribe
    if(this.isElectron)
    {
      this.sub1.unsubscribe
      this.sub2.unsubscribe
      this.sub3.unsubscribe
    }
  }
  download_tobu_drive(_system){
  	// if (_system=='mac'){
  	// this.http.post(this.tokenService.tokenOptions.apiBase + '/api/download_tobu_drive',  {'console': _system}, {responseType: 'blob'}).subscribe(
  	// 	blob => {
   //          console.log(blob)
   //          saveAs(blob, 'tobu_drive');
   //      }, error => {
   //            console.log(error);
   //      } 
  	// )
    this.http.post(environment.baseUrl + '/api/download_tobu_drive',  {'console': _system}).subscribe(
      // this.http.post(this.tokenService.tokenOptions.apiBase + '/api/download_tobu_drive',  {'console': _system}).subscribe(
      res => {
        // window.open(res['url'], "_blank");
        window.location.assign(res['url']);
      },
      err=> console.log(err)
    )
  }
  download_tobu_desktop(_system){
    this.authService.http_post('/api/download_tobu_desktop',  {'console': _system}).subscribe(
      // this.http.post(this.tokenService.tokenOptions.apiBase + '/api/download_tobu_desktop',  {'console': _system}).subscribe(
      res => {
        // window.open(res['url'], "_blank");
        window.location.assign(res['url']);
      },
      err=> console.log(err)
    )
  }
  open_video_help(){
    // this.modal.load_modal_by_name('help_video', {})
    this.router.navigate(['/help-videos/tobu_drive'])
  }
  // initialize_upload(){
  //     win.api.receive("fileUpload", (data) => {
  //           console.log(`Received data from file read process`);
  //           console.log(data['file']);
  //           console.log(data['last_sync_time'].getTime())
  //           this.upload_file(data['file'], data['file_name'], data['folder_path'], data['last_sync_time'], data['computer_id'])

  //     });
  //     win.api.receive("dirread", (data) => {
  //           console.log(`Received this from dir read process`);
  //           console.log(data);
  //           data['folder_to_upload']['folder_path']
  //           for(let i=0; i< this.selected_folders.length; i++)
              
  //             { 
  //               console.log(this.selected_folders[i])
  //               if(this.selected_folders[i]['folder_path']===data['folder_to_upload']['folder_path']){
  //                   this.selected_folders[i]['files']=data['folder_to_upload']['files']
  //                   console.log(this.selected_folders[i]['files'])
  //               }
  //             }

  //       });

  //     win.api.receive("machineID", (data)=> {
  //       // console.log(data)
  //       this.computer_id=data;
  //       this.get_computer_details(this.computer_id);
  //     })
  //     // win.api.receive("selectedFolders", (data)=> {
  //     //   // console.log(data)
  //     //   this.select_folders(data);
  //     // })
  //     win.api.send("getMachineID")
  //     win.api.receive("dirnotread", (data) => {
  //           console.log(`Received this dir not read process`);
  //           console.log(data);
  //     });
      
  //   // this.fs.readdir('path to img', (err: Error, files: [string]) => {
  //   //       if (err) {
  //   //         console.error(err);
  //   //       }
  //   //       this.entries = ['../'].concat(files);
  //   //       console.log(this.entries)
  //   //     });
  //   // this.fileService.getFile('path to png')

  //  // console.log('loading files')
    
    
    
  //   //win.api.send("readfile", {file: 'path to pdf'});
  //   // win.api.receive("dirread", (data) => {
  //   //         console.log(`Received following from main process`);
  //   //         console.log(data)
  //   //     });
  //   // win.api.send("readdir", "some data");
  // }

  // get_computer_details(computer_id){
  //   this.authService.post('/api/initialize_desktop_upload', {computer_id:this.computer_id}).subscribe(
  //     res=> {
  //       console.log(res);
  //       this.extensions = res['extensions'];
  //       this.sizeLimit = res['sizeLimit']
  //       let folders = res['folders_to_upload']
  //       // this.folders_to_upload=res['folders_to_upload'];
  //       for(let i=0; i<folders.length; i++)
  //       {
  //         this.selected_folders.push(folders[i])
  //         this.get_folder_details_for_backup(folders[i]);
          
  //       }
  //     },
  //     err => console.log(err)
  //   )
  // }
  upload_resumes(){
    this.is_backup_done=false;
    this.uploading=true
    this.is_select_folder_count_reset = false;
    this.authService.post('/api/set_folders_for_desktop_upload', {computer_id:this.computer_id, folders_to_upload:this.selected_folders.map((x,i,a)=>{return x.folder_path})}).subscribe(
      res=> {
        console.log(res)
        console.log(this.selected_folders)
        win.api.send("upload_resumes", this.selected_folders);
      }, err=>console.log(err))
    
  }
  get_folder_details_for_backup(folder){
    // this.folders_to_upload= folders_to_upload//[{
            //   folder_path: 'path to home',
            //   last_sync_time: -2193370825000
            // }]
        
        win.api.send("readdir", {
          folder_to_upload: folder, 
          sizeLimit: this.sizeLimit, 
          extensions: this.extensions
        });
  }
   
    //Usage example:
    // var file = dataURLtoFile('data:text/plain;base64,aGVsbG8gd29ybGQ=','hello.txt');
    // console.log(file);

  select_folders(folder_paths){
    // let path = this.selectFolder.nativeElement.files[0].path
    // path=path.split('/')
    // path.pop();
    // let folder_path = path.join('/')
    for(let j=0;j<folder_paths.length; j++)
    // console.log(this.selectFolder.nativeElement)
    // console.log(this.selectFolder.innerHtml)
    {
      if(!this.selected_folders.map((x,i,a)=> {return x.folder_path}).includes(folder_paths[j]))
      {
        console.log('already parent folder exists')
        let already_synced=false;
        let is_active=true;
        let last_sync_time=-2209013725000
        for(let k=0; k<this.linked_folders_ever.length; k++){
          console.log(folder_paths[j]);
          console.log(this.linked_folders_ever[k]['folder_path'])
          if(folder_paths[j].includes(this.linked_folders_ever[k]['folder_path'])){
            //subfolder of a folder that has been synced
            already_synced=true;
            if(this.linked_folders_ever[k]['is_currently_selected'])//has not been unlinked
            {
              // console.log("parent folder already syncing");
              is_active=true
              this.navbar.set_notification('Warning', 'Already Added', 'A parent folder is already getting backed up')
              break;
            }
            else{
              is_active=false
              if(this.linked_folders_ever[k]['last_sync_time']>last_sync_time)
              {last_sync_time=this.linked_folders_ever[k]['last_sync_time']}
            }
          }
          else{
          //
          }
        }
        if(already_synced)
        {
          if(!is_active){
            console.log("parent folder synced in past");
            console.log(this.linked_folders_ever)
            console.log(folder_paths[j])
            this.selected_folders.push({folder_path: folder_paths[j], last_sync_time: this.linked_folders_ever['last_sync_time'], total_resumes_processed: this.linked_folders_ever['total_resumes_processed']});
            this.get_folder_details_for_backup({folder_path: folder_paths[j], last_sync_time: this.linked_folders_ever['last_sync_time'], total_resumes_processed: this.linked_folders_ever['total_resumes_processed']});
            
          }else if(already_synced && !is_active)
          {
            console.log("parent folder already syncing");
          }
        }else
        {
          this.authService.get('/api/get_is_old_customer_synced').subscribe(
            res=> 
            
          //if is old customer = last_sync_time else last_sync_time=-2209013725000
          {
            console.log(res);
            if(res['last_sync_time']!=null && res['last_sync_time']!='')
            {
              this.first_folder_last_sync_time = res['last_sync_time'];
              this.navbar.set_notification('Warning', 'Already Synced', 'This folder was synced in the past')
            }
            console.log(this.first_folder_last_sync_time)
            this.selected_folders.push({folder_path: folder_paths[j], last_sync_time: this.first_folder_last_sync_time, total_resumes_processed: this.linked_folders_ever['total_resumes_processed']});
            this.get_folder_details_for_backup({folder_path: folder_paths[j], last_sync_time: this.first_folder_last_sync_time, total_resumes_processed: this.linked_folders_ever['total_resumes_processed']});
          }
          )
        }
        //if existing subfolder of prior synced
        
//if new folder
             
      }//default to not synced
    // console.log($ev.value)
    // console.log(path)
    }
  }
  remove_folder(index, folder_path){
    // console.log(index)

    swal({
          title: 'Are you sure?',
          text: 'This will stop backing up the folder in the future',
          icon: 'warning',
          buttons: {
              cancel: {
                  text: 'No, cancel please!',
                  value: null,
                  visible: true,
                  className: "",
                  closeModal: true
              },
              confirm: {
                  text: 'Yes, stop back up!',
                  value: true,
                  visible: true,
                  className: "bg-danger",
                  closeModal: true
              }
          }
      }).then((isConfirm) => {
          if (isConfirm) {
            console.log(index)
            if (index > -1) {
              console.log("splicing")
              this.selected_folders.splice(index, 1);
              let up = this.uploads_pending;
              up[folder_path]=0
              console.log(this.selected_folders.map((x,i,a)=>{return x.folder_path}))
              this.navbar.set_uploads_pending_desktop({uploads_pending: up, after_resume_upload:true})
              this.authService.post('/api/set_folders_for_desktop_upload', {computer_id:this.computer_id, folders_to_upload:this.selected_folders.map((x,i,a)=>{return x.folder_path})}).subscribe(
                res=> {
                  console.log(res);
                }, err=>console.log(err))
            }
              
          } else {
              swal('Cancelled', 'Your resumes shall stay', 'error');
          }
      });

    
  }
  
  // upload_file(data, file_name, folder_path, last_sync_time, computer_id){
  //   var date:Date = new Date();
  //   var baseDate: Date = new Date(Date.UTC(1970, 0, 1));
  //   var timestamp = (date.getTime() - baseDate.getTime()).toString();
  //   var folder_path = folder_path;
  //   var last_sync_time = last_sync_time;
  //   console.log(last_sync_time);
  //   console.log(last_sync_time.getTime())
  //   var computer_id = computer_id
  //   var create_mass_upload_tags = this.get_tags(file_name, folder_path)
  //   const formData: FormData = new FormData();
  //   formData.append('file_body', new Blob([data]), file_name);
  //   formData.append('user_upload_timestamp', timestamp);
  //   formData.append('folder_path', folder_path);
  //   formData.append('last_sync_time', last_sync_time.getTime());
  //   formData.append('computer_id', computer_id);
  //   // form.append('file_count', this.queue.length);
  //   formData.append('tags', create_mass_upload_tags);
  //   this.authService.post('/api/upload_resume_to_database_from_desktop_electron', formData).subscribe(
  //     res=> console.log(res),
  //     err=> console.log(err)
  //   );
  // }
  // get_tags(file_path, folder_path){
  //   var file_array = file_path.split('/');
  //   var folder_array = folder_path.split('/');
  //   let primary_tag = folder_array[folder_array.length-1];
  //   let sub = file_path.replace(folder_path, '')
  //   let tags = sub.split('/')
  //   tags.pop()
  //   tags.shift()
  //   tags = tags.concat([primary_tag])
  //   return tags
  //   // return (file_array - parent_array + [parent_folder])
  // }
  select_new(){
    win.api.send("selectFolder")
    this.doNothing.nativeElement.click();
  }
  select_archive(){
    win.api.send("selectArchive")
    this.doNothing.nativeElement.click();
  }
  initialize_electron(){
    console.log('initialize_electron');
    this.has_hit_tobu_drive_limit();
    win.api.receive("selectedFolders", (data)=> {
        console.log(data)
        this.select_folders(data);

        this.doNothing.nativeElement.click();
      })
    win.api.receive("progressUpdate", (data)=> {
        console.log(data)
        // this.select_folders(data);
        this.mbox_progress = data
        this.doNothing.nativeElement.click();
      })
    // this.navbar.set_is_electron_initialized(true)
    this.sub1=this.navbar.selected_folders_desktop$.subscribe(
        res=> {
          this.selected_folders=res['folders'];
          this.extensions=res['extensions'];
          this.sizeLimit=res['sizeLimit'];
          this.computer_id = res['computer_id']
          console.log(res);
          this.doNothing?.nativeElement.click();
        },
        err=> console.log(err)
        )
    this.sub2=this.navbar.uploads_pending_desktop$.subscribe(
        res=> {
          console.log(res)
          this.uploads_pending=res['uploads_pending'];
          var keys = Object.keys(this.uploads_pending);
          console.log(keys)
          var values = keys.map(function(v) { return res['uploads_pending'][v]; });
          console.log(values)
          this.total_uploads_pending = values.reduce((a, b) => a + b, 0);
          console.log(this.total_uploads_pending)
          this.is_backup_done=(this.total_uploads_pending==0)
          //reset only when no uploads are pending and uploads pending is updated after resume upload- not after reading directory(which happens on folder reset)
          if(this.is_backup_done && !this.is_select_folder_count_reset && res['after_resume_upload']){
            this.reset_selected_folders()
          }
          console.log(this.is_backup_done)
          console.log(res);
          this.doNothing?.nativeElement.click();
        },
        err=> console.log(err)
        )
    this.sub3=this.navbar.linked_folders_ever_desktop$.subscribe(
        res=> {
          this.linked_folders_ever=res['linked_folders_ever'];
          
          console.log(res);
          this.doNothing?.nativeElement.click();
        },
        err=> console.log(err)
        )
    this.sub4=this.navbar.uploads_processing_desktop$.subscribe(
        res=> {
          console.log('uploads processing')
          console.log(res)
          this.uploads_processing=res['uploads_processing'];
          // var keys = Object.keys(this.uploads_processing);
          // console.log(keys)
          // var values = keys.map(function(v) { return res['uploads_processing'][v]; });
          // console.log(values)
          // this.total_uploads_processing_pending = values.reduce((a, b) => a + b, 0);
          // console.log(this.total_uploads_processing_pending)
          this.doNothing?.nativeElement.click();
        },
        err=> console.log(err)
        )
     this.sub5=this.navbar.do_full_sync_folders_desktop$.subscribe(
        res=> {
          this.do_full_sync_folders=res['folders'];
          console.log(res);
          this.doNothing?.nativeElement.click();
        },
        err=> console.log(err)
        )

    // win.api.receive("dirread", (data) => {
    //         console.log(`Received this from dir read process`);
    //         console.log(data);
    //         data['folder_to_upload']['folder_path']
    //         for(let i=0; i< this.selected_folders.length; i++)
              
    //           { 
    //             console.log(this.selected_folders[i])
    //             if(this.selected_folders[i]['folder_path']===data['folder_to_upload']['folder_path']){
    //                 this.selected_folders[i]['files']=data['folder_to_upload']['files']
    //                 console.log(this.selected_folders[i]['files'])
    //             }
    //           }

    //     });
  }
  reset_selected_folders(){
    this.is_select_folder_count_reset=true;
    setTimeout(()=>{win.api.send("getMachineID");}, 3000)
    
  }
  do_nothing(){
    setTimeout(()=>{console.log("nothing")}, 1000)
    
  }
  has_hit_tobu_drive_limit(){
    this.authService.get('/api/has_hit_tobu_drive_limit').subscribe(
      res=>{
        console.log(res);
        this.hit_tobu_drive_limit=res['has_hit_limit']
      },
      err=>
      console.log(err))
  }
  clean_time(time){
    if(time==undefined || time <0){return "N/A"}
    else{return new Date(time).toLocaleString();}
  }
  refresh_electron(){
    if(this.isElectron){
            win.api.send("refreshApp")
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthenticationService} from '../../../core/authentication/authentication.service';
import { Params, Router, ActivatedRoute } from "@angular/router";
import { HttpHeaders,HttpClient} from '@angular/common/http';
// import { AngularTokenService } from 'angular-token';
import {environment} from '../../../../environments/environment.prod'

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {
  id ='';
    constructor(
    	private authService: AuthenticationService,
    	private router: Router,
      private http: HttpClient,
      // private tokenService: AngularTokenService,
      private route: ActivatedRoute
	  ) { }

  ngOnInit() {
  	this.id= this.route.snapshot.params['id']
  	
      this.http.post(environment.baseUrl +'/api/unsubscribe_to_candidate_mails', {id: this.id}).subscribe(
        // this.http.post(this.tokenService.tokenOptions.apiBase +'/api/unsubscribe_to_candidate_mails', {id: this.id}).subscribe(
  		res=> console.log(res), 
  		err => console.log(err)
  		)
  }

}

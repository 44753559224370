import { Component, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from'@angular/forms';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service';
import { ModalsService} from '../../../layout/modals/modals.service';
import { Subscription } from 'rxjs';
import { CandidateService} from '../../../routes/shared/candidate/candidate.service';

import 'rxjs/add/operator/switchMap'; 
import 'rxjs/add/operator/map';

@Component({
  selector: '[app-transfer-to-job]',
  templateUrl: './transfer-to-job.component.html',
  styleUrls: ['./transfer-to-job.component.scss']
})
export class TransferToJobComponent implements OnDestroy {
  plo:any;
  open_positions:any;
  transfer_candidates = this.fb.group({
    job_role_ids: this.fb.array([], Validators.required)
  });
  params ={};
  called_from ='';
  sub:Subscription; 
  clicked_save =false;
  select1000=0;
  clients =[]
  // transfer_from_tobu_selected:any;
  constructor(
  	private authService: AuthenticationService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private fb: FormBuilder,
    private shared: SharedService,
    private navbar: NavbarService,
    private modal: ModalsService,
    private candidate: CandidateService
  	) { }

  ngOnInit() {
    this.get_plo();
    this.sub = this.navbar.current_page$.subscribe(
      res => {
        this.called_from= res['name'];
          console.log(this.called_from)
      }
    );
    this.navbar.select1000$.subscribe(
      res => {
        // console.log(res);
        this.select1000=res;
        // this.transfer_from_tobu_selected = res['selected']
    });
  }
  ngOnDestroy(){
    this.sub.unsubscribe;
  }

  get_plo(){
    this.authService.post('/api/transfer_candidates_modal', null).subscribe(
      res => {
        console.log(res)
        this.plo=res;
        this.open_positions = res['grouped_open_positions'];
        this.clients = res['clients']
      },
      // error => {console.log(error)}
    )
  }
  onCheckChange($event, array_name){
    this.shared.onCheckChange($event, this.transfer_candidates ,array_name);
    // console.log(this.transfer_candidates.controls['job_role_ids'])
    // this.indexResumeRankingForm($event, this.transfer_candidates.value);
  }
  submitTransferCandidateForm($ev, value: any) {
     // console.warn($ev);
     //from candidate view
     this.clicked_save =true;
     if(this.called_from === 'analytics' || this.called_from === 'resume' || this.called_from === 'emailhistory' || this.called_from === 'comments' || this.called_from === 'activity' || this.called_from === 'interviews'){
     // this.modal.small_modal_selected$
     //  .subscribe(params=>
     //    this.params = this.shared.merge_options(params['params'], value)
     //  )
     this.params= this.shared.merge_options(this.candidate.get_params_from_url(this.router.url), value);
     // console.log(this.params);
      }
      else{
     //from inbox/resumereports
     this.navbar.resumes_selected$
       .subscribe(params => 
         this.params =this.shared.merge_options({'uploaded_resume_ids': params, 'no_of_resumes_to_be_transferred': this.select1000['count'], 'tobu_url': this.router.url}, value)
       );
      }
      // console.log(this.params);

        this.authService.post('/api/transfer_resume_to_job_role', this.params)
      .subscribe(
        res => {
          // console.log(res);
          this.clicked_save =false;
          this.navbar.set_notification('Success','Transfer Initiated', 'The transfer has been started, you will see a notification(bell icon - top right) when completed');
        },
        // err => console.log(err)
      )
     // console.warn(value);
     // this.modal.hide_modal('small');
     this.modal.hide_modal_by_name('transfer_to_job');
      // this.navbar.resumes_selected$
      // .switchMap(params => 
      //   this.authService.post('/api/transfer_resume_to_job_role', this.shared.merge_options({'uploaded_resume_ids': params}, value)))
      // .subscribe(
      //  res => console.log(res),
      //  err => console.log(err)
      //  )
     //.subscribe(x=>console.warn(x));
     
    
    };

}

import { Component, OnInit, OnDestroy} from '@angular/core';
import { AuthenticationService} from '../../../core/authentication/authentication.service'
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService }     from '../../../layout/header/navbar.service';
import { ModalsService } from '../../../layout/modals/modals.service';
// import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
//import { Job_role} from './model/job_role'
import { Params, Router, ActivatedRoute , NavigationEnd, NavigationStart} from "@angular/router";
//import { ListviewComponent } from '../resumereports/listview/listview.component';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CandidateService } from '../../shared/candidate/candidate.service';
// import {Page} from "tns-core-modules/ui/page";
import { HttpHeaders,HttpClient} from '@angular/common/http';
// import { AngularTokenService } from 'angular-token';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { SEOService} from '../../../core/shared/seo.service'
import { environment} from '../../../../environments/environment.prod'


@Component({
  selector: 'app-listview',
  templateUrl: './listview.component.html',
  styleUrls: ['./listview.component.scss']
})
export class ListviewComponent implements OnInit {
	blogs: any;
  constructor(
  	public authService: AuthenticationService, 
    private fb: FormBuilder, 
    private router: Router, 
    private route: ActivatedRoute, 
    private shared: SharedService,
    private navbar: NavbarService,
    private modal: ModalsService,
    private candidate: CandidateService,
    private http: HttpClient,
    // private tokenService: AngularTokenService,
    public sanitizer: DomSanitizer,
    private seo: SEOService
    // private page: Page
    ) { }

  ngOnInit() {
  	this.get_blogs();
  }
  get_blogs(){
  	// this.http.get(this.tokenService.tokenOptions.apiBase +'/api/get_blogs').subscribe(
    this.http.get(environment.baseUrl +'/api/get_blogs').subscribe(
  		res=> {
  			console.log(res);
  			this.blogs = res['blogs']
  		},
  		err=>  console.log(err)

    )
  }

}

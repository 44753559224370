import { Component, OnDestroy, ViewChild} from '@angular/core';
import { AuthenticationService} from '../../../core/authentication/authentication.service'
import { Router, ActivatedRoute, Params} from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ModalsService } from '../../../layout/modals/modals.service'
import { Observable, Subscription} from 'rxjs';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service';
// import { ToasterService, ToasterConfig } from 'angular2-toaster/angular2-toaster';
import { CandidateService } from '../../../routes/shared/candidate/candidate.service';
import { CustomValidators } from 'ng2-validation';
import { AngularEditorConfig } from '@kolkov/angular-editor';
// import * as QuillNamespace from 'quill';
// let Quill: any = QuillNamespace;
// import ImageResize from 'quill-image-resize-module';
// Quill.register('modules/imageResize', ImageResize);



declare var $: any;
let Quill: any = null;
//temp fix summernote
// const origToString = Object.prototype.toString;
// Object.prototype.toString = function() {
//     'use strict';
//     if (this === null) return '[object Null]';
//     return origToString.call(this);
// };
//end temp fix

@Component({
  selector: '[app-email]',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnDestroy {
 
  own_email_accounts = [];
  email_templates: any;
  signatures: any;
  to_emails ='';
  showCC = false;
  showBCC = false;
  contents: string='';
  params ={};
  called_from ='';
  message_for_customer={};
  sub: Subscription;
  sub1:Subscription;
  sub2:Subscription;
  send_email_from_tobu_selected = false;
  //to_email_list = ;
  email_candidate_form= this.fb.group({
	// resume_report_id: [this.route.snapshot.params['candidate_id']],
	// job_role_id: [this.route.parent.snapshot.params['id']],
	// email_candidates_to_email: [],
	email_candidates_from_email: [''],
	email_candidate_from_email_id: [''],
	email_candidates_cc: ['',CustomValidators.email],
	email_candidates_bcc: ['', CustomValidators.email],
	email_candidates_subject: [''],
  email_content: [''],
  min_no_of_days_of_last_contact: ['']
  // email_candidates_uploaded_resume_ids: ['']
  });
  clicked_send_email = false;
  // toasterConfig: any;
  // toasterconfig: ToasterConfig = new ToasterConfig({
  //       positionClass: 'toast-bottom-right',
  //       showCloseButton: true
  //   });
  select1000={'selected': false, 'count':0};
  candidate_mail_count = 0;
  showDetails = {
    dmarc: false,
    mx: false,
    spf: false
  };
  editor_modules = {
      toolbar: {
        container: [
          [{ 'font': [] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'align': [] }],
          ['link', 'image']
        ]
      },
      imageResize: true
    };
    selected_ea_id:any;
    ea_is_personal_email_id = 'false';
    ea_no_of_bulk_emails_left_for_the_day = 0;
    can_send_bulk_emails = false;
    sending_solo_email = true
    // own_email_accounts = [
    //   { email_user_name: 'User 1' , email_account_id: 1, is_personal_email_id: 'true'},
    //   { email_user_name: 'User 2' , email_account_id: 2, is_personal_email_id: 'false'},
    //   { email_user_name: 'User 3', email_account_id: 3 , is_personal_email_id: 'true'}
    // ];
  constructor(
  	private authService: AuthenticationService,
    private route: ActivatedRoute, 
  	private router: Router,
    private modal: ModalsService,
    private shared: SharedService,
    private fb: FormBuilder,
    private navbar: NavbarService,
    // public toasterService: ToasterService,
    private candidate: CandidateService,
    ) { 
    if (this.shared.isBrowser()) {
      Quill = require('quill');
      const ImageResize = require('quill-image-resize').default;
      Quill.register('modules/imageResize', ImageResize);
    }
    this.editor_modules = {
      toolbar: {
        container: [
          [{ 'font': [] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'align': [] }],
          ['link', 'image']
        ]
      },
      imageResize: true
    };
  }

  ngOnInit() {
  	this.get_plo();
  	// this.load_summernote();
    // this.calculate_params();
    this.sub = this.navbar.current_page$.subscribe(
      res => {
        this.called_from= res['name'];
        this.calculate_params();
        // console.log(this.params)
        // console.log(this.called_from);
        // this.populate_to_emails();
      }
    );
    this.navbar.select1000$.subscribe(
      res => {
        // console.log(res);
        this.select1000=res;
        this.send_email_from_tobu_selected = res['selected']
        
        if(this.select1000?.count>0){
          this.candidate_mail_count= Math.min(this.select1000?.count, this.ea_no_of_bulk_emails_left_for_the_day)
        }
    });
    
    
  }

  ngOnDestroy(){
    this.sub.unsubscribe;
    this.sub1.unsubscribe
  }
  get_plo(){
  	this.authService.get('/api/get_email_account_details').subscribe(

  		res => {
        console.log(res);
  			this.own_email_accounts = res['own_email_accounts'];
        let i=res['own_email_accounts'].length-1;
        this.email_candidate_form.patchValue({
          'email_candidate_from_email_id': res['own_email_accounts'][i]['email_account_id']
        })
        // this.email_candidate_form.patchValue({
        //   'email_candidate_from_email_id': 1
        // })
  			this.email_templates = res['email_templates'];
  			this.signatures = res['signatures'];
        this.can_send_bulk_emails = res['can_user_send_bulk_emails']
        this.ea_picked()
  		},
  		error =>{
  			// console.log(error)
  		}
  	)

    this.authService.get('/api/is_tobu_legit_email_sender_for_domain').subscribe(

      res => {
        console.log(res);
        this.message_for_customer = res['message_for_customer']
      },
      error =>{
        // console.log(error)
      }
    )
  }
  submitEmailCandidateForm($ev, value: any) {
    $ev.preventDefault();
    //this.toasterService.pop('Success', 'Email Sent', 'Email Successfully sent');
    
    this.clicked_send_email=true;
    value = this.shared.merge_options(value, {'email_candidates_message': this.contents, 'mail_sent_from_tobu': this.send_email_from_tobu_selected, 'tobu_url': this.router.url, 'email_blast': this.select1000['selected'], 'no_of_people_to_be_mailed': this.select1000['count'], 'recipient_type': 'Database Candidate'})
    // if(this.called_from === 'analytics' || this.called_from === 'resumes' || this.called_from === 'emailhistory' || this.called_from === 'comments' || this.called_from === 'activity'){
    //  this.modal.medium_modal_selected$
    //   .subscribe(params=>
    this.params = this.shared.merge_options(this.params, value)
    console.log(this.params)
     //  )
     //  }
     //  else{
     // //from inbox/resumereports
     // this.navbar.resumes_selected$
     //   .subscribe(params => 
     //     this.params =this.shared.merge_options({'uploaded_resume_ids': params}, value)
     //   );
     //  }
      // console.log(this.params);
    // console.log(value.email_candidates_to_email);
    // console.log(value.email_account_user_name);
    // console.log(value.email_candidates_subject)
    // console.log(this.contents);
    this.authService.post(
      '/api/email_candidates', this.params

      ).subscribe(
      res => {
          // this.modal.hide_modal('medium');
        if(res['upgrade']==='Premium'){this.modal.hide_modal_by_name('email');setTimeout(()=>{ this.modal.load_modal_by_name('upgrade_plan', {}) }, 500)}
        else{
          this.modal.hide_modal_by_name('email');
          // console.log(res);
          this.navbar.set_notification('Success','Notice', res['notice']);
          this.clicked_send_email = false;
        }
      }, error => {
          // console.log(error);
      }
    )
  }
  ea_picked(): void {
    this.selected_ea_id = this.email_candidate_form.controls['email_candidate_from_email_id'].value;
    const selectedAccount = this.own_email_accounts.find(account => account.email_account_id === this.selected_ea_id);
    if (selectedAccount) {
      this.ea_is_personal_email_id = selectedAccount.is_personal_email_id;
      this.ea_no_of_bulk_emails_left_for_the_day = selectedAccount.no_of_bulk_emails_left_for_the_day;

        if(this.select1000?.count>0){
          this.candidate_mail_count= Math.min(this.select1000?.count, this.ea_no_of_bulk_emails_left_for_the_day)
        }
    }
    console.log(this.selected_ea_id);
    console.log(this.ea_is_personal_email_id);
    console.log(this.ea_no_of_bulk_emails_left_for_the_day)
  }
  // load_summernote(){
  //   $('#summernote').summernote({
  //       height: 230,
  //       dialogsInBody: true,
  //       callbacks: {
  //           onChange: (contents, $editable) => {
  //               this.contents = contents;
  //               // console.log(contents);
  //           }
  //       }
  //   });
  //   $('.note-popover').css({'display': 'none'});
  // }
  calculate_params(){
    
    if(this.called_from === 'analytics' || this.called_from === 'resume' || this.called_from === 'emailhistory' || this.called_from === 'comments' || this.called_from === 'activity' || this.called_from === 'interviews'){
     // this.modal.medium_modal_selected$
     //  .subscribe(params=>
     //    this.params = params['params']
     //  )
         this.params = this.candidate.get_params_from_url(this.router.url);
         console.log(this.params)
         this.populate_to_emails();
      }
      else{
     //from inbox/resumereports
     this.sub2 = this.navbar.resumes_selected$
       .subscribe(params => 
         {
           console.log(params)

           this.params ={'uploaded_resume_ids': params};
           this.populate_to_emails();
         }
       );
      }

  }
  populate_to_emails(){
    this.sub1 = this.authService.post('/api/get_to_emails', this.params).subscribe(
      res=> {
        console.log(res)
        // this.to_emails = res['to_emails'];
        if(res['to_emails']!=undefined)
        {
          this.to_emails = res['to_emails'].length>1 ? res['to_emails'].length.toString()+" candidates selected will be mailed individually" : res['to_emails'][0]
          this.sending_solo_email = (res['to_emails'].length === 1)
          if(res['to_emails'].length > 1) {this.send_email_from_tobu_selected = true}
        }
      },
      // err => console.log(err)
    )
  }
  auto_fill_template(_val){
    // console.log(_val)
    this.authService.post('/api/load_email_template', this.shared.merge_options(_val, {'email_candidate_template_id': _val}))
    .subscribe(
      res => {
        console.log(res);
        // this.contents = res['content'];
        // $('#summernote').summernote('reset');
        // $('#summernote').summernote('enable');
        // $('#summernote_container').html('<div id="summernote" class="summernote"><span>'+this.contents+'</span></div>');
        // this.load_summernote();
        // $('#summernote').summernote('pasteHTML', this.contents);
        this.email_candidate_form.patchValue({
          'email_candidates_subject': res['subject'],
          'email_content': res['content']
        });
  
      },
      // err => console.log(err)
    )
  }

  send_email_from_tobu($ev){

    this.send_email_from_tobu_selected = $ev.target.checked;

  }
  formatFeedback(feedback: string): string {
    return feedback.replace(/\n/g, '<br>');
  }
  toggleDetails(type: string) {
    this.showDetails[type] = !this.showDetails[type];
  }
  get shouldShowCard(): boolean {
    return this.send_email_from_tobu_selected && !this.ea_is_personal_email_id &&(
      !this.message_for_customer['is_dmarc_set'] ||
      !this.message_for_customer['is_mx_set'] ||
      !this.message_for_customer['is_spf_set']
    );
  }
  get shouldShowErrorCard(): boolean {
    console.log (this.ea_is_personal_email_id)
    console.log (this.send_email_from_tobu_selected)
    return this.send_email_from_tobu_selected && (this.ea_is_personal_email_id==='true')
  }
  name = 'Angular 6';
  htmlContent = '';

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    // uploadUrl: '/api/upload_email_images',
    // uploadWithCredentials: false,
    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  }

}

import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service'
import { Subscription, Observable } from 'rxjs';
import { ModalsService } from '../../../layout/modals/modals.service';
import { ActivatedRoute, Router, Params} from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { FormBuilder, FormGroup, FormArray, FormControl} from'@angular/forms';
import { saveAs } from 'file-saver';
import 'rxjs/add/operator/switchMap'; 
import 'rxjs/add/operator/map';

@Component({
  selector: '[app-notification-alert]',
  templateUrl: './notification-alert.component.html',
  styleUrls: ['./notification-alert.component.scss']
})
export class NotificationAlertComponent implements OnInit {
  // import_report_id = '';
  // partnership_invite_id ='';
  // resume_report_id ='';
  // shared_resume ='';
  // updated_tracking='';
  // upload_report_id='';
	alert_type='';
	// upload_report='';
	failed_uploads=null;
	pluralized_string='';
	successful_upload_count='';
	total_file_count='';
	job_role_title='';
	job_role_id='';
  failed_imports=null;
  successful_import_count='';
	tags={};
  notification_id=''
  no_of_new_candidates=0
  no_of_new_versions=0
  no_of_old_versions=0
  // mass_download_resume_reports_task_id='';

  constructor(
  	private authService: AuthenticationService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private fb: FormBuilder,
    private navbar: NavbarService,
    private modal: ModalsService,
    private shared: SharedService
    ) { }

  ngOnInit() {
  	this.modal.notification_alert_params_selected$.map(
  		params=> {
  			// console.log(params);
  			return params['params']
  				
  	// 		this.import_report_id = params['import_report_id']
			// this.partnership_invite_id =params['partnership_invite_id']
			// this.resume_report_id =params['resume_report_id ']
			// this.shared_resume =params['shared_resume']
			// this.updated_tracking=params['updated_tracking']
			// this.upload_report_id=params['upload_report_id']
  		}
  	).switchMap(
  		res => {return this.authService.post('/api/load_notification', res)}
  	).subscribe(
  		res => {
  			console.log(res);
  			this.alert_type = res['alert_type'];
       	this.failed_uploads = res['failed_uploads'];
       	this.pluralized_string = res['pluralized_string'];
       	this.successful_upload_count = res['successful_upload_count'];
       	this.total_file_count = res['total_file_count'];
       	this.job_role_title = res['job_role_title']==='' ?  'Database' : res['job_role_title'];
       	this.job_role_id = res['job_role_id'];
       	this.tags = res['tags'];
        this.failed_imports = res['failed_imports'];
        this.notification_id = res['notification_id']//for download tracker
        this.no_of_new_candidates = res['no_of_new_candidates']
        this.no_of_new_versions=res['no_of_new_versions']
        this.no_of_old_versions=res['no_of_old_versions']
        // this.mass_download_resume_reports_task_id = res['mass_download_resume_reports_task_id']
        this.successful_import_count = res['successful_import_count']
        if(this.alert_type ==='resume_report'){
          this.router.navigate(['/jobs/candidates', this.job_role_id, 'listview']);
          this.modal.hide_modal_by_name('notification_alert');
          this.modal.hide_modal_by_name('notifications');
        }
  		},
  		// err => console.log(err)
  	)
  	
  }
  download_tracker(_notification_id){
    this.authService.post('/api/download_tracker_from_notification', {notification_id: _notification_id}).subscribe(
      res => {
        //saveAs(res, 'tracker.csv');
        window.open(res['link'])
        // window.location.assign(res['link']);
      },
      err=> console.log(err)
      )
  }
  mark_as_resume(_failed_upload_id){

    this.authService.post('/api/mark_failed_upload_as_resume', {failed_upload_id: _failed_upload_id}).subscribe(
      res => {
        console.log(res);
        this.navbar.set_notification('Success', 'Success', res['notice'])
        this.failed_uploads = this.failed_uploads.filter(function( obj ) {
          return obj.id !== _failed_upload_id;
        });
      },
      err=> console.log(err)
      )
  }


}

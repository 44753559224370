import { Component, OnInit } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse} from '@angular/common/http' ;
// import * as $ from 'jquery'
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import {Observable} from 'rxjs'
declare var $;
// import {AngularTokenService} from 'angular-token';
import { AuthenticationService} from '../../../core/authentication/authentication.service';
import { environment} from '../../../../environments/environment.prod'
import { NavbarService } from '../../../layout/header/navbar.service'
@Component({
  selector: '[app-pricing]',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  isLoggedIn = false
  base_standard = 0;
  base_premium = 0;
  base_platinum = 0;
  basic_package_price =0;
  standard_package_price =0;
  premium_package_price =0;
  platinum_package_price =0;
  premium_plan_currency='';
  standard_plan_currency ='';
  platinum_plan_currency ='';
  pricing_location ='US';
  duration = 'monthly';
  currency ='US$';
  standard_package_price_strike = 0;
  premium_package_price_strike =0;
  platinum_package_price_strike =0;
  user_email_count=1;
  resume_db_scaling_factor=1
  price_mapping = {};
  db_size='5k';
  plan_requested=false;
  estimated_one_time_cost=0
  gmail_estimate=0
  oneTimeExtractForm=this.fb.group({
    how_old: [''],
    how_big: ['']
  })

  // form = new FormGroup({
  //   duration:  new FormControl('Monthly', Validators.compose([Validators.required])), 
  //   resume_db_scaling_factor:  new FormControl(1, Validators.compose([Validators.required])), 
  //   currency:  new FormControl('US$', Validators.compose([Validators.required])), 
  //   package_type:  new FormControl('Standard', Validators.compose([Validators.required]))
  // });
  constructor(
	private http: HttpClient,
  private authService: AuthenticationService,
  // private tokenService: AngularTokenService, 
  private navbar: NavbarService,
  private fb: FormBuilder
  	) { }

  ngOnInit() {
    this.isLoggedIn = this.authService.isLoggedIn();
    this.oneTimeExtractForm.patchValue({
      how_big: '5k',
      how_old: 1
    })

    this.http.get(environment.baseUrl+'/api/get_pricing_mappings').subscribe(
        res=> {
          this.price_mapping=res['pricing_mapping'];
          this.base_standard = res['base_prices']['base_standard']
          this.base_premium = res['base_prices']['base_premium']
          this.base_platinum = res['base_prices']['base_platinum']
          console.log(res)
          console.log(this.base_standard)
          this.update_pricing();
          this.estimateOneTime()
          //console.log(this.getPrice('50k', this.price_mapping))
        })
  	this.get_region()
    this.show_pricing()
    // .subscribe(
  		// res => {
  		// 	console.log(res);
  		// 	this.pricing_location = res['countryCode'];
  		// 	this.show_pricing();
  		// 	this.set_currency(this.pricing_location);
  		// },
  		// err => console.log(err)
  		// );
  }
  get_region(){
    // return  this.http.get('https://ipapi.co/8.8.8.8/json/')
    // return this.http.get('http://ip-api.com/json')
    // return  this.http.get('https://ipapi.co/8.8.8.8/json/')
    //options here https://stackoverflow.com/questions/391979/how-to-get-clients-ip-address-using-javascript
    this.authService.get('/api/get_time_zone').subscribe(
    res=>{ 
      console.log(res)  
      // if(res['timezone_set']!="true"){
      $.getJSON("https://api.ipgeolocation.io/ipgeo?apiKey="+ res['key'], (data) => {
          // console.log(JSON.stringify(data, null, 2));
           console.log(data);
           this.pricing_location=data['country_code2'];
            this.set_currency(this.pricing_location);
            // this.show_pricing();
            this.update_pricing();
            this.estimateOneTime()
          // this.assign_timezone(data['time_zone']['name'])
        });
      
    }, err=> console.log(err))
    // $.getJSON('https://api.ipgeolocation.io/ipgeo?apiKey=', (data) => {
    //   // console.log(JSON.stringify(data, null, 2));
    //   console.log(data);
    //   this.pricing_location=data['country_code2'];
    //   this.set_currency(this.pricing_location);
    //   this.show_pricing();
    //   // this.currency= (data['country_code']==="IN") ? 'INR' : 'USD';
    // });
    // return this.http.get('https://ip-api.com/json')
  }

  private handleError(error: HttpErrorResponse):
      Observable<any> {
        //Log error in the browser console
        console.error('observable error: ', error);
        return Observable.throw(error);
  }

  show_pricing(){

  	  var _location = this.pricing_location;
  	  var _duration = this.duration
      // this.form.patchValue({
      //   'company_type': 'Recruitment Agency'
      // })
      
      // if(_location=="IN"){
      //   if(_duration=="annual"){
      //   this.basic_package_price = 0
      //   this.standard_package_price =  this.base_standard*80*0.5 ;
      //   this.standard_package_price_strike  = this.base_standard*80*0.85
      //   this.premium_package_price =  this.base_premium*80*0.5 ;
      //   this.premium_package_price_strike = this.base_premium*80*0.85;
      //   this.platinum_package_price =  this.base_platinum*80*0.5 ;
      //   this.platinum_package_price_strike = this.base_platinum*80*0.85;
      //   }
      //   else if(_duration=="quarterly"){
      //   this.basic_package_price = 0
      //   this.standard_package_price =  this.base_standard*60*0.55 ;
      //   this.standard_package_price_strike  = this.base_standard*80*0.9
      //   this.premium_package_price =  this.base_premium*80*0.55 ;
      //   this.premium_package_price_strike = this.base_premium*80*0.9;
      //   this.platinum_package_price =  this.base_platinum*80*0.55 ;
      //   this.premium_package_price_strike = this.base_platinum*80*0.9;
      //   }
      //   else if(_duration=="monthly"){
      //   this.basic_package_price = 0
      //   this.standard_package_price =  this.base_standard*80*0.65 ;
      //   this.standard_package_price_strike  = this.base_standard*80
      //   this.premium_package_price =  this.base_premium*80*0.65 ;
      //   this.premium_package_price_strike = this.base_premium*80;
      //   this.platinum_package_price =  this.base_platinum*80*0.65 ;
      //   this.platinum_package_price_strike = this.base_platinum*80;
      //   }
      //   this.premium_plan_currency= "Premium INR"
      //   this.standard_plan_currency = "Standard INR"
      //   this.platinum_plan_currency = "Platinum INR"
      // }
      // else{
      
      //   if(_duration=="quarterly"){
      //   this.basic_package_price = 0
      //   this.standard_package_price =  this.base_standard*0.9 ;
      //   this.premium_package_price =  this.base_premium*0.9 ;
      //   this.platinum_package_price =  this.base_platinum*0.9 ;
      //   }
      //   else if(_duration=="monthly"){
      //   this.basic_package_price = 0
      //   this.standard_package_price =  this.base_standard;
      //   this.premium_package_price =  this.base_premium ;
      //   this.platinum_package_price =  this.base_platinum ;
      //   }
      //   this.premium_plan_currency= "Premium"
      //   this.standard_plan_currency = "Standard"
      //   this.platinum_plan_currency = "Platinum"
      // }
   }
   set_currency(_location){
   	  if(_location==="IN"){
   	  	this.currency = 'INR';
   	  }
   	  else{
   	  	this.currency ='US$';
   	  }
   }
   durationChange(_value){
   	this.duration = _value;
   	this.show_pricing();
     this.update_pricing();
   }

   userCountChange(_value){
     this.user_email_count=_value;
     this.show_pricing();
     this.update_pricing();
   }
   resumeCountChange(_value){
     this.db_size= _value
     console.log(_value)
     // if(_value==='5k') {this.resume_db_scaling_factor = 0.5}
     // else if(_value==='25k') {this.resume_db_scaling_factor = 0.8}
     // else if(_value==='50k') {this.resume_db_scaling_factor = 1}
     // else if(_value==='75k') {this.resume_db_scaling_factor = 1.5}
     // else if(_value==='100k') {this.resume_db_scaling_factor = 2}
     // else if(_value==='150k') {this.resume_db_scaling_factor = 3}
     // else if(_value==='250k') {this.resume_db_scaling_factor = 5}
     // else if(_value==='500k') {this.resume_db_scaling_factor = 9}
     // else if(_value==='1m') {this.resume_db_scaling_factor = 17}
     this.resume_db_scaling_factor = this.getScalingFactor(_value, this.price_mapping)
    // console.log(this.resume_db_scaling_factor)
    this.show_pricing();
    this.update_pricing();
   }

   getScalingFactor(key, arr) {
     console.log(arr)
    for (let i = 0, len = arr.length; i < len; i++) {
      const f = arr[i];
      if (f.resume_count === key) {
        return f['scaling_factor'];
      }
      
      // if (f.scaling_factor && !f.resume_count) {
      //   const cf = this.getPrice(key, f.scaling_factor);
      //   if (cf) {
      //     return cf;
      //   }
      // }
    }
  }
  pick_plan(plan){
    this.plan_requested=true
    if(plan==='silver'){
      this.authService.post('/api/upgrade_request_api', {package_type: 'silver', price: this.standard_package_price, email_count: this.user_email_count, db_size: this.db_size, duration: this.duration , how_old: 'n/a', how_big: 'n/a'}).subscribe(
        res=> {
          console.log(res);
          this.navbar.set_notification('Success', 'Notice', 'Successfully requested upgrade - Please Check mail for Next steps in a short while');
        })
    }else if(plan==='gold'){
      this.authService.post('/api/upgrade_request_api', {package_type: 'gold', price: this.premium_package_price, email_count: this.user_email_count, db_size: this.db_size, duration: this.duration , how_old: 'n/a', how_big: 'n/a'}).subscribe(
        res=> {
          this.navbar.set_notification('Success', 'Notice', 'Successfully requested upgrade - Please Check mail for Next steps in a short while');
          console.log(res)
        })

    }else if(plan==='platinum'){
      this.authService.post('/api/upgrade_request_api', {package_type: 'platinum', price: this.platinum_package_price, email_count: this.user_email_count, db_size: this.db_size, duration: this.duration , how_old: 'n/a', how_big: 'n/a'}).subscribe(
        res=> {
          this.navbar.set_notification('Success', 'Notice', 'Successfully requested upgrade - Please Check mail for Next steps in a short while');
          console.log(res)
        })

    }else if(plan==='one_time'){
      this.authService.post('/api/upgrade_request_api', {package_type: 'one_time', price: this.estimated_one_time_cost, how_old: this.oneTimeExtractForm.controls['how_old'].value, how_big: this.oneTimeExtractForm.controls['how_big'].value, email_count: 'n/a', db_size: 'n/a', duration: 'n/a'}).subscribe(
        res=> {
          this.navbar.set_notification('Success', 'Notice', 'Successfully requested upgrade - Please Check mail for Next steps in a short while');
          console.log(res)
        })
     }
  }
  update_pricing(){
          this.standard_package_price = Math.round(this.base_standard*0.8*this.resume_db_scaling_factor + this.base_standard*0.4*3)
          this.premium_package_price =  Math.round(this.base_premium*0.8*this.resume_db_scaling_factor + this.base_premium*0.4*3)
          this.platinum_package_price =  Math.round(this.base_platinum*0.8*this.resume_db_scaling_factor + this.base_platinum*0.4*3)
            if(this.duration=="annual"){
            this.basic_package_price = 0
            this.standard_package_price =   Math.round(this.standard_package_price*0.85);
            this.premium_package_price =   Math.round(this.premium_package_price*0.85) ;
            this.platinum_package_price =   Math.round(this.platinum_package_price*0.85) ;
            }
            console.log(this.pricing_location)
       if(this.pricing_location=="IN"){
         this.standard_package_price =   this.standard_package_price*80*0.6;
         this.premium_package_price =   this.premium_package_price*80*0.6;
         this.platinum_package_price =   this.platinum_package_price*80*0.6;
        }
  }
  estimateOneTime(){
    let how_old= this.oneTimeExtractForm.controls['how_old'].value
    let how_big= this.oneTimeExtractForm.controls['how_big'].value
    let j=(this.base_standard*1.4*this.getScalingFactor(how_big, this.price_mapping) )
    this.estimated_one_time_cost=Math.round(3*j+(how_old-1)*j/9)
    if(this.pricing_location=="IN"){this.estimated_one_time_cost =   this.estimated_one_time_cost*80*0.6;}
    console.log(how_big)
    console.log(how_old)
  }
  // estimateHowBigOneTime(_val){
  //   console.log(_val)
  // }

}
import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service'
import { Subscription, Observable } from 'rxjs';
import { ModalsService } from '../../../layout/modals/modals.service';
import { ActivatedRoute, Router, Params} from '@angular/router';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { FormBuilder, FormGroup, FormArray, FormControl} from'@angular/forms';

@Component({
  selector: '[app-notifications]',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  notifications = [];
  constructor(
  	private authService: AuthenticationService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private fb: FormBuilder,
    private navbar: NavbarService,
    private modal: ModalsService,
    private shared: SharedService
    ) { }

  ngOnInit() {
  	this.get_plo();
  }
  get_plo(){
  	this.authService.get('/api/index_notifications').subscribe(
  		res => {
  			// console.log(res)
  			this.notifications = res['notifications'];
  		},
  		// err => console.log(err)
  	)
  }
  load_notification(_id){
        // this.modal.load_modal('notification_alert', 'small', _id);
        this.modal.load_modal_by_name('notification_alert', _id);
    }

}

import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl} from'@angular/forms';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service';
import { ModalsService } from '../../../layout/modals/modals.service';



@Component({
  selector: '[app-keyword-rank]',
  templateUrl: './keyword-rank.component.html',
  styleUrls: ['./keyword-rank.component.scss']
})
export class KeywordRankComponent implements OnInit {
  plo: any;
  job_role_id ='';
  rankKeywordsForm=this.fb.group({
    ranking_preferred_skills: [''],
    ranking_required_skills: [''],
    job_titles: ['']
  });
  clicked_save =false;
  constructor(
  	private authService: AuthenticationService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private fb: FormBuilder,
    private shared: SharedService,
    private navbar: NavbarService,
    private modal: ModalsService
  	) { }

  ngOnInit() {
    this.get_plo();
  }
  get_plo(){
    this.job_role_id = this.router.url.split('?')[0].split("/").reverse()[1];
    this.authService.post('/api/keyword_ranking_modal', {job_role_id: this.job_role_id}).subscribe(
      res=> {
        // console.log(res);
        
        this.rankKeywordsForm.patchValue({
          job_titles: this.shared.tags_string_to_form_control(res['job_titles_ranking_criteria']),
          ranking_required_skills: this.shared.tags_string_to_form_control(res['required_skills_ranking_criteria']),
          ranking_preferred_skills: this.shared.tags_string_to_form_control(res['preferred_skills_ranking_criteria']),
        });
        // console.log(res);

      }, 
      // error => console.log(error)
      )
  }
  submitRankKeywordsForm($ev, value: any) {
    $ev.preventDefault();
    if (this.rankKeywordsForm.valid) {
      this.clicked_save =true;
      // console.warn($ev);
      // console.warn(value);
      this.authService.post('/api/rank_resume_reports_for_job_role', {
        'job_role_id': this.job_role_id,
        'job_titles': this.shared.tags_form_control_to_string(value.job_titles),
        'ranking_required_skills': this.shared.tags_form_control_to_string(value.ranking_required_skills),
        'ranking_preferred_skills': this.shared.tags_form_control_to_string(value.ranking_preferred_skills),
      }).subscribe(
        res => {
          // console.log(res);
          this.clicked_save =false;
          if(res['upgrade']==='Premium'){this.modal.hide_modal_by_name('keyword_rank');setTimeout(()=>{ this.modal.load_modal_by_name('upgrade_plan', {}) }, 500)}
          else{
            this.navbar.set_notification('Success','Ranking Completed', 'This page will refresh now');
            // setTimeout(function(){ location.reload(); }, 3000);
            this.shared.refresh(this.router.url);
          }
                  },
        // err => console.log(err)
      )
    }
  }

}

import { Injectable } from '@angular/core';
import { ReplaySubject, Subject }    from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ModalsService {
  // private bigModalsSource: Subject<Object> = new ReplaySubject<Object>();
  // big_modal_selected$ = this.bigModalsSource.asObservable();
  // private smallModalsSource = new ReplaySubject<any>();
  // small_modal_selected$ = this.smallModalsSource.asObservable();
  // private mediumModalsSource = new ReplaySubject<any>();
  // medium_modal_selected$ = this.mediumModalsSource.asObservable();

  private candidateModalSource = new Subject<any>();
  candidate_modal_selected$ = this.candidateModalSource.asObservable();
  private configureEmailModalSource = new Subject<any>();
  configure_email_modal_selected$ = this.configureEmailModalSource.asObservable();
  private configureSendOnlyEmailModalSource = new Subject<any>();
  configure_send_only_email_modal_selected$ = this.configureSendOnlyEmailModalSource.asObservable();
  private searchModalSource = new Subject<any>();
  search_modal_selected$ = this.searchModalSource.asObservable();
  private createJobModalSource = new Subject<any>();
  create_job_modal_selected$ = this.createJobModalSource.asObservable();
  private createClientModalSource = new Subject<any>();
  create_client_modal_selected$ = this.createClientModalSource.asObservable();
  private editJobModalSource = new Subject<any>();
  edit_job_modal_selected$ = this.editJobModalSource.asObservable();
  private inviteUsersModalSource = new Subject<any>();
  invite_users_modal_selected$ = this.inviteUsersModalSource.asObservable();
  private emailModalSource = new Subject<any>();
  email_modal_selected$ = this.emailModalSource.asObservable();
  private manageCollaboratorsModalSource = new Subject<any>();
  manage_collaborators_modal_selected$ = this.manageCollaboratorsModalSource.asObservable();
  private editStagesModalSource = new Subject<any>();
  edit_stages_modal_selected$ = this.editStagesModalSource.asObservable();
  private uploadModalSource = new Subject<any>();
  upload_modal_selected$ = this.uploadModalSource.asObservable();
  private shareCandidateModalSource = new Subject<any>();
  share_candidate_modal_selected$ = this.shareCandidateModalSource.asObservable();
  private notificationsModalSource = new Subject<any>();
  notifications_modal_selected$ = this.notificationsModalSource.asObservable();

  private transferToJobModalSource = new Subject<any>();
  transfer_to_job_modal_selected$ = this.transferToJobModalSource.asObservable();
  private addTagsModalSource = new Subject<any>();
  add_tags_modal_selected$ = this.addTagsModalSource.asObservable();
  private manageTagsModalSource = new Subject<any>();
  manage_tags_modal_selected$ = this.manageTagsModalSource.asObservable();
  private downloadCsvModalSource = new Subject<any>();
  download_csv_modal_selected$ = this.downloadCsvModalSource.asObservable();
  private exportStandardizedTemplateModalSource = new Subject<any>();
  export_standardized_template_modal_selected$ = this.exportStandardizedTemplateModalSource.asObservable();
  private keywordRankModalSource = new Subject<any>();
  keyword_rank_modal_selected$ = this.keywordRankModalSource.asObservable();
  private massUpdateModalSource = new Subject<any>();
  mass_update_modal_selected$ = this.massUpdateModalSource.asObservable();
  private sourceFromVendorModalSource = new Subject<any>();
  source_from_vendor_modal_selected$ = this.sourceFromVendorModalSource.asObservable();
  private jobsForVendorModalSource = new Subject<any>();
  jobs_for_vendor_modal_selected$ = this.jobsForVendorModalSource.asObservable();
  private showAppointmentModalSource = new Subject<any>();
  show_appointment_modal_selected$ = this.showAppointmentModalSource.asObservable();
  private notificationAlertModalSource = new Subject<any>();
  notification_alert_modal_selected$ = this.notificationAlertModalSource.asObservable();
  private topUpModalSource = new Subject<any>();
  top_up_modal_selected$ = this.topUpModalSource.asObservable();
  private notificationAlertParamsSource = new ReplaySubject<any>();
  notification_alert_params_selected$ = this.notificationAlertParamsSource.asObservable();
  private candidateCollaboratorsModalSource = new Subject<any>();
  candidate_collaborators_modal_selected$ = this.candidateCollaboratorsModalSource.asObservable();
  private candidateCollaboratorsModalId = new ReplaySubject<any>();
  candidate_collaborators_modal_id$ = this.candidateCollaboratorsModalId.asObservable();
  private resumeTempUrlSource = new ReplaySubject<any>();
  resume_temp_url$ = this.resumeTempUrlSource.asObservable();
  private searchResultCountSource = new Subject<any>();
  search_result_count$ = this.searchResultCountSource.asObservable();
  private resumeDetailsCountSource = new Subject<any>();
  resume_detail_count$ = this.resumeDetailsCountSource.asObservable();
  private spinnerModalSource = new Subject<any>();
  spinner_modal_selected$ = this.spinnerModalSource.asObservable();
  private savedSearchModalSource = new Subject<any>();
  saved_search_modal_selected$ = this.savedSearchModalSource.asObservable();
  private jobApplicationModalSource = new ReplaySubject<any>();
  job_application_modal_selected$ = this.jobApplicationModalSource.asObservable();
  private customTrackerModalSource = new Subject<any>();
  custom_tracker_modal_selected$ = this.customTrackerModalSource.asObservable();
  private standardizedTemplateModalSource = new Subject<any>();
  standardized_template_modal_selected$ = this.standardizedTemplateModalSource.asObservable();
  private upgradePlanModalSource = new Subject<any>();
  upgrade_plan_modal_selected$ = this.upgradePlanModalSource.asObservable();
  private editBannerModalSource = new Subject<any>();
  edit_banner_modal_selected$ = this.editBannerModalSource.asObservable();
  private editLogoModalSource = new Subject<any>();
  edit_logo_modal_selected$ = this.editLogoModalSource.asObservable();
  private editAboutCompanyModalSource = new Subject<any>();
  edit_about_company_modal_selected$ = this.editAboutCompanyModalSource.asObservable();
  private careerSettingsyModalSource = new Subject<any>();
  career_settings_modal_selected$ = this.careerSettingsyModalSource.asObservable();
  private addJobsToCareerPageModalSource = new Subject<any>();
  add_jobs_to_career_page_modal_selected$ = this.addJobsToCareerPageModalSource.asObservable();
  private tobuDriveStatsModalSource = new Subject<any>();
  tobu_drive_stats_modal_selected$ = this.tobuDriveStatsModalSource.asObservable();
  private autoForwardingModalSource = new Subject<any>();
  auto_forwarding_modal_selected$ = this.autoForwardingModalSource.asObservable();
  private adminUpdatePricingPlanModalSource = new Subject<any>();
  admin_update_pricing_plan_modal_selected$ = this.adminUpdatePricingPlanModalSource.asObservable();
  private createTagModalSource = new Subject<any>();
  create_tag_modal_selected$ = this.createTagModalSource.asObservable();
  private helpModalSource = new Subject<any>();
  help_modal_selected$ = this.helpModalSource.asObservable();
  private autoResponseModalSource = new Subject<any>();
  auto_response_modal_selected$ = this.autoResponseModalSource.asObservable();
  private helpVideoModalSource = new Subject<any>();
  help_video_modal_selected$ = this.helpVideoModalSource.asObservable();
  private arrangeDemoModalSource = new Subject<any>();
  arrange_demo_modal_selected$ = this.arrangeDemoModalSource.asObservable();
  private exportAppointmentsSource = new Subject<any>();
  export_appointments_modal_selected$ = this.exportAppointmentsSource.asObservable();
  private leadCardSource = new Subject<any>();
  lead_card$ = this.leadCardSource.asObservable();
  private disabledUserSource = new Subject<any>();
  disabled_user_modal_selected$ = this.disabledUserSource.asObservable();
  private customizeColumnOrderModalSource = new Subject<any>();
  customize_column_order_modal_selected$ = this.customizeColumnOrderModalSource.asObservable();

  private accessLevelsSource = new Subject<any>();
  access_levels$ = this.accessLevelsSource.asObservable();
  
  constructor() { 
    // console.log('hiding candidate modal')
    this.candidateModalSource.next({'state': 'hidden'});
    this.configureEmailModalSource.next({'state': 'hidden'});
    this.configureSendOnlyEmailModalSource.next({'state': 'hidden'});
    this.searchModalSource.next({'state': 'hidden'});
    this.createJobModalSource.next({'state': 'hidden'});
    this.createClientModalSource.next({'state': 'hidden'});
    this.editJobModalSource.next({'state': 'hidden'});
    this.inviteUsersModalSource.next({'state': 'hidden'});
    this.emailModalSource.next({'state': 'hidden'});
    this.manageCollaboratorsModalSource.next({'state': 'hidden'});
    this.editStagesModalSource.next({'state': 'hidden'});
    this.uploadModalSource.next({'state': 'hidden'});
    this.shareCandidateModalSource.next({'state': 'hidden'});
    this.notificationsModalSource.next({'state': 'hidden'});
    this.transferToJobModalSource.next({'state': 'hidden'});
    this.addTagsModalSource.next({'state': 'hidden'});
    this.manageTagsModalSource.next({'state': 'hidden'});
    this.downloadCsvModalSource.next({'state': 'hidden'});
    this.exportStandardizedTemplateModalSource.next({'state': 'hidden'});
    this.keywordRankModalSource.next({'state': 'hidden'});
    this.massUpdateModalSource.next({'state': 'hidden'});
    this.sourceFromVendorModalSource.next({'state': 'hidden'});
    this.jobsForVendorModalSource.next({'state': 'hidden'});
    this.showAppointmentModalSource.next({'state': 'hidden'});
    this.notificationAlertModalSource.next({'state': 'hidden'});
    this.candidateCollaboratorsModalSource.next({'state': 'hidden'});
    this.spinnerModalSource.next({'state': 'hidden'});
    this.topUpModalSource.next({'state': 'hidden'});
    this.jobApplicationModalSource.next({'state': 'hidden'});
    this.customTrackerModalSource.next({'state': 'hidden'});
    this.standardizedTemplateModalSource.next({'state': 'hidden'});
    this.upgradePlanModalSource.next({'state': 'hidden'});
    this.editBannerModalSource.next({'state': 'hidden'});
    this.editLogoModalSource.next({'state': 'hidden'});
    this.editAboutCompanyModalSource.next({'state': 'hidden'});
    this.careerSettingsyModalSource.next({'state': 'hidden'});
    this.addJobsToCareerPageModalSource.next({'state': 'hidden'});
    this.tobuDriveStatsModalSource.next({'state': 'hidden'})
    this.autoForwardingModalSource.next({'state': 'hidden'})
    this.adminUpdatePricingPlanModalSource.next({'state': 'hidden'});
    this.createTagModalSource.next({'state': 'hidden'});
    this.helpModalSource.next({'state': 'hidden'});
    this.autoResponseModalSource.next({'state': 'hidden'});
    this.helpVideoModalSource.next({'state': 'hidden'});
    this.arrangeDemoModalSource.next({'state': 'hidden'});
    this.exportAppointmentsSource.next({'state': 'hidden'})
    this.leadCardSource.next({'state': 'hidden'});
    this.accessLevelsSource.next({'state': 'hidden'});
    this.disabledUserSource.next({'state': 'hidden'});
    this.customizeColumnOrderModalSource.next({'state': 'hidden'});
  }
  // load_modal(name: string, size: string, params: any) {
  //   if (size==='big'){
  //     this.bigModalsSource.next({name:name, params: params});
  //   }
  //   else if (size==='small'){
  //     this.smallModalsSource.next({name:name, params: params});
  //   }
  //   else{
  //     this.mediumModalsSource.next({name:name, params: params});
  //   }
  // }
  // hide_modal(size:string) {
  //   if (size==='big'){
  //     // console.log('hiding big modal')
  //     // console.log(this.bigModalsSource);
  //       this.bigModalsSource.next({name: '', params: {}});
  //   }
  //   else if (size==='small'){
  //     this.smallModalsSource.next({name:'', params: {}});
  //   }
  //   else{
  //     this.mediumModalsSource.next({name:'', params: {}});
  //   }
  // }

  load_modal_by_name(name, params){
    // console.log('loading')
    // console.log(name)
    if (name==="candidate"){
      // console.log('displaying candidate')
      this.candidateModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="configure_email"){
      // console.log('displaying config email')
      this.configureEmailModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="configure_send_only_email"){
      // console.log('displaying config email')
      this.configureSendOnlyEmailModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="search"){
      // console.log('displaying search')
      this.searchModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="create_job"){
      // console.log('displaying create job')
      this.createJobModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="create_client"){
      // console.log('displaying create job')
      this.createClientModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="edit_job"){
      // console.log('displaying edit job')
      this.editJobModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="invite_users"){
      this.inviteUsersModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="email"){
      // console.log('displaying email')
      this.emailModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="manage_collaborators"){
      this.manageCollaboratorsModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="upload"){
      this.uploadModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="share_candidate"){
      this.shareCandidateModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="notifications"){
      this.notificationsModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="transfer_to_job"){
      this.transferToJobModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="add_tags"){
      this.addTagsModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="manage_tags"){
      this.manageTagsModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="download_csv"){
      this.downloadCsvModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="export_standardized_template"){
      this.exportStandardizedTemplateModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="keyword_rank"){
      this.keywordRankModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="mass_update"){
      this.massUpdateModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="source_from_vendor"){
      this.sourceFromVendorModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="jobs_for_vendor"){
      this.jobsForVendorModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="show_appointment"){
      this.showAppointmentModalSource.next({'state': 'displayed', 'params': params});
    }
    if (name==="notification_alert"){
      this.notificationAlertModalSource.next({'state': 'displayed', 'params': params});
      this.notificationAlertParamsSource.next({'params': params});
    }
    if (name==="candidate_collaborators"){
      this.candidateCollaboratorsModalSource.next({'state': 'displayed', 'params': params});
      this.candidateCollaboratorsModalId.next({'params': params})
    }
    if (name==="saved_search"){
      this.savedSearchModalSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="spinner"){
      // console.log('displaying spinner')
      this.spinnerModalSource.next({'state': 'displayed'});
    }
    if (name==="top_up"){
      this.topUpModalSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="job_application"){
      console.log('showing job app')
      this.jobApplicationModalSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="custom_tracker"){
      this.customTrackerModalSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="standardized_template"){
      this.standardizedTemplateModalSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="upgrade_plan"){
      this.upgradePlanModalSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="edit_banner"){
      this.editBannerModalSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="edit_logo"){
      this.editLogoModalSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="edit_about_company"){
      this.editAboutCompanyModalSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="career_settings"){
      this.careerSettingsyModalSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="add_jobs_to_career_page"){
      this.addJobsToCareerPageModalSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="tobu_drive_stats"){
      this.tobuDriveStatsModalSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="auto_forwarding"){
      this.autoForwardingModalSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="admin_update_pricing_plan"){
      this.adminUpdatePricingPlanModalSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="create_tag"){
      this.createTagModalSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="help"){
      this.helpModalSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="auto_response"){
      this.autoResponseModalSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="help_video"){
      this.helpVideoModalSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="arrange_demo"){
      this.arrangeDemoModalSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="export_appointments"){
      this.exportAppointmentsSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="lead_card"){
      this.leadCardSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="access_levels"){
      this.accessLevelsSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="edit_stages"){
      this.editStagesModalSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="disabled_user"){
      this.disabledUserSource.next({'state': 'displayed', 'params': {}});
    }
    if (name==="customize_column_order"){
      this.customizeColumnOrderModalSource.next({'state': 'displayed', 'params': {}});
    }
  }
  hide_modal_by_name(name){
    // console.log('hiding')
    // console.log(name);
    if (name==="candidate"){
      // console.log('hiding candidate modal')
      this.candidateModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="configure_email"){
      // console.log('hiding configure email')
      this.configureEmailModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="configure_send_only_email"){
      // console.log('displaying config email')
      this.configureSendOnlyEmailModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="search"){
      // console.log('hiding search')
      this.searchModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="create_job"){
      this.createJobModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="create_client"){
      this.createClientModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="edit_job"){
      this.editJobModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="invite_users"){
      this.inviteUsersModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="email"){
      this.emailModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="manage_collaborators"){
      this.manageCollaboratorsModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="upload"){
      this.uploadModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="share_candidate"){
      this.shareCandidateModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="notifications"){
      this.notificationsModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="transfer_to_job"){
      this.transferToJobModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="add_tags"){
      this.addTagsModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="manage_tags"){
      this.manageTagsModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="download_csv"){
      this.downloadCsvModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="export_standardized_template"){
      this.exportStandardizedTemplateModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="keyword_rank"){
      this.keywordRankModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="mass_update"){
      this.massUpdateModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="source_from_vendor"){
      this.sourceFromVendorModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="jobs_for_vendor"){
      this.jobsForVendorModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="show_appointment"){
      this.showAppointmentModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="notification_alert"){
      this.notificationAlertModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="candidate_collaborators"){
      this.candidateCollaboratorsModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="saved_search"){
      this.savedSearchModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="spinner"){
      console.log('hiding spinner')
      this.spinnerModalSource.next({'state': 'hidden'});
    }
    if (name==="top_up"){
      this.topUpModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="job_application"){
      this.jobApplicationModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="custom_tracker"){
      this.customTrackerModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="standardized_template"){
      this.standardizedTemplateModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="upgrade_plan"){
      this.upgradePlanModalSource.next({'state': 'hidden', 'params': {}});
    }
     if (name==="edit_banner"){
      this.editBannerModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="edit_logo"){
      this.editLogoModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="edit_about_company"){
      this.editAboutCompanyModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="career_settings"){
      this.careerSettingsyModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="add_jobs_to_career_page"){
      this.addJobsToCareerPageModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="tobu_drive_stats"){
      this.tobuDriveStatsModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="auto_forwarding"){
      this.autoForwardingModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="admin_update_pricing_plan"){
      this.adminUpdatePricingPlanModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="create_tag"){
      this.createTagModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="help"){
      this.helpModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="auto_response"){
      this.autoResponseModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="help_video"){
      this.helpVideoModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="arrange_demo"){
      this.arrangeDemoModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="export_appointments"){
      this.exportAppointmentsSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="lead_card"){
      this.leadCardSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="access_levels"){
      this.accessLevelsSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="edit_stages"){
      this.editStagesModalSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="disabled_user"){
      this.disabledUserSource.next({'state': 'hidden', 'params': {}});
    }
    if (name==="customize_column_order"){
      this.customizeColumnOrderModalSource.next({'state': 'hidden', 'params': {}});
    }
  }
  set_resume_temp_url(_url){
    this.resumeTempUrlSource.next(_url);
    console.log(_url)
  }
  get_resume_temp_url(){
    return this.resume_temp_url$;
  }
  destroy_resume_temp_url(){
    this.resumeTempUrlSource.next('');
  }
  set_search_result_count(_count){
    this.searchResultCountSource.next(_count);
  }
  get_search_result_count(){
    return this.search_result_count$;
  }
  destroy_search_result_count(){
    this.searchResultCountSource.next('');
  }
  set_resume_details_and_count(_count){ //for prev and next and count of comments etc
    this.resumeDetailsCountSource.next(_count);
  }
  get_resume_details_and_count(){
    return this.resume_detail_count$;
  }
  destroy_resume_details_and_count(){
    this.resumeDetailsCountSource.next('');
  }
}
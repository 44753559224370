import { Component, OnDestroy } from '@angular/core';
import { Params, Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService} from '../../../../core/authentication/authentication.service'
import { Subscription }   from 'rxjs';
import {NavbarService} from '../../../../layout/header/navbar.service';
import {ModalsService} from '../../../../layout/modals/modals.service'
import { SharedService } from '../../../../core/shared/shared.service';
import { CandidateService } from '../candidate.service';

@Component({
  selector: '[app-activitylog]',
  templateUrl: './activitylog.component.html',
  styleUrls: ['./activitylog.component.scss']
})
export class ActivitylogComponent implements OnDestroy {

  plo: any ;
  rcar: any;
  uar: any;
  activity_log: any;
  generated_params ={};

  subscription: Subscription;
  sub1: Subscription;
  sub2: Subscription;
  constructor(
  	private authService: AuthenticationService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private navbar: NavbarService,
    private shared: SharedService,
    private candidate: CandidateService,
    private modal: ModalsService
    ) { }

  ngOnInit() {
  	this.get_restrictions()
  	this.get_plo();
    // this.modal.load_modal_by_name('candidate', {});
  }

  ngOnDestroy(){
  	this.subscription.unsubscribe;
    this.sub1.unsubscribe;
    this.sub2.unsubscribe;
    // this.modal.hide_modal_by_name('candidate');
  }

  get_plo(){
  	// var url_split = this.router.url.split('/');
  	// var candidate_id = url_split[url_split.length-2];
  	// console.log(candidate_id);
    // this.generated_params = this.candidate.get_params_from_url(this.router.url);
    // if(this.router.url.includes('inbox')){
  	 //  this.authService.post('/api/get_activity_log_for_resume', this.generated_params).subscribe(
    //     res => {
    //       this.plo =  res
    //       this.activity_log = this.plo.activity_log;
    //       console.log(res);
    //     }, error => {
    //       console.log(error);
    //     }
    // )
    // }
    // else{
    //   this.authService.post('/api/get_activity_log_for_resume_report', this.generated_params).subscribe(
    //     res => {
    //       this.plo =  res
    //       this.activity_log = this.plo.activity_log;
    //       console.log(res);
    //     }, error => {
    //       console.log(error);
    //     }
    //   )
    // }
    // this.load_activity();
    // this.sub1 = this.router.events.pipe(
    // filter(event => event instanceof NavigationEnd)  
    // ).subscribe((event: NavigationEnd) => {
    //   this.load_activity();
    //   console.log(event.url);
    // });    
    this.sub1 = this.route.params.subscribe(
      res=> {
        this.load_activity();
      }
    )
  }

  load_activity(){
    this.sub2=this.fetch_activity()
      .subscribe(
        res => {
          if(res['notice']==='Access Denied')
          {
            this.router.navigate(['/candidate_inbox'])
          }
          // this.plo =  res
          // this.analytics = this.plo.analytics;
          this.activity_log = res['activity_log'];
          // console.log(res);
        }, error => {
          // console.log(error);
        }
      )
  }
  fetch_activity(){
    this.generated_params = this.candidate.get_params_from_url(this.router.url);
    // console.log(this.generated_params)
    if(this.router.url.includes('inbox')){
      return this.authService.post('/api/get_activity_log_for_resume', this.generated_params)
    }
    else
    {
      return this.authService.post('/api/get_activity_log_for_resume_report', this.generated_params)
    }
  }
  get_restrictions(){
    this.subscription= this.shared.current_restrictions$.subscribe(
        res => {
          this.rcar = res["rcar"];
          this.uar = res["uar"]
        }
    );
  }

}

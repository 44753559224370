import { Component, HostBinding, OnInit , Inject, Injectable} from '@angular/core';

import { SettingsService } from './core/settings/settings.service';
import { SharedService } from './core/shared/shared.service';
import { HttpHeaders,HttpClient} from '@angular/common/http';
// import { AngularTokenService } from 'angular-token';
import {Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot} from '@angular/router'; // import Router and NavigationEnd
import { NavbarService } from './layout/header/navbar.service';
//import {AngularTokenService} from 'angular-token'
import { SEOService } from './core/shared/seo.service';
import { mergeMap, delay, filter, map } from 'rxjs/operators';
import { ElectronService } from 'ngx-electron';
import { AuthenticationService} from './core/authentication/authentication.service';
import { WindowRef } from './core/shared/window-ref.service';
import { PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';

import { environment} from '../environments/environment.prod'
let win = undefined//(<any>window);
declare const FS:any
declare let ga: Function;
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    @HostBinding('class.layout-fixed') get isFixed() { return this.settings.getLayoutSetting('isFixed'); };
    @HostBinding('class.aside-collapsed') get isCollapsed() { return this.settings.getLayoutSetting('isCollapsed'); };
    @HostBinding('class.layout-boxed') get isBoxed() { return this.settings.getLayoutSetting('isBoxed'); };
    @HostBinding('class.layout-fs') get useFullLayout() { return this.settings.getLayoutSetting('useFullLayout'); };
    @HostBinding('class.hidden-footer') get hiddenFooter() { return this.settings.getLayoutSetting('hiddenFooter'); };
    @HostBinding('class.layout-h') get horizontal() { return this.settings.getLayoutSetting('horizontal'); };
    @HostBinding('class.aside-float') get isFloat() { return this.settings.getLayoutSetting('isFloat'); };
    @HostBinding('class.offsidebar-open') get offsidebarOpen() { return this.settings.getLayoutSetting('offsidebarOpen'); };
    @HostBinding('class.aside-toggled') get asideToggled() { return this.settings.getLayoutSetting('asideToggled'); };
    @HostBinding('class.aside-collapsed-text') get isCollapsedText() { return this.settings.getLayoutSetting('isCollapsedText'); };
    isElectron=false;
    folders_to_upload = [];
    linked_folders_ever = [];
    sizeLimit = '';
    mime_types = []
    extensions = []
    selected_folders=[];
    computer_id =''
    uploads_pending = [];
    uploads_processing = [];
    processing_counts=[];
    is_logged_in=false;
    hit_tobu_drive_limit=false;
    do_full_sync_folders = []
    first_time_setter = true
    constructor(
        public settings: SettingsService, 
        private shared: SharedService,
        private http: HttpClient,
        // private tokenService: AngularTokenService,
        public router: Router,
        private seo: SEOService,
        private activatedRoute: ActivatedRoute,
        private _electronService:ElectronService,
        private authService: AuthenticationService,
        private navbar: NavbarService,
        @Inject(PLATFORM_ID) private platformId: any
        ) {
      this.is_logged_in = this.authService.isLoggedIn();
    
    if(isPlatformBrowser(platformId))
    { win = (<any>window)}
        // subscribe to router events and send page views to Google Analytics
          if (this._electronService.isElectronApp)
            {
              console.log('loading electron')
              this.initialize_electron();
              this.isElectron=true
            };
          this.set_app_title();
          this.router.events.pipe(
                   filter((event) => event instanceof NavigationEnd),
                   map(() => this.activatedRoute),
                   // map(() => this.getDeepestTitle(this.router.routerState.snapshot.root)),
                   map((route) => {
                     while (route.firstChild) route = route.firstChild;
                     return route;
                   }),
                   filter((route) => route.outlet === 'primary'),
                   mergeMap((route) => route.data)
                  ).subscribe(event => {
                    console.log(event['title'])
                  // if (event instanceof NavigationEnd) {
                    console.log('here')
                    if(this.shared.isBrowser())
                   { ga('set', 'page', event.urlAfterRedirects);
                                       ga('send', 'pageview');}
                    var seo_title = event['title']
                    var seo_description = event['ogUrl']
                    var seo_OgURL = event['title'] + event['description']
                    if(event['title']===undefined){
                      console.log('here2')
                      seo_title = 'Email Resume Extractor|Internal Resume Database Builder|Tobu'
                      seo_description= "Tobu Automatically Identifies Resumes from a Recruiter's Email History and Desktop and builds a searchable Private Internal Resume Database. Try it for free!"
                      seo_OgURL = 'https://tobu.ai'
                    }
        
                    // this.seo.updateTitle(seo_title);
                    // this.seo.updateOgUrl(seo_description);
                    // this.seo.updateDescription(seo_OgURL)
                  // }
        
                });

        
    }
    title: any;

    private getDeepestTitle(routeSnapshot: ActivatedRouteSnapshot) {
      var title = routeSnapshot.data ? routeSnapshot.data['title'] : '';
      if (routeSnapshot.firstChild) {
        title = this.getDeepestTitle(routeSnapshot.firstChild) || title;
      }
      return title;
    }

    ngOnInit() {
        // prevent empty links to reload the page
        document.addEventListener('click', e => {
            const target = e.target as HTMLElement;
            if (target.tagName === 'A' && ['', '#'].indexOf(target.getAttribute('href')) > -1)
                e.preventDefault();
        });
         this.shared.load_restrictions();
         this.authService.get('/api/current_user_details').subscribe(
            res => {
              console.log(res);
              //fs
              if(this.shared.isBrowser())
                {
                  FS.identify(res['id'], {
                                  displayName: res['full_name'],
                                  email: res['email'],
                                  // TODO: Add your own custom user variables here, details at
                                  // https://help.fullstory.com/hc/en-us/articles/360020623294
                                  reviewsWritten_int: 14
                                });
                }
            },
            err=> {
                console.log(err)
            }
          )

         // this.router.events.pipe(
         //   filter((event) => event instanceof NavigationEnd),
         //   // map(() => this.activatedRoute),
         //   // map((route) => {
         //   //   while (route.firstChild) route = route.firstChild;
         //   //   return route;
         //   // }),
         //   // filter((route) => route.outlet === 'primary'),
         //   // mergeMap((route) => route.data)
         //  )
         //  .subscribe((event) => {
         //    this.seo.updateTitle(event['title']);
         //    this.seo.updateOgUrl(event['ogUrl']);
         //    //Updating Description tag dynamically with title
         //    this.seo.updateDescription(event['title'] + event['description'])
         //  }); 
        
        
    }
    set_app_title(){
      var seo_title = 'Tobu- Email Resume Extractor|Internal Resume Database Builder'
      var seo_description= "Tobu Automatically Identifies Resumes from a Recruiter's Email History and Desktop and builds a searchable Private Internal Resume Database. Try it for free!"
      var seo_OgURL = 'https://tobu.ai'
      this.seo.updateTitle(seo_title);
      this.seo.updateOgUrl(seo_OgURL);
      this.seo.updateDescription(seo_description)
    }
    initialize_electron(){
      // this.navbar.set_is_electron_initialized(false);
      
      win.api.receive("fileUpload", (data) => {
            console.log(`Received data from file read process`);
            console.log(data)
            console.log(data['batch']);
            console.log(data['last_sync_time'].getTime())
            console.log(data['first_sync_time'].getTime())
            // this.upload_file(data['file'], data['file_path'], data['file_name'], data['folder_path'], data['last_sync_time'], data['computer_id'],data['platformOs'])
            
            this.upload_batch(data['batch'], data['folder_path'], data['last_sync_time'], data['first_sync_time'], data['computer_id'],data['platformOs'], data['batch_report_time'], data['batch_report_file_count'],  data['batch_report_batch_count'], data['batch_report_first_sync_time'], data['batch_report_last_sync_time'], data['batch_no'])


      });
      win.api.receive("soloFileUpload", (data) => {
            console.log(`Received data from file read process`);
            console.log(data)
            // console.log(data['batch']);
            console.log(data['last_sync_time'].getTime())
            this.upload_file(data['file'], data['file_path'], data['file_name'], data['folder_path'], data['last_sync_time'], data['computer_id'],data['platformOs'])
            // this.upload_batch(data['batch'], data['folder_path'], data['last_sync_time'], data['computer_id'],data['platformOs'])

      });
      win.api.receive("hasHitLimit", (data) => {
            console.log(`Checking has hit limit`);
            this.has_hit_tobu_drive_limit()

      });
      win.api.receive("notify", (data) => {
            console.log(`setting notification`);
            console.log(data)
            this.notify(data['header'], data['message'])

      });
      win.api.receive("message", (data) => {
            // console.log(`setting notification`);
            console.log(data)
            this.notify(data['header'], data['message'])

      });
      win.api.receive("dirread", (data) => {
            console.log(`Received this from dir read process`);
            console.log(data);
            //data['folder_to_upload']['folder_path']

            for(let i=0; i< this.selected_folders.length; i++)
              
              { 
                console.log(this.selected_folders[i])
                if(this.selected_folders[i]['folder_path']===data['folder_to_upload']['folder_path']){
                    this.selected_folders[i]['files']=data['folder_to_upload']['files']
                    this.selected_folders[i]['uploads_pending']=data['folder_to_upload']['uploads_pending']
                    console.log(this.selected_folders[i]['files'])
                    this.uploads_pending[this.selected_folders[i]['folder_path']]=data['folder_to_upload']['uploads_pending']
                }
              }
            console.log(this.selected_folders)
            this.navbar.set_selected_folders_desktop({folders: this.selected_folders, extensions: this.extensions, sizeLimit: this.sizeLimit, computer_id: this.computer_id})
            this.navbar.set_uploads_pending_desktop({uploads_pending: this.uploads_pending, after_resume_upload:false})
        });

      win.api.receive("updateUploadPending", (data) => {
            console.log(`Received this from update upload pending process`);
            console.log(data);
            this.uploads_pending[data['folder_path']]=this.uploads_pending[data['folder_path']]+1
            console.log(this.uploads_pending)
      })

      win.api.receive("machineID", (data)=> {
        // console.log(data)
        this.computer_id=data;
        this.get_computer_details(this.computer_id);
        
      })
      // win.api.receive("selectedFolders", (data)=> {
      //   console.log(data);
      //   // this.navbar.set_selected_fodlers_desktop(data)
      //  // this.select_folders(data)
      // })
      if(this.is_logged_in)
      {win.api.send("getMachineID")}
      win.api.receive("dirnotread", (data) => {
            console.log(`Received this dir not read process`);
            console.log(data);
      });
      
    // this.fs.readdir('IMGPATH', (err: Error, files: [string]) => {
    //       if (err) {
    //         console.error(err);
    //       }
    //       this.entries = ['../'].concat(files);
    //       console.log(this.entries)
    //     });
    // this.fileService.getFile('IMGPATH')

   // console.log('loading files')
    
    
    
    //win.api.send("readfile", {file: 'pdfpath'});
    // win.api.receive("dirread", (data) => {
    //         console.log(`Received following from main process`);
    //         console.log(data)
    //     });
    // win.api.send("readdir", "some data");
  }
  get_computer_details(computer_id){
    this.selected_folders=[]

    this.authService.post('/api/initialize_desktop_upload', {computer_id:this.computer_id}).subscribe(
      res=> {
        console.log(res);
        this.extensions = res['extensions'];
        this.sizeLimit = res['sizeLimit'];
        this.navbar.set_selected_folders_desktop({folders: this.selected_folders, extensions: this.extensions, sizeLimit: this.sizeLimit, computer_id: this.computer_id})
        let folders = res['folders_to_upload'];
        this.linked_folders_ever = res['linked_folders_ever'];
        this.navbar.set_linked_folders_ever_desktop({linked_folders_ever: this.linked_folders_ever})
        // this.folders_to_upload=res['folders_to_upload'];
        for(let i=0; i<folders.length; i++)
        {
          this.selected_folders.push(folders[i])
          this.get_folder_details_for_backup(folders[i]);
          
        }
        // setInterval(function(){ 
        //     this.check_uploads();   
        // }, 5000); //2 mins
        if(this.first_time_setter)
        {
          this.check_uploads()
          setInterval(() => this.check_uploads(), 600000);
          this.first_time_setter = false

         }

        
        // this.upload_resumes();
      },
      err => console.log(err)
    )
  }
  check_uploads(){
    console.log(this.computer_id)
    this.authService.post('/api/check_folders_for_desktop_upload', {computer_id:this.computer_id}).subscribe(
      
      res=> {
        console.log(res);
        if(res['folders']?.length>0){
          win.api.send("getMachineID")
          //setTimeout(()=>{win.api.send("getMachineID");}, 3000)
          this.do_full_sync_folders = res['folders']
          this.navbar.set_do_full_sync_folders_desktop({folders: this.do_full_sync_folders})
          setTimeout(()=>this.upload_resumes(), 5000)
          console.log('uploading resumes do full sync')
        }
        // win.api.send("upload_resumes", this.selected_folders);
      }, err=>console.log(err))
  }
  upload_resumes(){
    this.authService.post('/api/set_folders_for_desktop_upload', {computer_id:this.computer_id, folders_to_upload:this.selected_folders.map((x,i,a)=>{return x.folder_path})}).subscribe(
    // this.authService.post('/api/set_folders_for_desktop_upload', {computer_id:this.computer_id, folders_to_upload:this.selected_folders.map((x,i,a)=>{return x.folder_path}), folder_info: this.selected_folders.map((x,i,a)=>{return {folder_name: x.folder_path, no_of_files: x.length}})}).subscribe(
      res=> {
        console.log(res)
        console.log(this.selected_folders)
        win.api.send("upload_resumes", this.selected_folders);
      }, err=>console.log(err))
    
  }
  get_folder_details_for_backup(folder){
    // this.folders_to_upload= folders_to_upload//[{
            //   folder_path: 'folder_path',
            //   last_sync_time: -2193370825000
            // }]
        
        win.api.send("readdir", {
          folder_to_upload: folder, 
          sizeLimit: this.sizeLimit, 
          extensions: this.extensions
        });
  }
  upload_file(data, file_path, file_name, folder_path, last_sync_time, computer_id, platformOs){
    var date:Date = new Date();
    var baseDate: Date = new Date(Date.UTC(1970, 0, 1));
    var timestamp = (date.getTime() - baseDate.getTime()).toString();
    var folder_path = folder_path;
    var last_sync_time = last_sync_time;
    console.log(last_sync_time);
    console.log(last_sync_time.getTime())
    var computer_id = computer_id
    var create_mass_upload_tags = this.get_tags(file_name, folder_path, platformOs)
    const formData: FormData = new FormData();
    formData.append('file_body', new Blob([data]), file_name);
    formData.append('user_upload_timestamp', timestamp);
    formData.append('folder_path', folder_path);
    formData.append('last_sync_time', last_sync_time.getTime());
    formData.append('computer_id', computer_id);
    formData.append('desktop_path', file_path);
    // form.append('file_count', this.queue.length);
    formData.append('tags', create_mass_upload_tags);
    //update upload file_count
    this.uploads_pending[folder_path]=this.uploads_pending[folder_path]-1
    console.log(this.uploads_pending)
    this.navbar.set_uploads_pending_desktop({uploads_pending: this.uploads_pending, after_resume_upload:true})
    //end update upload file count
    console.log(formData)
    this.authService.post_html('/api/upload_solo_resume_to_database_from_desktop_electron', formData).subscribe(
      res=> console.log(res),
      err=> console.log(err)
    );
  }
  upload_batch(batch, folder_path, last_sync_time, first_sync_time, computer_id, platformOs, batch_report_time, batch_report_file_count, batch_report_batch_count, batch_report_first_sync_time, batch_report_last_sync_time, batch_no){
    const formData: FormData = new FormData();
    for(let b=0; b< batch.length; b++){
      // batch[b]['file']=new Blob(batch[b]['file']);
      formData.append('file_data[]', new Blob([batch[b]['file']]), batch[b]['file_name']);
      batch[b]['file']=""
    }
    // console.log(file_data)
    console.log(batch)
    var date:Date = new Date();
    var baseDate: Date = new Date(Date.UTC(1970, 0, 1));
    var timestamp = (date.getTime() - baseDate.getTime()).toString();
    var folder_path = folder_path;
    var last_sync_time = last_sync_time;
    console.log(last_sync_time);
    console.log(last_sync_time.getTime())
    console.log(batch_report_time)
    console.log(batch_report_file_count)
    var computer_id = computer_id
    //var create_mass_upload_tags = batch ['tags']//this.get_tags(file_name, folder_path, platformOs)
    
    // for (var i = 0; i < batch.length; i++) {
        
    // }
    formData.append('batch', JSON.stringify(batch));
    // formData.append('file_body', new Blob([batch]), timestamp);
    formData.append('user_upload_timestamp', timestamp);
    formData.append('folder_path', folder_path);
    formData.append('last_sync_time', last_sync_time.getTime());
    formData.append('first_sync_time', first_sync_time.getTime());
    formData.append('computer_id', computer_id);
    formData.append('batch_report_time', batch_report_time);
    formData.append('batch_report_file_count', batch_report_file_count)
    formData.append('total_no_of_batches',  batch_report_batch_count);
    formData.append('batch_report_start', batch_report_first_sync_time);
    formData.append('batch_report_end',  batch_report_last_sync_time);
    formData.append('batch_no',  batch_no);
    // formData.append('desktop_path', file_path);
    // form.append('file_count', this.queue.length);
    //formData.append('tags', create_mass_upload_tags);
    //update upload file_count
    this.uploads_pending[folder_path]=this.uploads_pending[folder_path]-batch.length
    console.log(this.uploads_pending)
    this.navbar.set_uploads_pending_desktop({uploads_pending: this.uploads_pending, after_resume_upload:true})
    //end update upload file count
    console.log("uploading to electron")
    console.log(formData)
    this.authService.post_html('/api/upload_resume_to_database_from_desktop_electron', formData).subscribe(
      res=> {
        console.log(res);
        if(this.processing_counts[batch_report_time]===undefined){this.processing_counts[batch_report_time]=res['batch_count']}
          else{this.processing_counts[batch_report_time]= this.processing_counts[batch_report_time]+ res['batch_count']}
        this.uploads_processing[folder_path]={ batch_report_time: res['batch_report_time'], processing_count: this.processing_counts[batch_report_time], batch_report_file_count: batch_report_file_count}
        this.navbar.set_uploads_processing_desktop({uploads_processing: this.uploads_processing})
      },
      err=> console.log(err)
    );
  }
  get_tags(file_path, folder_path, platformOs){
    
    console.log(platformOs)
    if(platformOs==='darwin'|| platformOs==='linux')
    {
      console.log("identified linux or mac")
      var file_array = file_path.split('/');
      var folder_array = folder_path.split('/');
      let primary_tag = folder_array[folder_array.length-1];
      let sub = file_path.replace(folder_path, '')
      let tags = sub.split('/')
      tags.pop()
      tags.shift()
      tags = tags.concat([primary_tag])
      console.log(tags)
      return tags
      }
    else if(platformOs==='win32')
      {
        console.log("win")
        var file_array = file_path.split('\\');
        var folder_array = folder_path.split('\\');
        let primary_tag = folder_array[folder_array.length-1];
        let sub = file_path.replace(folder_path, '')
        let tags = sub.split('\\')
        tags.pop()
        tags.shift()
        tags = tags.concat([primary_tag])
        console.log(tags)
        return tags
      }
    // return (file_array - parent_array + [parent_folder])
  }
  has_hit_tobu_drive_limit(){
    this.authService.get('/api/has_hit_tobu_drive_limit').subscribe(
      res=>{
        console.log(res);
        //this.hit_tobu_drive_limit=res['has_hit_limit']
        win.api.send("limitReached", res);
        if(res['has_hit_limit'])
        {
          
          this.navbar.set_notification('Warning', 'Resume Limit Reached', 'Desktop Upload Resume limit reached')
        }
      },
      err=>
      console.log(err))
  }
  notify(header, message){
    this.navbar.set_notification('Warning', header, message)
  }
}

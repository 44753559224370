import { Injectable, Inject } from '@angular/core';
import { AuthenticationService} from '../../core/authentication/authentication.service';
import { SharedService} from '../../core/shared/shared.service';
import { Params, Router, ActivatedRoute } from "@angular/router";
import { NavbarService } from '../../layout/header/navbar.service'
import { HttpHeaders,HttpClient} from '@angular/common/http';

import {environment} from '../../../environments/environment.prod'
import { PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
import { ElectronService } from 'ngx-electron';

@Injectable()
export class MenuService {

    menuItems: Array<any>;
    rcar: any={};
    uar: any={};
    itemToBePushed: any = {
        name: "",
        link: "",
        icon: "",
        submenu: []
    };
    is_logged_in = false;

    constructor(private authService: AuthenticationService,  
        private router: Router, 
        private route: ActivatedRoute, 
        private shared: SharedService,
         private navbar: NavbarService,
         private http: HttpClient,
         private _electronService: ElectronService,
         @Inject(PLATFORM_ID) private platformId: any,
         ) {
        this.menuItems = [];
    }

    addMenu(items: Array<{
        text: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        alert?: string,
        submenu?: Array<any>
    }>) {
        // items.forEach((item) => {
        //     this.menuItems.push(item);
        // });
        if(isPlatformBrowser(this.platformId))
        {this.is_logged_in = this.authService.isLoggedIn();
        // this.authService.http_get('/api/is_logged_in', {}).subscribe(
        //     res=> {
        //         console.log(res)
        //         if(res['is_logged_in']===true){this.populate_menu(items);this.is_logged_in=true;}
        //         else{this.is_logged_in=false}
        //     })
        if(this.is_logged_in){
                  this.populate_menu(items)
                }
                this.navbar.isLoggedIn$.subscribe(res=>{
                  if(res ===true || res === false)
                  {this.is_logged_in = res}
                  console.log(this.is_logged_in)
                  if(this.is_logged_in){this.populate_menu(items)}
                })}
        
    }

    getMenu() {
        return this.menuItems;
    }
    populate_menu(items){
      this.authService.post('/api/get_access_rights_api', null).subscribe(
            res => {
                    var uar=res["uar"];
                    // console.log(uar);
                    var rcar=res["rcar"];
                    console.log(rcar);
                    console.log(items)
                    if(this._electronService.isElectronApp){
                        items.forEach((item) => {
                            if(!['Jobs', 'Appointments', 'Career Page', 'Candidates', 'Invite Users', 'Extracts', 'Email Campaign', 'Reports', 'Settings', 'Parser'].includes(item['text']))
                            {this.menuItems.push(item);}
                        });
                    }
                    else if (!rcar['is_ats_user']){
                        items.forEach((item) => {
                            if(!['Jobs', 'Appointments', 'Career Page'].includes(item['text']))
                            {this.menuItems.push(item);}
                        });
                    }
                    else{
                    items.forEach((item) => {
                        this.menuItems.push(item);
                    });}
            }, error => {
                // console.log(error);
            }
        )
    }
    remove_menu_item(item){
        this.menuItems = this.getMenu()
        console.log(this.menuItems)
        this.menuItems = this.menuItems.filter(a => a['text'] !== item);
        console.log(this.menuItems)
        // this.populate_menu(menuItems)
        return this.menuItems;
    }

}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from'@angular/forms';
import { AuthenticationService } from '../../../../core/authentication/authentication.service'
import { SharedService } from '../../../../core/shared/shared.service';

@Component({
  selector: '[app-freshness]',
  templateUrl: './freshness.component.html',
  styleUrls: ['./freshness.component.scss']
})
export class FreshnessComponent implements OnInit {
	@ViewChild('updateSource', {static: false}) updateSource;
	bsValue = new Date();
	bsConfig = {
      containerClass: 'theme-angle'
    }
	updateFreshnessForm=this.fb.group({
	    start_date: ['', Validators.required],
	    end_date: ['', Validators.required],
	  });
  // PIE
    // -----------------------------------
    pieData = [{
            'label': 'Fresh(<6 months old)',
            'color': '#42E14B',
            'data': 40
        }, {
            'label': 'Not Fresh(>6 months old)',
            'color': '#F77600',
            'data': 90
        }];
    pieOptions = {
        series: {
            pie: {
                show: true,
                innerRadius: 0,
                label: {
                    show: true,
                    radius: 0.8,
                    formatter: function(label, series) {
                        return '<div class="flot-pie-label">' +
                            // label + ' : ' +
                            Math.round(series.percent) +
                            '%</div>';
                    },
                    background: {
                        opacity: 0.8,
                        color: '#222'
                    }
                }
            }
        }
    };
  constructor(
  		private authService: AuthenticationService,
  		private fb: FormBuilder,
  		private shared: SharedService,
  	) { }

  ngOnInit() {
  	this.update_freshness();
  }
  update_freshness(){
  	// $ev.preventDefault();
  	this.authService.post('/api/update_fresness_report', {
      // 'end': this.shared.convert_date_to_string(value.end_date),
      // 'start': this.shared.convert_date_to_string(value.start_date),
    }).subscribe(
      res => {
      	console.log(res)
      	var fresh = parseInt(res['fresh_count']);
      	console.log(fresh);
      	var outdated = parseInt(res['outdated_count']);
      	console.log(outdated);
        this.pieData = [{
            'label': 'Fresh(<6 months old)',
            'color': '#42E14B',
            'data': fresh
        }, {
            'label': 'Not Fresh(>6 months old)',
            'color': '#F77600',
            'data': outdated
        }];
      },
      // err=> console.log(err)
    )
  }

}

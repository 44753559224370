import { Component, OnInit } from '@angular/core';
import { AuthenticationService} from '../../../core/authentication/authentication.service';
import { Params, Router, ActivatedRoute } from "@angular/router";
import { HttpHeaders,HttpClient} from '@angular/common/http';
// import { AngularTokenService } from 'angular-token';
import {environment} from '../../../../environments/environment.prod'

import {saveAs} from "file-saver";
@Component({
  selector: 'app-latest-tobu-version',
  templateUrl: './latest-tobu-version.component.html',
  styleUrls: ['./latest-tobu-version.component.scss']
})
export class LatestTobuVersionComponent implements OnInit {

  constructor(
    	private authService: AuthenticationService,
    	private router: Router,
      private http: HttpClient,
      // private tokenService: AngularTokenService,
      private route: ActivatedRoute
	  ) { }

  ngOnInit() {
  	 // this.http.get(this.tokenService.tokenOptions.apiBase + '/api/get_latest_tobu_drive_version', {responseType: 'blob'}).subscribe(
	  	// 	blob => {
	   //          console.log(blob)
	   //          saveAs(blob, 'latest_tobu_drive_version.txt');
	   //          this.router.navigate['/']
	   //      }, error => {
	   //            console.log(error);
	   //      } 
	  	// )
	  this.http.get(environment.baseUrl + '/api/get_latest_tobu_drive_version').subscribe(
      // this.http.get(this.tokenService.tokenOptions.apiBase + '/api/get_latest_tobu_drive_version').subscribe(
      res => {
        window.open(res['url']);
      },
      err=> console.log(err)
    )

  }

}
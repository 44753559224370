import { Component, OnInit } from '@angular/core';
import { ModalsService} from '../../modals/modals.service';

@Component({
  selector: '[app-navoptions-appointments]',
  templateUrl: './navoptions-appointments.component.html',
  styleUrls: ['./navoptions-appointments.component.scss']
})
export class NavoptionsAppointmentsComponent implements OnInit {

  constructor(
  	private modal: ModalsService) { }

  ngOnInit() {
  }
  load_modal(_modal_name){
  	this.modal.load_modal_by_name(_modal_name, {})
  }

}

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { Router, ActivatedRoute } from "@angular/router";
import { ModalsService } from '../../../layout/modals/modals.service';
import { NavbarService } from '../../../layout/header/navbar.service';
import { AuthenticationService} from '../../../core/authentication/authentication.service';
import { SharedService } from '../../../core/shared/shared.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
@Component({
  selector: '[app-tobu-drive-stats]',
  templateUrl: './tobu-drive-stats.component.html',
  styleUrls: ['./tobu-drive-stats.component.scss']
})
export class TobuDriveStatsComponent implements OnInit {
bsValue = new Date();
	bsConfig = {
      containerClass: 'theme-angle'
  	}

	page: number = 1;
  	itemsPerPage:any;
public singleData;

    constructor(
    	public http: HttpClient,
    	public router: Router, 
        public route: ActivatedRoute,
        private modal: ModalsService,
        private navbar: NavbarService,
        private authService: AuthenticationService,
        private shared: SharedService,
        private fb: FormBuilder
        ) {
        // ng2Table
        this.length = this.ng2TableData.length;
    }

    // ng2Table
    public rows: Array<any> = [];

    public columns: Array<any> = [
		{ title: 'Total Files Uploaded', name: 'total_files_uploaded' },
		{ title: 'Old Version Duplicate Resumes', name: 'old_version_duplicates' },
		{ title: 'Exact Duplicate Resumes', name: 'exact_duplicates' },
		{ title: 'Unique Resumes', name: 'unique_resumes' },
		{ title: 'Non Resumes', name: 'non_resumes' },
    ];
    public maxSize: number = 5;
    public numPages: number = 1;
    public length: number = 0;

    public config: any = {
        paging: true,
        sorting: { columns: this.columns },
        filtering: { filterString: '' },
        className: ['table-striped', 'table-bordered', 'mb-0']
    };

    public ng2TableData: Array<any> = [];

    public ngOnInit(): void {
    	this.load_data();
        // this.onChangeTable(this.config);
    }

    public changePage(page: any, data: Array<any> = this.ng2TableData): Array<any> {
        let start = (page.page - 1) * page.itemsPerPage;
        let end = page.itemsPerPage > -1 ? (start + page.itemsPerPage) : data.length;
        return data.slice(start, end);
    }

    public changeSort(data: any, config: any): any {
        if (!config.sorting) {
            return data;
        }

        let columns = this.config.sorting.columns || [];
        let columnName: string = void 0;
        let sort: string = void 0;

        for (let i = 0; i < columns.length; i++) {
            if (columns[i].sort !== '' && columns[i].sort !== false) {
                columnName = columns[i].name;
                sort = columns[i].sort;
            }
        }

        if (!columnName) {
            return data;
        }

        // simple sorting
        return data.sort((previous: any, current: any) => {
            if (previous[columnName] > current[columnName]) {
                return sort === 'desc' ? -1 : 1;
            } else if (previous[columnName] < current[columnName]) {
                return sort === 'asc' ? -1 : 1;
            }
            return 0;
        });
    }

    public changeFilter(data: any, config: any): any {
        console.log(data)
        let filteredData: Array<any> = data;
        this.columns.forEach((column: any) => {
            console.log(this.columns)
            if (column.filtering) {
                filteredData = filteredData.filter((item: any) => {
                    return item[column.name].match(column.filtering.filterString);
                });
            }
        });

        if (!config.filtering) {
            return filteredData;
        }

        if (config.filtering.columnName) {
            return filteredData.filter((item: any) =>
                item[config.filtering.columnName].match(this.config.filtering.filterString));
        }

        let tempArray: Array<any> = [];
        filteredData.forEach((item: any) => {
            let flag = false;
            this.columns.forEach((column: any) => {
                if (item[column.name].toString().match(this.config.filtering.filterString)) {
                    flag = true;
                }
            });
            if (flag) {
                tempArray.push(item);
            }
        });
        filteredData = tempArray;

        return filteredData;
    }

    public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
        if (config.filtering) {
            (<any>Object).assign(this.config.filtering, config.filtering);
        }

        if (config.sorting) {
            (<any>Object).assign(this.config.sorting, config.sorting);
        }
        console.log(this.ng2TableData)
        console.log(this.config)

        let filteredData = this.changeFilter(this.ng2TableData, this.config);
        let sortedData = this.changeSort(filteredData, this.config);
        // this.rows = page && config.paging ? this.changePage(page, sortedData) : sortedData;
        this.rows = sortedData;
        // this.length = sortedData.length;
    }
    public update_page(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }){
    	console.log(config);
    	console.log(page);
    	this.router.navigate([], {queryParams:
         {
          'page': page['page'],
        }, relativeTo : this.route, queryParamsHandling: "merge"})
    }

    public onCellClick(data: any): any {
        console.log(data);
    }
    load_data(){
    	this.authService.get('/api/load_tobu_drive_stats').subscribe(
    		res => {
    			console.log(res);
    			this.itemsPerPage=res['per_page']
	        	this.length=res['total_count']
	        	var i =0;
    			var input={};
    			this.ng2TableData =[];
    			// var u=res['tobu_drive_stats'];
    			// for (i = 0; i < u.length; i++) { 
    			// 	input= {
	    		// 	'total_files_uploaded': res['tobu_drive_stats'][i]['total_files_uploaded'],
			    //     'old_version_duplicates': res['tobu_drive_stats'][i]['old_version_duplicates'],
			    //     'exact_duplicates': res['tobu_drive_stats'][i]['exact_duplicates'],
			    //     'unique_resumes': res['tobu_drive_stats'][i]['unique_resumes'],
			    //     'non_resumes': res['tobu_drive_stats'][i]['unique_resumes'],
    			// 	}
				this.ng2TableData.push(res);
				// }
    		},
    		err=> console.log(err)
    	)
    
    }


}

import { Component, OnInit, OnDestroy, AfterViewInit} from '@angular/core';
import { AuthenticationService} from '../../../core/authentication/authentication.service'
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService }     from '../../../layout/header/navbar.service';
import { ModalsService } from '../../../layout/modals/modals.service';
// import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
//import { Job_role} from './model/job_role'
import { Params, Router, ActivatedRoute , NavigationEnd, NavigationStart} from "@angular/router";
//import { ListviewComponent } from '../resumereports/listview/listview.component';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CandidateService } from '../../shared/candidate/candidate.service';
// import {Page} from "tns-core-modules/ui/page";
import { HttpHeaders,HttpClient} from '@angular/common/http';
// import { AngularTokenService } from 'angular-token';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { SEOService} from '../../../core/shared/seo.service'
import {environment} from '../../../../environments/environment.prod'
import 'rxjs/add/operator/switchMap'; 
import 'rxjs/add/operator/map';
@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements AfterViewInit {
	title ='';
	content = ''
	author = ''
  banner_url = ''
  tags = []
  sub: any; 
  linkedin_url= ''
  facebook_url= ''
  twitter_url= ''
  isBrowser = false
          
  constructor(
  	public authService: AuthenticationService, 
    private fb: FormBuilder, 
    private router: Router, 
    private route: ActivatedRoute, 
    private shared: SharedService,
    private navbar: NavbarService,
    private modal: ModalsService,
    private candidate: CandidateService,
    private http: HttpClient,
    // private tokenService: AngularTokenService,
    public sanitizer: DomSanitizer,
    private seo: SEOService
    // private page: Page
    ) { }

  ngOnInit() {
    this.linkedin_url = 'http://www.linkedin.com/shareArticle?mini=true&url=' + environment.feURL+this.router.url
    this.facebook_url = 'https://www.facebook.com/sharer/sharer.php?u='+ environment.feURL + this.router.url
    this.twitter_url = 'https://twitter.com/share?url=' + environment.feURL + this.router.url
    this.isBrowser=this.shared.isBrowser();
     // this.sub=this.route.params.switchMap(params => {
     //  this.page.on('navigatedTo', (data) => {
     //     console.log("reached page")
     //   })

     // this.page.on('navigatingFrom', (data) => { 
     //     console.log("leaving page")
     //   })
    	// if(this.router.url.includes('apply')){
    	// 	this.modal.load_modal_by_name('job_application', {});
    	// }
     //  else{
     //    this.modal.hide_modal_by_name('job_application');
     //  }
     // if (event instanceof NavigationStart) {
        // console.log(params);
        // if(params != null && params != undefined){ 
        //   if(params['job_id']==='0'){
        //     return Observable.empty();
        //   }
        //   else
        //   {
        //     // return this.authService.post('/api/get_career_page_job_description', {'id': params['job_id']});
        //     console.log('getting career page job desc');
        //     return this.http.post(this.tokenService.tokenOptions.apiBase +'/api/get_career_page_job_description', {'id': params['job_id']});
        //     // return Observable.empty();
        //   }
        // }
        // else{
        //   console.log('not getting career page job desc')
        //   return Observable.empty();
        // }
      	// return this.authService.post('/api/get_blog_post', {'id': params['id']});
        // return this.http.post(this.tokenService.tokenOptions.apiBase +'/api/get_blog_post', {'id': params['id']});
      // }
    // }
    let params = this.route.snapshot.params;
    console.log(params);
    this.http.post(environment.baseUrl +'/api/get_blog_post', {'id': params['id']})
    // this.http.post(this.tokenService.tokenOptions.apiBase +'/api/get_blog_post', {'id': params['id']})
    .subscribe(
          res=> {
            console.log(res);
            if(res['blog_exists'])
            {
              this.title=res['blog']['title']
              this.content=res['blog']['content'];
              this.author=res['blog']['author']
              this.banner_url=res['banner_url']
              this.tags=res['blog']['tags']===null ? []:  res['blog']['tags'].split(',')
              this.seo.updateTitle(this.title.slice(0,50) + ' | Tobu Blog');
              this.seo.updateDescription(this.content.slice(0,150));
              if (this.tags != [] ){console.log('setting keywords');this.seo.updateKeywords(this.tags.join(','))}
            }
            else{
              this.router.navigate(['/']) 
            }
          },
          err=> console.log(err)
    )
  }
  ngAfterViewInit(){
    // this.sub.unsubscribe();
    
  }
  // test(){
  //   this.seo.updateTitle("abcdefg")
  // }


}

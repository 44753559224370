import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from'@angular/forms';
import { FileUploader , FileUploaderOptions,FileItem, ParsedResponseHeaders} from 'ng2-file-upload';
import { NavbarService } from '../../../layout/header/navbar.service';
import { ModalsService } from '../../../layout/modals/modals.service';
import { SharedService } from '../../../core/shared/shared.service';
import { HttpHeaders, HttpClient} from '@angular/common/http' 
import { environment } from '../../../../environments/environment';
import { Observable} from 'rxjs';

import {TokenService} from '../../../core/authentication/token.service';

import {saveAs} from "file-saver";


const URL = environment.baseUrl+'/api/create_mass_upload';
let maxFileSize=5*1024*1024;
@Component({
  selector: '[app-upload-resumes]',
  templateUrl: './upload-resumes.component.html',
  styleUrls: ['./upload-resumes.component.scss']
})
export class UploadResumesComponent implements OnInit {
  plo: any;
  job_role_id:any = '';
  company_id:any ='';
  create_mass_upload_tags:any;
  cv_sources:any;
  job_roles =[];
  current_user_id = '';
  auto_id_resumes = true;
  public uploader: FileUploader = new FileUploader({maxFileSize:maxFileSize, allowedMimeType: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'application/rtf', 'text/plain', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ], url: URL, itemAlias: 'resume_source', autoUpload: true, queueLimit: 10});
    public hasBaseDropZoneOver: boolean = false;
    // public hasAnotherDropZoneOver: boolean = false;

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }
   //uploadForm is not used to submit form, only added for validation of source input
  uploadForm=this.fb.group({
    sources: [''],
    create_mass_upload_tags: ['']
  });
  current_country ={};
  current_currency = {};
  current_salary_period: any;
  expected_currency = {};
  expected_salary_period: any;
  countries = []
  candidate_country_code ='';
  currencies = [];
  salary_period_options = ['Annual', 'Monthly', 'Hourly'];
  genders= [ 'Male', 'Female', 'Other'];
  subscription:any;
  rcar:any;
  uar:any;
  is_admin=false
  clicked_save=false;
  has_hit_limit =false;
  hide_candidate_without_resume_form = true;
  create_resume_details = new FormGroup({
    resume_full_name: new FormControl('', Validators.required),
    resume_date_of_birth: new FormControl(),
    resume_gender: new FormControl(),
    resume_email: new FormControl('', Validators.email),
    resume_phone_number: new FormControl(),
    resume_years_of_work_experience: new FormControl('', Validators.pattern('^(0|[1-9][0-9]*)$')),
    // resume_ctc: new FormControl(),
    resume_salary_amount: new FormControl('', Validators.pattern('^(0|[1-9][0-9]*)$')),
    resume_salary_period: new FormControl(),
    resume_salary_currency: new FormControl(),
    resume_expected_salary_amount: new FormControl('', Validators.pattern('^(0|[1-9][0-9]*)$')),
    resume_expected_salary_period: new FormControl(),
    resume_expected_salary_currency: new FormControl(),
    resume_current_employer: new FormControl(),
    resume_current_job_position: new FormControl(),
    resume_current_location: new FormControl(),
    resume_current_country: new FormControl(),
    // resume_current_zipcode: new FormControl(),
    resume_preferred_location: new FormControl(),
    resume_educational_qualification: new FormControl(),
    resume_notice_period: new FormControl(),
    resume_expected_ctc: new FormControl(),
    resume_willingness_to_relocate: new FormControl(),
    resume_reason_for_job_change: new FormControl(),
    resume_interview_availability: new FormControl(),
    resume_marital_status: new FormControl(),
    resume_college_attended: new FormControl(),
    resume_linkedin_profile_url: new FormControl(),
    resume_additional_info: new FormControl(),
  });
  uploaded_resume = null
  is_ats_user=false
  // create_mass_upload = new  FormGroup({
  //   job_role_id: new FormControl(),
  //   tags:new FormControl(),
  //   cv_source: new FormControl(),
  //   uploaded_resume_resume_source: new FormControl(),
  //   file_count: new FormControl(),
  // });
  // timestamp:any;
  // current_user_id: any;

  constructor(
  	private authService: AuthenticationService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private fb: FormBuilder,
  	private navbar: NavbarService,
    private modal: ModalsService,
    private shared: SharedService,
    private tokenService2: TokenService
  	) { }

  ngOnInit() {
  	// console.log();
  	this.get_plo();
    this.get_restrictions();
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    // this.cv_sources = [{
    //   'display': 'Database', 'value': 'Database'
    // }]
  	
  	
  }
  get_plo(){
    if (!this.is_called_from_inbox()){
  	  this.job_role_id = this.router.url.split('?')[0].split("/").reverse()[1];
    }
    else{
      this.job_role_id = '0';
    }
  	this.authService.post('/api/upload_resumes_modal', null).subscribe(
  		res => {
        console.log(res)
  			this.plo = res;
        this.countries=res['country_names'];
        this.currencies=res['currencies']
  			this.company_id =  res['company_id'];
        this.job_roles = res['job_roles'];
        this.current_user_id= res['current_user_id'];
        this.has_hit_limit=res['has_hit_limit'];
        this.cv_sources = [{
          'display': 'Database', 'value': 'Database'
        }]
        this.create_mass_upload_tags = []
        this.update_upload_parameters();
        this.upload_builder( this.company_id, this.current_user_id);
        // this.current_user_id = res['current_user_id'];
        // this.set_timestamp();
  			//var timestamp = '9187239871230'//new Date() - new Date(Date.UTC(1970, 0, 1));
  			
  			}
  		)
  }
  get_restrictions(){
    console.log("entered restrictions")
    this.subscription= this.shared.current_restrictions$.subscribe(
        res => {
          this.rcar = res["rcar"];
          this.is_ats_user=res["rcar"]["is_ats_user"]
          this.uar = res["uar"];
          // console.log(res);
          this.is_admin=this.uar['is_admin']
        }
    );
  }
  // set_timestamp(){
  //   var date:Date = new Date();
  //   var baseDate: Date = new Date(Date.UTC(1970, 0, 1));
  //   this.timestamp = this.current_user_id + (date.getTime() - baseDate.getTime()).toString();
  //   console.log(this.timestamp)
  // }
  update_upload_parameters(){
      var uo: FileUploaderOptions = {};
      let httpHeaders = new HttpHeaders({
           'Content-Type' : 'application/json',
           'Access-Control-Allow-Origin':'*',
           'Accept' : 'application/json',
           'Authorization': 'Bearer ' + this.tokenService2.getToken()
        });
      uo.headers = 
     [
       {name: 'access-token', value : localStorage.getItem("access_token")},
       {name: 'Authorization', value : 'Bearer '+ this.tokenService2.getToken()}, 
       // {name: 'client', value : localStorage.getItem("client")},
       // {name: 'expiry', value : localStorage.getItem("expiry")},
       // {name: 'token-type', value : localStorage.getItem("tokenType")},
       // {name: 'uid', value : localStorage.getItem("uid")},

     ];
      // console.log(uo);
      
      this.uploader.setOptions(uo);

      // console.log(this.uploader);
  //    this.upload_builder(this.job_role.id , this.create_mass_upload_tags, "1" , this.create_mass_upload_cv_source)

    }
  //  create_mass_uploadForm($ev, value: any) {
  //   $ev.preventDefault();
  //   for (let c in this.create_mass_upload.controls) {
  //       this.create_mass_upload.controls[c].markAsTouched();
  //   }
  //   this.upload_builder(value.job_role_id, value.user_upload_time_stamp, '43' , value.tags, value.file_count, value.cv_source)
  //   if (this.create_mass_upload.valid) {
  //       this._tokenService.post(
  //         'api/create_mass_upload', 
  //         {
  //           // 'job_role_id': value.job_role_id,
  //           // 'tags':value.tags,
  //           // 'cv_source': value.cv_source,
  //           // 'uploaded_resume_resume_source': value.uploaded_resume_resume_source,
  //           // 'file_count': value.file_count,
  //           //stage: value.stage
  //         }
  //         ).subscribe(
  //         res => {
  //             console.log(res.json());
  //             //this.router.navigate(res.id); navigate to the created job
  //             console.log(res);
  //         }, error => {
  //             console.log(error);
  //         }
  //       )
  //   }
      
  // }
//     upload_builder(_job_role_id, _upload_id, _resume_count, _cv_source){

//      this.uploader.onBuildItemForm = function(fileItem, form)
//      { 
//        form.append('job_role_id',_job_role_id);
//       //form.append('company_id', _company_id);
//     this.uploader.onBuildItemForm = function(fileItem, form)
//     { 

      
//      // form.append('upload_id', _upload_id);

//       //if (this._job_role_id){ form.append('job_role_id',_job_role_id, JSON.stringify(this._job_role_id)); } 
//       //if (this._company_id){ form.append('company_id',_company_id, JSON.stringify(this._company_id)); } 
//       return {fileItem, form} 

//     };
// this.uploader.onCompleteItem = (item:any, response:any, status:any)=> {
//             console.log( item, status);
//             //var resp=JSON.parse(response)
//             //this.uploadres=resp;
//             //console.log(this.uploadres);
//           //  console.log(this.upload_id);   
//             console.log(response)
//            var parser = new DOMParser()
//            var doc = parser.parseFromString(response,"text/html") 
//            console.log("bdbj",doc)
//            var id = doc.querySelector('form#resume_update_form input').getAttribute("value")
//            console.log("kjsa",id);
//           // window.location.href="/career/confirmdetails/"+id;
//                this.router.navigate(['/career/confirmdetails/',id]);    // pass route in the navigate function instead of Component Name


//         };
//      }
//   }
  upload_builder( _company_id,  _current_user_id){
    console.log("entered builder")
    this.update_upload_parameters();
    var date:Date = new Date();
    var baseDate: Date = new Date(Date.UTC(1970, 0, 1));
    var timestamp = _current_user_id + (date.getTime() - baseDate.getTime()).toString();
    var job_role_id = this.job_role_id;
    var cv_sources = this.cv_sources.map((x,i,a) => {return x.value})
    var create_mass_upload_tags = this.create_mass_upload_tags.map((x,i,a) => {return x.value})
    var auto_id_resumes = this.auto_id_resumes;
    // console.log(this.cv_sources)
    //this.set_timestamp();
    this.uploader.onBuildItemForm = function(fileItem, form)
    { 
      console.log("building form")
      //var timestamp ='9871239761'
      form.append('job_role_id',job_role_id);
      form.append('company_id', _company_id); 
      form.append('user_upload_timestamp', timestamp);
      form.append('file_count', this.queue.length);
      form.append('cv_source', cv_sources);
      form.append('tags', create_mass_upload_tags);
      form.append('auto_id_resumes', auto_id_resumes);
      return {fileItem, form} 
    };
	  this.uploader.onCompleteItem = (item:any, response:any, status:any, headers:any)=> {
      console.log( item, status,response, headers);
      var resp=JSON.parse(response);
      console.log(response);
      console.log(headers);
      if (headers['access-token']!== undefined && headers['access-token']!== ''){
      	localStorage.setItem('access_token', headers['access-token']);
    		localStorage.setItem("expiry", headers['expiry']);
        this.update_upload_parameters()
    	}
      
      // console.log(resp);  
    };
    this.uploader.onCompleteAll =()=> {
      this.navbar.set_notification('Success','Uploaded Resumes Completed', 'Refreshing Results');
      this.modal.hide_modal_by_name('upload');
      // setTimeout(function(){ location.reload(); }, 3000);
      this.shared.refresh(this.router.url);
    }
  }
  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        let data = JSON.parse(response); //success server response
        console.log(data)
    }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        let error = JSON.parse(response); //error server response
  }

  public requestAutocompleteCvSource = (text: string): Observable<any> => {
    const url = `/company_sources?q=${text}`;
    var resp =  this.authService.get(url);
    this.update_upload_parameters();
    this.upload_builder( this.company_id, this.current_user_id);
    return resp.map(data => data);

  };
  set_job_role_id(_val){
    this.job_role_id=_val.toString();
    // console.warn(this.job_role_id);
    // this.update_upload_parameters();
    this.upload_builder( this.company_id, this.current_user_id)
  }
  is_called_from_inbox(){
    return this.router.url.includes('inbox')
  }
  build_form(){
    // console.warn('got here')
    
  }
  set_cv_source($ev){
    // console.log($ev);
    // this.update_upload_parameters();
    this.upload_builder( this.company_id, this.current_user_id)
  }
  set_create_mass_upload_tags($ev){
    // console.log($ev);
    // this.update_upload_parameters();
    this.upload_builder( this.company_id, this.current_user_id)
  }
  update_force_upload(_checked){
    this.auto_id_resumes=_checked;
    // console.log(this.auto_id_resumes)
    // this.update_upload_parameters();
    this.upload_builder( this.company_id, this.current_user_id)
  }
  submitCreateResumeDetailsForm($ev, value){
    $ev.preventDefault();
    for (let c in this.create_resume_details.controls) {
            this.create_resume_details.controls[c].markAsTouched();
        }
    // if (this.submitdetailsForm.dirty && this.submitdetailsForm.valid)
    if (this.create_resume_details.valid) 
    {
      console.log(value);
      this.clicked_save=true
      // console.log(this.generated_params);
       this.authService.post('/api/create_candidate_without_resume', this.shared.merge_options(value, {job_role_id: this.job_role_id, cv_sources: this.cv_sources.map((x,i,a) => {return x.value}), tags: this.create_mass_upload_tags.map((x,i,a) => {return x.value})})
      ).subscribe(
        res => {
          // console.log(res);
          this.navbar.set_notification('Success','Candidate Details updated', res['notice']);
          this.modal.hide_modal_by_name('upload');
          // setTimeout(function(){ location.reload(); }, 3000);
          this.shared.refresh(this.router.url);
          // this.details.push(res['comment']);

        },
         err=> console.log(err)
      )
    }
  }
  select_country(){
    // console.log(this.update_resume_details.controls['resume_current_country'].value)
    this.candidate_country_code = this.create_resume_details.controls['resume_current_country'].value
  }
  public requestAutocompleteCityName = (text: string): Observable<any> => {
    const url = `/api/city_names?term=${text}&country=`+ this.candidate_country_code;
    console.log(url);
    return this.authService
        .get(url)
        .map(data => data);
  };
  public requestAutocompletePostalCode = (text: string): Observable<any> => {
    const url = `/api/postal_code_autocomplete?term=${text}&country=`+ this.candidate_country_code;
    return this.authService
        .get(url)
        .map(data => data);
  };
  get_sample_resume(){
      this.authService.downloadFile('/api/get_sample_resume', {}).subscribe(
        blob => {
              console.log(blob)
              saveAs(blob, 'sample_resume.xlsx');
          }, error => {
                console.log(error);
          } 
      )
    }

}

import { Injectable, Inject } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { AuthenticationService} from '../../core/authentication/authentication.service';
import { FormGroup, FormBuilder, FormControl,  FormArray} from '@angular/forms';
import { Observable , Subscription} from 'rxjs';
import { PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute } from "@angular/router";
import {HttpClient} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class SharedService {
  // rcar: any={};
  // uar: any={};
  restrictions= new ReplaySubject();
  sub: Subscription;
    // private paramsSource = new ReplaySubject<any>();
  current_restrictions$ = this.restrictions.asObservable();
 

  // private messageSource = new BehaviorSubject('default message');
  // currentMessage = this.messageSource.asObservable();

  constructor(
    private authService: AuthenticationService, 
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
    private http: HttpClient
    ) { }

  // changeMessage(message: string) {
  //   this.messageSource.next(message)
  // }
  load_restrictions(){
    // console.warn('leading restrictions')
    if(this.authService.isLoggedIn())
      {
        this.sub = this.authService.post('/api/get_access_rights_api', null).subscribe(
        res => {
                //var restrictionsSource=new Subject(res);
                //this.restrictions = restrictionsSource.asObservable()
                this.restrictions.next(res)
                // console.log(this.restrictions);
                // var rcarSource=new BehaviorSubject(res["rcar"]);
                // var rcar = rcarSource.asObservable()
                // console.log(rcar);
            }, error => {
            // console.log(error);
        });
      }
  }
  isBrowser(){
      return isPlatformBrowser(this.platformId)
  }
  isElectron(){
    var userAgent = navigator.userAgent.toLowerCase();
      return (userAgent.indexOf(' electron/') > -1) 
  }
  
    refresh(url) {
      // this.router.navigateByUrl('.', { skipLocationChange: true }).then((
      // this.router.navigateByUrl('.'))
      // return this.router.navigateByUrl(url);
      this.router.navigateByUrl('/loading', { skipLocationChange: true }).then(() => {
        setTimeout(()=>{ 
          this.router.navigateByUrl(url);
        }, 3000);
          
      }); 
    }

  //form checkbox filler
  onCheckChange(event, form_obj ,array_name) {
    const formArray: FormArray = form_obj.get(array_name) as FormArray;
    /* Selected */
    if(event.target.checked){
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    }
    /* unselected */
    else{
      // find the unselected element
      let i: number = 0;
      formArray.controls.forEach((ctrl: FormControl) => {
        if(ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }
  onCheckArrayChange(event ,array) {
    console.log(JSON.parse(event.target.value))
    console.log(event.target.checked)
    console.log(array)
    /* Selected */
    if(event.target.checked){
      // Add a new control in the arrayForm
      array.push(JSON.parse(event.target.value));
    }
    /* unselected */
    else{
      // find the unselected element
      let i: number = 0;
      array.forEach(item => {
        console.log(item)
        // console.log(JSON.parse(item))
        if(item['uploaded_resume_id'] ===JSON.parse(event.target.value)['uploaded_resume_id']) {
          console.log("removing")
          console.log(event.target.value)
          // Remove the unselected element from the arrayForm
          array.splice(i, 1);
          return;
        }
        i++;
      });
    }
    console.log(array)
    // console.log(array);
  }
  // onCheckSortChange(event, form_obj, array_name){
  //   const formArray: FormArray = form_obj.get(array_name) as FormArray;
  //   /* Selected */
  //   if(event.target.checked){
  //     // Add a new control in the arrayForm
  //     formArray.push(new FormControl(event.target.value));
  //   }
  //   /* unselected */
  //   else{
  //     // find the unselected element
  //     let i: number = 0;
  //     formArray.controls.forEach((ctrl: FormControl) => {
  //       if(ctrl.value == event.target.value) {
  //         // Remove the unselected element from the arrayForm
  //         formArray.removeAt(i);
  //         return;
  //       }
  //       i++;
  //     });
  //   }
  // }
  merge_options=function(obj1, obj2) {
    var obj3 = {};
    for (var attrname in obj1) { obj3[attrname] = obj1[attrname]; }
    for (var attrname in obj2) { obj3[attrname] = obj2[attrname]; }
    return obj3;
  }
  //takes form control values and converts them to comma separated string
  tags_form_control_to_string(formControl_obj){
    return (formControl_obj===undefined || formControl_obj===null || formControl_obj==='') ? '' : formControl_obj.map((x, i, a) => {return x.value}).join(',');
  }
  //takes string from url and converts to formcontrol object
  //the split.replace etc is to allow city names like san fran, US etc to be allowed as tags without separating.
  tags_string_to_form_control(tag_string){
    return (tag_string=== undefined || tag_string === null || tag_string==='') ? null : tag_string.split(", ").join("| ").split(',').map((x, i, a) => {return {'display': x.split("| ").join(", "), 'value': x.split("| ").join(", ")}})
  }
  string_null_check(string){
    return (string=== undefined || string === null || string[0]=== undefined || string[0]==='') ? '' : string 
  }

  convert_hash_to_array(hash){
    return Object.keys(hash).map(function(key) {return {key: key, value: hash[key]};});

  }
  convert_arr_of_hash_to_array(arr){
    let result = [];
    for (var hash of arr) {
      // console.log(hash); // prints values: 10, 20, 30, 40
      result.push(Object.keys(hash).map(function(key) {return {key: key, value: hash[key]};})[0]);
    }
    return result
    

  }
  convert_date_to_query_string(date){
    if (date ===undefined || date === null || date ===''){
      return ''
    }
    else{
    var d= new Date(date);
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return d.getDate().toString()+" "+months[d.getMonth()]+" "+d.getFullYear().toString();
    }
  }
  convert_date_time_to_string(date, time){
    if (date ===undefined || date === null || date ===''){
      return ''
    }
    else{
    var d= new Date(date);
    // var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return (d.getMonth()+1).toString()+"/" +d.getDate().toString()+"/"+d.getFullYear().toString()+ " " +time;
    }
  }

  convert_date_to_string(date){
    if (date ===undefined || date === null || date ===''){
      return ''
    }
    else{
    var d= new Date(date);
    // var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return (d.getMonth()+1).toString()+"/" +d.getDate().toString()+"/"+d.getFullYear().toString();
    }
  }
  convert_date_to_string_with_space(date){
    if (date ===undefined || date === null || date ===''){
      return ''
    }
    else{
    var d= new Date(date);
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return d.getDate().toString()+" " +months[(d.getMonth())]+" "+d.getFullYear().toString();
    }
  }
  is_hash_empty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }


  is_hash_equal(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if (
        areObjects && !this.is_hash_equal(val1, val2) ||
        !areObjects && val1 !== val2
      ) {
        return false;
      }
    }

    return true;
  }
  is_hash_equal_with_open_result(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (Math.abs(keys1.length - keys2.length)>1.1) {
      return false;
    }

    for (const key of keys1) {
      if(key!='open_result')
      {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = this.isObject(val1) && this.isObject(val2);
        if (
          areObjects && !this.is_hash_equal(val1, val2) ||
          !areObjects && val1 !== val2
        ) {
          return false;
        }
      }
    }

    return true;
  }
  isObject(object) {
    return object != null && typeof object === 'object';
  }
  generate_times(){
    var x = 15; //minutes interval
    var times = []; // time array
    var tt = 0; // start time
    var ap = [' AM', ' PM']; // AM-PM
    //loop to increment the time and push results in array
    for (var i=0;tt<24*60; i++) {
      var hh = Math.floor(tt/60); // getting hours of day in 0-24 format
      var mm = (tt%60); // getting minutes of the hour in 0-55 format
      if(hh%12===0){
        times[i] = ("12:" + ("0" + mm).slice(-2) + ap[Math.floor(hh/12)]); // pushing data in array in [00:00 - 12:00 AM/PM format]
      }
      else{
      
      times[i] = ("0" + (hh % 12)).slice(-2) + ':' + ("0" + mm).slice(-2) + ap[Math.floor(hh/12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
      }
      tt = tt + x;
    }
    var b = times.splice(0,36);
    times = times.concat(b);
    return times;
  }
  //get raw params as a has from url
  sortParams(link) {
    let queryParams = link.split('?')[1];
    if(queryParams===undefined){
      queryParams=''
      return {};
    }
    let params = queryParams.split('&');
    // console.log(params)
    let pair = null;
    let data = {};
    params.forEach((d) => {
      pair = d.split('=');
      data[`${pair[0]}`] = pair[1].replace(/%20/g, " ");
    });
    return data;
  }
  // validateNumber(control){
  //   if (typeof +control.value === "number" && !isNaN(+control.value)) return null;
  // }
  validateNumber() {
    return (control): { [key: string]: boolean } | null => {
        if (control.value !== undefined && (isNaN(control.value) )) {
            return { 'numeral': true };
        }
        return null;
    };
  }
  get_ip(){
  return this.http.get<{ip:string}>('https://jsonip.com')
    // .subscribe( data => {
    //   console.log('th data', data);
    //   return  data
    // })
    }

isEmpty(obj) {
    return Object.keys(obj).length === 0;
}
remove_value_from_array(_key, _array){
  console.log(_key)
  console.log(_array)
  // const myArray = [1, 2, 3, 4, 5];

  const index = _array.indexOf(_key);
  console.log(index)
  _array.splice(index, 1);
  console.log(_array)
  return _array
}

}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from'@angular/forms';
import { AuthenticationService } from '../../../../core/authentication/authentication.service'
import { SharedService } from '../../../../core/shared/shared.service';

@Component({
  selector: '[app-job-titles]',
  templateUrl: './job-titles.component.html',
  styleUrls: ['./job-titles.component.scss']
})
export class JobTitlesComponent implements OnInit {
	@ViewChild('updateJobTitles', {static: false}) updateJobTitles;
	bsValue = new Date();
	bsConfig = {
      containerClass: 'theme-angle'
  }
	updateJobTitlesForm=this.fb.group({
	    start_date: ['', Validators.required],
	    end_date: ['', Validators.required]
	});
    barData: any;
    barOptions = {
        series: {
            bars: {
                align: 'center',
                lineWidth: 0,
                show: true,
                barWidth: 0.6,
                fill: 0.9
            }
        },
        grid: {
            borderColor: '#eee',
            borderWidth: 1,
            hoverable: true,
            backgroundColor: '#fcfcfc'
        },
        tooltip: true,
        tooltipOpts: {
            content: function(label, x, y) { return x + ' : ' + y; }
        },
        xaxis: {
            tickColor: '#fcfcfc',
            mode: 'categories'
        },
        yaxis: {
        	minTickSize:1,
            min: 0,
            // position: ($scope.app.layout.isRTL ? 'right' : 'left'),
            tickColor: '#eee'
        },
        shadowSize: 0
    };

  constructor(
  	private authService: AuthenticationService,
  	private fb: FormBuilder,
  	private shared: SharedService,) { }
  ngOnInit() {
  	var d = new Date();
 	d.setDate(d.getDate()-14000);
  	this.updateJobTitlesForm.patchValue({
        start_date: d,
        end_date: new Date(),
    });
    setTimeout(()=>{
      this.updateJobTitles.nativeElement.click();
    }, 2000)
  }
  submitUpdateJobTitlesForm($ev, value){
  	$ev.preventDefault();
  	this.authService.post('/api/update_job_titles_report', {
      'end': this.shared.convert_date_to_string(value.end_date),
      'start': this.shared.convert_date_to_string(value.start_date),
    }).subscribe(
      res => {
      	console.log(res)
		this.barData= [{
			"label": "Resume Count",
			"color": "#F8D732",
		    "data": res['data']
		}];
      },
      // err=> console.log(err)
    )
  }
}

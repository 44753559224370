import { Component, OnDestroy } from '@angular/core';
import { ModalsService} from '../../modals/modals.service';
import { ActivatedRoute, Route, Router} from'@angular/router';
import { Subscription } from 'rxjs'
import { NavbarService }     from '../navbar.service';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { SharedService } from '../../../core/shared/shared.service';
import { JobsService } from '../../../routes/jobs/jobs.service'
import {saveAs} from "file-saver";



const swal = require('sweetalert');
@Component({
  selector: '[app-navoptions-resumereports]',
  templateUrl: './navoptions-resumereports.component.html',
  styleUrls: ['./navoptions-resumereports.component.scss']
})
export class NavoptionsResumereportsComponent implements OnDestroy {
  params = {};
  url_params ={};
  concat_params ={};
  // current_page={};
  uar:any;
  sub: Subscription;
  sub2:Subscription;
  sub3:Subscription;
  resumes_selected=false;
  is_job_closed = false;
  search_redirect_params={};
  is_job_shared = false;
  is_job_internal_shared = false
  constructor(
  	private modal: ModalsService,
    private route: ActivatedRoute,
    private navbar: NavbarService,
    private authService: AuthenticationService,
    private shared: SharedService,
    private router: Router,
    private jobs: JobsService
  	) { }

  ngOnInit() {
    // console.log(this.route.snapshot.params);
    // console.log(this.navbar.current_page$);
     // console.log('reached here');
     this.get_uar()
    this.url_params = this.jobs.get_params_from_url(this.router.url);
    console.log(this.url_params)
    this.authService.post('/api/is_job_closed', this.url_params)
    .subscribe(
        res=> {
          this.is_job_closed = res['is_closed'];
          // console.log(res);
        }
    )

    this.authService.post('/api/is_job_shared', this.url_params)
    .subscribe(
        res=> {
          this.is_job_shared = res['is_job_shared'];
          this.is_job_internal_shared = res['is_job_internal_shared']
          console.log(res);
        }
    )
    // console.log(this.url_params)
    this.sub = this.navbar.current_page$.subscribe(
      res => {
        // console.log(res);
        // this.current_page = res;
        this.params = res['params'];
    });
    this.sub3 = this.navbar.select_all_candidates$
      .subscribe(params => 
         {
          console.log(params)
          if(params==true){
            this.route.queryParams.subscribe(queryParams => {
              // this.concat_params =this.shared.merge_options( queryParams, this.url_params);
              this.concat_params =this.shared.merge_options( this.concat_params, {'select_all_candidates': true})
              // this.concat_params =this.shared.merge_options( this.concat_params, {'url': this.router.url})

            })
          }
         }
       );

    this.load_params();
    this.get_search_db_params();
  }
  ngOnDestroy(){
    this.sub.unsubscribe;
    this.sub3.unsubscribe;
  }

  load_modal(_model_name, _size, _params){
  	// this.modal.load_modal(_model_name, _size, _params);
    this.modal.load_modal_by_name(_model_name, _params)

  }
  get_uar(){
    this.authService.post('/api/get_user_access_rights_api', null).subscribe(
      res => {
          this.uar =  res;
          console.log(res);
      }, error => {
          // console.log(error);
      }
    )
  }
  get_search_db_params(){
    // console.log(this.url_params);
    this.authService.post('/api/get_search_db_params', this.url_params).subscribe(
      res=> {
        console.log(res);
        this.search_redirect_params=res;
      }, 
      err=> {
        console.log(err)
      }
    )
  }
  go_to_search(){
    this.router.navigate(['/candidates/inbox/view'], { queryParams: this.search_redirect_params });
  }
  deleteResumes() {
      swal({
          title: 'Are you sure?',
          text: 'You will not be able to recover this file!',
          icon: 'warning',
          buttons: {
              cancel: {
                  text: 'Cancel!',
                  value: null,
                  visible: true,
                  className: "",
                  closeModal: true
              },
              confirm: {
                  text: 'Yes, delete it!',
                  value: true,
                  visible: true,
                  className: "bg-danger",
                  closeModal: true
              }
          }
      }).then((isConfirm) => {
          if (isConfirm) {
            console.log(this.concat_params)
              this.authService.post('/api/delete_candidates', this.concat_params)
              .subscribe(
                res =>{ 
                  // console.log(res);
                  this.navbar.set_notification('Success','Resumes Deleted', 'This page will refresh now');
                  // setTimeout(function(){ location.reload(); }, 3000);
                  this.shared.refresh(this.router.url);
                },
                // err => console.log(err)
              )
              swal('Deleted!', 'The selected candidates were deleted.', 'success');
              //refresh view
          } else {
              swal('Cancelled', 'Your candidates shall stay', 'error');
          }
      });
  }
  download_candidates(){
    // this.authService.downloadFile(
    //       '/api/download_actual_resumes_for_job_api', this.shared.merge_options(value, this.concat_params)
    //       ).subscribe(
    //       res => {
    //           // saveAs(res, 'candidates.csv');
    //           console.log(res)
    //           // this.modal.hide_modal('small');
    //           this.modal.hide_modal_by_name('download_csv')
    //           this.clicked_save =false;
    //           if(res==null)
    //           {this.navbar.set_notification('Success', 'Tracker Generated', 'Please Check your email for updated tracker, page wil refresh now.');
    //           // setTimeout(function(){ location.reload(); }, 3000);
    //           this.shared.refresh(this.router.url);
    //           }
            
    //           else{
    //             saveAs(res, 'candidates.csv');
    //           }           
              
    //           //feedback pending
    //       }, error => {
    //           // console.log(error);
    //       }
    //     )
    // if (!this.select1000['selected']){
      console.log(this.concat_params)
      // console.log('reached here')
      this.authService.downloadFile(
        // '/api/download_actual_resumes_for_job_api', this.shared.merge_options(value, this.concat_params)
        '/api/download_actual_resumes_for_job', this.concat_params
      ).subscribe(res => {
              saveAs(res );
              // saveAs(res, 'search_result_export'+this.search_query_id+'.zip');
              // const blob = new Blob([res], {
              //   type: 'application/zip'
              // });
              // const url = window.URL.createObjectURL(blob);
              // window.open(url);

          });
    // }
    // else{
    //   // console.log('reached point 2')
    //   this.authService.post(
    //     '/api/download_actual_resumes_for_search_all', 
    //     {
    //       'search_results': this.concat_params['uploaded_resume_ids'], 
    //       'resume_search_query_id': this.search_query_id, 
    //       'select1000' : this.select1000['selected'],
    //       'selection_count': this.select1000['count'],
    //       'url': this.router.url
    //     }
    //   ).subscribe(res => {
    //           // console.log(res);
    //           if(res['upgrade']==='Premium'){setTimeout(()=>{ this.modal.load_modal_by_name('upgrade_plan', {}) }, 3000)}
    //           else{this.navbar.set_notification('Success','Email Sent', res['message']);}
    //       });
    //   }
    // }
  }
  load_params(){
    this.navbar.resume_reports_selected$
       .subscribe(params => 
         {this.concat_params = this.shared.merge_options({'resume_report_ids': params, 'tobu_url': this.router.url}, this.url_params) 
         // console.log(this.concat_params);
       }
       );
    this.sub2 = this.navbar.resumes_selected$.subscribe(
      res => {
        // console.log(res);
        this.resumes_selected=(res !== undefined && res!== null && res !== '' && res.length>0);
    });
  }
  open_job(){
    this.navbar.set_notification('Success','Opening Job', 'This might take a few seconds');
    this.authService.post('/api/open_job_role', this.url_params)
    .subscribe(
      res=> {
        // console.log(res);
        this.is_job_closed =false;
        this.navbar.set_notification('Success','Done, Job Role opened', 'This page will refresh now');
        // setTimeout(function(){ location.reload(); }, 3000);
        this.shared.refresh(this.router.url);
      },
      // err=> console.log(err)
      )
  }
  close_job(){
    this.navbar.set_notification('Success','Closing Job', 'This might take a few seconds');
    this.authService.post('/api/close_job_role', this.url_params)
    .subscribe(
      res=> {
        // console.log(res);
        this.is_job_closed =true;
        this.is_job_closed =false;
        this.navbar.set_notification('Success','Done, Job Role Closed', 'This page will refresh now');
        // setTimeout(function(){ location.reload(); }, 3000);
        this.shared.refresh(this.router.url);
      },
      // err=> console.log(err)
      )
  }
  deleteJob() {
      swal({
          title: 'Are you sure?',
          text: 'Your will not be able to recover this job in the future',
          icon: 'warning',
          buttons: {
              cancel: {
                  text: 'No, cancel please!',
                  value: null,
                  visible: true,
                  className: "",
                  closeModal: true
              },
              confirm: {
                  text: 'Yes, delete it!',
                  value: true,
                  visible: true,
                  className: "bg-danger",
                  closeModal: true
              }
          }
      }).then((isConfirm) => {
          if (isConfirm) {
            // console.log(this.params)
              this.authService.post('/api/delete_job_role', this.params)
              .subscribe(
                res => {
                  // console.log(res);
                  this.router.navigate(['/jobs/list'])},
                // err => console.log(err)
              )
              swal('Deleted!', 'The selected job were deleted.', 'success');
          } else {
              swal('Cancelled', 'The job shall stay', 'error');
          }
      });
  }

  shareJob(){
    this.authService.post('/api/share_job_to_career_page', this.url_params).subscribe(
      res=> {
        // console.log(res);
        this.is_job_shared =true;
        this.navbar.set_notification('Success','Done, Job Role Shared', '');
        // setTimeout(function(){ location.reload(); }, 3000);
      },
      // err=> console.log(err)
      )
  }
  unshareJob(){
    this.authService.post('/api/remove_job_from_career_page', this.url_params).subscribe(
      res=> {
        // console.log(res);
        this.is_job_shared =false;
        this.navbar.set_notification('Success','Done, Job Role Removed from Career Page', '');
        // setTimeout(function(){ location.reload(); }, 3000);
      },
      // err=> console.log(err)
      )
  }

  shareInternalJob(){
    this.authService.post('/api/share_job_internally', this.url_params).subscribe(
      res=> {
        // console.log(res);
        this.is_job_shared =true;
        this.navbar.set_notification('Success','Done, Job Role Shared to Referral Page', '');
        // setTimeout(function(){ location.reload(); }, 3000);
      },
      // err=> console.log(err)
      )
  }
  unshareInternalJob(){
    this.authService.post('/api/unshare_job_internally', this.url_params).subscribe(
      res=> {
        // console.log(res);
        this.is_job_shared =false;
        this.navbar.set_notification('Success','Done, Job Role Removed from Referral Page', '');
        // setTimeout(function(){ location.reload(); }, 3000);
      },
      // err=> console.log(err)
      )
  }

}

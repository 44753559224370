import { Component, OnInit, Inject, Injectable } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { AuthenticationService} from '../../../core/authentication/authentication.service';
import { NavbarService } from '../../../layout/header/navbar.service';
import { ModalsService } from '../../../layout/modals/modals.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { SharedService } from '../../../core/shared/shared.service';
import { ElectronService } from 'ngx-electron';
// const win = (<any>window)
import { PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
let win = undefined;
@Component({
  selector: '[app-configure-send-only-email]',
  templateUrl: './configure-send-only-email.component.html',
  styleUrls: ['./configure-send-only-email.component.scss']
})
export class ConfigureSendOnlyEmailComponent implements OnInit {


  	plo: any = {};
	uar: any = {};
	rcar: any = {};
	current_user_email: any = '';
	email_accounts: any = [];
	mail_servers: any = [];
	own_email_accounts = [];
	error_mail_server: any;
	email_templates:any;
	is_configure_selected = false;
	configureCustomEmailForm= this.fb.group({
		mail_server: [''],
		email: ['', Validators.compose([Validators.required, CustomValidators.email])],
		password: ['', Validators.required],
		custom_incoming_mail_server: ['', Validators.required],
		custom_outgoing_mail_server: ['', Validators.required],
		custom_outgoing_port: ['', Validators.required],
		microsoft_exchange_mail_server: [''],
		mail_server_type: ['IMAP']
	  });
	configureEmailTemplateForm = this.fb.group({
		// email_candidate_templates: [''],
		email_candidate_subject: ['']
	  });
	is_other_visible = true;
	clicked_save =false;


  constructor(
  	private authService: AuthenticationService,
  	private router: Router,
  	private route: ActivatedRoute,
    private navbar: NavbarService,
    private fb: FormBuilder,
    private shared: SharedService,
    private modal: ModalsService,
    private electronService:ElectronService,
  	@Inject(PLATFORM_ID) private platformId: any
        ) {
    
    if(isPlatformBrowser(platformId))
    { win = (<any>window)} 
	}

  ngOnInit() {
  	this.get_plo();
  }
  get_plo() {
  	this.route.queryParams.subscribe(
      queryparams=> {
        this.navbar.set_current_page('configure-email', queryparams);
      }
    )
		this.authService.post('/api/configure_email', null).subscribe(
			res => {
				this.current_user_email = res['current_user_email'];
				this.mail_servers = res['mail_servers'];
				this.email_accounts = res['email_accounts'];
				this.error_mail_server = res['error_mail_server'];
				this.own_email_accounts = res['own_email_accounts'];
				this.email_templates = res['email_templates'];
				// console.log(res);
			},
			error => {
				// console.log(error);
			}
		)
	}
	checkOtherInput(_val){
		this.configureCustomEmailForm.patchValue({
			mail_server: 'IMAP'
		})
		if(_val==="Custom"){
			this.is_other_visible=true;
		}
		else{
			this.is_other_visible=false;
		}
	}
	submitConfigureCustomEmailForm($ev, _val){
		$ev.preventDefault();
        for (let c in this.configureCustomEmailForm.controls) {
            this.configureCustomEmailForm.controls[c].markAsTouched();
        }
		
		// console.log(_val);
		if (this.configureCustomEmailForm.valid) {
			this.clicked_save =true;
			this.authService.post('/api/save_email_account', _val)
			.subscribe(
				res => {
					console.log(res);
					this.clicked_save =false;
					// this.modal.hide_modal('big');
					this.modal.hide_modal_by_name('configure_email')
					this.navbar.set_notification('Success','Email Configure', res['notice']);
					// setTimeout(function(){ location.reload(); }, 3000);
					this.shared.refresh(this.router.url);
				},
				// err => console.log(err)
			)
		}
		else{
			this.navbar.set_notification('Warning', 'Please complete form', 'Please ensure all fields are filled');
		}
	}
	configure_email(_account){
		
    	this.fetch_account_detail(_account).subscribe(
    		res=> {
    			// console.log(res);
    			if(this.electronService.isElectronApp)
		          {
		            win.api.send("openExternalURL", res['url'])
		          }
		          else
		    			{
		            window.location.href = res['url']
		          }
    		},
    		// err=> console.log(err)
    	)
		
	}
	fetch_account_detail(_account){
		if (_account ==='microsoft'){
			return this.authService.get('/api/configure_email/microsoft')
		}else if (_account ==='gmail'){
			return this.authService.get('/api/configure_email/gmail')
		}else if (_account ==='zoho'){
			return this.authService.get('/api/configure_email/zoho')
		}
	}
}
import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
// import { AngularTokenService } from 'angular-token';
import { HttpHeaders,HttpClient} from '@angular/common/http';
@Component({
  selector: 'formly-field-stepper',
  template: `
  <mat-horizontal-stepper>
    <mat-step 
      *ngFor="let step of field.fieldGroup; let index = index; let last = last;">
      
      <formly-field [field]="step" ></formly-field>

      <div>
        <button matStepperPrevious *ngIf="index !== 0"
          class="btn btn-primary"
          type="button" style="margin-right:10px">
          Back
        </button>

        <button matStepperNext *ngIf="!last"
          class="btn btn-primary" type="button"
          [disabled]="!isValid(step)"  style="margin-right:10px">
          Next
        </button>

        <button *ngIf="last" class="btn btn-primary"
          [disabled]="!form.valid || clicked_save"
          type="submit" style="margin-right:10px">
          Submit
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
`,
})
export class FormlyFieldStepper extends FieldType {

  isValid(field: FormlyFieldConfig) {
    if (field.key) {
      return field.formControl.valid;
    }

    return field.fieldGroup.every(f => this.isValid(f));
  }
  // submit_partial(index, field){
  //   if(index===0){
  //     console.log(this.model['email']);
  //   }
    
  // }
}
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl} from'@angular/forms';
import { JobsService } from '../../jobs/jobs.service';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service';

import { ModalsService } from '../../../layout/modals/modals.service';



@Component({
  selector: '[app-source-from-vendor]',
  templateUrl: './source-from-vendor.component.html',
  styleUrls: ['./source-from-vendor.component.scss']
})
export class SourceFromVendorComponent implements OnInit {
  url_params ={};
  // is_shared_with_recruitment_agency=false;
  valForm = this.fb.group(
  {
    agency_ids: this.fb.array([]),
  })
  agencies =[];
  selectedAll:any;
  clicked_save =false;
  constructor(
  	private authService: AuthenticationService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private fb: FormBuilder,
    private jobs: JobsService,
    private shared: SharedService,
    private navbar: NavbarService,
    private modal: ModalsService

  	) { }

  ngOnInit() {
    this.get_plo();
  }
  get_plo(){
    this.url_params = this.jobs.get_params_from_url(this.router.url);
    console.log(this.valForm.value)
    this.authService.post('/api/load_share_with_multiple_recruitment_agencies_modal', this.url_params).subscribe(
      res => {
          // console.log(res);
          this.agencies = res['all_active_recruitment_agencies']
          this.valForm.setControl('agency_ids', this.fb.array(this.populate_agencies() || []));
          console.log(this.valForm.value)
      }, error => {
          // console.log(error);
      }
    )
  }
  onCheckChange($event){
    this.shared.onCheckChange($event, this.valForm ,'agency_ids');
  }
  submitForm($ev, value: any) {
    // console.log(value);
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
        this.valForm.controls[c].markAsTouched();
    }

    if (this.valForm.valid) {
      console.log(this.shared.merge_options(value, this.url_params))
      this.clicked_save =true;
        this.authService.post(
          '/api/share_with_multiple_recruitment_agencies', this.shared.merge_options(value, this.url_params)
          ).subscribe(
          res => {
              // console.log(res);
              this.clicked_save =false;
              this.navbar.set_notification('Success','Shared with vendors', 'The job has been shared with the vendors selected');
              this.modal.hide_modal_by_name('source_from_vendor');
              //this.router.navigate(res.id); navigate to the created job
              // console.log(res);
          }, error => {
              // console.log(error);
          }
        )
    }
  }
  selectAll() {
        this.selectedAll = !this.selectedAll;

        for (var i = 0; i < this.agencies.length; i++) {
            this.agencies[i]['is_shared_with_recruitment_agency'] = this.selectedAll;
        } 
        this.valForm.setControl('agency_ids', this.fb.array(this.populate_agencies() || []));
  }
  checkIfAllSelected() {
      var totalSelected =  0;
      for (var i = 0; i < this.agencies.length; i++) {
            if(this.agencies[i]['is_shared_with_recruitment_agency']) totalSelected++;
        } 
    this.selectedAll = totalSelected === this.agencies.length;

  return true;
  }
  private populate_agencies(): any {
    // var as = {
    //       stage: [''],
    //       stage_type_id: [''],
    //       collaborators: ['']
    //   }
    var a =[]
    console.log(this.agencies)
    for (let agency of this.agencies) {
      if (agency['is_shared_with_recruitment_agency'])
      {a.push((agency['id']).toString())}
    }

    return a;
  }
}

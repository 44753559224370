import { Component, OnDestroy } from '@angular/core';
import { AuthenticationService } from '../../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params,NavigationEnd} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from'@angular/forms';
import { ModalsService } from '../../../../layout/modals/modals.service'
import { Subscription, Observable} from 'rxjs';
import { CandidateService } from '../candidate.service';
import { SharedService } from '../../../../core/shared/shared.service';
import { NavbarService } from '../../../../layout/header/navbar.service';
import { JobsService } from '../../../../routes/jobs/jobs.service';
import { filter } from 'rxjs/operators';
import { AngularEditorConfig } from '@kolkov/angular-editor';
const swal = require('sweetalert');

declare var $: any;
let Quill: any = null;
// import * as QuillNamespace from 'quill';
// let Quill: any = QuillNamespace;
// import ImageResize from 'quill-image-resize-module';
// Quill.register('modules/imageResize', ImageResize);

//temp fix summernote
// const origToString = Object.prototype.toString;
// Object.prototype.toString = function() {
//     'use strict';
//     if (this === null) return '[object Null]';
//     return origToString.call(this);
// };
//end temp fix

@Component({
  selector: '[app-interviews]',
  templateUrl: './interviews.component.html',
  styleUrls: ['./interviews.component.scss']
})
export class InterviewsComponent implements OnDestroy {
  generated_params ={};
  sub:Subscription;
  sub1:Subscription;
  sub2:Subscription;
  recruitment_stages =[];
  candidate_appointments= [];
  interviewers = [];
  clicked_schedule_appointment=false;
  email_account_ids = [];
  // interviewers_selected =[];
  bsValue = new Date();
  maxDate = new Date();
  is_send_email_selected = false;
  bsConfig = {
      containerClass: 'theme-angle'
  }
  times =this.shared.generate_times();
  current_stage_id ='';
  // Time picker
  // mytime: Date = new Date();
  // start_time: Date = new Date();
  // end_time: Date = new Date();
  scheduleInterviewForm=this.fb.group({
    candidate_appointment_id:[''],
    stage_id: [''],
    start_date: ['', Validators.required],
    end_date: ['', Validators.required],
    time_zone: [''],
    send_mail_to_candidate: [''],
    appointment_message: [''],
    interviewers: [''],
    interview_location: [''],
    online_link: [''],
    start_time: ['', Validators.required],
    end_time:['', Validators.required],
    interview_reminder:[''],
    interview_reminder_unit:['Minutes'],
    email_account_id: [''],
    content: ['']
  });
  editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '15rem',
        minHeight: '5rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        defaultFontName: 'Arial',
        // uploadUrl: '/api/upload_email_images',
        // uploadWithCredentials: false,
        toolbarHiddenButtons: [
          ['bold']
          ],
        customClasses: [
          {
            name: "quote",
            class: "quote",
          },
          {
            name: 'redText',
            class: 'redText'
          },
          {
            name: "titleText",
            class: "titleText",
            tag: "h1",
          },
        ]
      }
      editor_modules = {
      // toolbar: {
      //   container: [
      //     [{ 'font': [] }],
      //     [{ 'size': ['small', false, 'large', 'huge'] }],
      //     ['bold', 'italic', 'underline', 'strike'],
      //     [{ 'header': 1 }, { 'header': 2 }],
      //     [{ 'color': [] }, { 'background': [] }],
      //     [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      //     [{ 'align': [] }],
      //     ['link', 'image']
      //   ]
      // },
      // imageResize: true
    };
  
  contents: string='';
  constructor(
  	private authService: AuthenticationService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private fb: FormBuilder,
    private modal: ModalsService,
    private candidate: CandidateService,
    private shared: SharedService,
    private navbar: NavbarService,
    private job: JobsService
  	) { if (this.shared.isBrowser()) {
      Quill = require('quill');
      const ImageResize = require('quill-image-resize').default;
      Quill.register('modules/imageResize', ImageResize);
    }
    this.editor_modules = {
      toolbar: {
        container: [
          [{ 'font': [] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'align': [] }],
          ['link', 'image']
        ]
      },
      imageResize: true
    };
  }

  ngOnInit() {

    // this.load_summernote();
    // console.log(this.scheduleInterviewForm.get('candidate_appointment_id').value);
    // this.generated_params = this.candidate.get_params_from_url(this.router.url);
    // console.log(this.generated_params)
    // // if(this.is_called_from_job()){
    //   this.sub = this.authService.post('/api/load_interview_modal', this.generated_params).subscribe(
    //     res => {
    //       console.log(res);
    //       this.recruitment_stages = res['recruitment_stages'];
    //       this.candidate_appointments = res['candidate_appointments'];
    //       this.interviewers = res['collaborator_options'];
    //       console.log(this.candidate_appointments);
    //     },
    //     err => {console.log(err)}
    //   )
    // // }
    // this.load_interviews();
    // this.modal.load_modal_by_name('candidate', {});
    // this.sub1 = this.router.events.pipe(
    // filter(event => event instanceof NavigationEnd)  
    // ).subscribe((event: NavigationEnd) => {
    //   this.load_interviews();
    //   console.log(event.url);
    // });    
    this.sub1 = this.route.params.subscribe(
      res=> {
        this.load_interviews();
      }
    )   
    this.scheduleInterviewForm.get('start_date').valueChanges.subscribe(
        res => (this.set_end_date())
      )
  }
  ngOnDestroy(){
    // this.modal.hide_modal_by_name('candidate');
    this.sub1.unsubscribe;
    this.sub2.unsubscribe;
  }
  load_interviews(){
    this.sub2=this.fetch_interviews()
      .subscribe(
        res => {
          if(res['upgrade']==='Premium'){this.modal.hide_modal_by_name('candidate');setTimeout(()=>{ this.modal.load_modal_by_name('upgrade_plan', {}) }, 500)}
          else{
            if(res['notice']==='Access Denied')
            {
              this.router.navigate(['/candidate_inbox'])
            }
            this.recruitment_stages = res['recruitment_stages'];
            this.candidate_appointments = res['candidate_appointments'];
            this.interviewers = res['collaborator_options'];
            this.current_stage_id = res['current_stage_id'].toString();
            this.scheduleInterviewForm.patchValue({
              'stage_id': this.current_stage_id
            })
            this.email_account_ids = res['email_account_ids']
          }
          // console.log(this.candidate_appointments);
          // console.log(res);
        }, error => {
          // console.log(error);
        }
      )
  }
  fetch_interviews(){
    this.generated_params = this.candidate.get_params_from_url(this.router.url);
    // console.log(this.generated_params)
    if(this.router.url.includes('inbox')){
      return Observable.empty();
    }
    else
    {
      return this.authService.post('/api/load_interview_modal', this.generated_params)
    }
  
  }
  submitScheduleInterviewForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.scheduleInterviewForm.controls) {
        this.scheduleInterviewForm.controls[c].markAsTouched();
    }
    if (this.scheduleInterviewForm.dirty && this.scheduleInterviewForm.valid)
    // if (this.scheduleInterviewForm.valid) 
    {
      this.clicked_schedule_appointment=true;
      // console.warn($ev);
      // console.warn(value);
      // console.log(this.shared.convert_date_to_query_string(value.start_date));
      // console.log(this.shared.convert_date_time_to_string(value.end_date, value.end_time));
      // console.log(value.start_date);
      // console.log(value.start_time);
      //new appointment
      if(value.candidate_appointment_id==='')
      {
        this.authService.post('/api/schedule_interview', {
          'interview_end': this.shared.convert_date_time_to_string(value.end_date, value.end_time),
          'interview_start': this.shared.convert_date_time_to_string(value.start_date, value.start_time),
          'send_mail_to_candidate': value.send_mail_to_candidate,
          'appointment_message': value.content,
          'interview_location': value.interview_location,
          'online_link': value.online_link,
          'interviewers': value.interviewers,
          'stage_id': value.stage_id,
          'interview_reminder_unit': value.interview_reminder_unit,
          'interview_reminder': value.interview_reminder,
          'resume_report_id': this.generated_params['resume_report_id'],
          'email_account_id': value.email_account_id,
        }).subscribe(
          res => {
            // console.log(res);
            // this.navbar.set_notification('Success','Appointment Scheduled', 'Appointment successfully scheduled');
            // this.candidate_appointments.push(res['candidate_appointment']);
            // this.scheduleInterviewForm.reset();
            // this.clicked_schedule_appointment=false;
            this.navbar.set_notification('Success','Appointment Scheduled', 'Appointment successfully scheduled. this page will refresh now');
            // this.candidate_appointments.push(res['candidate_appointment']);
            // this.scheduleInterviewForm.reset();
            // this.clicked_schedule_appointment=false;
            // setTimeout(function(){ location.reload(); }, 3000);
            this.shared.refresh(this.router.url);
          },
          // err=> console.log(err)
        )
      }
      //reschedule
      else
      {
        this.authService.post('/api/update_interview', {
          'candidate_appointment_id': value.candidate_appointment_id,
          'interview_end': this.shared.convert_date_time_to_string(value.end_date, value.end_time),
          'interview_start': this.shared.convert_date_time_to_string(value.start_date, value.start_time),
          'send_mail_to_candidate': value.send_mail_to_candidate,
          'appointment_message': value.content,
          'interview_location': value.interview_location,
          'online_link': value.online_link,
          'interviewers': value.interviewers,
          'stage_id': value.stage_id,
          'interview_reminder_unit': value.interview_reminder_unit,
          'interview_reminder': value.interview_reminder,
          'resume_report_id': this.generated_params['resume_report_id'],
        }).subscribe(
          res => {
            if(res['upgrade']==='Premium'){this.modal.hide_modal_by_name('candidate');setTimeout(()=>{ this.modal.load_modal_by_name('upgrade_plan', {}) }, 500)}
            else{
              // console.log(res);
              this.navbar.set_notification('Success','Appointment Rescheduled', 'Appointment successfully re-scheduled. this page will refresh now');
              // this.candidate_appointments.push(res['candidate_appointment']);
              // this.scheduleInterviewForm.reset();
              // this.clicked_schedule_appointment=false;
              // setTimeout(function(){ location.reload(); }, 3000);
              this.shared.refresh(this.router.url);
            }
          },
          // err=> console.log(err)
        )
      }
    }
    // this.modal.hide_modal('big');
  }
  send_email_changed($ev){

    this.is_send_email_selected = $ev.target.checked;
    // this.load_summernote()

  }
  set_end_date(){
    // setTimeout(function(){ 
      // var val = this.scheduleInterviewForm.get('start_date').value; 
      // console.log(val);
      this.scheduleInterviewForm.patchValue({
        end_date: this.scheduleInterviewForm.get('start_date').value
      })
    // }, 3000)
    
  }
  set_end_time(){
    this.scheduleInterviewForm.patchValue({
        end_time: this.times[this.times.indexOf(this.scheduleInterviewForm.get('start_time').value)+2]
      })
  }
  cancel_appointment(_id){
    swal({
          title: 'Are you sure?',
          text: 'Your appointment will be removed from the calendar',
          icon: 'warning',
          buttons: {
              cancel: {
                  text: 'No, cancel please!',
                  value: null,
                  visible: true,
                  className: "",
                  closeModal: true
              },
              confirm: {
                  text: 'Yes, delete it!',
                  value: true,
                  visible: true,
                  className: "bg-danger",
                  closeModal: true
              }
          }
      }).then((isConfirm) => {
          if (isConfirm) {
            this.authService.post('/api/cancel_appointment', {'id': _id})
            .subscribe(
              res=>  {
                // console.log(res);
                if(res['upgrade']==='Premium'){this.modal.hide_modal_by_name('candidate');setTimeout(()=>{ this.modal.load_modal_by_name('upgrade_plan', {}) }, 500)}
                else{
                  this.load_interviews();
                }
              },
              // err=> console.log(err)
            )
              
          } else {
              swal('Cancelled', 'Your Appointment shall stay', 'error');
          }
      });
    
  }
  is_called_from_job(){
     return this.router.url.includes('listview')
  }
  reschedule_appointment(_id){
    this.scheduleInterviewForm.reset();
    // console.log(_id)
    this.authService.post('/api/get_candidate_appointment', {'id': _id.toString()})
    .subscribe(
      res=> {
        // console.log(res);
        this.scheduleInterviewForm.patchValue({

        'end_date': res['candidate_appointment']['end_date'],
        'start_date': res['candidate_appointment']['start_date'],
        'end_time': res['candidate_appointment']['end_time'],
        'start_time': res['candidate_appointment']['start_time'],
        'candidate_appointment_id': res['candidate_appointment']['id'],
        // 'interview_end': res['candidate_appointment']['end_date'], res['candidate_appointment']['end_time)'],
        // 'interview_start': this.shared.convert_date_time_to_string(res['candidate_appointment']['start_date'], res['candidate_appointment']['start_time)'],
        'interview_location': res['candidate_appointment']['location'],

        'online_link': res['candidate_appointment']['online_link'],
        'interviewers': res['candidate_appointment']['interviewers'],
        'stage_id': res['candidate_appointment']['recruitment_stage_id']
        })
      },
      // err=> console.log(err)
    )
  }
  // load_summernote(){
  //   $('#summernote').summernote({
  //           height: 230,
  //           dialogsInBody: true,
  //           callbacks: {
  //               onChange: (contents, $editable) => {
  //                   this.contents = contents;
  //                   // console.log(contents);
  //               }
  //           }
  //       });
  //       $('.note-popover').css({'display': 'none'});
  // }
  add_interviewer(){
    var job_params = this.job.get_params_from_url(this.router.url)
    console.log(job_params)
    this.modal.hide_modal_by_name('candidate');
    this.router.navigate(['jobs/candidates', job_params['job_role_id']]);
    this.modal.load_modal_by_name('manage_collaborators', job_params)
  }


}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ImageCropperComponent, CropperSettings, Bounds } from 'ngx-img-cropper';
// import { FileUploader , FileUploaderOptions} from 'ng2-file-upload';
import { AuthenticationService} from '../../../core/authentication/authentication.service';
import { NavbarService } from '../../../layout/header/navbar.service';
import { ModalsService } from '../../../layout/modals/modals.service';
import {Router} from '@angular/router';
import { SharedService } from '../../../core/shared/shared.service';


@Component({
  selector: '[app-edit-banner]',
  templateUrl: './edit-banner.component.html',
  styleUrls: ['./edit-banner.component.scss']
})
export class EditBannerComponent implements OnInit {
	name: string;
    data1: any;
    // data2:any;
    cropperSettings: CropperSettings;
    // cropperSettings2: CropperSettings;
    croppedImage:any;
    banner_file: any;
    image_blob:any;
    // image_blob2:any;
    banner_url: any; 
    // logo_url: any;
    edit_logo = true;
    edit_banner = true;
    contents='';

  	@ViewChild('cropper', {static: false}) cropper: ImageCropperComponent;
  	// @ViewChild('cropper2', {static: false}) cropper2: ImageCropperComponent;

    constructor(
        private authService: AuthenticationService,
        private navbar: NavbarService,
        private modal: ModalsService,
        private shared: SharedService,
        private router: Router
        ) {
        this.name = 'Angular2';
        this.cropperSettings = new CropperSettings();

        this.cropperSettings.noFileInput = true;

        this.cropperSettings.width = 700;
        this.cropperSettings.height = 212.5;

        this.cropperSettings.croppedWidth = 1400;
        this.cropperSettings.croppedHeight = 425;

        this.cropperSettings.canvasWidth = 700;
        this.cropperSettings.canvasHeight = 212.5;

        this.cropperSettings.minWidth = 350;
        this.cropperSettings.minHeight = 106.25;

        this.cropperSettings.cropperDrawSettings.strokeColor = 'rgba(0,0,0,.25)';
        this.cropperSettings.cropperDrawSettings.strokeWidth = 2;

        this.cropperSettings.rounded = false;

        this.data1 = {};
    }

  ngOnInit() {
  }
  fileChangeListener($event) {
        let image: any = new Image();
        let file: File = $event.target.files[0];
        console.log(file);
        let myReader: FileReader = new FileReader();
        let that = this;
        myReader.onloadend = function(loadEvent: any) {
            image.src = loadEvent.target.result;
            that.cropper.setImage(image);
        };
        this.banner_file=file;
        console.log(image);
        console.log(file);
        console.log(myReader);

        myReader.readAsDataURL(file);
    }

     save_banner(){
    // this.convert_b64_to_blob(this.data1.image)
        fetch(this.data1.image)
          .then((response) => {
            return response.blob();
          })
          .then((data) => {
            console.log(data);
            this.image_blob=data;
            let formData = new FormData(); 
            formData.append('file', this.image_blob, 'banner'); 
            console.log(formData);
            // this.modal.load_modal_by_name('spinner', {});
            this.authService.post_html('/api/save_banner_for_recruitment_company', formData).subscribe(
              (val) => {
                console.log(val);
                // this.modal.hide_modal_by_name('spinner');
                // this.load_images()
                this.navbar.set_notification('Success','Notice', val['notice']);
                // setTimeout(function(){ location.reload(); }, 1000);
                this.shared.refresh(this.router.url);
              });
            return false; 
          });
    
    }
    setRoundedMethod(value: boolean) {
        this.cropperSettings.rounded = value;
    }

    cropped(bounds: Bounds) {
        console.log(bounds);
        // console.log(this.croppedImage);
        console.log(this.data1);
        // this.save();
    }
    imageCropped($ev){
        this.croppedImage = $ev.base64;
        // console.log(this.data1.image)
        // this.save();
    }

}

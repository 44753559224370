import { Component, OnDestroy } from '@angular/core';
import { AuthenticationService} from '../../../core/authentication/authentication.service'
import { Router, ActivatedRoute, Params} from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { ModalsService } from '../../../layout/modals/modals.service'
import { Observable, Subscription} from 'rxjs';
import { SharedService } from '../../../core/shared/shared.service';
import { CandidateService } from '../candidate/candidate.service';
import { NavbarService } from '../../../layout/header/navbar.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

declare var $: any;
let Quill:any = null;
// import * as QuillNamespace from 'quill';
// let Quill: any = QuillNamespace;
// import ImageResize from 'quill-image-resize-module';
// Quill.register('modules/imageResize', ImageResize);
//temp fix summernote
// const origToString = Object.prototype.toString;
// Object.prototype.toString = function() {
//     'use strict';
//     if (this === null) return '[object Null]';
//     return origToString.call(this);
// };
//end temp fix

@Component({
  selector: '[app-share-candidate]',
  templateUrl: './share-candidate.component.html',
  styleUrls: ['./share-candidate.component.scss']
})
export class ShareCandidateComponent implements OnDestroy {
  own_email_accounts = [];
  email_templates: any;
  signatures: any;
  loading = false;
  to_emails ='';
  showCC = false;
  showBCC = false;
  contents: string='';
  params ={};
  called_from ='';
  url='';
  sub: Subscription;
  //to_email_list = ;
  email_client_form= this.fb.group({
  // resume_report_id: [this.route.snapshot.params['client_id']],
  // job_role_id: [this.route.parent.snapshot.params['id']],
  email_clients_to_email: ['', Validators.compose([Validators.required, CustomValidators.email])],
  email_clients_from_email: [''],
  email_client_from_email_id: [''],
  email_clients_cc: ['', CustomValidators.email],
  email_clients_bcc: ['', CustomValidators.email],
  email_clients_subject: [''],
  content: ['']
  // email_clients_uploaded_resume_ids: ['']
  });
  clicked_send_email = false;
  abs_url ='';
  generated_params ={};
  resume_report_ids:any;
  image_temp_url ='';
  // url = 'assets/test_resume1.png'
  uploaded_resume_id ='';
  attach_edited_resume_selected = true;
  attach_original_resume_selected =false;
  show_edited_resume = true;
  resume_saved=false;
  edited_resume_url ='';
  file_name = '';
  editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '15rem',
        minHeight: '5rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        defaultFontName: 'Arial',
        // uploadUrl: '/api/upload_email_images',
        // uploadWithCredentials: false,
        toolbarHiddenButtons: [
          ['bold']
          ],
        customClasses: [
          {
            name: "quote",
            class: "quote",
          },
          {
            name: 'redText',
            class: 'redText'
          },
          {
            name: "titleText",
            class: "titleText",
            tag: "h1",
          },
        ]
      }
      editor_modules = {
      // toolbar: {
      //   container: [
      //     [{ 'font': [] }],
      //     [{ 'size': ['small', false, 'large', 'huge'] }],
      //     ['bold', 'italic', 'underline', 'strike'],
      //     [{ 'header': 1 }, { 'header': 2 }],
      //     [{ 'color': [] }, { 'background': [] }],
      //     [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      //     [{ 'align': [] }],
      //     ['link', 'image']
      //   ]
      // },
      // imageResize: true
    };
  constructor(
    private authService: AuthenticationService,
    private route: ActivatedRoute, 
    private router: Router,
    private modal: ModalsService,
    private shared: SharedService,
    private fb: FormBuilder,
    private navbar: NavbarService,
    private candidate: CandidateService
    ) {
    if (this.shared.isBrowser()) {
      Quill = require('quill');
      const ImageResize = require('quill-image-resize').default;
      Quill.register('modules/imageResize', ImageResize);
    }
    this.editor_modules = {
      toolbar: {
        container: [
          [{ 'font': [] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'align': [] }],
          ['link', 'image']
        ]
      },
      imageResize: true
    };
  }

  ngOnInit() {

    
    // this.load_summernote();
    // this.sub = this.navbar.current_page$.subscribe(
    //   res => {
    //     this.called_from= res['name'];
    //     this.calculate_params();
    //     this.get_plo();
    //     console.log(this.called_from)
    //   }
    // );
    // this.populate_to_emails();
    // this.sub = this.modal.medium_modal_selected$
    this.generated_params = this.candidate.get_params_from_url(this.router.url);
    // console.log(this.generated_params)
    // this.modal.load_modal_by_name('spinner', {});
    this.loading = true
    this.sub= this.authService.post('/api/get_email_account_details_for_client_share', this.generated_params).subscribe(
        res => {
          console.log(res);
          // this.modal.hide_modal_by_name('spinner');
          this.loading = false
          this.own_email_accounts = res['own_email_accounts'];
          let i=res['own_email_accounts'].length-1;
          this.email_client_form.patchValue({
            'email_client_from_email_id': res['own_email_accounts'][i]['email_account']['id']
          })
          this.email_templates = res['email_templates'];
          this.signatures = res['signatures'];
          this.abs_url = res['abs_resume_url'];
          this.image_temp_url = res['image_temp_url'];
          this.file_name = res['file_name'];
          this.uploaded_resume_id = res['uploaded_resume_id'];
          console.log(this.uploaded_resume_id);
          console.log('uploaded_resume_id set')
          if(this.image_temp_url==undefined){this.attach_original_resume_selected = true; this.attach_edited_resume_selected = false;}
        },
        // err => {console.log(err)}
      )
    this.navbar.resume_reports_selected$
       .subscribe(params => 
         {
           this.resume_report_ids= params;
         // console.log(this.concat_params);
         }
    );
  }
  ngOnDestroy(){
    this.sub.unsubscribe;
  }
  // get_plo(){
  //   this.authService.post('/api/get_email_account_details_for_client_share', this.params).subscribe(

  //     res => {
  //       console.log(res);
  //       this.own_email_accounts = res['own_email_accounts'];
  //       this.email_templates = res['email_templates'];
  //       this.signatures = res['signatures'];
  //       this.abs_url = res['abs_resume_url'];
  //     },
  //     error =>{
  //       console.log(error)
  //     }
  //   )
  // }
  submitEmailClientForm($ev, value: any) {
    $ev.preventDefault();
    //this.toasterService.pop('Success', 'Email Sent', 'Email Successfully sent');
    
    this.clicked_send_email=true;
    value = this.shared.merge_options(value, {'email_clients_message': this.contents})
    console.log(this.resume_report_ids)
    if(this.resume_report_ids!=undefined){
      value = this.shared.merge_options(value, {'resume_report_ids': this.resume_report_ids})
    }
    else{
      value = this.shared.merge_options(value, this.generated_params)
    }
    // value = this.shared.merge_options(value, {'resume_url': this.abs_url})
    // if(this.called_from === 'analytics' || this.called_from === 'resumes' || this.called_from === 'emailhistory' || this.called_from === 'comments' || this.called_from === 'activity'){
    //  this.modal.medium_modal_selected$
    //   .subscribe(params=>
    this.params = this.shared.merge_options(this.params, value)
     //  )
     //  }
     //  else{
     // //from inbox/resumereports
     // this.navbar.resumes_selected$
     //   .subscribe(params => 
     //     this.params =this.shared.merge_options({'uploaded_resume_ids': params}, value)
     //   );
     //  }
    // if no validation errors
    // this.sub.unsubscribe;
    // console.log(this.params);
    // console.log(value.email_clients_to_email);
    // console.log(value.email_account_user_name);
    // console.log(value.email_clients_subject)
    // console.log(this.contents);
    console.log(this.resume_report_ids)
    if(this.resume_report_ids===undefined){ 
      console.log('here')
      this.params = this.shared.merge_options(this.params, {'attach_edited_resume': this.attach_edited_resume_selected, 'attach_original_resume': this.attach_original_resume_selected, 'edited_resume_url': this.edited_resume_url})
      this.authService.post(
        '/api/email_clients', this.params

        ).subscribe(
        res => {
          if(res['upgrade']==='Premium'){this.modal.hide_modal_by_name('share_candidate');setTimeout(()=>{ this.modal.load_modal_by_name('upgrade_plan', {}) }, 500)}
          else{
            // this.modal.hide_modal('medium');
            // console.log(res);
            this.navbar.set_notification('Success','Notice', res['notice']);
            // setTimeout(function(){ location.reload(); }, 3000);
            this.shared.refresh(this.router.url);
            this.clicked_send_email = false;
          }
        }, error => {
            // console.log(error);
        }
      )
    }
    else{
      console.log('post')
      this.authService.post(
        '/api/email_multiple_resumes_to_client', this.params
      ).subscribe(
        res=> {
         if(res['upgrade']==='Premium'){this.modal.hide_modal_by_name('share_candidate');setTimeout(()=>{ this.modal.load_modal_by_name('upgrade_plan', {}) }, 500)}
         else{
          console.log(res);
          this.navbar.set_notification('Success','Notice', res['notice']);
          // setTimeout(function(){ location.reload(); }, 3000);
          this.shared.refresh(this.router.url);
         }
        },
        err=> {
          console.log(err)
        }
      )
     }
  }

  // load_summernote(){
  //   $('#summernote').summernote({
  //       height: 230,
  //       dialogsInBody: true,
  //       callbacks: {
  //           onChange: (contents, $editable) => {
  //               this.contents = contents;
  //               // console.log(contents);
  //           }
  //       }
  //   });
  //   $('.note-popover').css({'display': 'none'});
  // }
  auto_fill_template(_val){
    // console.log(_val)
    this.authService.post('/api/load_email_template', this.shared.merge_options(_val, {'email_candidate_template_id': _val}))
    .subscribe(
      res => {
        console.log(res);
        this.contents = res['content'];
        // $('#summernote').summernote('reset');
        // $('#summernote').summernote('enable');
        // $('#summernote_container').html('<div id="summernote" class="summernote"><span>'+this.contents+'</span></div>');
        // this.load_summernote();
        // $('#summernote').summernote('pasteHTML', this.contents);
        this.email_client_form.patchValue({
          'email_clients_subject': res['subject'],
          'content': res['content']
        });
  
      },
      // err => console.log(err)
    )
  }
  // save($event, _url){
  //   console.log($event);
  //   this.authService.post('/api/save_edited_image_of_resume', {blob: $event, url: _url}).subscribe(
  //     res=> console.log(res)
  //   )
  //   // saveAs($event, 'image.jpg');
  // }
  // postMethod(files: FileList) {
  //   this.fileToUpload = files.item(0); 
  save($event, _url){
    let formData = new FormData(); 
    console.log($event);
    let uploaded_resume_id = this.uploaded_resume_id.toString()
    formData.append('file', $event, this.file_name); 
    formData.append('url', this.file_name); 
    formData.append('uploaded_resume_id', uploaded_resume_id); 
    // this.modal.load_modal_by_name('spinner', {});
    this.loading = true;
    console.log(formData)
    this.authService.post_html('/api/save_edited_image_of_resume', formData).subscribe(
      (val) => {
        // this.modal.hide_modal_by_name('spinner');
        this.loading = false
        this.show_edited_resume=false;
        this.resume_saved=true;
        console.log(val);
        this.navbar.set_notification('Success','Notice', val['notice']);
        this.edited_resume_url = val['edited_resume_url']
      });
    return false; 
  }
  onCheckEditAttachment($event){
    this.attach_edited_resume_selected=!this.attach_edited_resume_selected
    console.log(this.attach_edited_resume_selected)
    // this.attach_edited_resume_selected=$event
  }
  onCheckSendOriginalAttachment($event){
    this.attach_original_resume_selected=!this.attach_original_resume_selected
    console.log(this.attach_original_resume_selected)
  }
  // calculate_params(){
  //   if(this.called_from === 'analytics' || this.called_from === 'resumes' || this.called_from === 'emailhistory' || this.called_from === 'comments' || this.called_from === 'activity'){
  //    this.modal.medium_modal_selected$
  //     .subscribe(params=>
  //       {
  //         this.params = params['params'];
  //         return this.params;
  //         console.log(this.params);
  //       }
  //     )
  //   }
  //    //  else{
  //    // //from inbox/resumereports
  //    // this.navbar.resumes_selected$
  //    //   .subscribe(params => 
  //    //     this.params ={'uploaded_resume_ids': params}
  //    //   );
  //    //  }
      
  // }
  // populate_to_emails(){
  //   this.authService.post('/api/get_to_emails', this.params).subscribe(
  //     res=> {
  //       this.to_emails = res['to_emails'];
  //     },
  //     err => console.log(err)
  //   )
  // }

}

import { NgModule } from '@angular/core';

import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { NavsearchComponent } from './header/navsearch/navsearch.component';
import { OffsidebarComponent } from './offsidebar/offsidebar.component';
import { UserblockComponent } from './sidebar/userblock/userblock.component';
import { UserblockService } from './sidebar/userblock/userblock.service';
import { FooterComponent } from './footer/footer.component';
import { SharedroutesModule} from '../routes/shared/sharedroutes.module'
import { SharedModule } from '../shared/shared.module';
// import { NavoptionsComponent } from './header/navoptions/navoptions.component';
import { NavoptionsResumereportsComponent } from './header/navoptions-resumereports/navoptions-resumereports.component';
import { ModalsComponent} from './modals/modals.component';
// import { NavoptionsCandidatesComponent } from './header/navoptions-candidates/navoptions-candidates.component';
import { NavoptionsJobsComponent } from './header/navoptions-jobs/navoptions-jobs.component';
// import { NavoptionsInboxComponent } from './header/navoptions-inbox/navoptions-inbox.component';
// import { ToasterService } from 'angular2-toaster/angular2-toaster';
import { ToastrService } from 'ngx-toastr';
import { Modals2Component } from './modals2/modals2.component';

// import { ActionCableService, Channel } from 'angular2-actioncable';
import { NavoptionsAppointmentsComponent } from './header/navoptions-appointments/navoptions-appointments.component';

@NgModule({
    imports: [
        SharedModule,
        SharedroutesModule
    ],
    providers: [
        UserblockService,
        ToastrService,
        // ActionCableService
    ],
    declarations: [
        LayoutComponent,
        SidebarComponent,
        UserblockComponent,
        HeaderComponent,
        NavsearchComponent,
        OffsidebarComponent,
        FooterComponent,
        // NavoptionsComponent,
        NavoptionsResumereportsComponent,
        ModalsComponent,
        // NavoptionsCandidatesComponent,
        NavoptionsJobsComponent,
        // NavoptionsInboxComponent,
        Modals2Component,
        NavoptionsAppointmentsComponent
    ],
    exports: [
        LayoutComponent,
        SidebarComponent,
        UserblockComponent,
        HeaderComponent,
        NavsearchComponent,
        OffsidebarComponent,
        FooterComponent
    ]
})
export class LayoutModule { }

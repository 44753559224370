import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl} from'@angular/forms';
import { NavbarService} from '../../../layout/header/navbar.service';
import { saveAs } from 'file-saver';
import { SharedService } from '../../../core/shared/shared.service';
import { ModalsService } from '../../../layout/modals/modals.service';
import { JobsService } from '../../jobs/jobs.service'



@Component({
  selector: '[app-download-csv]',
  templateUrl: './download-csv.component.html',
  styleUrls: ['./download-csv.component.scss']
})
export class DownloadCsvComponent implements OnInit {
  resumes_selected = [];
  resume_search_query_id = '';
  valForm = new FormGroup({
    tracker_id: new FormControl(),
  });
  url_params={};
  concat_params ={};
  all_trackers =[];
  clicked_save =false;
  constructor(
  	private authService: AuthenticationService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private fb: FormBuilder,
    private navbar: NavbarService,
    private modal: ModalsService,
    private shared: SharedService,
    private jobs: JobsService,


  	) { }

  ngOnInit() {
    // console.log('reached here');
    this.url_params = this.jobs.get_params_from_url(this.router.url);
    // console.log(this.url_params)
    this.get_plo();
    this.load_params();
    this.valForm.patchValue({
      'tracker_id': '0'
    })
  }
  // download_csv(){
  //   this.navbar.current_params$.subscribe(params => console.log(this.resume_search_query_id=params['resume_search_query_id']));
  //   this.authService.downloadFile('/api/download_resumes', {'search_results': this.resumes_selected, 'resume_search_query_id': this.resume_search_query_id}).subscribe(res => {
  //           saveAs(res, 'temp.csv');
  //       });
  //   // var blob = new Blob(["Hello, world!"], {type: "text/plain;charset=utf-8"});
  //   //   saveAs(blob, "hello world.txt");
  //    }
  // processFile(data:any) {
  //   const blob = new Blob([data], { type: 'text/csv' });
  //   const url= window.URL.createObjectURL(blob);
  //   window.open(url);
  // }
  get_plo(){
    // console.log(this.url_params)
    this.authService.post(
          '/api/load_download_modal', this.url_params
          ).subscribe(
          res => {
              // console.log(res);
              this.all_trackers = res['all_trackers'];
              //feedback pending
          }, error => {
              // console.log(error);
          }
    )
  }
  load_params(){
    
    // console.log(this.url_params);
    if (this.url_params['job_role_id'] === "0"){this.concat_params=this.shared.merge_options(this.url_params, {url: this.router.url}) }
    else{
       this.navbar.resume_reports_selected$
        .subscribe(params => 
          {
           console.log(params)
          this.concat_params =this.shared.merge_options( {'resume_report_ids': params}, this.url_params);
          this.concat_params =this.shared.merge_options( this.concat_params, {'select_all_candidates': false});
          this.concat_params =this.shared.merge_options( this.concat_params, {'url': this.router.url})
          // console.log(this.concat_params);
          }
       );
      this.navbar.select_all_candidates$
      .subscribe(params => 
         {
          console.log(params)
          if(params==true){
            this.route.queryParams.subscribe(queryParams => {
              this.concat_params =this.shared.merge_options( queryParams, this.url_params);
              this.concat_params =this.shared.merge_options( this.concat_params, {'select_all_candidates': true})
              this.concat_params =this.shared.merge_options( this.concat_params, {'url': this.router.url})

            })
          }
         }
       );
      
    }
    // console.log(this.concat_params);
  }
  submitForm($ev, value: any) {
    console.log(value);
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
        this.valForm.controls[c].markAsTouched();
    }

    if (this.valForm.valid) {
        this.clicked_save =true;
        this.authService.downloadFile(
          '/api/generate_custom_tracker_for_job', this.shared.merge_options(value, this.concat_params)
          ).subscribe(
          res => {
              // saveAs(res, 'candidates.csv');
              console.log(res)
              // this.modal.hide_modal('small');
              this.modal.hide_modal_by_name('download_csv')
              this.clicked_save =false;
              if(res==null)
              {this.navbar.set_notification('Success', 'Tracker Generated', 'Please Check your email for updated tracker, page wil refresh now.');
              // setTimeout(function(){ location.reload(); }, 3000);
              this.shared.refresh(this.router.url);
              }
            
              else{
                saveAs(res, 'candidates.csv');
              }           
              
              //feedback pending
          }, error => {
              // console.log(error);
          }
        )
    }
  }
  open_configure_custom_tracker(){
    this.modal.hide_modal_by_name('download_csv');
    setTimeout(()=>{ this.modal.load_modal_by_name('custom_tracker', {}); }, 500);
    
  }
}

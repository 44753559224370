import { Injectable } from '@angular/core';
import { Subject, ReplaySubject, BehaviorSubject }    from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  private navOptionsSource = new ReplaySubject<any>(1);
  current_page$ = this.navOptionsSource.asObservable();
  set_current_page(name: string, params:any) {
    this.navOptionsSource.next({'name': name, 'params': params});
    console.log(name);
  }
  private paramsSource = new ReplaySubject<any>(1);
  current_params$ = this.paramsSource.asObservable();
  set_current_params( params:any) {
    this.paramsSource.next(params);
    // console.log(params);
  }
  get_current_params(){
  	// console.log(this.current_params$)
  	return this.current_params$
  }
  private resumesSelectedSource = new ReplaySubject<any>(1); //==behaviour subject, buffer size is 1. nice article on using subjects https://alligator.io/rxjs/subjects/
  resumes_selected$ = this.resumesSelectedSource.asObservable();
  set_resumes_selected(params:any) {
    this.resumesSelectedSource.next(params);
    console.log(params);
  }
  private resumesEmailsSource = new ReplaySubject<any>(1);
  resume_emails_selected$ = this.resumesEmailsSource.asObservable();
  set_resume_emails_selected(params:any) {
    this.resumesEmailsSource.next(params);
    console.log(params);
  }
  private select1000Source = new ReplaySubject<any>(1);
  select1000$ = this.select1000Source.asObservable();
  set_select1000(params:any) {
    this.select1000Source.next(params);
    console.log(params);
  }
  
  private selectAllCandidatesSource = new ReplaySubject<any>(1);
  select_all_candidates$ = this.selectAllCandidatesSource.asObservable();
  set_select_all_candidates(params:any) {
    this.selectAllCandidatesSource.next(params);
    console.log(params);
  }
  
  private resumeReportsSelectedSource = new ReplaySubject<any>(1);
  resume_reports_selected$ = this.resumeReportsSelectedSource.asObservable();
  set_resume_reports_selected(params:any) {
    this.resumeReportsSelectedSource.next(params);
    console.log(params);
  }
  // private resumeSearchQuerySource = new ReplaySubject<any>();
  // resume_search_query_id$ = this.resumeSearchQuerySource.asObservable();
  // set_resume_search_query_id(params:any) {
  //   this.resumeSearchQuerySource.next(params);
  //   console.log(params);
  // }
  private jobsSelectedSource = new ReplaySubject<any>(1);
  jobs_selected$ = this.jobsSelectedSource.asObservable();
  set_jobs_selected(params:any) {
    this.jobsSelectedSource.next(params);
    // console.log(params);
  }
  private notificationSource = new ReplaySubject<any>(1);
  notification$ = this.notificationSource.asObservable();
  set_notification(_type, _title, _message ) {
    this.notificationSource.next({'type': _type, 'title': _title, 'message': _message});
    // console.log({'type': _type, 'title': _title, 'message': _message});
  }

  private navbarVisibleSource = new ReplaySubject<any>(1);
  navbar_visible$ = this.navbarVisibleSource.asObservable();
  set_navbar_visible(_is_navbar_visible) {
    this.navbarVisibleSource.next(_is_navbar_visible);
    // console.log({'type': _type, 'title': _title, 'message': _message});
  }

  private isLoggedInSource = new ReplaySubject<any>(1);
  isLoggedIn$ = this.isLoggedInSource.asObservable();
  set_is_logged_in(_is_logged_in) {
    this.isLoggedInSource.next(_is_logged_in);
    // console.log({'type': _type, 'title': _title, 'message': _message});
  }

  private tawkVisibleSource = new ReplaySubject<any>(1);
  tawk_visible$ = this.tawkVisibleSource.asObservable();
  set_tawk_visible(_is_tawk_visible) {
    this.tawkVisibleSource.next(_is_tawk_visible);
    // console.log({'type': _type, 'title': _title, 'message': _message});
  }

  private adminUpdatePricingSource = new ReplaySubject<any>(1);
  admin_update_pricing_plan$ = this.adminUpdatePricingSource.asObservable();
  set_admin_update_pricing_company_id(_company_id) {
    console.log(_company_id)
    this.adminUpdatePricingSource.next(_company_id);
    // console.log({'type': _type, 'title': _title, 'message': _message});
  }

  private helpMessageSource = new ReplaySubject<any>(1);
  help_message$ = this.helpMessageSource.asObservable();
  set_help_message(_header, _message) {
    this.helpMessageSource.next({header: _header, message: _message});
    // console.log({'type': _type, 'title': _title, 'message': _message});
  }

  //set query params for candidate page: to check if should reload upon closing candidate card, i.e. if query params does not change, then do not reload content
  private candidateInboxQueryResultsSource = new ReplaySubject<any>(1);
  candidate_inbox_query_results$ = this.candidateInboxQueryResultsSource.asObservable();
  set_candidate_inbox_query_results(_params) {
    this.candidateInboxQueryResultsSource.next({params: _params});
    // console.log(_params)
    // console.log({'type': _type, 'title': _title, 'message': _message});
  }
  //this is for saving the params to the page to check if the query params have changed before adding the loading 
  private candidateInboxQueryParamsSource = new ReplaySubject<any>(1);
  candidate_inbox_query_params$ = this.candidateInboxQueryParamsSource.asObservable();
  set_candidate_inbox_query_params(_params) {
    this.candidateInboxQueryParamsSource.next({params: _params});
    // console.log(_params)
    // console.log({'type': _type, 'title': _title, 'message': _message});
  }

  private jobsListViewResultsSource = new ReplaySubject<any>(1);
  job_list_view_results$ = this.jobsListViewResultsSource.asObservable();
  set_job_list_view_results$(_params) {
    this.jobsListViewResultsSource.next({params: _params});
  }
  private typeOfDocumentSource = new ReplaySubject<any>(1);
  type_of_document$ = this.typeOfDocumentSource.asObservable();
  set_type_of_document$(_params) {
    this.typeOfDocumentSource.next(_params);
  }
  private selectedFoldersDesktopSource = new ReplaySubject<any>(1);
  selected_folders_desktop$ = this.selectedFoldersDesktopSource.asObservable();
  set_selected_folders_desktop(_params) {
    // console.log("setting folders")
    // console.log(_params)
    this.selectedFoldersDesktopSource.next(_params);
  }
  private doFullSyncFoldersDesktopSource = new ReplaySubject<any>(1);
  do_full_sync_folders_desktop$ = this.doFullSyncFoldersDesktopSource.asObservable();
  set_do_full_sync_folders_desktop(_params) {
    this.doFullSyncFoldersDesktopSource.next(_params);
  }

    private linkedFoldersEverDesktopSource = new ReplaySubject<any>(1);
  linked_folders_ever_desktop$ = this.linkedFoldersEverDesktopSource.asObservable();
  set_linked_folders_ever_desktop(_params) {
    this.linkedFoldersEverDesktopSource.next(_params);
  }
  private desktopUploadsPendingSource = new ReplaySubject<any>(1);
  uploads_pending_desktop$ = this.desktopUploadsPendingSource.asObservable();
  set_uploads_pending_desktop(_params) {
    this.desktopUploadsPendingSource.next(_params);
  }
  private desktopUploadsProcessingSource = new ReplaySubject<any>(1);
  uploads_processing_desktop$ = this.desktopUploadsProcessingSource.asObservable();
  set_uploads_processing_desktop(_params) {
    this.desktopUploadsProcessingSource.next(_params);
  }
  private isElectronInitializedSource = new ReplaySubject<any>(1);
  is_electron_initialized$ = this.isElectronInitializedSource.asObservable();
  set_is_electron_initialized(_params) {
    this.isElectronInitializedSource.next(_params);
    console.log(_params)
  }
  private syncStatsSource = new ReplaySubject<any>(1);
  sync_stats$ = this.syncStatsSource.asObservable();
  set_sync_stats(_params) {
    this.syncStatsSource.next(_params);
  }
  constructor() { }
}

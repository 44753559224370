import { Component, OnInit , OnDestroy} from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params} from '@angular/router';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service';
import { Observable, Subscription} from 'rxjs';
import { ModalsService } from '../../../layout/modals/modals.service';

import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FileUploader , FileUploaderOptions} from 'ng2-file-upload';
import 'rxjs/add/operator/switchMap'; 
import 'rxjs/add/operator/map';
import {saveAs} from "file-saver";
import { environment } from '../../../../environments/environment'
import {TokenService} from '../../../core/authentication/token.service';

declare var $: any;
let Quill:any = null;
// import * as QuillNamespace from 'quill';
// let Quill: any = QuillNamespace;
// import ImageResize from 'quill-image-resize-module';
// Quill.register('modules/imageResize', ImageResize);
const URL = environment.baseUrl+'/api/mass_upload_jobs_via_excel';
let maxFileSize = 5*1024*1024
@Component({
  selector: '[app-create-job]',
  templateUrl: './create-job.component.html',
  styleUrls: ['./create-job.component.scss']
})
export class CreateJobComponent implements OnDestroy {
  plo: any = {};
  uar: any = {};
  rcar: any= {};
  form_data:any;
  collaborator_options: any;
  employment_type_categories: any;
  default_stages: any=[];
  default_statuses: any;
  show_mass_create_jobs=false;
  client_contact_people = [];
  // stageList: Array<string> = ['abc', 'bcd'];
  stage_types=[];
  listOne: Array<string> ;
  clicked_save =false;
  clients:any;
  client:any;
  sub:Subscription;
  contents = '';
  
  public uploader: FileUploader = new FileUploader({maxFileSize:maxFileSize, allowedMimeType: [ 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ], url: URL, itemAlias: 'mass_jobs', autoUpload: true});
    public hasBaseDropZoneOver: boolean = false;
    // public hasAnotherDropZoneOver: boolean = false;

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }
  // interviewers = ['a','b','c'];


  valForm = new FormGroup({
    client_name: new FormControl(),
    // client_description: new FormControl(),
    // client_locations: new FormControl(),
    // client_contact_persons: new FormControl(),
    // client_id: new FormControl(),
    client_contact_people_ids: new FormControl(),
    client_emails: new FormControl(),
    client_phone_nos: new FormControl(),
    job_role_title: new FormControl('',[Validators.required]),
    no_of_openings: new FormControl(),
    job_location: new FormControl(),
    job_description: new FormControl(),
    employment_type: new FormArray([]),
    public_job_description: new FormControl(),
    required_qualifications: new FormControl(),
    preferred_qualifications: new FormControl(),
    required_skills: new FormControl(),
    preferred_skills: new FormControl(),
    preferred_industries: new FormControl(),
    ctc: new FormControl(),
    min_years_experience: new FormControl(),
    max_years_experience: new FormControl(),
    interview_locations: new FormControl(),
    preferred_candidate_locations: new FormControl(),
    job_value: new FormControl(),
    is_pinned: new FormControl(),
    content: new FormControl(),
    // all_stages: new FormArray([ 
      // {
      // stage: new FormArray([]), 
      // stage_type_id: new FormArray([]), 
      // collaborators: new FormArray([])
    //   }
    // ])
    all_stages: new FormArray([
      this.fb.group({
          stage: [''],
          stage_type_id: [''],
          collaborators: ['']
      })
    ]),
    set_stages_as_default: new FormControl()

  });
  editor_modules = {
      // toolbar: {
      //   container: [
      //     [{ 'font': [] }],
      //     [{ 'size': ['small', false, 'large', 'huge'] }],
      //     ['bold', 'italic', 'underline', 'strike'],
      //     [{ 'header': 1 }, { 'header': 2 }],
      //     [{ 'color': [] }, { 'background': [] }],
      //     [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      //     [{ 'align': [] }],
      //     ['link', 'image']
      //   ]
      // },
      // imageResize: true
    };
  // set_all_stages:any; 
  // valForm2 = new FormArray([ {item:new FormConrol, color: new FormControl, tags: new FormArray[''] }])
  // collaborators = [];
  constructor(
  	private authService: AuthenticationService,
  	private route: ActivatedRoute,
  	private router: Router,
    private shared: SharedService,
    private fb:FormBuilder,
    private navbar: NavbarService,
    private tokenService2: TokenService,
    private modal: ModalsService

  	) { 
    if (this.shared.isBrowser()) {
      Quill = require('quill');
      const ImageResize = require('quill-image-resize').default;
      Quill.register('modules/imageResize', ImageResize);
    }
    this.editor_modules = {
      toolbar: {
        container: [
          [{ 'font': [] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'align': [] }],
          ['link', 'image']
        ]
      },
      imageResize: true
    };
  }

  ngOnInit() {
    this.get_plo();
    // this.get_uar();
    // this.load_summernote();
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.get_rcar();
    this.sub=this.navbar.current_params$
      .switchMap(params => {
        console.log(params);
        if (params['resume_email_id']!=null)
        {return this.authService.post('/api/create_job_from_job_description', params)}
        else if (params['position_id']!=null)
        {return this.authService.post('/api/duplicate_job', params)}
        else {return null}
      }).subscribe(
        res => {
            console.log(res);
            this.valForm.patchValue({
              job_role_title: res['job_title'],
              job_description:res['job_description'],
              job_location: res['job_location'],
              min_years_experience: res['years_of_work_experience']['min_years_of_experience']===null ? '' :  res['years_of_work_experience']['min_years_of_experience'].toString(),
              max_years_experience: res['years_of_work_experience']['max_years_of_experience']===null ? '' :  res['years_of_work_experience']['max_years_of_experience'].toString(), 
              ctc: res['salary'],
              preferred_candidate_locations: res['preferred_candidate_locations'],
              job_value: res['job_value'],
              interview_locations:res['interview_locations'],
              required_skills:res['required_skills'],
              preferred_skills:res['preferred_skills'],
              preferred_industries: res['preferred_industries'],
              employment_type_names:res['employment_type_names'],
              no_of_openings: res['no_of_openings'],
              required_qualifications: res['required_qualifications'],
              preferred_qualifications: res['preferred_qualifications']
            })

        }, error => {
            // console.log(error);
        }
      );

  }
  get_plo(){
    this.authService.get('/api/new_job_role').subscribe(
      res => {
          this.plo =  res;
          this.collaborator_options=this.plo.collaborator_options;
          this.employment_type_categories=this.plo.employment_type_categories;
          this.default_stages=this.plo.default_stages;
          this.default_statuses=this.plo.default_statuses;
          this.stage_types = this.plo.stage_types;
          this.clients=this.plo.clients
          this.update_upload_parameters();
          this.upload_builder( res['company_id'], res['current_user_id']);
          // this.valForm.patchValue({all_stages: new FormArray(this.all_stages())});
          this.valForm.setControl('all_stages', this.fb.array(this.all_stages() || []));
          // console.log(this.valForm.controls.all_stages)
          console.log(res);
      }, error => {
          // console.log(error);
      }
    )
  }
  get_uar(){
    this.authService.post('/api/get_user_access_rights_api', null).subscribe(
      res => {
          this.uar =  res;
          // console.log(res);
      }, error => {
          // console.log(error);
      }
    )
  }
  get_rcar(){
    this.authService.post('/api/get_recruitment_company_access_rights_api', null).subscribe(
      res => {
          this.rcar =  res;
          console.log(res);
      }, error => {
          // console.log(error);
      }
    )
  }
  
  // onCheckSortChange($event, array_name){
  //   this.shared.onCheckSortChange($event, this.valForm, array_name);
  // }
  onCheckChange($event){
    console.log($event)
    this.shared.onCheckChange($event, this.valForm ,'employment_type');
  }
  submitForm($ev, value: any) {
    $ev.preventDefault();
    console.log(value);
    for (let c in this.valForm.controls) {
        this.valForm.controls[c].markAsTouched();
    }

     if (this.valForm.valid) {
        this.clicked_save =true;
        this.authService.post(
          '/api/create_job_role', this.shared.merge_options(value, {'public_job_description': this.contents, 'employment_type_hash_array': value['employment_type'].map((x,i,a)=> {return JSON.parse(x)} )})
          // {
          //   client_name: value.client_name,
          //   client_description: value.client_description,
          //   client_locations: value.client_locations,
          //   client_contact_persons: value.client_contact_persons,
          //   client_emails: value.client_emails,
          //   client_phone_nos: value.client_phone_nos,
          //   job_role_title: value.job_role_title, 
          //   no_of_openings: value.no_of_openings, 
          //   job_locations: value.job_locations, 
          //   job_description: value.job_description, 
          //   required_qualifications: value.required_qualifications, 
          //   preferred_qualifications: value.preferred_qualifications, 
          //   required_skills: value.required_skills, 
          //   preferred_skills: value.preferred_skills, 
          //   preferred_industries: value.preferred_industries, 
          //   ctc: value.ctc, 
          //   min_years_of_experience: value.min_years_experience, 
          //   max_years_of_experience: value.max_years_experience, 
          //   interview_locations: value.interview_locations, 
          //   preferred_candidate_locations: value.preferred_candidate_locations,
          //   all_stages: value.all_stages
          //   // collaborators: value.collaborators,
          //   // stages: value.stages,
          //   // stage_type_ids: value.stage_type_ids,
          //   //stage: value.stage
          // }
          ).subscribe(
          res => {
              // console.log(res);
              this.modal.hide_modal_by_name('create_job');
              // if(res['upgrade']==='Premium'){setTimeout(()=>{ this.modal.load_modal_by_name('upgrade_plan', {}) }, 3000}
              this.sub.unsubscribe;
              this.router.navigate(['jobs/candidates/',res['job_role_id']]);// navigate to the created job
              this.clicked_save =false;
          }, error => {
              // console.log(error);
          }
        )
     }
     else{
       this.navbar.set_notification('Warning', 'Please complete form', 'Please ensure all fields are filled')
     }
  }
  ngOnDestroy(){
    this.sub.unsubscribe;
  }
  public requestAutocompleteCollaborator = (text: string): Observable<any> => {
    const url = `/user_collaborators?q=${text}`;
    return this.authService
        .get(url)
        .map(data => data);
  };
  mass_add_interviewers(val){
    console.log(val)
  }
  private all_stages(): any {
    // var as = {
    //       stage: [''],
    //       stage_type_id: [''],
    //       collaborators: ['']
    //   }
    var a =[]
    for (let ds of this.default_stages) {
      a.push(this.fb.group({
          stage: [ds['name']],
          stage_type_id: [ds['recruitment_stage_type_id'].toString()],
          collaborators: ['']
      }))
    }

    return a;
  }
   addStage() {
    const control = <FormArray>this.valForm.controls['all_stages'];
    control.push(this.fb.group({
          stage: ['New Stage'],
          stage_type_id: ['3'],
          collaborators: ['']
      }));
    // console.log(control)
  }
  removeStage(i){
    const control = <FormArray>this.valForm.get('all_stages');
    control.removeAt(i)
    // console.log(control[i]);
  }
  autofill_client(_client_name){
    this.authService.post('/api/get_client_details', {client_name: _client_name}).subscribe(
      res=> {
        console.log(res);
        this.client=res['client'];
        this.client_contact_people = res['client_contact_people']
        // this.valForm.patchValue({
        //   client_name: this.client['name'],
        //   client_description: this.client['description'],
          // client_locations: this.client['locations'],
          // client_contact_persons:this.client['contact_persons'],
          // client_emails: this.client['emails'],
          // client_phone_nos: this.client['phone_nos'],

        // });

      },
      err=> console.log(err)
    )
  }
  select_client_contact(_client_contact_person_id){

  }
  add_to_all_stages(_collaborator){
    if(_collaborator!='0'){
      // console.log(_collaborator)
      console.log(this.valForm.controls)
      var current_stages = this.valForm.controls['all_stages']
      var a =[]
      for (let ds of current_stages['value']) {
        // console.log(ds['collaborators'])
        var c=ds['collaborators']==='' ? [] : ds['collaborators'];
        // console.log(c)
        // console.log(c.concat(_collaborator))
        a.push(this.fb.group({
            stage: [ds['stage']],
            stage_type_id: [ds['stage_type_id']],
            collaborators: [c.concat(_collaborator)]
        }))
      }
      // console.log(a);

      this.valForm.setControl('all_stages', this.fb.array(a));
      
    }
    
  }

    // private all_stages(): any {
  //     return this.fb.group({
  //         stage: [''],
  //         stage_type_id: [''],
  //         collaborators: ['']
  //     });
  //   }
  // load_summernote(){
  //   console.log('rendering summernote')
  //   $('#summernote').summernote({
  //       height: 230,
  //       dialogsInBody: true,
  //       callbacks: {
  //           onChange: (contents, $editable) => {
  //               this.contents = contents;
  //               // console.log(contents);
  //           }
  //       }
  //   });
  //   $('.note-popover').css({'display': 'none'});
  // }
  update_upload_parameters(){
      var uo: FileUploaderOptions = {};
      uo.headers = 
     [
       // {name: 'access-token', value : localStorage.getItem("accessToken")}, 
       // {name: 'client', value : localStorage.getItem("client")},
       // {name: 'expiry', value : localStorage.getItem("expiry")},
       // {name: 'token-type', value : localStorage.getItem("tokenType")},
       // {name: 'uid', value : localStorage.getItem("uid")}
       {name: 'access-token', value : localStorage.getItem("access_token")},
       {name: 'Authorization', value : 'Bearer '+ this.tokenService2.getToken()}, 
     ];
      // console.log(uo);
      
      this.uploader.setOptions(uo);
      // console.log(this.uploader);
  //    this.upload_builder(this.job_role.id , this.create_mass_upload_tags, "1" , this.create_mass_upload_cv_source)

    }
  upload_builder( _company_id,  _current_user_id){
    this.update_upload_parameters();
    var date:Date = new Date();
    var baseDate: Date = new Date(Date.UTC(1970, 0, 1));
    var timestamp = _current_user_id + (date.getTime() - baseDate.getTime()).toString();
    // console.log(this.cv_sources)
    //this.set_timestamp();
    this.uploader.onBuildItemForm = function(fileItem, form)
    { 
      
      //var timestamp ='9871239761'
      form.append('user_upload_timestamp', timestamp);
      form.append('file_count', this.queue.length);
      return {fileItem, form} 
    };
    this.uploader.onCompleteItem = (item:any, response:any, status:any, headers:any)=> {
      // console.log( item, status,response, headers);
      // var resp=JSON.parse(response);
      // console.log(response);
      // console.log(headers);
      if (headers['access-token']!== undefined && headers['access-token']!== ''){
        localStorage.setItem('accessToken', headers['access-token']);
        localStorage.setItem("expiry", headers['expiry']);
        this.update_upload_parameters()
      }
      
      // console.log(resp);  
    };
    this.uploader.onCompleteAll =()=> {
      this.navbar.set_notification('Success','Uploaded jobs', 'Please look for the bell icon on top right corner.');
      this.modal.hide_modal_by_name('upload');
      // setTimeout(function(){ location.reload(); }, 3000);
    }
  }
  editorConfig: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: '15rem',
      minHeight: '5rem',
      placeholder: 'Enter text here...',
      translate: 'no',
      defaultParagraphSeparator: 'p',
      defaultFontName: 'Arial',
      // uploadUrl: '/api/upload_email_images',
      // uploadWithCredentials: false,
      toolbarHiddenButtons: [
        ['bold']
        ],
      customClasses: [
        {
          name: "quote",
          class: "quote",
        },
        {
          name: 'redText',
          class: 'redText'
        },
        {
          name: "titleText",
          class: "titleText",
          tag: "h1",
        },
      ]
    }
    get_sample_jd(){
      this.authService.downloadFile('/api/get_sample_jd', {}).subscribe(
        blob => {
              console.log(blob)
              saveAs(blob, 'sample_jd.xlsx');
          }, error => {
                console.log(error);
          } 
      )
    }
    add_client_popup(){
      this.modal.load_modal_by_name('create_client', {})
    }
}

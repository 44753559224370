import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CandidateService {

  constructor() { }
  get_params_from_url(_url){
  	var url_split = _url.split('/');
    var search_result_type = url_split[url_split.length-3];
    // console.log(search_result_type);
    if (search_result_type === 'database'){
      var search_result_id = url_split[url_split.length-2];
      return {'search_result_id': search_result_id}
    }
    else if (search_result_type === 'email'){
      var resume_email_id = url_split[url_split.length-2];
      // return {'uploaded_resume_id': uploaded_resume_id}
      return {'resume_email_id': resume_email_id}
    }
    else if (search_result_type === 'listview'){
      var resume_report_id = url_split[url_split.length-2];
      return {'resume_report_id': resume_report_id}
    }
  }
}

import { Component, OnInit , AfterViewInit} from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { Router, ActivatedRoute, Params} from '@angular/router';
// import { AngularTokenService } from 'angular-token';
import { HttpHeaders,HttpClient} from '@angular/common/http';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { NavbarService } from '../../../layout/header/navbar.service'
import { ModalsService } from '../../../layout/modals/modals.service';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { SharedService } from '../../../core/shared/shared.service';
import { environment } from '../../../../environments/environment'

@Component({
  selector: '[app-arrange-demo-form]',
  templateUrl: './arrange-demo-form.component.html',
  styleUrls: ['./arrange-demo-form.component.scss']
})
export class ArrangeDemoFormComponent implements OnInit {
	demoForm = new FormGroup({
		email:  new FormControl(null, Validators.compose([Validators.required, CustomValidators.email])), 
		phone:  new FormControl(null, Validators.compose([Validators.required])), 
    	first_name:  new FormControl(null, Validators.compose([Validators.required])), 
    	company_type: new FormControl('Recruitment Agency', Validators.compose([Validators.required])), 
    	last_name:  new FormControl(null, Validators.compose([Validators.required])),
    	recruitment_company_name:  new FormControl(null, Validators.compose([Validators.required])),
      	cntry: new FormControl(),
      	internal_sales_comment: new FormControl(null, Validators.compose([Validators.required])),
      	designation: new FormControl(null, Validators.compose([Validators.required])),
      	channel: new FormControl(null, Validators.compose([Validators.required])),
        redemption_code: new FormControl()
    });
    clicked_save=false;
    countries=[{label:'', value:''}];
    channels = ['Referral from a Friend', 'Quora', 'Capterra', 'Google', 'Email from Tobu', 'Blogs', 'Wikipedia', 'Linkedin', 'Facebook', 'Twitter', 'App Sumo' , 'Partner','Other'];
    form = new FormGroup({});
  public captchaResponse?: string;
     readonly siteKey= '6LfMVowcAAAAABXkKmgZQKr0OuGRp0abMeW8ffj9'
    ip =''
    // action = 'login';
    token: string = undefined;
  model: any = {};
  options: FormlyFormOptions = {};

  fields: FormlyFieldConfig[] = [{
    type: 'stepper',
    fieldGroup: [
      {
        templateOptions: { label: 'Email' },
        fieldGroup: [
        {
            key: 'email',
            type: 'input',
            templateOptions: {
              label: 'Email',
              type: 'email',
              required: true,
              placeholder: "Business Email for Fast Approval"
            }
          } 
        ]
      },
      {
        templateOptions: { label: 'Contact' },
        fieldGroup: [
          {
            key: 'first_name',
            type: 'input',
            templateOptions: {
              label: 'First name',
              required: true,
            },
          },
          {
            key: 'last_name',
            type: 'input',
            templateOptions: {
              label: 'Last name',
              required: true,
            },
          },
          {
            key: 'phone',
            type: 'input',
            templateOptions: {
              label: 'Phone No',
              required: true,
            }
          }
        ],
      },
      {
        templateOptions: { label: 'Company' },
        fieldGroup: [
          {
            key: 'recruitment_company_name',
            type: 'input',
            templateOptions: {
              label: 'Company Name',
              required: true,
            },
          },
          {
            key: 'company_type',
            type: 'select',
            templateOptions: {
              label: 'Company Type',
              required: true,
              options: [
                {
                  label: 'Recruitment Agency',
                  value: 'Recruitment Agency'
                },
                {
                  label: 'Corporate',
                  value: 'Corporate'
                }
              ]
            },
          },
          {
            key: 'designation',
            type: 'input',
            templateOptions: {
              label: 'Designation',
              required: true,
            },
          },
          {
            key: 'cntry',
            type: 'select',
            templateOptions: {
              label: 'Primary Hiring Country',
              required: true,
              options: this.countries
            }
          }
        ],
      },
      {
      templateOptions: { label: 'Additional info' },
        fieldGroup: [
          {
            key: 'internal_sales_comment',
            type: 'input',
            templateOptions: {
              label: 'When to Contact for Support?',
              required: true,
              placeholder: "eg Weekdays:3-5pm Pacific time"
            },
          },
          {
            key: 'channel',
            type: 'select',
            templateOptions: {
              label: 'How you heard about Tobu?',
              required: true,
              options: this.channels.map((x,i,a)=> {return {label: x, value: x}})
            },
          },
          {
            key: 'redemption_code',
            type: 'input',
            templateOptions: {
              label: 'redemption code'
            },
          },
        ],
      },
    ],
  }];

  protected aFormGroup: FormGroup;
  constructor(
            // public _tokenService: AngularTokenService, 
            public settings: SettingsService, fb: FormBuilder, 
            private route: ActivatedRoute,
            private router: Router,
            private http: HttpClient,
            private navbar: NavbarService,
            private modal: ModalsService,
            private authService: AuthenticationService,
            private formBuilder: FormBuilder,
            private reCaptchaV3Service: ReCaptchaV3Service,
            private shared: SharedService
            ) { }

  ngOnInit() {
    this.shared.get_ip().subscribe(
          res=> this.ip = res['ip'])
    // this.aFormGroup = this.formBuilder.group({
    //   recaptcha: ['', Validators.required]
    // });
    // if(this.authService.isLoggedIn()){
    //         this.router.navigate(['/candidates/inbox/view']);
    //     }

  	let httpHeaders = new HttpHeaders({
           'Content-Type' : 'application/json'
        });

    // this.http.get(environment.baseUrl + '/api/get_captcha').subscribe(
    //   // this.http.get(this._tokenService.tokenOptions.apiBase + '/api/get_countries', {headers:httpHeaders}).subscribe(
    //         res => {
    //           this.siteKey = res['siteKey']
    //           // console.log(this.siteKey)
                
    //           },
    //         err => console.log(err)
    //         )
  	this.http.get(environment.baseUrl + '/api/get_countries', {headers:httpHeaders}).subscribe(
      // this.http.get(this._tokenService.tokenOptions.apiBase + '/api/get_countries', {headers:httpHeaders}).subscribe(
            res => {
              this.countries = res['countries'].map((x,i,a)=> {return {label: x['name'], value: x['country_code']}}).sort(function(a, b){
                  if(a.label < b.label) { return -1; }
                  if(a.label > b.label) { return 1; }
                  return 0;
              });
                console.log(this.countries);
                 this.getField('cntry', this.fields).templateOptions.options = this.countries;
              },
            err => console.log(err)
            )
    if(this.router.url.includes('referral')){this.demoForm.patchValue({channel: 'Partner'}); this.demoForm.patchValue({redemption_code: this.route.snapshot.paramMap.get('id')});}
    if(this.router.url.includes('appsumo')){this.demoForm.patchValue({channel: 'App Sumo'}); this.demoForm.patchValue({redemption_code: ''})}
    
  }
  ngAfterViewInit(){
    Array.from(document.getElementsByClassName('mat-horizontal-stepper-header-container') as HTMLCollectionOf<HTMLElement>)[0].style.visibility = 'hidden';
  }
  getField(key: string, fields: FormlyFieldConfig[]): FormlyFieldConfig {
    for (let i = 0, len = fields.length; i < len; i++) {
      const f = fields[i];
      if (f.key === key) {
        return f;
      }
      
      if (f.fieldGroup && !f.key) {
        const cf = this.getField(key, f.fieldGroup);
        if (cf) {
          return cf;
        }
      }
    }
  }
  submitForm($ev, value: any) {
        // console.log("submitted");
        $ev.preventDefault();
        for (let c in this.demoForm.controls) {
            this.demoForm.controls[c].markAsTouched();
        }
        let httpHeaders = new HttpHeaders({
           'Content-Type' : 'application/json'
        });
        if(this.demoForm.valid){
          this.reCaptchaV3Service.execute(this.siteKey, 'homepage', (token) => {
           // console.log('This is your token: ', token);
          this.captchaResponse = token;
              this.clicked_save=true;
           
                // this.modal.load_modal_by_name('spinner', {});
                // console.log(this._tokenService.tokenOptions.apiBase);
                console.log(value);
                 this.http.post(environment.baseUrl + '/api/arrange_demo', this.shared.merge_options(value, {captcha: this.captchaResponse, ip: this.ip}), {headers:httpHeaders}).subscribe(
                   // this.http.post(this._tokenService.tokenOptions.apiBase + '/api/arrange_demo', value, {headers:httpHeaders}).subscribe(
                  res => {
                      console.log(res);
                      // this.modal.hide_modal_by_name('spinner');
                      this.clicked_save=false;
                      this.navbar.set_notification('Success', 'Notice', 'Success - Please Check mail for Next steps');
                      this.modal.hide_modal_by_name("arrange_demo")
                      this.router.navigate(['/account/login']);
                  }, error => {
                      // console.log(error);
                  }
                )
              }
             )}
        else{
          console.log(this.demoForm.errors)
              this.navbar.set_notification('Warning', 'Please complete form', 'Please ensure all fields are filled')
        }

    
        //}
    }
    url_contains(_string){
      return this.router.url.includes(_string)
    }
    submit() {
    // alert(JSON.stringify(this.model));
        let httpHeaders = new HttpHeaders({
           'Content-Type' : 'application/json'
        });
        this.clicked_save=true
        this.reCaptchaV3Service.execute(this.siteKey, 'homepage', (token) => {
           // console.log('This is your token: ', token);
          this.captchaResponse = token;
          this.clicked_save=true;
            // this.modal.load_modal_by_name('spinner', {});
            // console.log(this._tokenService.tokenOptions.apiBase);
             this.http.post(environment.baseUrl + '/api/arrange_demo', this.shared.merge_options(this.model, {captcha: this.captchaResponse, ip: this.ip}), {headers:httpHeaders}).subscribe(
               // this.http.post(this._tokenService.tokenOptions.apiBase + '/api/arrange_demo', this.shared.merge_options(this.model, {captcha: this.captchaResponse, ip: this.ip}), {headers:httpHeaders}).subscribe(
              res => {
                  console.log(res);
                  // this.modal.hide_modal_by_name('spinner');
                  this.clicked_save=false;
                  this.navbar.set_notification('Success', 'Notice', res['notice']);
                  this.modal.hide_modal_by_name("arrange_demo")
                  this.router.navigate(['/account/login']);
              }, error => {
                  // console.log(error);
              }
            )
           })
          }
    login(){
      this.router.navigate(['/account/login']);
    }
    signup(){
      this.router.navigate(['/account/signup']);
    }
    modelChange(ev){
      // console.log(ev);
    }

}

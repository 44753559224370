import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from'@angular/forms';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service'
import { Subscription, Observable } from 'rxjs';
import { ModalsService } from '../../../layout/modals/modals.service';

@Component({
  selector: '[app-help]',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
	message='';
	header = '';
    constructor(
  	private authService: AuthenticationService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private shared: SharedService,
  	private navbar: NavbarService,
  	private modal: ModalsService,
  	) { }

  ngOnInit() {
  	this.navbar.help_message$.subscribe(
  		res=> {
  			console.log(res);
  			this.message = res['message']
  			this.header = res['header']

  		});
  }

}

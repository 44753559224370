import { Injectable , Inject} from '@angular/core';
import { 
  HttpEvent, HttpRequest, HttpHandler, 
  HttpInterceptor, HttpErrorResponse 
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../authentication/authentication.service';
import { ActivatedRoute, Router, Params} from '@angular/router';
import { NotificationService } from './notification.service';
import {SharedService} from '../../shared/shared.service'
import { ElectronService } from 'ngx-electron';
// const win = (<any>window)
import { PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
let win = undefined;


@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService : AuthenticationService, 
    private notifier: NotificationService, 
    private router: Router, 
    private shared: SharedService,
    private electronService: ElectronService,
   @Inject(PLATFORM_ID) private platformId: any
   ) {
        
        if(isPlatformBrowser(platformId))
        { win = (<any>window)}
    }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const re = '/api/is_logged_in';
    // Exclude interceptor for login request:
    // console.log(request)
    // if (request.url.search(re) === -1 ) {
    
      return next.handle(request).pipe(
        retry(0),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401 || error.status === 400) {
            // refresh token
            // if(!this.authService.isLoggedIn()){
              // console.log("401")
              // if(this.authService.isLoggedIn()){
                
              // }
              // this.router.navigate(['/account/login'])
              if(this.shared.isBrowser()){
                // console.log('failed2')
                this.notifier.showError("Invalid Credentials, Please log in again");
                this.authService.logOut();
                // window.localStorage.removeItem('access_token');
                // window.localStorage.removeItem('client');
                // window.localStorage.removeItem('expiry');
                // window.localStorage.removeItem('uid');
                // window.localStorage.removeItem('tokenType');
                //console.log(this.router.url)
                // window.location.href = '/account/login';
                //this.shared.refresh('/account/login');
                // if(!this.router.url.includes('/account/login')){
                  // setTimeout(function(){ 
                  //   if(this.electronService.isElectronApp)
                  //   {console.log("reset1");
                  //     this.router.navigate['/account/login']}
                  //   else
                  //   {console.log("reset2")
                  //     window.location.href = '/account/login';}
                  //    }, 3000);
                // }
              }

            // }
          } else if(error.status === 429){
            if(this.shared.isBrowser()){
                // console.log('failed2')
                this.notifier.showError("Too many requests- try after some time.");}

          }
          else {
            return throwError(error);
          }
        })
      );
    // }    
  }
}
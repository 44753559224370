import { Component, OnInit , ViewChild} from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { AuthenticationService} from '../../../core/authentication/authentication.service';
import { NavbarService } from '../../../layout/header/navbar.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { SharedService } from '../../../core/shared/shared.service';
import { ModalsService } from '../../../layout/modals/modals.service';

@Component({
  selector: '[app-access-levels]',
  templateUrl: './access-levels.component.html',
  styleUrls: ['./access-levels.component.scss']
})
export class AccessLevelsComponent implements OnInit {

  create_role_selected=false;
  edit_selected=false;
	clicked_save = false;
	roles:any = []
	role:any = {}
	is_admin=false //to save if admin role is loaded to disable
	unique_role = true
	roleForm =this.fb.group({
    	access_role_id: [''],
    	name:['', Validators.required],
    	can_view_candidates: [''],
		can_add_candidates: [''],
		can_search_for_candidates: [''],
		can_view_comments: [''],
		can_add_comments: [''],
		can_view_candidate_tracking_info: [''],
		can_update_candidate_tracking_info: [''],
		can_add_jobs: [''],
		can_view_jobs: [''],
		can_view_job_data: [''],
		can_view_candidate_ranking: [''],
		can_update_candidate_ranking: [''],
		can_view_email_inbox: [''],
		can_send_emails: [''],
		can_view_reports: [''],
		can_manage_users: [''],
		can_manage_vendors: [''],
		can_manage_billing: [''],
		can_view_calendar: [''],
		can_add_appointments: [''],
		can_update_jobs: [''],
		can_update_candidates: [''],
		can_delete_jobs: [''],
		can_delete_candidates: [''],
		can_permanently_delete_candidates: [''],
		can_access_company_wide_jobs: [''],
		can_access_company_wide_calendar: [''],
		can_access_ctc: [''],
		can_access_company_department_wide_jobs: [''],
		can_access_notice_period: [''],
		can_add_clients: [''],
		can_delete_clients: [''],
		can_download_resumes: [''],
		can_edit_stages: [''],
		can_create_tags: [''],
		can_edit_tags: [''],
		can_delete_tags: ['']
  	});
	constructor(
  	private authService: AuthenticationService,
  	private router: Router,
  	private route: ActivatedRoute,
    private navbar: NavbarService,
    private fb: FormBuilder,
    private shared: SharedService,
    private modal: ModalsService
  	) {
       }

  ngOnInit() {
  	// this.load_summernote();
  	this.load_roles();
  	
  }
  submitRoleForm($ev, _val){
		this.authService.post('/api/update_role', _val)
		.subscribe(
			res => {
				
				this.navbar.set_notification('Success','Role Saved', 'The role has been successfully saved');
				// setTimeout(function(){ location.reload(); }, 3000);
				this.shared.refresh(this.router.url);
			},
			// err => console.log(err)
		)
	}

	load_role(_id){
		console.log(_id)
		this.authService.post('/api/load_role', {'access_role_id': _id})
		.subscribe(
			res => {
				console.log(res);
				// console.log(res['role']['content'])
				// this.contents = res['role']['content'];
				// $('#summernote_container').html('<div id="summernote" class="summernote"><span>'+this.contents+'</span></div>');
				// this.load_summernote();
				// $('#summernote').summernote('pasteHTML', this.contents);
				this.role = res['role']
				this.roleForm.patchValue({
          			'access_role_id': res['role']['id'],
					'name': res['role']['name'],
					'can_view_candidates': res['role']['can_view_candidates'],
					'can_add_candidates': res['role']['can_add_candidates'],
					'can_search_for_candidates': res['role']['can_search_for_candidates'],
					'can_view_comments': res['role']['can_view_comments'],
					'can_add_comments': res['role']['can_add_comments'],
					'can_view_candidate_tracking_info': res['role']['can_view_candidate_tracking_info'],
					'can_update_candidate_tracking_info': res['role']['can_update_candidate_tracking_info'],
					'can_add_jobs': res['role']['can_add_jobs'],
					'can_view_jobs': res['role']['can_view_jobs'],
					'can_view_job_data': res['role']['can_view_job_data'],
					'can_view_candidate_ranking': res['role']['can_view_candidate_ranking'],
					'can_update_candidate_ranking': res['role']['can_update_candidate_ranking'],
					'can_view_email_inbox': res['role']['can_view_email_inbox'],
					'can_send_emails': res['role']['can_send_emails'],
					'can_view_reports': res['role']['can_view_reports'],
					'can_manage_users': res['role']['can_manage_users'],
					'can_manage_vendors': res['role']['can_manage_vendors'],
					'can_manage_billing': res['role']['can_manage_billing'],
					'can_view_calendar': res['role']['can_view_calendar'],
					'can_add_appointments': res['role']['can_add_appointments'],
					'can_update_jobs': res['role']['can_update_jobs'],
					'can_update_candidates': res['role']['can_update_candidates'],
					'can_delete_jobs': res['role']['can_delete_jobs'],
					'can_delete_candidates': res['role']['can_delete_candidates'],
					'can_permanently_delete_candidates': res['role']['can_permanently_delete_candidates'],
					'can_access_company_wide_jobs': res['role']['can_access_company_wide_jobs'],
					'can_access_company_wide_calendar': res['role']['can_access_company_wide_calendar'],
					'can_access_ctc': res['role']['can_access_ctc'],
					'can_access_company_department_wide_jobs': res['role']['can_access_company_department_wide_jobs'],
					'can_access_notice_period': res['role']['can_access_notice_period'],
					'can_add_clients': res['role']['can_add_clients'],
					'can_delete_clients': res['role']['can_delete_clients'],
					'can_download_resumes': res['role']['can_download_resumes'],
					'can_edit_stages': res['role']['can_edit_stages'],
					'can_create_tags': res['role']['can_create_tags'],
					'can_edit_tags': res['role']['can_edit_tags'],
					'can_delete_tags': res['role']['can_delete_tags']
				});
				console.log(this.roleForm.value)
				this.is_admin = res['is_admin']
				this.unique_role=true
  			
			},
			// err => console.log(err)
		)
	}
	reset_role_form(){
		this.roleForm.patchValue({
          			'access_role_id': '',
					'name': '',
					'can_view_candidates': true,
					'can_add_candidates': true,
					'can_search_for_candidates': true,
					'can_view_comments': true,
					'can_add_comments': true,
					'can_view_candidate_tracking_info': '',
					'can_update_candidate_tracking_info': '',
					'can_add_jobs': '',
					'can_view_jobs': '',
					'can_view_job_data': '',
					'can_view_candidate_ranking': '',
					'can_update_candidate_ranking': '',
					'can_view_email_inbox': '',
					'can_send_emails': '',
					'can_view_reports': '',
					'can_manage_users': '',
					'can_manage_vendors': '',
					'can_manage_billing': '',
					'can_view_calendar': '',
					'can_add_appointments': '',
					'can_update_jobs': '',
					'can_update_candidates': '',
					'can_delete_jobs': '',
					'can_delete_candidates': '',
					'can_permanently_delete_candidates': '',
					'can_access_company_wide_jobs': '',
					'can_access_company_wide_calendar': '',
					'can_access_ctc': '',
					'can_access_company_department_wide_jobs': '',
					'can_access_notice_period': '',
					'can_add_clients': '',
					'can_delete_clients': '',
					'can_download_resumes': '',
					'can_edit_stages': '',
					'can_create_tags': true,
					'can_edit_tags': true,
					'can_delete_tags': true
				});
		// this.role = {
		// 			'access_role_id': '',
		// 			'name': '',
		// 			'can_view_candidates': false,
		// 			'can_add_candidates': false,
		// 			'can_search_for_candidates': false,
		// 			'can_view_comments': false,
		// 			'can_add_comments': false,
		// 			'can_view_candidate_tracking_info': false,
		// 			'can_update_candidate_tracking_info': false,
		// 			'can_add_jobs': false,
		// 			'can_view_jobs': false,
		// 			'can_view_job_data': false,
		// 			'can_view_candidate_ranking': false,
		// 			'can_update_candidate_ranking': false,
		// 			'can_view_email_inbox': false,
		// 			'can_send_emails': false,
		// 			'can_view_reports': false,
		// 			'can_manage_users': false,
		// 			'can_manage_vendors': false,
		// 			'can_manage_billing': false,
		// 			'can_view_calendar': false,
		// 			'can_add_appointments': false,
		// 			'can_update_jobs': false,
		// 			'can_update_candidates': false,
		// 			'can_delete_jobs': false,
		// 			'can_delete_candidates': false,
		// 			'can_permanently_delete_candidates': false,
		// 			'can_access_company_wide_jobs': false,
		// 			'can_access_company_wide_calendar': false,
		// 			'can_access_ctc': false,
		// 			'can_access_company_department_wide_jobs': false,
		// 			'can_access_notice_period': false,
		// 			'can_add_clients': false,
		// 			'can_delete_clients': false,
		// 			'can_download_resumes': false,
		// 			'can_edit_stages': false,
		// 			'can_create_tags': false,
		// 			'can_edit_tags': false,
		// 			'can_delete_tags': false
		// }
		console.log(this.roleForm.value)
	}
	delete_role(_id){
		this.authService.post('/api/delete_role', {'access_role_id': _id}).subscribe(
			res=> {
				this.navbar.set_notification('Success','role Deleted', res['notice']);
				// setTimeout(function(){ location.reload(); }, 3000);
				this.shared.refresh(this.router.url);
			});
		this.load_roles();
	}
	
	// load_summernote(){
	//     $('#summernote').summernote({
	//         height: 230,
	//         dialogsInBody: true,
	//         callbacks: {
	//             onChange: (contents, $editable) => {
	//                 this.contents = contents;
	//                 // console.log(contents);
	//             }
	//         }
	//     });
	//     $('.note-popover').css({'display': 'none'});
 //  	}

  	load_roles(){
  		this.authService.get('/api/access_levels').subscribe(
			res => {
				this.roles = res['roles'];
				console.log(res);
			},
			error => {
				// console.log(error);
			}
		)
  	}
  	onChange(text: string): void {  
	  
	  console.log(this.role['name'])
	  if(this.role['name'] != text)
	  {
	  	console.log(text);
	  	if(this.roles.map((x,i,a)=> {return x['name'].toLowerCase()}).includes(text.toLowerCase())){
	  	  	this.unique_role=false
	  	  }
	  	  else{
	  	  	this.unique_role=true
	  	  }
	  	console.log(this.unique_role)
	  }
	  
	}
	create_role(){
		console.log(this.role)
  		this.create_role_selected=true;
  		this.edit_selected=true;
  		this.reset_role_form();
  		console.log(this.roleForm.value)
  	}
    
  edit_role(_val){
		this.create_role_selected=false;
		console.log(_val)
		this.load_role(_val)
		this.edit_selected= true;
	}
}

import { Component, OnInit, OnDestroy, Inject, Injectable} from '@angular/core';
import { AuthenticationService} from '../../../core/authentication/authentication.service'
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService }     from '../../../layout/header/navbar.service';
import { ModalsService } from '../../../layout/modals/modals.service';
import { CareerService} from '../../../routes/career/career.service'
// import { BehaviorSubject } from 'rxjs';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
//import { Job_role} from './model/job_role'
import { Params, Router, ActivatedRoute } from "@angular/router";
//import { ListviewComponent } from '../resumereports/listview/listview.component';
import { FormGroup, FormBuilder, FormControl, Validators , FormArray} from '@angular/forms';
import { FileUploader , FileUploaderOptions, FileItem, ParsedResponseHeaders} from 'ng2-file-upload';
import { HttpHeaders,HttpClient} from '@angular/common/http';
// import { AngularTokenService } from 'angular-token';
import { environment } from '../../../../environments/environment'
import { ElectronService } from 'ngx-electron';
const URL = environment.baseUrl+'/api/candidate_upload_resume';
// const win = (<any>window)
import { PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
import {TokenService} from '../../../core/authentication/token.service';
import { CustomValidators } from 'ng2-validation';

let win = undefined;
let maxFileSize = 5 * 1024 * 1024;
@Component({
  selector: '[app-job-application]',
  templateUrl: './job-application.component.html',
  styleUrls: ['./job-application.component.scss']
})
export class JobApplicationComponent implements OnInit {
  public uploader: FileUploader = new FileUploader({maxFileSize:maxFileSize, allowedMimeType: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'application/rtf', 'text/plain'], url: URL, itemAlias: 'resume_source', autoUpload: true});
    public hasBaseDropZoneOver: boolean = false;
    // public hasAnotherDropZoneOver: boolean = false;

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }
    job_role_id:any; 
    company_id:any;
    confirmation_id: any;
    uploaded_resume:any;
    active_section ='upload';
    original_career_page_url='';
    candidate_confirmation_token = '';
    countries =[];
    industries = [];
    current_country ={};
    current_currency = {};
    current_industry:any;
    current_salary_period: any;
    expected_currency = {};
    expected_salary_period: any;
    // countries = [{'country_name': 'United States', 'country_code': 'US'}, {'country_name': ''}]
    candidate_country_code ='';
    currencies = [];
    salary_period_options = ['Annual', 'Monthly', 'Hourly'];
    genders= [ 'Male', 'Female', 'Other'];
    email_sent_to_candidate:any;
    logo_url='';
    is_internal=false;
    bsConfig = {
        containerClass: 'theme-angle'
    }
    uploadForm=this.fb.group({
      employee_email: ['', Validators.compose([Validators.required, CustomValidators.email])],
      employee_id: ['', Validators.required]
    });
    validateOtpForm= this.fb.group({
      otp: ['', [Validators.pattern('^[0-9]{6}$'),Validators.required]]
    });
    otp_verified=true;
    enter_OTP=false
    
  // update_resume_details = new FormGroup({
  //   resume_id: new FormControl(),
  //   resume_full_name: new FormControl(),
  //   resume_date_of_birth: new FormControl(),
  //   resume_email: new FormControl(),
  //   resume_phone_number: new FormControl(),
  //   resume_years_of_work_experience: new FormControl(),
  //   resume_ctc: new FormControl(),
  //   resume_current_employer: new FormControl(),
  //   resume_current_job_position: new FormControl(),
  //   resume_current_location: new FormControl(),
  //   resume_preferred_location: new FormControl(),
  //   resume_educational_qualification: new FormControl(),
  //   resume_notice_period: new FormControl(),
  //   resume_expected_ctc: new FormControl(),
  //   resume_willingness_to_relocate: new FormControl(),
  //   resume_reason_for_job_change: new FormControl(),
  //   resume_interview_availability: new FormControl(),
  //   resume_marital_status: new FormControl(),
  //   resume_college_attended: new FormControl(),
  //   resume_linkedin_profile_url: new FormControl(),
  //   resume_additional_info: new FormControl(),
  // });
  bsValue = new Date();
    maxDate = new Date();
  update_resume_details = new FormGroup({
    resume_id: new FormControl(),
    resume_full_name: new FormControl(),
    resume_date_of_birth: new FormControl(),
    resume_email: new FormControl('', Validators.email),
    resume_phone_number: new FormControl(),
    resume_gender: new FormControl(),
    resume_years_of_work_experience: new FormControl('', Validators.pattern('^(0|[1-9][0-9]*)$')),
    // resume_ctc: new FormControl(),
    resume_salary_amount: new FormControl('', Validators.pattern('^(0|[1-9][0-9]*)$')),
    resume_salary_period: new FormControl(),
    resume_salary_currency: new FormControl(),
    resume_expected_salary_amount: new FormControl('', Validators.pattern('^(0|[1-9][0-9]*)$')),
    resume_expected_salary_period: new FormControl(),
    resume_expected_salary_currency: new FormControl(),
    resume_current_employer: new FormControl(),
    resume_current_job_position: new FormControl(),
    resume_current_industry: new FormControl(),
    resume_current_location: new FormControl(),
    resume_current_country: new FormControl(),
    // resume_current_zipcode: new FormControl(),
    resume_preferred_location: new FormControl(),
    resume_educational_qualification: new FormControl(),
    resume_notice_period: new FormControl(),
    resume_expected_ctc: new FormControl(),
    resume_willingness_to_relocate: new FormControl(false),
    resume_reason_for_job_change: new FormControl(),
    resume_interview_availability: new FormControl(),
    resume_marital_status: new FormControl(),
    resume_college_attended: new FormControl(),
    resume_linkedin_profile_url: new FormControl(),
    resume_additional_info: new FormControl(),
    questions: new FormArray([
    ]),
  });
  clicked_save=false;
  subscriptions = new Subscription();
  questionnaire_answers =[];

   
  constructor(
  	  	public authService: AuthenticationService, 
    private fb: FormBuilder, 
    private router: Router, 
    private route: ActivatedRoute, 
    private shared: SharedService,
    private navbar: NavbarService,
    private modal: ModalsService,
    private career: CareerService,
    private http: HttpClient,
    private tokenService2: TokenService,
    // private tokenService: AngularTokenService,
    private electronService: ElectronService,
    @Inject(PLATFORM_ID) private platformId: any
        ) {
    
    if(isPlatformBrowser(platformId))
    { win = (<any>window)}}

  ngOnInit() {

  	this.get_plo();
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; this.uploadForm.get('employee_email').value};
    if(!this.router.url.includes('confirm'))
  	{
      this.upload_builder();
  	  this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
      this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
    }
    else{
      this.load_confirmation_details();
    }
  }
  ngOnDestroy(){
    this.subscriptions.unsubscribe();
  }

  get_plo(){
    if(this.router.url.includes('referral')){this.is_internal=true; this.otp_verified=false}
  	if(this.router.url.includes('job') || this.router.url.includes('apply') ||  this.router.url.includes('confirm')){
  		var params= this.career.get_params_from_url(this.router.url)
  		this.job_role_id=params['job_role_id'];
  		this.company_id=params['company_id'];
      this.confirmation_id = params['confirmation_id'];
      this.email_sent_to_candidate= params['email_sent_to_candidate']===undefined ? '' : params['email_sent_to_candidate']
      this.get_company_mandatory_fields(this.company_id);
      this.get_company_logo(this.company_id)
  		console.log(params)
  	}
  	// else{

  	// }
    this.subscriptions.add(this.uploadForm.valueChanges
      .pipe(distinctUntilChanged()) // makes sure the value has actually changed.
      .subscribe(data => {
        // console.log(data);
        this.upload_builder()
      }));
  }

  get_company_mandatory_fields(_id){

    this.http.post(environment.baseUrl +'/api/get_company_mandatory_fields',{'id': _id}).subscribe(
      // this.http.post(this.tokenService.tokenOptions.apiBase +'/api/get_company_mandatory_fields',{'id': _id}).subscribe(
      res=> {
        console.log(res);
        // if(res['is_full_name_mandatory'])
          // {console.log("success")}
        // {this.update_resume_details.controls["full_name"].setValidators([Validators.required]);}
        if(res['is_full_name_mandatory'])
        {this.update_resume_details.controls["resume_full_name"].setValidators([Validators.required]);}
        if(res['is_date_of_birth_mandatory'])
        {this.update_resume_details.controls["resume_date_of_birth"].setValidators([Validators.required]);}
        if(res['is_email_mandatory'])
        {this.update_resume_details.controls["resume_email"].setValidators([Validators.required]);}
        if(res['is_phone_no_mandatory'])
        {this.update_resume_details.controls["resume_phone_number"].setValidators([Validators.required]);}
        if(res['is_years_of_experience_mandatory'])
        {this.update_resume_details.controls["resume_years_of_work_experience"].setValidators([Validators.required]);}
        if(res['is_current_employer_mandatory'])
        {this.update_resume_details.controls["resume_current_employer"].setValidators([Validators.required]);}
        if(res['is_current_job_position_mandatory'])
        {this.update_resume_details.controls["resume_current_job_position"].setValidators([Validators.required]);}
        if(res['is_current_industry_mandatory'])
        {this.update_resume_details.controls["resume_current_industry"].setValidators([Validators.required]);}
        if(res['is_current_location_mandatory'])
        {this.update_resume_details.controls["resume_current_location"].setValidators([Validators.required]);}
        if(res['is_preferred_location_mandatory'])
        {this.update_resume_details.controls["resume_preferred_location"].setValidators([Validators.required]);}
        if(res['is_salary_mandatory'])
        {this.update_resume_details.controls["resume_salary_amount"].setValidators([Validators.required]);}
        if(res['is_expected_salary_mandatory'])
        {this.update_resume_details.controls["resume_expected_salary_amount"].setValidators([Validators.required]);}
        if(res['is_educational_qualification_mandatory'])
        {this.update_resume_details.controls["resume_educational_qualification"].setValidators([Validators.required]);}
        if(res['is_notice_period_mandatory'])
        {this.update_resume_details.controls["resume_notice_period"].setValidators([Validators.required]);}
        if(res['is_willingness_to_relocate_mandatory'])
        {this.update_resume_details.controls["resume_willingness_to_relocate"].setValidators([Validators.required]);}
        if(res['is_reason_for_job_change_mandatory'])
        {this.update_resume_details.controls["resume_reason_for_job_change"].setValidators([Validators.required]);}
        if(res['is_interview_availability_mandatory'])
        {this.update_resume_details.controls["resume_interview_availability"].setValidators([Validators.required]);}
        if(res['is_marital_status_mandatory'])
        {this.update_resume_details.controls["resume_marital_status"].setValidators([Validators.required]);}
        if(res['is_college_attended_mandatory'])
        {this.update_resume_details.controls["resume_college_attended"].setValidators([Validators.required]);}
        if(res['is_additional_info_mandatory'])
        {this.update_resume_details.controls["resume_additional_info"].setValidators([Validators.required]);}
        if(res['is_gender_mandatory'])
        {this.update_resume_details.controls["resume_gender"].setValidators([Validators.required]);}

      }, 
      err=> console.log(err)
      )
  }
  get_company_logo(_id){
    this.http.post(environment.baseUrl +'/api/get_company_logo',{'company_id': _id}).subscribe(
      // this.http.post(this.tokenService.tokenOptions.apiBase +'/api/get_company_logo',{'company_id': _id}).subscribe(
      res=> {
        console.log(res);
        this.logo_url=res['logo_url']
      },
      err=> {console.log(err)}
      )
  }
  update_upload_parameters(){
      var uo: FileUploaderOptions = {};
      uo.headers = 
     [
       // {name: 'access-token', value : localStorage.getItem("accessToken")}, 
       // {name: 'client', value : localStorage.getItem("client")},
       // {name: 'expiry', value : localStorage.getItem("expiry")},
       // {name: 'token-type', value : localStorage.getItem("tokenType")},
       // {name: 'uid', value : localStorage.getItem("uid")}
       {name: 'access-token', value : localStorage.getItem("access_token")},
       {name: 'Authorization', value : 'Bearer '+ this.tokenService2.getToken()}, 
     ];
      
      this.uploader.setOptions(uo);
  }
  upload_builder(){
    // console.log(this.uploadForm.get('employee_email').value.toString())
    // console.log(this.uploadForm.get('employee_id').value.toString())
    this.update_upload_parameters();
    var date:Date = new Date();
    var baseDate: Date = new Date(Date.UTC(1970, 0, 1));
    var timestamp = (date.getTime() - baseDate.getTime()).toString();
    var job_role_id = this.job_role_id;
    var company_id=this.company_id;
    var email_sent_to_candidate=this.email_sent_to_candidate
    if(this.is_internal){
      var employee_email = this.uploadForm.get('employee_email').value.toString()//this.employee_email
      var employee_id = this.uploadForm.get('employee_id').value.toString()//this.employee_id
    }
    else{
      var employee_email = null
      var employee_id = null
    }
    var is_internal = this.is_internal
    // console.log(this.cv_sources)
    //this.set_timestamp();
    this.uploader.onBuildItemForm = function(fileItem, form)
    { 
      
      //var timestamp ='9871239761'
      form.append('job_role_id',job_role_id);
      form.append('company_id', company_id); 
      form.append('email_sent_to_candidate', email_sent_to_candidate); 
      form.append('user_upload_timestamp', timestamp);
      form.append('file_count', this.queue.length);
      form.append('is_internal', is_internal); 
      form.append('employee_email', employee_email);
      form.append('employee_id', employee_id);
      // form.append('cv_source', 'Career Page');
      return {fileItem, form} 
    };
	  this.uploader.onCompleteItem = (item:any, response:any, status:any, headers:any)=> {
      // console.log( item, status,response, headers);
      // var resp=JSON.parse(response);
      console.log(response);
      // console.log(headers);
      if (headers['access-token']!== undefined && headers['access-token']!== ''){
      	localStorage.setItem('accessToken', headers['access-token']);
    		localStorage.setItem("expiry", headers['expiry']);
        this.update_upload_parameters()
    	}
      
      // console.log(resp);  
    };
    this.uploader.onCompleteAll =()=> {
      this.navbar.set_notification('Success','Uploaded Resumes Completed', 'Please confirm your details.');
      // setTimeout(function(){ location.reload(); }, 3000);
    }
  }
  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        let data = JSON.parse(response); //success server response
        console.log(data)
        this.uploaded_resume=data['uploaded_resume'];
        this.original_career_page_url = data['original_career_page_url'];
        this.candidate_confirmation_token = data['candidate_confirmation_token'];
        this.countries = data['country_names']
        this.currencies = data['currencies'];
        this.current_country = data['current_country'];
        this.current_currency = data['current_currency'];
        this.current_industry = data['current_industry']
        this.current_salary_period = data['current_salary_period'];
        this.expected_currency = data['expected_currency'];
        this.expected_salary_period = data['expected_salary_period'];
        this.questionnaire_answers = data['questionnaire_answers'];
        this.industries = data['industries'];

        console.log(this.questionnaire_answers)
        for (let a of this.questionnaire_answers)  

          {  
            if(!this.shared.isEmpty(a['answer_of_candidate'])){
              this.addAnswer(a['id'], a['type_of_question'], a['answer_of_candidate']['content'], a['answer_of_candidate']['selected_answer_options'].map((x,i,a)=>{return {label: x.answer_row_label, id: x.answer_row_id}}), a['answer_of_candidate']['selected_answer_options'].map((x,i,a)=>{return x.answer_row_label}), a['answer_rows'].map((x,i,a)=>{return {label: x.label, id: x.id}}), a['content'])
            }
          else{
            this.addAnswer(a['id'], a['type_of_question'], '', [], [], a['answer_rows'].map((x,i,a)=>{return {label: x.label, id: x.id}}), a['content'])
            }
          }
        console.log(this.questions)
        this.load_candidate_details();
    }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
        let error = JSON.parse(response); //error server response
  }

  load_candidate_details(){
  	this.active_section='candidate_details';
  	this.update_form();
  }

  update_form(){
    // this.update_resume_details.patchValue({
    //   'resume_id': this.uploaded_resume['id'],
    //   'resume_full_name': this.uploaded_resume['full_name'],
    //   'resume_date_of_birth': this.uploaded_resume['date_of_birth'],
    //   'resume_email': this.uploaded_resume['email'],
    //   'resume_phone_number': this.uploaded_resume['phone_number'],
    //   'resume_years_of_work_experience': this.uploaded_resume['years_of_work_experience'],
    //   'resume_ctc': this.uploaded_resume['ctc'],
    //   'resume_current_employer': this.uploaded_resume['current_employer'],
    //   'resume_current_job_position': this.uploaded_resume['current_job_position'],
    //   'resume_current_location': this.uploaded_resume['current_location'],
    //   'resume_preferred_location': this.uploaded_resume['preferred_location'],
    //   'resume_educational_qualification': this.uploaded_resume['educational_qualification'],
    //   'resume_notice_period': this.uploaded_resume['notice_period'],
    //   'resume_expected_ctc': this.uploaded_resume['expected_ctc'],
    //   'resume_willingness_to_relocate': this.uploaded_resume['willingness_to_relocate'],
    //   'resume_reason_for_job_change': this.uploaded_resume['reason_for_job_change'],
    //   'resume_interview_availability': this.uploaded_resume['interview_availability'],
    //   'resume_marital_status': this.uploaded_resume['marital_status'],
    //   'resume_college_attended': this.uploaded_resume['college_attended'],
    //   'resume_linkedin_profile_url': this.uploaded_resume['linkedin_profile_url'],
    //   'resume_additional_info': this.uploaded_resume['additional_info'],
    //  });
    this.update_resume_details.patchValue({
      'resume_id': this.uploaded_resume['id'],
      'resume_full_name': this.uploaded_resume['full_name'],
      'resume_date_of_birth': (this.uploaded_resume['date_of_birth']===undefined || this.uploaded_resume['date_of_birth']===null || this.uploaded_resume['date_of_birth']==='') ? '': new Date(this.uploaded_resume['date_of_birth']), //this.uploaded_resume['date_of_birth'],
      'resume_email': this.uploaded_resume['email'],
      'resume_phone_number': this.uploaded_resume['phone_number'],
      'resume_gender': this.uploaded_resume['gender'],
      'resume_years_of_work_experience': this.uploaded_resume['years_of_work_experience'],
      // 'resume_ctc': this.uploaded_resume['ctc'],
      'resume_salary_amount': this.uploaded_resume['salary_amount'],
      'resume_salary_period': this.current_salary_period,//this.uploaded_resume['salary_period'],
      'resume_salary_currency': this.current_currency,//this.uploaded_resume['currency'],
      'resume_expected_salary_amount': this.uploaded_resume['expected_salary_amount'],
      'resume_expected_salary_period': this.expected_salary_period,//this.uploaded_resume['salary_period'],
      'resume_expected_salary_currency': this.expected_currency,//this.uploaded_resume['currency'],
      'resume_current_employer': this.uploaded_resume['current_employer'],
      'resume_current_job_position': this.uploaded_resume['current_job_position'],
      'resume_current_industry': this.current_industry['id'],
      'resume_current_location': (this.uploaded_resume['current_location']=== undefined || this.uploaded_resume['current_location'] === null || this.uploaded_resume['current_location']==='') ? [] : [{'display': this.uploaded_resume['current_location'], 'value': this.uploaded_resume['current_location']}],//this.uploaded_resume['current_location'],
      'resume_preferred_location': this.uploaded_resume['preferred_location'],
      'resume_educational_qualification': this.uploaded_resume['educational_qualification'],
      'resume_notice_period': this.uploaded_resume['notice_period'],
      'resume_expected_ctc': this.uploaded_resume['expected_ctc'],
      'resume_willingness_to_relocate': (this.uploaded_resume['willingness_to_relocate'] === undefined || this.uploaded_resume['willingness_to_relocate'] === '' || this.uploaded_resume['willingness_to_relocate'] === null) ? false : this.uploaded_resume['willingness_to_relocate'],
      'resume_reason_for_job_change': this.uploaded_resume['reason_for_job_change'],
      'resume_interview_availability': this.uploaded_resume['interview_availability'],
      'resume_marital_status': this.uploaded_resume['marital_status'],
      'resume_college_attended': this.uploaded_resume['college_attended'],
      'resume_linkedin_profile_url': this.uploaded_resume['linkedin_profile_url'],
      'resume_additional_info': this.uploaded_resume['additional_info'],
      'resume_current_country': this.current_country['country_code'],
      // 'resume_current_zipcode': this.uploaded_resume['current_zipcode'],

     });
    this.candidate_country_code=this.current_country['country_code'];
  }
  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.update_resume_details.controls) {
            this.update_resume_details.controls[c].markAsTouched();
        }
    // if (this.submitdetailsForm.dirty && this.submitdetailsForm.valid)
    if (this.update_resume_details.valid) 
    {
      this.clicked_save=true;
      // console.warn(value);
      // this.generated_params = this.shared.merge_options(this.generated_params, value)
      // console.log(this.generated_params);
      var concat_params = this.shared.merge_options(value, {'resume_id': this.uploaded_resume['id']});
      concat_params = this.shared.merge_options(concat_params, {'candidate_confirmation_token': this.candidate_confirmation_token})
      console.log(concat_params);
      // this.shared.merge_options(value, {'resume_id': this.uploaded_resume['id'], 'candidate_confirmation_token': this.candidate_confirmation_token});
      this.http.post(environment.baseUrl +'/api/update_candidate_upload_resume', concat_params
        // this.http.post(this.tokenService.tokenOptions.apiBase +'/api/update_candidate_upload_resume', concat_params
       // this.authService.post('/api/update_candidate_upload_resume', this.shared.merge_options(value, {'resume_id': this.uploaded_resume['id']})
      //   'interview_end': this.shared.convert_date_time_to_string(value.end_date, value.end_time),
      //   'interview_start': this.shared.convert_date_time_to_string(value.start_date, value.start_time),
      //   'send_mail_to_candidate': value.send_mail_to_candidate,
      //   'appointment_message': value.appointment_message,
      //   'interview_location': value.interview_location,
      //   'interviewers': value.interviewers,
      //   'stage_id': value.stage_id,
      //   'interview_reminder_unit': value.interview_reminder_unit,
      //   'interview_reminder': value.interview_reminder,
      //   'uploaded_resume_id': this.params['uploaded_resume_id']
      ).subscribe(
        res => {
          // console.log(res);
          // this.uploaded_resume=res['uploaded_resume'];
          this.navbar.set_notification('Success','Details updated', res['notice']+' You will now be redirected to the career page');
          this.clicked_save = false;
          // this.details.push(res['comment']);
          this.active_section='completed_application';
          setTimeout(function(){ 
            if(this.electronService.isElectronApp)
            {win.api.send("openExternalUrl",res['original_career_page_url'] )}
            else
            {window.location.href =res['original_career_page_url'] }
          }, 3000);
        },
         err=> console.log(err)
      )
    }
    // this.modal.hide_modal('big');
  }
  select_country(){
    // console.log(this.update_resume_details.controls['resume_current_country'].value)
    this.candidate_country_code = this.update_resume_details.controls['resume_current_country'].value
  }
  requestAutocompleteCityName = (text: string): Observable<any> => {
    // const url = this.tokenService.tokenOptions.apiBase+`/api/city_names?term=${text}&country=`+ this.candidate_country_code;
    const url = environment.baseUrl+`/api/city_names?term=${text}&country=`+ this.candidate_country_code;
    console.log(url);
    return this.http
        .get(url, {headers:  new HttpHeaders({'Content-Type' : 'application/json'})})
        .map(data => data);
  };

  load_confirmation_details(){
    this.http.post(environment.baseUrl +'/api/load_candidate_details',{confirmation_id: this.confirmation_id, company_id: this.company_id}).subscribe(
      // this.http.post(this.tokenService.tokenOptions.apiBase +'/api/load_candidate_details',{confirmation_id: this.confirmation_id, company_id: this.company_id}).subscribe(
        data => 
        {  
           this.uploaded_resume=data['uploaded_resume'];
           this.original_career_page_url = data['original_career_page_url'];
           this.candidate_confirmation_token = data['candidate_confirmation_token'];
           this.countries = data['country_names']
           this.currencies = data['currencies'];
           this.current_country = data['current_country'];
           this.current_currency = data['current_currency'];
           this.current_industry=data['current_industry']
           this.current_salary_period = data['current_salary_period'];
           this.expected_currency = data['expected_currency'];
           this.expected_salary_period = data['expected_salary_period'];
           this.industries=data['industries']
           this.questionnaire_answers = data['questionnaire_answers']
           console.log(this.questionnaire_answers)
            for (let a of this.questionnaire_answers)  

              {  
                if(!this.shared.isEmpty(a['answer_of_candidate'])){
                  this.addAnswer(a['id'], a['type_of_question'], a['answer_of_candidate']['content'], a['answer_of_candidate']['selected_answer_options'].map((x,i,a)=>{return {label: x.answer_row_label, id: x.answer_row_id}}), a['answer_of_candidate']['selected_answer_options'].map((x,i,a)=>{return x.answer_row_label}), a['answer_rows'].map((x,i,a)=>{return {label: x.label, id: x.id}}), a['content'])
                }
              else{
                this.addAnswer(a['id'], a['type_of_question'], '', [], [], a['answer_rows'].map((x,i,a)=>{return {label: x.label, id: x.id}}), a['content'])
                }
              }
            console.log(this.questions)
           this.load_candidate_details();}
        )
  }
  submitUploadOtpForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.uploadForm.controls) {
        this.uploadForm.controls[c].markAsTouched();
    }
    if (this.uploadForm.dirty && this.uploadForm.valid)
    // if (this.autoFowardingRuleForm.valid) 
    {
      // this.clicked_schedule_appointment=true;
      // console.warn($ev);
      // console.warn(value);
      // console.log(this.shared.convert_date_to_query_string(value.start_date));
      // console.log(this.shared.convert_date_time_to_string(value.end_date, value.end_time));
      // console.log(value.start_date);
      // console.log(value.start_time);
      //new appointment
      
        this.authService.post('/api/generate_otp_for_referral', {
          'employee_email': value.employee_email,
          'employee_id': value.employee_id
        }).subscribe(
          res => {
            this.navbar.set_notification('Success','Please Check Email', 'OTP sent to Email. Please enter OTP below');
            this.enter_OTP=true;
          },
          // err=> console.log(err)
        )
      
      //reschedule
      
    }
    // this.modal.hide_modal('big');
  }
    submitValidateOtpForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.validateOtpForm.controls) {
        this.validateOtpForm.controls[c].markAsTouched();
    }
    if (this.validateOtpForm.dirty && this.validateOtpForm.valid)
    // if (this.autoFowardingRuleForm.valid) 
    {
      // this.clicked_schedule_appointment=true;
      // console.warn($ev);
      // console.warn(value);
      // console.log(this.shared.convert_date_to_query_string(value.start_date));
      // console.log(this.shared.convert_date_time_to_string(value.end_date, value.end_time));
      // console.log(value.start_date);
      // console.log(value.start_time);
      //new appointment
      
        this.authService.post('/api/validate_employee_referral_otp', {
          'employee_email': this.uploadForm.get('employee_email').value,
          'otp': value.otp
        }).subscribe(
          res => {
            console.log(res)
            this.navbar.set_notification('Success','OTP Verification', res['notice']);
            if(res['notice']==="Success!"){this.otp_verified=true}
          },
          // err=> console.log(err)
        )
      
      //reschedule
      
    }
    // this.modal.hide_modal('big');
  }
  get questions() {
      return this.update_resume_details.controls["questions"] as FormArray;
    }
    addAnswer(_id, _type, _answer_content, _answer_rows,_answer_rows_labels, _question_options, _question_content){
      console.log(_answer_rows)
      let answer =''
      if(_type==="radio"){
        if(_answer_rows.length ===0){
          console.log('1')
          answer = ""
        }
        else{
          console.log('2')
          answer = _answer_rows[0]['label']
        }
      }
      let questionForm = this.fb.group({
          question_id: [_id],
          question_options:[_question_options],
          question_type: [_type],
          answer_content: [_answer_content],
          answer_rows: [_answer_rows],
          answer_rows_labels: [_answer_rows_labels],
          question_content: [_question_content],
          answer: [_type === "radio" ? answer : _answer_rows]
          // answer: [_answer_rows]
      })
      console.log(questionForm)
      this.questions.push(questionForm)
    }
    deleteQuestion(i: number) {
      this.questions.removeAt(i);
    }
    // questionnaireCheckboxChanged(_bool){

    // }
    // public questionnaireCheckBox:boolean;

     questionnaireCheckboxChanged(event, id, option){
        // this.saveUsername = value;
        console.log(event.currentTarget.checked)
        console.log(id)
        console.log(option)
        console.log(this.questions.value)
        let as=this.questions.value
        for( let a of as){
          console.log(a)
          if(a?.question_id == id){
            console.log('entered 0')
            // let question_options= a.question_options
            let answer_rows = a.answer_rows
            console.log(option)
            // for( let question_option of question_options){
              if(event.currentTarget.checked){
                console.log("entered 1")
                console.log(option)
                answer_rows.push(option);
                break;
              }
              else{
                console.log("entered 2");
                console.log(option)
                let obj = answer_rows.find(o => o.id === option.id);

                console.log(obj);
                const index = answer_rows.indexOf(obj);
                const x = answer_rows.splice(index, 1);
                break;
              }
            // }
            console.log(answer_rows)
          }
        }
        // this.questions.patchValue([{question_id: id}, {answer_rows: [option]}])
        console.log(this.questions)
    }

    questionnaireRadioChanged(event, id, option){
        // this.saveUsername = value;
        console.log(event.currentTarget.checked)
        console.log(id)
        console.log(option)
        console.log(this.questions.value)
        let as=this.questions.value
        for( let a of as){
          console.log(a)
          if(a?.question_id == id){
            console.log('entered 0')
            // let question_options= a.question_options
            let answer_rows = a.answer_rows
            // answer_rows=[]
            console.log(option)
            // for( let question_option of question_options){
              if(event.currentTarget.checked){
                console.log("entered 1")
                console.log(option)
                answer_rows.pop();
                answer_rows.push(option);
                break;
              }
              else{
                console.log("entered 2");
                console.log(option)
                // let obj = answer_rows.find(o => o.id === option.id);

                // console.log(obj);
                // const index = answer_rows.indexOf(obj);
                // const x = answer_rows.splice(index, 1);
                break;
              }
            // }
            console.log(answer_rows)
          }
        }
        // this.questions.patchValue([{question_id: id}, {answer_rows: [option]}])
        console.log(this.questions)
    }

  
}

export const environment = {
  production: true,
   // baseUrl: 'http://localhost:3000',
   // clientId: 'srUV45xbI0BWc3Nd4lNFNWTIj0Y3Q4q1_sLcc2pgzxc',//local-hp
   // clientId: '4UzqF_fZmce_IF2mdtlp43hTl9y53M0rOAnPl2UCAdw',//local-dell
   // clientId: 'p53c6p9nCM1EJYwqkLqWNdc4tZmfgRzh52j-0JCGEAo', //local-desktop
   // clientId: 'PfEglEJF2sICAlepEVI4PlgGBDcfp3iEEuGvz-Vd2bk',
  baseUrl: 'https://tobu.cloud',
  clientId: 'N8PImVklIEVN0iPvs5u0P912Iv_I6q0ljhmtYpxQWVo',//server
  feURL: 'https://tobu.ai',

};

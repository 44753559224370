import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl} from'@angular/forms';
import { JobsService } from '../../jobs/jobs.service'
import { ModalsService } from '../../../layout/modals/modals.service';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service';


@Component({
  selector: '[app-manage-collaborators]',
  templateUrl: './manage-collaborators.component.html',
  styleUrls: ['./manage-collaborators.component.scss']
})
export class ManageCollaboratorsComponent implements OnInit {
  plo: any = {};
  uar: any = {};
  rcar: any= {};
  recruitment_stages:any;
  collaborator_options: any;
  url_params:any;
  valForm = new FormGroup({
    all_stages: new FormArray([
      this.fb.group({
          stage: [''],
          collaborators: ['']
      })
    ])
  });
  clicked_save =false;
  constructor(
  	private authService: AuthenticationService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private fb: FormBuilder,
    private jobs: JobsService,
    private modal: ModalsService,
    private shared: SharedService,
    private navbar: NavbarService,
  	) { }
  ngOnInit() {
    this.get_plo();
  }
  get_plo(){
    this.url_params = this.jobs.get_params_from_url(this.router.url);
    this.authService.post('/api/manage_job_roles', this.url_params).subscribe(
      res => {
          this.collaborator_options=res['collaborator_options'];
          this.recruitment_stages = res['recruitment_stages']
          // this.valForm.patchValue({all_stages: new FormArray(this.all_stages())});
          this.valForm.setControl('all_stages', this.fb.array(this.all_stages() || []));
          // console.log(this.valForm.controls.all_stages)
          // console.log(res);
      }, error => {
          // console.log(error);
      }
    )
  }
  submitForm($ev, value: any) {
    $ev.preventDefault();
    // console.log(value);
    for (let c in this.valForm.controls) {
        this.valForm.controls[c].markAsTouched();
    }

    // if (this.valForm.valid) {
        this.clicked_save = true;
        this.authService.post(
          '/api/set_collaborators', this.shared.merge_options(value, this.url_params)
          ).subscribe(
          res => {
              // console.log(res);
              this.clicked_save =false;
              // this.router.navigate(['jobs/candidates/',res['job_role_id']]);// navigate to the created job
              this.navbar.set_notification('Success','Success', 'The collaborators have been notified of the changes');
              // this.modal.hide_modal('medium')
              this.modal.hide_modal_by_name('manage_collaborators')
          }, error => {
              // console.log(error);
          }
        )
    // }
  }
  private all_stages(): any {
    // var as = {
    //       stage: [''],
    //       stage_type_id: [''],
    //       collaborators: ['']
    //   }
    var a =[]
    for (let rs of this.recruitment_stages) {
      a.push(this.fb.group({
          stage: [rs['name']],
          collaborators: [rs['collaborators']]
      }))
    }

    return a;
  }
  
  add_to_all_stages(_collaborator){
    if(_collaborator!='0'){
      // console.log(_collaborator)
      console.log(this.valForm.controls)
      var current_stages = this.valForm.controls['all_stages']
      var a =[]
      for (let ds of current_stages['value']) {
        // console.log(ds['collaborators'])
        var c=ds['collaborators']==='' ? [] : ds['collaborators'];
        // console.log(c)
        // console.log(c.concat(_collaborator))
        a.push(this.fb.group({
            stage: [ds['stage']],
            //stage_type_id: [ds['stage_type_id']],
            collaborators: [c.concat(_collaborator)]
        }))
      }
      // console.log(a);

      this.valForm.setControl('all_stages', this.fb.array(a));
      
    }
    
  }
   

}

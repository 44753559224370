import { Component, OnInit } from '@angular/core';
import { ElectronService } from 'ngx-electron';

import { HttpHeaders, HttpClient} from '@angular/common/http' ;
import { AuthenticationService} from '../../../core/authentication/authentication.service';

import {environment} from '../../../../environments/environment.prod'
@Component({
  selector: '[app-configure-desktop]',
  templateUrl: './configure-desktop.component.html',
  styleUrls: ['./configure-desktop.component.scss']
})
export class ConfigureDesktopComponent implements OnInit {
  isElectron = false
  constructor(
    private _electronService: ElectronService,
    private http: HttpClient,
    private authService: AuthenticationService
    ) { }

  ngOnInit(): void {
    if (this._electronService.isElectronApp)
    {
      // this.initialize_upload();
      // this.sub = this.navbar.is_electron_initialized$.subscribe(
      //   res => {
      //     if(!res) {this.initialize_electron()}
      //   },
      // err=> console.log(err)
      // )
     
      this.isElectron=true;
      
    };
  }
  download_tobu_desktop(_system){
    this.authService.http_post('/api/download_tobu_desktop',  {'console': _system}).subscribe(
      res => {
        // window.open(res['url'], "_blank");
        window.location.assign(res['url']);
      },
      err=> console.log(err)
    )
  }
}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from'@angular/forms';
import { AuthenticationService } from '../../../../core/authentication/authentication.service'
import { SharedService } from '../../../../core/shared/shared.service';

@Component({
  selector: '[app-job-time-stats]',
  templateUrl: './job-time-stats.component.html',
  styleUrls: ['./job-time-stats.component.scss']
})
export class JobTimeStatsComponent implements OnInit {

  @ViewChild('jobStats', {static: false}) jobStats;
  bsValue = new Date();
  client_options:any;
  job_title_options: any;
  client_id:any;
	bsConfig = {
      containerClass: 'theme-angle'
  }
	jobStatsForm=this.fb.group({
	    start_date: ['', Validators.required],
	    end_date: ['', Validators.required],
	    client: [''],
	    job_titles: [''],
	    position_type: ['']
	});
    barData: any;
    barOptions = {
        series: {
            bars: {
                align: 'center',
                lineWidth: 0,
                show: true,
                barWidth: 0.6,
                fill: 0.9
            }
        },
        grid: {
            borderColor: '#eee',
            borderWidth: 1,
            hoverable: true,
            backgroundColor: '#fcfcfc'
        },
        tooltip: true,
        tooltipOpts: {
            content: function(label, x, y) { return x + ' : ' + y; }
        },
        xaxis: {
            tickColor: '#fcfcfc',
            mode: 'categories'
        },
        yaxis: {
        	minTickSize:1,
            min: 0,
            // position: ($scope.app.layout.isRTL ? 'right' : 'left'),
            tickColor: '#eee'
        },
        shadowSize: 0
    };

  constructor(
  	private authService: AuthenticationService,
  	private fb: FormBuilder,
  	private shared: SharedService,) { }
  ngOnInit() {
  	var d = new Date();
 	d.setDate(d.getDate()-90);
  	this.jobStatsForm.patchValue({
        start_date: d,
        end_date: new Date(),
    });
    this.get_client_options();
    setTimeout(()=>{
      this.jobStats.nativeElement.click();
    }, 2000)
  }
  choose_unit(val){
  	this.jobStatsForm.patchValue({
        position_type: val 
      })
  }
  submitjobStatsForm($ev, value){
  	$ev.preventDefault();
  	console.log(value)
  	this.authService.post('/api/update_job_time_stats', {
      'end': this.shared.convert_date_to_string(value.end_date),
      'start': this.shared.convert_date_to_string(value.start_date),
      'position_type': value.position_type,
      'job_titles': value.job_titles,
      'client_ids': [this.client_id]
    }).subscribe(
      res => {
      	console.log(res['data'])
		this.barData= [{
			"label": "Resume Count",
			"color": "#42E14B",
		    "data": res['data']
		}];
      },
      // err=> console.log(err)
    )
  }
  get_client_options(){
    this.authService.get('/api/get_client_options').subscribe(
      res=> {
        console.log(res)
        this.client_options=res['clients']
      }, 
      err=> console.log(err)
    )
  }
  get_job_title_options($ev){
  	console.log($ev);
    this.authService.post('/api/get_job_title_options', {client_name: $ev}).subscribe(
      res=>{
        console.log(res);
        this.job_title_options=res['job_titles']
        this.client_id=res['client_id']
      },
      err=> console.log(err)
    )
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl} from'@angular/forms';
import { ModalsService } from '../../../layout/modals/modals.service';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service'
import 'rxjs/add/operator/switchMap'; 
import 'rxjs/add/operator/map';
@Component({
  selector: '[app-show-appointment]',
  templateUrl: './show-appointment.component.html',
  styleUrls: ['./show-appointment.component.scss']
})
export class ShowAppointmentComponent implements OnInit {
  candidate_name ="";
  end_time ="";
  job_title= "";
  location ="";
  resume_report_id="";
  stage_name ="";
  start_time = "";
  job_role_id ='';
  constructor(
  	private authService: AuthenticationService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private fb: FormBuilder,
    private modal: ModalsService,
    private shared: SharedService,
    private navbar: NavbarService
  	) { }

  ngOnInit() {
    this.navbar.current_page$.switchMap(
      params=> {return params}
    )
    this.navbar.current_params$
      .switchMap(params => {
        // console.log(params);
        return this.authService.post('/api/load_show_appointment_modal', params);
      }).subscribe(
        res => {
            // console.log(res);
          if(res['upgrade']==='Premium'){ this.modal.load_modal_by_name('upgrade_plan', {}) }
          else{
            this.candidate_name = res['candidate_name']
            this.end_time = res['end_time']
            this.job_title=  res['job_title']
            this.location = res['location']
            this.resume_report_id= res['resume_report_id'].toString();
            this.stage_name = res['stage_name']
            this.start_time =  res['start_time']
            this.job_role_id = res['job_role_id'].toString();
          }

        }, error => {
            // console.log(error);
        }
      );
  }

}

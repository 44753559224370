import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavbarService }     from '../../../layout/header/navbar.service';


@Component({
  selector: 'app-application-successful',
  templateUrl: './application-successful.component.html',
  styleUrls: ['./application-successful.component.scss']
})
export class ApplicationSuccessfulComponent implements OnDestroy {

  constructor(private navbar: NavbarService) { }

  ngOnInit() {
  	this.navbar.set_navbar_visible(false);
    // this.navbar.set_tawk_visible(false);
  }
  ngOnDestroy(){
    this.navbar.set_navbar_visible(true);
    // this.navbar.set_tawk_visible(true);
  }
}

import { Component, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription }   from 'rxjs';
import {NavbarService} from '../../layout/header/navbar.service'
import { ModalsService} from '../modals/modals.service'
import {Location} from '@angular/common';
import {Router, ActivatedRoute} from '@angular/router';
import { CandidateService } from '../../routes/shared/candidate/candidate.service';
import { JobsService } from '../../routes/jobs/jobs.service';

import {AuthenticationService } from '../../core/authentication/authentication.service';
import {ViewChild, Input} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from '../../core/shared/shared.service';


@Component({
  selector: 'app-modals2',
  templateUrl: './modals2.component.html',
  styleUrls: ['./modals2.component.scss']
})
export class Modals2Component implements AfterViewInit {
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: true
  };

  @ViewChild('jobApplicationModal',{static: false}) public jobApplicationModal:ModalDirective;
  @ViewChild('helpVideoModal',{static: false}) public helpVideoModal:ModalDirective;

  @ViewChild('arrangeDemoModal',{static: false}) public arrangeDemoModal:ModalDirective;
  // @Input() title:string;
  subscription: Subscription;
  subscription2: Subscription;
  subscription3: Subscription;
  subscription4: Subscription;
  current_page: any = {};
  params: any = {};
  page_params: any = {};
  hideJobApplicationModal = true;
  hideHelpVideoModal = true;
  hideArrangeDemoModal = true;

  constructor(
    private modal: ModalsService,
    private navbar: NavbarService,
    private _location: Location,
    private router:Router,
    private candidate: CandidateService,
    private job:JobsService,
    private authService: AuthenticationService,
    private shared: SharedService,
    private route: ActivatedRoute
    ) { }

  ngOnInit() {
  	
    
  }
  ngAfterViewInit(){
  	this.modal.job_application_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideJobApplicationModal=false;
            this.jobApplicationModal.show();
          }
          if(res['state']==='hidden'){
            this.hideJobApplicationModal=true;
            this.jobApplicationModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.help_video_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideHelpVideoModal=false;
            this.helpVideoModal.show();
          }
          if(res['state']==='hidden'){
            this.hideHelpVideoModal=true;
            this.helpVideoModal.hide();
          }
          this.params = res["params"]
        }
    )
    this.modal.arrange_demo_modal_selected$.subscribe(
      res=>
        {
          // console.log(res);
          if(res['state']==='displayed'){
            this.hideArrangeDemoModal=false;
            this.arrangeDemoModal.show();
          }
          if(res['state']==='hidden'){
            this.hideArrangeDemoModal=true;
            this.arrangeDemoModal.hide();
          }
          this.params = res["params"]
        }
    )
  }



  ngOnDestroy(){
    if(this.shared.isBrowser()){
    this.subscription.unsubscribe;
    this.subscription2.unsubscribe;
    this.subscription3.unsubscribe;
    this.subscription4.unsubscribe;
    }
  }

  
  hideModal(name) {
    console.log("hiding modal")
    // this.router.navigate(['../'], { relativeTo: this.route });
    
    // let routerLink = this.router.url.split('/apply')
    // console.log(routerLink);
    // this.router.navigate([routerLink[0]]);
    // this.router.navigate(["../../parent"], {relativeTo: this.route});
    this.modal.hide_modal_by_name(name)
  }

  
}

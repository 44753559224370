import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params,NavigationEnd} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from'@angular/forms';
import { ModalsService } from '../../../layout/modals/modals.service'
import { Subscription, Observable} from 'rxjs';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service';
import { JobsService } from '../../../routes/jobs/jobs.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: '[app-auto-forwarding]',
  templateUrl: './auto-forwarding.component.html',
  styleUrls: ['./auto-forwarding.component.scss']
})
export class AutoForwardingComponent implements OnInit {

  sub:Subscription;
  sub1:Subscription;
  sub2:Subscription;
  autoFowardingRuleForm=this.fb.group({
    subject: ['', Validators.required],
    from: [''],
    job: ['', Validators.required],
    rule_id: ['']
  });
  rules:any;
  job_roles:any;
  clicked_save_rule=false
  current_job_id=''
  constructor(
  	private authService: AuthenticationService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private fb: FormBuilder,
    private modal: ModalsService,
    private shared: SharedService,
    private navbar: NavbarService,
    private job: JobsService
  	) { }

  ngOnInit() {
    this.load_auto_forwarding_rules();   
    
  }
  ngOnDestroy(){
    // this.modal.hide_modal_by_name('candidate');
    this.sub1.unsubscribe;
    this.sub2.unsubscribe;
  }
  
  load_auto_forwarding_rules(){
    this.authService.get('/api/get_auto_forwarding_rules').subscribe(
      res => {
        console.log(res);
        this.rules= res['rules'];
        this.job_roles = res['job_roles']

      },
      err => console.log(err)
    )
  }
  submitAutoFowardingRuleForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.autoFowardingRuleForm.controls) {
        this.autoFowardingRuleForm.controls[c].markAsTouched();
    }
    if (this.autoFowardingRuleForm.dirty && this.autoFowardingRuleForm.valid)
    // if (this.autoFowardingRuleForm.valid) 
    {
      // this.clicked_schedule_appointment=true;
      // console.warn($ev);
      // console.warn(value);
      // console.log(this.shared.convert_date_to_query_string(value.start_date));
      // console.log(this.shared.convert_date_time_to_string(value.end_date, value.end_time));
      // console.log(value.start_date);
      // console.log(value.start_time);
      //new appointment
      
        this.authService.post('/api/create_auto_forwarding_rule', {
          'subject': value.subject,
          'from': value.from,
          'job_role_id': value.job
        }).subscribe(
          res => {
            // console.log(res);
            // this.navbar.set_notification('Success','Appointment Scheduled', 'Appointment successfully scheduled');
            // this.candidate_appointments.push(res['candidate_appointment']);
            // this.autoFowardingRuleForm.reset();
            // this.clicked_schedule_appointment=false;
            this.rules= res['rules'];
            this.navbar.set_notification('Success','Rule Set', 'Rule set successfully.');
            // this.candidate_appointments.push(res['candidate_appointment']);
            // this.autoFowardingRuleForm.reset();
            // this.clicked_schedule_appointment=false;
            // setTimeout(function(){ location.reload(); }, 3000);
          },
          // err=> console.log(err)
        )
      
      //reschedule
      
    }
    // this.modal.hide_modal('big');
  }
  delete_rule(_rule_id){
    this.authService.post('/api/delete_auto_forwarding_rule', {rule_id: _rule_id}).subscribe(
      res=> {
        console.log(res);
        this.navbar.set_notification('Success','Rule Deleted', 'Rule deleted successfully.');
        this.rules= res['rules'];
      },
      err => console.log(err)
    )
  }
  


}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslatorService } from '../core/translator/translator.service';
import { MenuService } from '../core/menu/menu.service';
//import { SharedModule } from '../shared/shared.module';
// import { SharedtobuModule } from './shared/sharedtobu.module';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { menu } from './menu';
import { menu_non_ats } from './menu';
import { routes } from './routes';
import { AuthenticationService} from '../core/authentication/authentication.service';
import { PrivacyComponent } from './static-pages/privacy/privacy.component';
import { TermsComponent } from './static-pages/terms/terms.component';
import { PricingComponent } from './static-pages/pricing/pricing.component';
import { AboutComponent } from './static-pages/about/about.component';
import { CookiePolicyComponent } from './static-pages/cookie-policy/cookie-policy.component';
import { DownloadDriveComponent } from './static-pages/download-drive/download-drive.component';
import { MicrosoftComponent } from './configure/microsoft/microsoft.component';
import { ZohoComponent } from './configure/zoho/zoho.component';
import { GmailComponent } from './configure/gmail/gmail.component';
import { TagInputModule } from 'ngx-chips';
import { ParserComponent } from './parser/parser.component';
import { ApplicationSuccessfulComponent} from './career/application-successful/application-successful.component'
// import {} from './career/'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule} from 'ng2-file-upload';
import { VulnerabilityNoticeComponent } from './static-pages/vulnerability-notice/vulnerability-notice.component';
import { UnsubscribeComponent } from './static-pages/unsubscribe/unsubscribe.component';
import { SharedroutesModule} from '../routes/shared/sharedroutes.module'
import { ListviewComponent } from './blog/listview/listview.component'
import { PostComponent } from './blog/post/post.component';
import { HelpVideosComponent } from './static-pages/help-videos/help-videos.component';
import { DemoComponent } from './static-pages/demo/demo.component';
import { GreenhouseComponent } from './configure/greenhouse/greenhouse.component';
import { SecurityCertificateComponent } from './static-pages/security-certificate/security-certificate.component';
import { TobuJksComponent } from './static-pages/tobu-jks/tobu-jks.component';
import { LatestTobuVersionComponent } from './static-pages/latest-tobu-version/latest-tobu-version.component';
import { DbaComponent } from './static-pages/dba/dba.component';
import { SitemapComponent } from './static-pages/sitemap/sitemap.component'
import { LoadingComponent } from './static-pages/loading/loading.component'
import { NgxElectronModule} from 'ngx-electron';
import { MonetizeComponent } from './monetize/monetize.component';
import { GoogleApiDisclosureComponent } from './static-pages/google-api-disclosure/google-api-disclosure.component';
import { AffiliateComponent } from './static-pages/affiliate/affiliate.component';
import { AffiliateTermsComponent } from './static-pages/affiliate-terms/affiliate-terms.component';
import { TrendsComponent } from './static-pages/trends/trends.component'
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { GdriveComponent } from './configure/gdrive/gdrive.component';
import { OneDriveComponent } from './configure/one-drive/one-drive.component';
@NgModule({
    imports: [
        // SharedtobuModule,
        TagInputModule,
        CommonModule,
        NgxElectronModule,
        NgxJsonViewerModule,
        RouterModule.forRoot(routes, {
        initialNavigation: 'enabled',
        // onSameUrlNavigation: 'reload'
    }),
        FormsModule,                               // <========== Add this line!
        ReactiveFormsModule,
        FileUploadModule,
        SharedroutesModule
    ],
    declarations: [PrivacyComponent, TermsComponent, PricingComponent, AboutComponent, CookiePolicyComponent, DownloadDriveComponent, MicrosoftComponent, ZohoComponent, GmailComponent, ParserComponent, VulnerabilityNoticeComponent, UnsubscribeComponent, ApplicationSuccessfulComponent, ListviewComponent, PostComponent, HelpVideosComponent, DemoComponent, GreenhouseComponent, SecurityCertificateComponent, TobuJksComponent, LatestTobuVersionComponent, DbaComponent, SitemapComponent, LoadingComponent, MonetizeComponent, GoogleApiDisclosureComponent, AffiliateComponent, AffiliateTermsComponent, TrendsComponent, GdriveComponent, OneDriveComponent],
    exports: [
        RouterModule
    ], 
    providers:[
       // AuthenticationService
    ]
})

export class RoutesModule {
    constructor(public menuService: MenuService, tr: TranslatorService) {
        menuService.addMenu(menu);
        // menuService.remove_menu_item('Jobs')
    }
}

import { Component, OnDestroy} from '@angular/core';
import { AuthenticationService} from '../../../core/authentication/authentication.service'
import { Router, ActivatedRoute, Params} from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators, NgForm } from '@angular/forms';
import { ModalsService } from '../../../layout/modals/modals.service'
import { Observable, Subscription} from 'rxjs';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service';
// import { ToasterService, ToasterConfig } from 'angular2-toaster/angular2-toaster';
import { CandidateService } from '../../../routes/shared/candidate/candidate.service';
import { CustomValidators } from 'ng2-validation';
import { AngularEditorConfig } from '@kolkov/angular-editor';


declare var $: any;
let Quill:any = null;
const swal = require('sweetalert');
// import * as QuillNamespace from 'quill';
// let Quill: any = QuillNamespace;
// import ImageResize from 'quill-image-resize-module';
// Quill.register('modules/imageResize', ImageResize);
// @ViewChild('f') form: NgForm;
//temp fix summernote
// const origToString = Object.prototype.toString;
// Object.prototype.toString = function() {
//     'use strict';
//     if (this === null) return '[object Null]';
//     return origToString.call(this);
// };
//end temp fix


@Component({
  selector: '[app-email-campaign]',
  templateUrl: './email-campaign.component.html',
  styleUrls: ['./email-campaign.component.scss']
})
export class EmailCampaignComponent implements OnDestroy {

  own_email_accounts = [];
  email_templates: any;
  signatures: any;
  to_emails ='';
  showCC = false;
  showBCC = false;
  contents: string='';
  params ={};
  called_from ='';
  sub: Subscription;
  send_email_from_tobu_selected = false;
  daily_email_limit = 1000;
  //to_email_list = ;
  email_candidate_form= this.fb.group({
	// resume_report_id: [this.route.snapshot.params['candidate_id']],
	// job_role_id: [this.route.parent.snapshot.params['id']],
	// email_candidates_to_email: [],
	email_candidates_from_email: [''],
	email_candidate_from_email_id: [''],
	email_candidates_cc: ['',CustomValidators.email],
	email_candidates_bcc: ['', CustomValidators.email],
	email_candidates_subject: ['', Validators.required],
	min_age_of_resume_years: [''],
	max_age_of_resume_years: [''], 
	min_age_of_resume_months: [''],
	max_age_of_resume_months: [''],
	min_no_of_days_of_last_contact: [''],
  content: ['']
  // email_candidates_uploaded_resume_ids: ['']
  });
  clicked_send_email = false;
  // toasterConfig: any;
  // toasterconfig: ToasterConfig = new ToasterConfig({
  //       positionClass: 'toast-bottom-right',
  //       showCloseButton: true
  //   });
  // select1000={'selected': false, 'count':0};
  candidate_count = -1
  mail_cycle_days=0;
  is_paid_customer= false;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    // uploadUrl: '/api/upload_email_images',
    // uploadWithCredentials: false,
    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  }
  editor_modules = {
      // toolbar: {
      //   container: [
      //     [{ 'font': [] }],
      //     [{ 'size': ['small', false, 'large', 'huge'] }],
      //     ['bold', 'italic', 'underline', 'strike'],
      //     [{ 'header': 1 }, { 'header': 2 }],
      //     [{ 'color': [] }, { 'background': [] }],
      //     [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      //     [{ 'align': [] }],
      //     ['link', 'image']
      //   ]
      // },
      // imageResize: true
    };
  constructor(
  	private authService: AuthenticationService,
    private route: ActivatedRoute, 
  	private router: Router,
    private modal: ModalsService,
    private shared: SharedService,
    private fb: FormBuilder,
    private navbar: NavbarService,
    // public toasterService: ToasterService,
    private candidate: CandidateService,
    ) { 
    if (this.shared.isBrowser()) {
      Quill = require('quill');
      const ImageResize = require('quill-image-resize').default;
      Quill.register('modules/imageResize', ImageResize);
    }
    this.editor_modules = {
      toolbar: {
        container: [
          [{ 'font': [] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'align': [] }],
          ['link', 'image']
        ]
      },
      imageResize: true
    };
  }

  ngOnInit() {

  	this.get_plo();
  	// this.load_summernote();
    // this.sub = this.navbar.current_page$.subscribe(
    //   res => {
    //     this.called_from= res['name'];
    //     this.calculate_params();
    //     // console.log(this.params)
    //     // console.log(this.called_from);
    //     // this.populate_to_emails();
    //   }
    // );
    
    
  }
  ngOnDestroy(){
    // this.sub.unsubscribe;
  }
  get_plo(){
    this.authService.get('/api/can_activate_candidate_database').subscribe(
      res=> {
        console.log(res);
        if(res['upgrade']==='Premium'){setTimeout(()=>{ this.router.navigate(['extracts']);this.modal.load_modal_by_name('upgrade_plan', {}) }, 500)}

      },
      err=> console.log(err)
      )
  	this.authService.get('/api/get_email_account_details').subscribe(

  		res => {
        // console.log(res);
  			this.own_email_accounts = res['own_email_accounts'];
        let i=res['own_email_accounts'].length-1;
        this.email_candidate_form.patchValue({
          'email_candidate_from_email_id': res['own_email_accounts'][i]['email_account']['id']
        })
  			this.email_templates = res['email_templates'];
  			this.signatures = res['signatures'];
        this.is_paid_customer = res['is_paid_customer']
  		},
  		error =>{
  			// console.log(error)
  		}
  	)
  }
  submitEmailCandidateForm( value: any) {
    // $ev.preventDefault();
    // this.toasterService.pop('Success', 'Email Sent', 'Email Successfully sent');
    this.navbar.set_notification('Success', 'Email Sent', 'Email Successfully sent');
    
    this.clicked_send_email=true;
    value = this.shared.merge_options(value, {'email_candidates_message': this.contents, 'mail_sent_from_tobu': true, 'recipient_type': 'Database Candidate', 'tobu_url': ("min_age_of_resume_years=" + this.email_candidate_form.get('min_age_of_resume_years').value +"&max_age_of_resume_years=" + this.email_candidate_form.get('max_age_of_resume_years').value + "&min_age_of_resume_months=" + this.email_candidate_form.get('min_age_of_resume_months').value + "&max_age_of_resume_months=" + this.email_candidate_form.get('max_age_of_resume_months').value )})
    // if(this.called_from === 'analytics' || this.called_from === 'resumes' || this.called_from === 'emailhistory' || this.called_from === 'comments' || this.called_from === 'activity'){
    //  this.modal.medium_modal_selected$
    //   .subscribe(params=>
    this.params = this.shared.merge_options(this.params, value)
    console.log(this.params)
     //  )
     //  }
     //  else{
     // //from inbox/resumereports
     // this.navbar.resumes_selected$
     //   .subscribe(params => 
     //     this.params =this.shared.merge_options({'uploaded_resume_ids': params}, value)
     //   );
     //  }
      // console.log(this.params);
    // console.log(value.email_candidates_to_email);
    // console.log(value.email_account_user_name);
    // console.log(value.email_candidates_subject)
    // console.log(this.contents);
    this.authService.post(
      '/api/activate_candidate_database', this.params

      ).subscribe(
      res => {
        if(res['upgrade']==='Premium'){this.modal.hide_modal_by_name('email');setTimeout(()=>{ this.modal.load_modal_by_name('upgrade_plan', {}) }, 500)}
        else{
          // this.modal.hide_modal('medium');
          this.modal.hide_modal_by_name('email');
          console.log(res);
          this.navbar.set_notification('Success','Notice', res['notice']);
          this.clicked_send_email = false;
          this.router.navigate(['/email-campaign/email-campaign/past-campaigns']);
        }
      }, error => {
          // console.log(error);
      }
    )
    console.log("submitted")
  }

  // load_summernote(){
  //   $('#summernote').summernote({
  //       height: 230,
  //       dialogsInBody: true,
  //       callbacks: {
  //           onChange: (contents, $editable) => {
  //               this.contents = contents;
  //               // console.log(contents);
  //           }
  //       }
  //   });
  //   $('.note-popover').css({'display': 'none'});
  // }

  send_campaign() {
      swal({
          title: 'Are you sure?',
          text: 'This will send an email out to the Entire Database of Candidates Selected',
          icon: 'warning',
          buttons: {
              cancel: {
                  text: 'Cancel!',
                  value: null,
                  visible: true,
                  className: "",
                  closeModal: true
              },
              confirm: {
                  text: 'Yes, Send it!',
                  value: true,
                  visible: true,
                  className: "bg-danger",
                  closeModal: true
              }
          }
      }).then((isConfirm) => {
          if (isConfirm) {
            this.submitEmailCandidateForm(this.email_candidate_form.value)
              swal('Email Sent!', 'The Email has been Scheduled.', 'success');
              //refresh view
          } else {
              swal('Cancelled', 'The Email will not be sent', 'error');
          }
      });
  }
  // calculate_params(){
    
  //   if(this.called_from === 'analytics' || this.called_from === 'resumes' || this.called_from === 'emailhistory' || this.called_from === 'comments' || this.called_from === 'activity' || this.called_from === 'interviews'){
  //    // this.modal.medium_modal_selected$
  //    //  .subscribe(params=>
  //    //    this.params = params['params']
  //    //  )
  //        this.params = this.candidate.get_params_from_url(this.router.url);
  //        this.populate_to_emails();
  //     }
  //     else{
  //    //from inbox/resumereports
  //    this.navbar.resumes_selected$
  //      .subscribe(params => 
  //        {
  //          this.params ={'uploaded_resume_ids': params};
  //          this.populate_to_emails();
  //        }
  //      );
  //     }

  // }
  populate_to_emails(){
    this.authService.post('/api/get_to_emails', this.params).subscribe(
      res=> {
        // console.log(res)
        this.to_emails = res['to_emails'];
      },
      // err => console.log(err)
    )
  }
  auto_fill_template(_val){
    // console.log(_val)
    this.authService.post('/api/load_email_template', this.shared.merge_options(_val, {'email_candidate_template_id': _val}))
    .subscribe(
      res => {
        console.log(res);
        // this.contents = res['content'];
        // $('#summernote').summernote('reset');
        // $('#summernote').summernote('enable');
        // $('#summernote_container').html('<div id="summernote" class="summernote"><span>'+this.contents+'</span></div>');
        // this.load_summernote();
        // $('#summernote').summernote('pasteHTML', this.contents);
        this.email_candidate_form.patchValue({
          'email_candidates_subject': res['subject'],
          'content': res['content']
        });
  
      },
      // err => console.log(err)
    )
  }

  send_email_from_tobu($ev){

    this.send_email_from_tobu_selected = $ev.target.checked;

  }

  set_age_range(){
  	this.authService.post('/api/get_mail_candidate_count', {
  		tobu_url: ("min_age_of_resume_years=" + this.email_candidate_form.get('min_age_of_resume_years').value +"&max_age_of_resume_years=" + this.email_candidate_form.get('max_age_of_resume_years').value + "&min_age_of_resume_months=" + this.email_candidate_form.get('min_age_of_resume_months').value + "&max_age_of_resume_months=" + this.email_candidate_form.get('max_age_of_resume_months').value )
  		// min_age_of_resume_years: this.email_candidate_form.get('min_age_of_resume_years').value,
  		// max_age_of_resume_years: this.email_candidate_form.get('max_age_of_resume_years').value,
  		// min_age_of_resume_months: this.email_candidate_form.get('min_age_of_resume_months').value,
  		// max_age_of_resume_months: this.email_candidate_form.get('max_age_of_resume_months').value
  	}).subscribe(
  	res=> {
  		console.log(res);
      this.daily_email_limit = res['daily_email_limit']
  		this.candidate_count = res['count'];
  		this.mail_cycle_days = Math.ceil(res['count']/this.daily_email_limit);
  	},
  	err=> console.log(err)
  	)
  }

}

import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from'@angular/forms';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service'
import { Subscription, Observable } from 'rxjs';
import { ModalsService } from '../../../layout/modals/modals.service';
import { JobsService } from '../../jobs/jobs.service';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import 'rxjs/add/operator/switchMap'; 
import 'rxjs/add/operator/map';
import {saveAs} from "file-saver";
import { FileUploader , FileUploaderOptions} from 'ng2-file-upload';
import {TokenService} from '../../../core/authentication/token.service';
import { environment } from '../../../../environments/environment'
const URL = environment.baseUrl+'/api/attach_job_description';
let maxFileSize = 5*1024*1024;
declare var $: any;
let Quill:any = null;
// import * as QuillNamespace from 'quill';
// let Quill: any = QuillNamespace;
// import ImageResize from 'quill-image-resize-module';
// Quill.register('modules/imageResize', ImageResize);

@Component({
  selector: '[app-job-description]',
  templateUrl: './job-description.component.html',
  styleUrls: ['./job-description.component.scss']
})
export class JobDescriptionComponent implements OnInit {
  uar ={};
  rcar:any;
  plo:any;
  subscription: Subscription;
  hiddenEditForm = true;
  hiddenAttachmentForm = true
  url_params ={};
  clients:any;
  client:any;
  // current_page:any ={};
  clicked_save =false;
  job_role:any;
  client_contact_people = [];
  job_attachment:any;
  client_name = ''
  public uploader: FileUploader = new FileUploader({maxFileSize:maxFileSize, allowedMimeType: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf', 'application/rtf', 'text/plain' ], url: URL, itemAlias: 'job_attachment', autoUpload: true});
  employment_type_categories =[];
  job_role_employment_type_names= '';
  client_contact_people_selected = []
  edit_job_description = new FormGroup({
    client_name: new FormControl(),
    // client_description: new FormControl(),
    // client_locations: new FormControl(),
    // client_contact_persons: new FormControl(),
    // client_emails: new FormControl(),
    // client_phone_nos: new FormControl(),
    client_contact_people_ids: new FormControl(),
    job_role_title: new FormControl('', Validators.required),
    no_of_openings:  new FormControl(),
    job_locations: new FormControl(),
    employment_type: new FormArray([]),
    job_description: new FormControl(),
    public_job_description: new FormControl(),
    required_qualifications: new FormControl(),
    preferred_qualifications: new FormControl(),
    required_skills: new FormControl(),
    preferred_skills: new FormControl(),
    preferred_industries: new FormControl(),
    ctc: new FormControl(),
    interview_locations: new FormControl(),
    preferred_candidate_locations: new FormControl(),
    min_years_of_experience: new FormControl(),
    max_years_of_experience: new FormControl(),
    is_pinned: new FormControl(),
    content: new FormControl(),
    job_value: new FormControl()
  });
  contents = '';
  editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '15rem',
        minHeight: '5rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        defaultFontName: 'Arial',
        // uploadUrl: '/api/upload_email_images',
        // uploadWithCredentials: false,
        toolbarHiddenButtons: [
          ['bold']
          ],
        customClasses: [
          {
            name: "quote",
            class: "quote",
          },
          {
            name: 'redText',
            class: 'redText'
          },
          {
            name: "titleText",
            class: "titleText",
            tag: "h1",
          },
        ]
      }
      editor_modules = {
      // toolbar: {
      //   container: [
      //     [{ 'font': [] }],
      //     [{ 'size': ['small', false, 'large', 'huge'] }],
      //     ['bold', 'italic', 'underline', 'strike'],
      //     [{ 'header': 1 }, { 'header': 2 }],
      //     [{ 'color': [] }, { 'background': [] }],
      //     [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      //     [{ 'align': [] }],
      //     ['link', 'image']
      //   ]
      // },
      // imageResize: true
    };
  constructor(
  	private authService: AuthenticationService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private shared: SharedService,
  	private navbar: NavbarService,
  	private modal: ModalsService,
    private jobs: JobsService,
    public sanitizer: DomSanitizer,
    private tokenService2: TokenService,
  	) { 
    if (this.shared.isBrowser()) {
      Quill = require('quill');
      const ImageResize = require('quill-image-resize').default;
      Quill.register('modules/imageResize', ImageResize);
    }
    this.editor_modules = {
      toolbar: {
        container: [
          [{ 'font': [] }],
          [{ 'size': ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'align': [] }],
          ['link', 'image']
        ]
      },
      imageResize: true
    };
  }

  // ngOnDestroy(){
  // 	this.subscription.unsubscribe;
  // }

  ngOnInit(){
  	this.get_plo();
    console.log('entered line2')
  	this.get_restrictions();
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    // this.upload_builder();
  	// this.navbar.get_current_params().subscribe(current_params => {
  	// 	console.log('anything');
   //     // this.current_page = current_params;
   //    console.log(current_params); 
   //    // return current_params;
   //   })
  }
  get_plo(){
  	// console.log(this.route.snapshot.params);
  	// console.log('reached here');
    this.url_params = this.jobs.get_params_from_url(this.router.url);
  	// console.log(this.navbar.current_page$);
  	// this.modal.big_modal_selected$.subscribe(
  	// 	res=> {console.log(res)});
  	// this.navbar.set_current_page('resumereports', {'id': '13'});
  	this.navbar.get_current_params().map(current_params => {
      // this.current_page = current_params;
      console.log(current_params); 
      return current_params;
     })
      .switchMap(params => {
      	// console.log(params);
        if(params !== null && params !== undefined){ 
          return this.authService.post('/api/get_job_description', params);
        }
        else{
          return Observable.empty();
        }
      }).subscribe(
        res => {
            console.log(res)
            this.plo =  res;
            this.job_role=res['job_role']
            this.contents = res['job_role']['public_job_description'];
            this.employment_type_categories = res['employment_type_categories']
            this.job_role_employment_type_names = res['job_role_employment_type_names']
            this.client_contact_people = res['client_contact_people']
            this.client_contact_people_selected = res['client_contact_people_selected']
            this.client_name = res['client_name']
            this.job_attachment = res['job_attachment']
            // $('#summernote_container').html('<div id="summernote" class="summernote"><span>'+ this.contents +'</span></div>');
            // this.load_summernote();
            this.update_form();
            this.upload_builder();
            // console.log(res);
            this.clients=res['clients']
        }, error => {
            // console.log(error);
        }
      );

  	// this.navbar.current_page$.subscribe(
   //    res => {
   //    	console.log(res);
   //      this.current_page = res;
   //      this.authService.post('/api/get_job_description', {'id:': this.current_page['params']['id']}).subscribe(
	  // 		res=> {
	  //         	this.plo=res;
	  // 		},
	  // 		error => {
	  // 			console.log(error)
	  // 		}
	  // 	)
        
   //  }, 
   //  	error => {console.log(error)}
   //  );
  	
  }
  update_form(){
    this.edit_job_description.patchValue(
      {
        'client_name': this.client_name,
        // 'client_description': this.job_role['client_description'],
        // 'client_locations': this.job_role['client_locations'],
        // 'client_contact_persons': this.job_role['client_contact_persons'],
        // 'client_emails': this.job_role['client_emails'],
        // 'client_phone_nos': this.job_role['client_phone_nos'],
        'client_contact_people_ids': this.client_contact_people_selected,
        'job_role_title': this.job_role['job_role_title'],
        'no_of_openings': this.job_role['no_of_openings'],
        'job_locations': this.job_role['job_locations'],
        'job_description': this.job_role['job_description'],
        'content': this.job_role['public_job_description'],
        'required_qualifications': this.job_role['required_qualifications'],
        'preferred_qualifications': this.job_role['preferred_qualifications'],
        'required_skills': this.job_role['required_skills'],
        'preferred_skills': this.job_role['preferred_skills'],
        'preferred_industries': this.job_role['preferred_industries'],
        'ctc': this.job_role['ctc'],
        'interview_locations': this.job_role['interview_locations'],
        'preferred_candidate_locations': this.job_role['preferred_candidate_locations'],
        'min_years_of_experience': this.job_role['min_years_of_experience'],
        'max_years_of_experience': this.job_role['max_years_of_experience'],
        'is_pinned': this.job_role['is_pinned'],
        'job_value': this.job_role['job_value']
      }
    )
  }
  edit_job_descriptionForm($ev, value: any) {
    // console.log(value);
    $ev.preventDefault();
    for (let c in this.edit_job_description.controls) {
        this.edit_job_description.controls[c].markAsTouched();
    }

    if (this.edit_job_description.valid) {
      this.clicked_save =true;
        this.authService.post(
          '/api/edit_job_description', this.shared.merge_options(this.shared.merge_options(value, {job_role_id: this.job_role.id}), {'public_job_description': this.contents, 'employment_type_hash_array': value['employment_type'].map((x,i,a)=> {return JSON.parse(x)} )})
          // {
          //   'edit_client_name': value.edit_client_name,
          //   'client_description': value.client_description,
          //   'client_locations': value.client_locations,
          //   'client_contact_persons': value.client_contact_persons,
          //   'client_emails': value.client_emails,
          //   'edit_client_phone_nos': value.edit_client_phone_nos,
          //   'job_title': value.job_title,
          //   'no_of_openings': value.no_of_openings,
          //   'job_locations': value.job_locations,
          //   'job_description': value.job_description,
          //   'required_qualifications': value.required_qualifications,
          //   'preferred_qualifications': value.preferred_qualifications,
          //   'required_skills': value.required_skills,
          //   'preferred_skills': value.preferred_skills,
          //   'preferred_industries': value.preferred_industries,
          //   'ctc': value.ctc,
          //   'interview_locations': value.interview_locations,
          //   'preferred_candidate_locations': value.preferred_candidate_locations,
          //   'min_years_experience': value.min_years_experience,
          //   'max_years_experience': value.max_years_experience,
          //   //stage: value.stage
          // }
          ).subscribe(
          res => {
              // console.log(res);
              //this.router.navigate(res.id); navigate to the created job
              this.job_role = res['job_role'];
              this.update_form();
              this.clicked_save =false;
              this.navbar.set_notification("Success", "Success", res['notice']);
              this.hiddenEditForm = true;
              this.modal.hide_modal_by_name('edit_job')
              // setTimeout(function(){ location.reload(); }, 3000);
              this.shared.refresh(this.router.url);
          }, error => {
              // console.log(error);
          }
        )
    }
    else{
       this.navbar.set_notification('Warning', 'Please complete form', 'Please ensure all fields are filled')
    }
  }
  get_restrictions(){
     this.shared.current_restrictions$.subscribe(
          res => {
            this.rcar = res["rcar"];
            // console.log("shared");
            // console.log(this.rcar);
            this.uar = res["uar"]
          }
        );
  }
  // edit_jd(){
  // 	this.hiddenEditForm=false;
  // }
  onCheckChange($event){
    console.log($event)
    this.shared.onCheckChange($event, this.edit_job_description ,'employment_type');
  }
  // load_summernote(){
  //   $('#summernote').summernote({
  //       height: 230,
  //       dialogsInBody: true,
  //       callbacks: {
  //           onChange: (contents, $editable) => {
  //               this.contents = contents;
  //               // console.log(contents);
  //           }
  //       }
  //   });
  //   $('.note-popover').css({'display': 'none'});
  // }
  autofill_client(_client_name){
    this.authService.post('/api/get_client_details', {client_name: _client_name}).subscribe(
      res=> {
        console.log(res);
        this.client=res['client'];
        this.edit_job_description.patchValue({
          client_name: this.client['name'],
          client_description: this.client['description'],
          client_locations: this.client['locations'],
          client_contact_persons:this.client['contact_persons'],
          client_emails: this.client['emails'],
          client_phone_nos: this.client['phone_nos'],

        });
      },
      err=> console.log(err)
    )
  }
  upload_builder(){
    console.log('entered builder')
    this.update_upload_parameters();
    // var date:Date = new Date();
    // var baseDate: Date = new Date(Date.UTC(1970, 0, 1));
    // var timestamp = _current_user_id + (date.getTime() - baseDate.getTime()).toString();
    // var resume_comment = this.comments_form.get('resume_comment').value;
    // console.log(resume_comment)
    // var is_shared_with_agency = this.comments_form.get('is_shared_with_agency').value;
    // console.log(is_shared_with_agency)
    // var is_shared_with_company = this.comments_form.get('is_shared_with_company').value;
    // console.log(is_shared_with_company)
    // var generated_params = this.generated_params
    var job_role_id = this.job_role.id
    // console.log(this.cv_sources)
    //this.set_timestamp();
    this.uploader.onBuildItemForm = function(fileItem, form)
    { 
      console.log("building form")
      // form.append('resume_comment', resume_comment); 
      // form.append('is_shared_with_agency', is_shared_with_agency);
      // form.append('is_shared_with_company', is_shared_with_company);
      // if(generated_params['search_result_id'] !=undefined){
      //   form.append('search_result_id', generated_params['search_result_id']);
      // } 
      // if(generated_params['resume_email_id'] !=undefined){
      //   form.append('resume_email_id', generated_params['resume_email_id']);
      // } 
      // if(generated_params['resume_report_id'] !=undefined){
        form.append('job_role_id', job_role_id);
      // }
      // console.log(form)
      return {fileItem, form}
    };
    this.uploader.onCompleteItem = (item:any, response:any, status:any, headers:any)=> {
      console.log( item);
      console.log(status);
      console.log(response);
      console.log( headers)
      var resp=JSON.parse(response);
      console.log(response);
      console.log(headers);
      if (headers['access-token']!== undefined && headers['access-token']!== ''){
        localStorage.setItem('accessToken', headers['access-token']);
        localStorage.setItem("expiry", headers['expiry']);
        this.update_upload_parameters()
      }
      
      // console.log(resp);  
    };
    this.uploader.onCompleteAll =()=> {
      this.navbar.set_notification('Success','Attachment Added', 'Attachment Successfully Added, page will refresh now');

      // this.uploader.cancelAll()
      this.modal.hide_modal_by_name('edit_job')
      this.shared.refresh(this.router.url);
    }
  }

  update_upload_parameters(){
      var uo: FileUploaderOptions = {};
      console.log("entered uplaod params")
      console.log(this.tokenService2.getToken())
      console.log(localStorage.getItem("access_token"))
      uo.headers = 
     [
       // {name: 'access-token', value : localStorage.getItem("accessToken")}, 
       // {name: 'client', value : localStorage.getItem("client")},
       // {name: 'expiry', value : localStorage.getItem("expiry")},
       // {name: 'token-type', value : localStorage.getItem("tokenType")},
       // {name: 'uid', value : localStorage.getItem("uid")}
       {name: 'access-token', value : localStorage.getItem("access_token")},
       {name: 'Authorization', value : 'Bearer '+ this.tokenService2.getToken()}, 
     ];
      console.log(uo);
      
      this.uploader.setOptions(uo);
      // console.log(this.uploader);
  //    this.upload_builder(this.job_role.id , this.create_mass_upload_tags, "1" , this.create_mass_upload_cv_source)

    }
    // test(){
    //   this.upload_builder();
    //   this.uploader.uploadAll()
    // }
    download_attachment(_id, _file_name){
    // console.log('downloading');
    this.authService.downloadFile('/api/download_job_attachment', {'id': _id}).subscribe(blob => {
            // console.log(blob)
            saveAs(blob, _file_name);
        }, error => {
              // console.log(error);
        }
    )
  }
}

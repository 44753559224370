import { Component, OnDestroy, SecurityContext } from '@angular/core';
import { Params, Router, ActivatedRoute,NavigationEnd } from "@angular/router";
import { AuthenticationService} from '../../../../core/authentication/authentication.service'
import { Subscription }   from 'rxjs';
import {NavbarService} from '../../../../layout/header/navbar.service'
import { SharedService } from '../../../../core/shared/shared.service';
import { CandidateService } from '../candidate.service';
import { filter } from 'rxjs/operators';
import {ModalsService} from '../../../../layout/modals/modals.service'
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: '[app-emailhistory]',
  templateUrl: './emailhistory.component.html',
  styleUrls: ['./emailhistory.component.scss']
})
export class EmailhistoryComponent implements OnDestroy {

  plo: any ;
  rcar: any;
  uar: any;
  emails: any;
  generated_params ={};

  subscription: Subscription;
  sub1: Subscription;
  sub2: Subscription;
  candidate_not_interested=false;
  unsubscribe_to_mails= false;

  constructor(
  	private authService: AuthenticationService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private navbar: NavbarService,
    private shared: SharedService,
    private candidate: CandidateService,
    private modal: ModalsService,
    public sanitizer:DomSanitizer
    ) { }

  ngOnInit() {
  	this.get_restrictions()
  	this.get_plo();
    // this.modal.load_modal_by_name('candidate', {});
  }

  ngOnDestroy(){
  	this.subscription.unsubscribe;
    this.sub1.unsubscribe;
    this.sub2.unsubscribe;
    // this.modal.hide_modal_by_name('candidate');
  }

  get_plo(){
    // this.generated_params = this.candidate.get_params_from_url(this.router.url);
    // if(this.router.url.includes('inbox')){
  	 //  this.authService.post('/api/get_email_history_for_resume', this.generated_params).subscribe(
    //     res => {
    //       this.plo =  res
    //       this.emails = this.plo.emails;
    //       console.log(res);
    //     }, error => {
    //       console.log(error);
    //     }
    //   )
    // }
    // else{
    //   this.authService.post('/api/get_email_history_for_resume_report', this.generated_params).subscribe(
    //     res => {
    //       this.plo =  res
    //       this.emails = this.plo.emails;
    //       console.log(res);
    //     }, error => {
    //       console.log(error);
    //     }
    //   )
    // }
    // this.load_history();
    // this.sub1 = this.router.events.pipe(
    // filter(event => event instanceof NavigationEnd)  
    // ).subscribe((event: NavigationEnd) => {
    //   this.load_history();
    //   console.log(event.url);
    // });    
    this.sub1 = this.route.params.subscribe(
      res=> {
        if(res['notice']==='Access Denied')
          {
            this.router.navigate(['/candidate_inbox'])
          }
        this.load_history();
      }
    )    
  }

  load_history(){
    this.sub2 = this.fetch_history()
      .subscribe(
        res => {
          this.plo =  res
          // this.emails= res['emails'].map((x,i,a)=> {return {'data': x['data'], 'content': this.sanitizer.bypassSecurityTrustHtml(x['content'])}})
          this.emails= res['emails'].map((x,i,a)=> {return {'data': x['data'], 'content': this.sanitizer.sanitize(SecurityContext.HTML,this.sanitizer.bypassSecurityTrustHtml(x['content']))}})
          //this.domSanitizer.sanitize(SecurityContext.HTML,this.domSanitizer.bypassSecurityTrustHtml(this.parishDetail.mass_timings));

          // this.emails = this.sanitizer.bypassSecurityTrustHtml(this.plo.emails);
          console.log(this.emails);
          this.candidate_not_interested = res['candidate_not_interested']
          this.unsubscribe_to_mails = res['unsubscribe_to_mails']
        }, error => {
          // console.log(error);
        }
      )
  }
  fetch_history(){
    this.generated_params = this.candidate.get_params_from_url(this.router.url);
    // console.log(this.generated_params)
    if(this.router.url.includes('inbox')){
      return this.authService.post('/api/get_email_history_for_resume', this.generated_params)
    }
    else
    {
      return this.authService.post('/api/get_email_history_for_resume_report', this.generated_params)
    }
  }
  
  get_restrictions(){
    this.subscription= this.shared.current_restrictions$.subscribe(
        res => {
          this.rcar = res["rcar"];
          this.uar = res["uar"]
        }
    );
  }
  mark_candidate_not_interested(){
    this.authService.post('/api/mark_candidate_not_interested', this.generated_params).subscribe(
      res=> {
        console.log(res);
        this.candidate_not_interested = res['candidate_not_interested']
      },
      err=> console.log(err)
      )
  }
    mark_candidate_interested(){
    this.authService.post('/api/mark_candidate_interested', this.generated_params).subscribe(
      res=> {
        console.log(res);
        this.candidate_not_interested = res['candidate_not_interested']
      },
      err=> console.log(err)
      )
  }
    mark_candidate_unsubscribe(){
    this.authService.post('/api/mark_candidate_unsubscribe', this.generated_params).subscribe(
      res=> {
        console.log(res);
        this.unsubscribe_to_mails = res['unsubscribe_to_mails']
      },
      err=> console.log(err)
      )
  }
    mark_candidate_subscribe(){
    this.authService.post('/api/mark_candidate_subscribe', this.generated_params).subscribe(
      res=> {
        console.log(res);
        this.unsubscribe_to_mails = res['unsubscribe_to_mails']
      },
      err=> console.log(err)
      )
  }

}
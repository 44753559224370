import { Component, OnInit , ViewChild} from '@angular/core';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import {ModalsService} from '../../layout/modals/modals.service';
import {NavbarService} from '../../layout/header/navbar.service';
import { FormBuilder, FormGroup, FormArray, FormControl} from'@angular/forms';
import { throwError, of, empty } from 'rxjs';
import { TagInputComponent as SourceTagInput } from 'ngx-chips';

@Component({
  selector: 'app-monetize',
  templateUrl: './monetize.component.html',
  styleUrls: ['./monetize.component.scss']
})
export class MonetizeComponent implements OnInit { 
	clicked_save = false;
	inviteClientForm=this.fb.group({
	    user_type: [''],
	    email: [''],
	    custom_message: ['']
	    
	  });
	team_mates =[];
	  vendors = [];
	  clients = [];
	  pending_team_mates =[];
	  pending_vendors = [];
	  pending_clients = [];
	  pending_recruiter_friends = []
	  is_send_email_selected = false;
	  tagInput: SourceTagInput;
	  current_user_detail:any;
	  current_clients=[];
	  public validators = [ this.must_be_email.bind(this) ];
	  public errorMessages = {
	    'must_be_email': 'Please be sure to use a valid email format'
	  };

	  public readonly onAddedFunc = this.beforeAdd.bind(this);

	  private addFirstAttemptFailed = false;

	  private must_be_email(control: FormControl) {        
	    if (this.addFirstAttemptFailed && !this.validateEmail(control.value)) {
	      return { "must_be_email": true };
	    }
	    return null;
	  }
  constructor(
  	private authService: AuthenticationService,
  	private navbar: NavbarService,
  	private modal: ModalsService,
    private fb: FormBuilder,
  	) { }

  ngOnInit(): void {
  	this.authService.get('/api/current_user_details').subscribe(
      res => {
        this.current_user_detail = res;
        
        this.inviteClientForm.patchValue({
          user_type: 'client' ,
          custom_message: 'This is ' + this.current_user_detail['full_name'] +' from ' + this.current_user_detail['company_name'] +'. Please accept invite to get access to my database' 
        })
        
      }
    )
    this.refresh_invite_list();
    this.get_clients_for_db_share()
  }
  submitInviteClientForm($ev, value: any) {
        $ev.preventDefault();
        for (let c in this.inviteClientForm.controls) {
            this.inviteClientForm.controls[c].markAsTouched();
        }
        // console.log(value);
        this.clicked_save=true;
            
            this.authService.post('/api/send_client_invitation', {
              emails: value['email'].map((x, i, a) => {return x.value}),
              custom_message: value.custom_message
            }).subscribe(
              res => {
                  console.log(res);
                  this.clicked_save=false;
                  this.navbar.set_notification('Success','Sent invitation', 'The invitation has been sent');
                  this.modal.hide_modal_by_name('invite_users');
              }, error => {
                  console.log(error);
              }
            )
          
    }
    cancel_invite(_invite_id, _invite_type){
	    this.authService.post('/api/cancel_invite', {'user_id': _invite_id, 'invite_type': _invite_type}).subscribe(
	      res=> {
	        console.log(res);
	        this.navbar.set_notification('Success','Notice', res['notice']);
	        //refresh email list
	        this.refresh_invite_list();
	      },
	      err=> console.log(err)
	    )
	  }
	  resend_invite(_invite_id, _invite_type){
	    // this.modal.load_modal_by_name('spinner', {});
	    this.authService.post('/api/resend_invite', {'user_id': _invite_id, 'invite_type': _invite_type}).subscribe(
	      res=> {
	        this.navbar.set_notification('Success','Notice', res['notice']);
	        //refresh email list
	        this.refresh_invite_list();
	        // this.modal.hide_modal_by_name('spinner');
	        console.log(res)
	      },
	      err=> console.log(err)
	    )
	  }
	  refresh_invite_list(){
	    this.authService.get('/api/get_invitation_details').subscribe(
	      res => {
	        console.log(res);
	        // this.team_mates = res['team_mates'];
	        // this.vendors = res['vendors'];
	        this.clients = res['clients'];
	        // this.pending_team_mates =res['pending_team_mates'];
	        // this.pending_vendors = res['pending_vendors'];
	        this.pending_clients = res['pending_clients'];
	        // this.pending_recruiter_friends = res['pending_recruiter_friends']
	      },
	      err => {
	        console.log(err)
	      }
	    )
	  }
	  get_clients_for_db_share(){
	  	this.authService.post('/api/get_clients_for_db_share', {}).subscribe(
	      res => {
	        console.log(res);
	        this.current_clients = res['current_clients'];
	      },
	      err => {
	        console.log(err)
	      }
	    )
	  }
	  unshare_db_with_client(_client_id){
	  	this.authService.post('/api/unshare_db_with_client', {id: _client_id}).subscribe(
	      res => {
	        console.log(res);
	        this.get_clients_for_db_share()
	        // this.current_clients = res['current_clients'];
	      },
	      err => {
	        console.log(err)
	      }
	    )
	  }
	  share_db_with_client(_client_id){
	  	this.authService.post('/api/share_db_with_client', {id: _client_id}).subscribe(
	      res => {
	        console.log(res);
	        this.get_clients_for_db_share()
	        // this.current_clients = res['current_clients'];
	      },
	      err => {
	        console.log(err)
	      }
	    )
	  }
	  private beforeAdd(tag: string) {

	    if (!this.validateEmail(tag)) {
	      if (!this.addFirstAttemptFailed) {
	        this.addFirstAttemptFailed = true;
	        this.tagInput.setInputValue(tag);
	      }
	      return throwError(this.errorMessages['must_be_email']);
	    }
	    this.addFirstAttemptFailed = false;
	    return of(tag);
	  }
	  private validateEmail(text: string) {
	    var EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/i;
	    return (text && EMAIL_REGEXP.test(text));
	  }
}

import { Component, OnInit } from '@angular/core';
import { AuthenticationService} from '../../../core/authentication/authentication.service';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { SharedService } from '../../../core/shared/shared.service';
@Component({
  selector: 'app-zoho',
  templateUrl: './zoho.component.html',
  styleUrls: ['./zoho.component.scss']
})
export class ZohoComponent implements OnInit {

  constructor(
  	private authService: AuthenticationService,
  	private router: Router,
  	private route: ActivatedRoute,
    private shared: SharedService
  ) { }

  ngOnInit() {
  	// console.log('got here');
  	// console.log(this.route.snapshot.queryParams['code'])
    var code = this.route.snapshot.queryParams['code']
  	this.authService.post('/api/zoho_callback', {'code': code})
  	.subscribe(
      res=> {
        // console.log(res);
        // this.router.navigate(['/settings/settings/configureemail'])
        setTimeout(()=>{ 
          //window.location.href = '/'; 
          console.log('rerouting')
          // this.router.navigate['/settings/configureemail'];
          this.shared.refresh('/settings/configureemail');
        }, 2000);
      },
      err=> {
        // console.log(err)
      }
    )
  }

}

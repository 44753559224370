import { BrowserModule, BrowserTransferStateModule, } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { AngularTokenModule} from 'angular-token';
import { AppComponent } from './app.component';
import { AuthenticationService} from './core/authentication/authentication.service';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { SignupComponent } from './routes/account/signup/signup.component';
import { SetpasswordComponent } from './routes/account/setpassword/setpassword.component';
import { RecoverComponent } from './routes/account/recover/recover.component';
import { LoginComponent } from './routes/account/login/login.component';
import { ConfirmaccountComponent } from './routes/account/confirmaccount/confirmaccount.component';
import { AuthGuard} from "./core/authentication/auth.guard";
import { GlobalErrorHandler} from './core/errors/error-handler';
import { ErrorHandler } from '@angular/core';
import { ServerErrorInterceptor} from './core/errors/services/server-error.interceptor';
import { LoggingService } from './core/errors/services/logging.service';
import { WindowRef } from './core/shared/window-ref.service';
// import { Ng2CableModule } from 'ng2-cable';
import { SEOService } from './core/shared/seo.service';
import { FileService } from './core/shared/file.service';
import { TransferHttpCacheModule } from '@nguniversal/common';
import {environment} from '../environments/environment.prod';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap'
import { RedirectGuard } from  './core/authentication/redirect.guard'
// import { AngularEditorModule } from '@kolkov/angular-editor';

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
    declarations: [
        AppComponent,
        // SafePipe
    ],
    imports: [
        HttpClientModule,
        // AngularTokenModule.forRoot({
            // apiBase: 'http://localhost:3000'
            // apiBase: environment.baseUrl
            // apiBase: 'https://testserver.edwave.org'
            // apiBase: 'https://www.tobu.cloud'
        // }),
        BrowserAnimationsModule, // required for ng2-tag-input
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        CoreModule,
        LayoutModule,
        SharedModule.forRoot(),
        RoutesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        TransferHttpCacheModule,
        BrowserTransferStateModule,
        ReactiveFormsModule,
        FormlyModule.forRoot({ extras: { lazyRender: true } }),
        FormlyBootstrapModule,
        // Ng2CableModule,
        // AngularEditorModule
    ],
    providers: [
        // AngularTokenModule, 
        AuthenticationService, 
        AuthGuard,
        WindowRef,
        SEOService,
        FileService,
        RedirectGuard,
        // LoggingService,
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        {provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true}
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

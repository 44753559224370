import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from'@angular/forms';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service'
import { Subscription, Observable } from 'rxjs';
import { ModalsService } from '../../../layout/modals/modals.service';
import { JobsService } from '../../jobs/jobs.service'
import 'rxjs/add/operator/switchMap'; 
import 'rxjs/add/operator/map';

@Component({
  selector: '[app-mass-update-tracking]',
  templateUrl: './mass-update-tracking.component.html',
  styleUrls: ['./mass-update-tracking.component.scss']
})
export class MassUpdateTrackingComponent implements OnInit {
  url_params:any;
  concat_params:any;
  recruitment_stages =[];
  valForm = new FormGroup({
    recruitment_stage_id: new FormControl('', Validators.required),
  });
  clicked_save =false;
  constructor(
  	private authService: AuthenticationService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private fb: FormBuilder,
    private navbar: NavbarService,
    private modal: ModalsService,
    private jobs: JobsService,
    private shared: SharedService
  	) { }

  ngOnInit() {
    this.get_plo();
  }
  get_plo(){
    // console.log(this.route.snapshot.params);
    // console.log('reached here');
    this.url_params = this.jobs.get_params_from_url(this.router.url);
    // console.log(this.url_params);
    this.navbar.resume_reports_selected$
       .subscribe(params => 
         {this.concat_params =this.shared.merge_options( {'resume_report_ids': params, 'tobu_url': this.router.url}, this.url_params);
         // console.log(this.concat_params);
       }
       );
    this.navbar.select_all_candidates$
      .subscribe(params => 
         {
          console.log(params)
          if(params==true){
            this.route.queryParams.subscribe(queryParams => {
              // this.concat_params =this.shared.merge_options( queryParams, this.url_params);
              this.concat_params =this.shared.merge_options( this.concat_params, {'select_all_candidates': true})
              // this.concat_params =this.shared.merge_options( this.concat_params, {'url': this.router.url})

            })
          }
         }
       );

    // console.log(this.navbar.current_page$);
    // this.modal.big_modal_selected$.subscribe(
    //   res=> {console.log(res)});
    // this.navbar.set_current_page('resumereports', {'id': '13'});
    this.navbar.get_current_params().map(current_params => {
      // this.current_page = current_params;
      // console.log(current_params); 
      return current_params;
     })
      .switchMap(params => {
        // console.log(params);
        if(params !== null && params !== undefined){ 
          return this.authService.post('/api/load_mass_tracking_modal', params);
        }
        else{
          return Observable.empty();
        }
      }).subscribe(
        res => {
            // console.log(res);
            this.recruitment_stages = res['recruitment_stages']
        }, error => {
            // console.log(error);
        }
      );

    // this.navbar.current_page$.subscribe(
   //    res => {
   //      console.log(res);
   //      this.current_page = res;
   //      this.authService.post('/api/get_job_description', {'id:': this.current_page['params']['id']}).subscribe(
    //     res=> {
    //           this.plo=res;
    //     },
    //     error => {
    //       console.log(error)
    //     }
    //   )
        
   //  }, 
   //    error => {console.log(error)}
   //  );
    
  }
  submitForm($ev, value: any) {
    // console.log(value);
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
        this.valForm.controls[c].markAsTouched();
    }

    if (this.valForm.valid) {
      this.clicked_save =true;
        this.authService.post(
          '/api/mass_update_resume_stage', this.shared.merge_options(value, this.concat_params)
          ).subscribe(
          res => {
              // console.log(res);
              this.clicked_save =false;
              // this.modal.hide_modal('small');
              this.modal.hide_modal_by_name('mass_update');
              this.navbar.set_notification('Success','Update Completed', 'This page will refresh now');
              // setTimeout(function(){ location.reload(); }, 3000);
              this.shared.refresh(this.router.url);
              //feedback pending
          }, error => {
              // console.log(error);
          }
        )
    }
  }
  

}

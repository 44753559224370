import { Component, OnDestroy , ViewChild, ViewEncapsulation} from '@angular/core';
import { Params, Router, ActivatedRoute ,NavigationEnd} from "@angular/router";
import { AuthenticationService} from '../../../../core/authentication/authentication.service'
import { FormGroup, FormBuilder, FormControl, Validators , FormArray} from '@angular/forms';
import { Subscription }   from 'rxjs';
import {NavbarService} from '../../../../layout/header/navbar.service'
import { SharedService } from '../../../../core/shared/shared.service';
import { CandidateService } from '../candidate.service'
import { filter } from 'rxjs/operators';
import {ModalsService} from '../../../../layout/modals/modals.service'
import { Observable} from 'rxjs';
import { environment } from '../../../../../environments/environment'
import { ImageCropperComponent, CropperSettings, Bounds } from 'ngx-img-cropper';
const swal = require('sweetalert');

@Component({
  selector: '[app-details]',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class DetailsComponent implements OnDestroy {
  showCloseButton={}
  plo: any ;
  rcar: any;
  uar: any;
  loading = true;
  uploaded_resume: any;
  access_from_candidates=true;
  access_from_jobs=false;
  // comments: any = {};
  job_role:any;
  cv_source:any;
  tags: any;
  resume_source_url:any;
  is_resume_attached:any
  subscription: Subscription;
  sub1: Subscription;
  sub2: Subscription;
  current_page: any;
  generated_params ={};
  showEditCandidate=false;
  clicked_save=false;
  resume_temp_url ='';
  countries =[];
  current_country ={};
  current_currency = {};
  current_salary_period: any;
  expected_currency = {};
  expected_salary_period: any;
  current_industry: any;
  nationality: any;
  industries = []
  resume_photo_url='';
  // countries = [{'country_name': 'United States', 'country_code': 'US'}, {'country_name': ''}]
  candidate_country_code ='';
  currencies = [];
  salary_period_options = ['Annual', 'Monthly', 'Hourly'];
  genders= [ 'Male', 'Female', 'Other'];
  name: string;
    // data1: any;
  data2:any;
  questionnaire_answers =[];
  display_answers = []
  bsConfig = {
        containerClass: 'theme-angle'
    }
  bsValue = new Date();
    maxDate = new Date();
  // cropperSettings: CropperSettings;
  cropperSettings2: CropperSettings;
  croppedImage:any;
  banner_file: any;
  // image_blob:any;
  image_blob2:any;
  show_upload_image = false;

  update_resume_details = new FormGroup({
    resume_id: new FormControl(),
    resume_full_name: new FormControl(),
    resume_date_of_birth: new FormControl(),
    resume_gender: new FormControl(),
    resume_email: new FormControl('', Validators.email),
    resume_phone_number: new FormControl(),
    resume_years_of_work_experience: new FormControl('', Validators.pattern('^(0|[1-9][0-9]*)$')),
    // resume_ctc: new FormControl(),
    resume_salary_amount: new FormControl('', Validators.pattern('^(0|[1-9][0-9]*)$')),
    resume_salary_period: new FormControl(),
    resume_salary_currency: new FormControl(),
    resume_expected_salary_amount: new FormControl('', Validators.pattern('^(0|[1-9][0-9]*)$')),
    resume_expected_salary_period: new FormControl(),
    resume_expected_salary_currency: new FormControl(),
    resume_current_employer: new FormControl(),
    resume_current_job_position: new FormControl(),
    resume_current_location: new FormControl(),
    resume_current_country: new FormControl(),
    resume_current_industry: new FormControl(),
    // resume_current_zipcode: new FormControl(),
    resume_preferred_location: new FormControl(),
    resume_educational_qualification: new FormControl(),
    resume_notice_period: new FormControl(),
    resume_expected_ctc: new FormControl(),
    resume_willingness_to_relocate: new FormControl(),
    resume_reason_for_job_change: new FormControl(),
    resume_interview_availability: new FormControl(),
    resume_marital_status: new FormControl(),
    resume_college_attended: new FormControl(),
    resume_linkedin_profile_url: new FormControl(),
    resume_additional_info: new FormControl(),
    resume_no_of_job_positions_held: new FormControl(),
    resume_nationality: new FormControl(),
    questions: new FormArray([
      
    ]),
  });
  update_tags = new FormGroup({
    tags: new FormControl(),
  });
  showEditTags = false;

    @ViewChild('cropper2', {static: false}) cropper2: ImageCropperComponent;
  constructor(
  	private authService: AuthenticationService, 
    private fb: FormBuilder, 
    private router: Router, 
    private route: ActivatedRoute, 
    private navbar: NavbarService,
    private shared: SharedService,
    private candidate: CandidateService,
    private modal: ModalsService
    ) { 
        this.name = 'Angular2';
        this.cropperSettings2 = new CropperSettings();

        this.cropperSettings2.noFileInput = true;

        this.cropperSettings2.width = 200;
        this.cropperSettings2.height = 200;

        this.cropperSettings2.croppedWidth = 200;
        this.cropperSettings2.croppedHeight = 200;

        this.cropperSettings2.canvasWidth = 200;
        this.cropperSettings2.canvasHeight = 200;

        this.cropperSettings2.minWidth = 106.25;
        this.cropperSettings2.minHeight = 106.25;

        this.cropperSettings2.cropperDrawSettings.strokeColor = 'rgba(0,0,0,.25)';
        this.cropperSettings2.cropperDrawSettings.strokeWidth = 2;

        this.cropperSettings2.rounded = false;

        this.data2 = {};
      }

  ngOnInit() {
  	// this.get_restrictions()
  	this.get_plo();
    // this.modal.load_modal_by_name('candidate', {});
    if(this.router.url.includes('/candidates/inbox'))
      {this.access_from_candidates=true}
    else
      {this.access_from_candidates=false}
    if(this.router.url.includes('/jobs/candidates'))
      {this.access_from_jobs=true}
    else
      {this.access_from_jobs=false}
  }

  ngOnDestroy(){
    console.log('des sub details')
  	// this.subscription.unsubscribe();
    this.sub1.unsubscribe();
    this.sub2.unsubscribe();
    this.modal.destroy_resume_temp_url();
    // this.modal.hide_modal_by_name('candidate');
  }

  get_plo(){
   //   this.generated_params = this.candidate.get_params_from_url(this.router.url);
  	
  	
  	// console.log(this.generated_params);
  	// // this.route.params.subscribe((params: Params) => {
   // //    console.log(params);
   // //    console.log('params above');
   // //    this.candidate_id = params['candidate_id']
   // //  });
  	
  	// console.log('posting detial param');
  	// console.log(this.router.url.split('/'));
   //  if(this.router.url.includes('inbox'))
   //  {
   //    this.authService.post('/api/show_resume_details', this.generated_params).subscribe(
   //      res => {
   //        // this.plo =  res
   //        this.uploaded_resume = res['uploaded_resume'];
   //        // this.details = this.plo.details;
   //        // this.job_role = this.plo.job_role;
   //        // console.log(this.job_role);
   //        this.cv_source = res['cv_source'];
   //        this.tags = res['tags'];
   //        this.resume_source_url = res['resume_source_url'];
   //        // this.is_resume_attached = this.plo.is_resume_attached;
   //        console.log(res);
   //        this.update_form();
   //      }, error => {
   //        console.log(error);
   //      }
   //    )
   //  }
   //  else
   //  {
  	//   this.authService.post('/api/show_resume_report_details', this.generated_params).subscribe(
   //      res => {
   //        this.plo =  res
   //        this.uploaded_resume = this.plo.uploaded_resume;
   //        // this.details = this.plo.details;
   //        // this.job_role = this.plo.job_role;
   //        // console.log(this.job_role);
   //        this.cv_source = this.plo.cv_source;
   //        this.tags = this.plo.tags;
   //        this.resume_source_url = this.plo.resume_source_url;
   //        // this.is_resume_attached = this.plo.is_resume_attached;
   //        console.log(res);
   //        this.update_form();
   //      }, error => {
   //        console.log(error);
   //      }
   //    )
   //  }
    // this.load_details();
    // this.sub1 = this.router.events.pipe(
    // filter(event => event instanceof NavigationEnd)  
    // ).subscribe((event: NavigationEnd) => {
    //   this.load_details();
    //   console.log(event.url);
    // });    
    this.sub1 = this.route.queryParams.subscribe(
      res=> {
        this.loading=true
        console.log(this.route.snapshot.queryParams)
        if(this.route.snapshot.queryParams['open_result']===undefined){this.sub1.unsubscribe();}
        else
        {this.load_details();this.generate_resume_photo();}
      }
    )  
    // this.generate_resume_image();
    
  }

  load_details(){
    this.sub2=this.fetch_details()
      .subscribe(
        res => {
         if(res['upgrade']==='Premium'){this.modal.hide_modal_by_name('candidate');setTimeout(()=>{ this.modal.load_modal_by_name('upgrade_plan', {}) }, 500)}
         else{
          if(res['notice']==='Access Denied')
          {
            this.router.navigate(['/candidate_inbox'])
          }
          else
          {
            console.log(res);
            this.plo =  res
            this.rcar = res["rcar"];
            this.uar = res["uar"];
            this.uploaded_resume = this.plo.uploaded_resume;
            // this.details = this.plo.details;
            // this.job_role = this.plo.job_role;
            // console.log(this.job_role);
            this.cv_source = this.plo.cv_source;
            this.tags = this.plo.tags//.map((x,i,a) => {return x['tag']});
            console.log(this.tags)
            this.resume_source_url = this.plo.resume_source_url;
            // this.resume_temp_url = res['resume_url'];
            // this.set_temp_url(this.resume_temp_url)
            this.countries = res['country_names']
            this.currencies = res['currencies'];
            this.current_country = res['current_country'];
            this.current_currency = res['current_currency'];
            this.current_salary_period = res['current_salary_period'];
            this.expected_currency = res['expected_currency'];
            this.expected_salary_period = res['expected_salary_period'];
            this.current_industry = res['current_industry']
            this.industries =res['industries'];
            console.log(this.industries)
            this.modal.set_resume_details_and_count({comment_count: res['comment_count'], prev_result: res['prev_result'], next_result: res['next_result']})
            this.update_tags.patchValue({
              tags: this.shared.tags_string_to_form_control(this.tags.map((x,i,a) => {return x['tag']}).join())
            })
            this.nationality=res['nationality'];
            // if(!this.shared.isEmpty(res['questionnaire_answers']))
            // {
            this.questionnaire_answers = res['questionnaire_answers']
            this.display_answers = res['display_answers']

            console.log(this.questionnaire_answers)
            for (let a of this.questionnaire_answers)  

              {  
                if(!this.shared.isEmpty(a['answer_of_candidate'])){
                  this.addAnswer(a['id'], a['type_of_question'], a['answer_of_candidate']['content'], a['answer_of_candidate']['selected_answer_options'].map((x,i,a)=>{return {label: x.answer_row_label, id: x.answer_row_id}}), a['answer_of_candidate']['selected_answer_options'].map((x,i,a)=>{return x.answer_row_label}), a['answer_rows'].map((x,i,a)=>{return {label: x.label, id: x.id}}), a['content'])
                }
              else{
                this.addAnswer(a['id'], a['type_of_question'], '', [], [], a['answer_rows'].map((x,i,a)=>{return {label: x.label, id: x.id}}), a['content'])
                }
              }
            console.log(this.questions)
            // }
            // this.is_resume_attached = this.plo.is_resume_attached;
            this.navbar.set_type_of_document$({type_of_document: this.uploaded_resume.type_of_document})
            
            this.update_form();
            this.loading=false;
          }
         }
        }, error => {
          console.log(error);
        }
      )
  }
  fetch_details(){
    this.generated_params = this.candidate.get_params_from_url(this.router.url);
    // console.log(this.generated_params)
    if(this.router.url.includes('inbox')){
      return this.authService.post('/api/show_resume_details', this.generated_params)
    }
    else
    {
      return this.authService.post('/api/show_resume_report_details', this.generated_params)
    }
  }
  // get_restrictions(){
  //   // this.subscription= this.shared.current_restrictions$.subscribe(
  //   this.subscription = this.authService.post('/api/get_recruitment_company_access_rights_api', null).subscribe(
  //       res => {
  //         this.rcar = res["rcar"];
  //         this.uar = res["uar"];
  //         // console.log(res);
  //       }
  //   );
  // }
  update_form(){
    console.log(this.uploaded_resume)
    this.update_resume_details.patchValue({
      'resume_id': this.uploaded_resume['id'],
      'resume_full_name': this.uploaded_resume['full_name'],
      'resume_date_of_birth': (this.uploaded_resume['date_of_birth']===undefined || this.uploaded_resume['date_of_birth']===null || this.uploaded_resume['date_of_birth']==='') ? '': new Date(this.uploaded_resume['date_of_birth']),
      'resume_email': this.uploaded_resume['email'],
      'resume_gender': this.uploaded_resume['gender'],
      'resume_phone_number': this.uploaded_resume['phone_number'],
      'resume_years_of_work_experience': this.uploaded_resume['years_of_work_experience'],
      // 'resume_ctc': this.uploaded_resume['ctc'],
      'resume_salary_amount': this.uploaded_resume['salary_amount'],
      'resume_salary_period': this.current_salary_period,//this.uploaded_resume['salary_period'],
      'resume_salary_currency': this.current_currency,//this.uploaded_resume['currency'],
      'resume_expected_salary_amount': this.uploaded_resume['expected_salary_amount'],
      'resume_expected_salary_period': this.expected_salary_period,//this.uploaded_resume['salary_period'],
      'resume_expected_salary_currency': this.expected_currency,//this.uploaded_resume['currency'],
      'resume_current_employer': this.uploaded_resume['current_employer'],
      'resume_current_job_position': this.uploaded_resume['current_job_position'],
      'resume_current_industry': this.current_industry['id'],
      'resume_current_location': (this.uploaded_resume['current_location']=== undefined || this.uploaded_resume['current_location'] === null || this.uploaded_resume['current_location']==='') ? [] : [{'display': this.uploaded_resume['current_location'], 'value': this.uploaded_resume['current_location']}],//this.uploaded_resume['current_location'],
      'resume_preferred_location': this.uploaded_resume['preferred_location'],
      'resume_educational_qualification': this.uploaded_resume['educational_qualification'],
      'resume_notice_period': this.uploaded_resume['notice_period'],
      'resume_expected_ctc': this.uploaded_resume['expected_ctc'],
      'resume_willingness_to_relocate': this.uploaded_resume['willingness_to_relocate'],
      'resume_reason_for_job_change': this.uploaded_resume['reason_for_job_change'],
      'resume_interview_availability': this.uploaded_resume['interview_availability'],
      'resume_marital_status': this.uploaded_resume['marital_status'],
      'resume_college_attended': this.uploaded_resume['college_attended'],
      'resume_linkedin_profile_url': this.uploaded_resume['linkedin_profile_url'],
      'resume_additional_info': this.uploaded_resume['additional_info'],
      'resume_current_country': this.current_country['country_code'],
      'resume_no_of_job_positions_held': this.uploaded_resume['no_of_job_positions_held'],
      'resume_nationality': (this.nationality=== undefined || this.nationality === null || this.nationality==='') ? [] : [{'display': this.nationality, 'value': this.nationality}],//this.nationality
      // 'resume_current_zipcode': this.uploaded_resume['current_zipcode'],

     });

    this.candidate_country_code=this.current_country['country_code'];
    // this.update_resume_details.controls['resume_current_country'].setValue(this.current_country['country_code'], {onlySelf: true});
  }
  submitForm($ev, value: any) {
    $ev.preventDefault();
    for (let c in this.update_resume_details.controls) {
            this.update_resume_details.controls[c].markAsTouched();
        }
    // if (this.submitdetailsForm.dirty && this.submitdetailsForm.valid)
    if (this.update_resume_details.valid) 
    {
      // this.clicked_save=true;
      // fetch(this.data2.image)
      //     .then((response) => {
      //       return response.blob();
      //     })
      //     .then((data) => {
      //       console.log(data);
      //       this.image_blob2=data;
      //       let formData = new FormData(); 
      //       formData.append('file', this.image_blob2, 'profile_image'); 
      //       console.log(formData);
            // this.generated_params = this.shared.merge_options(this.shared.merge_options(this.generated_params, value),  formData)
            this.generated_params = this.shared.merge_options(this.generated_params, value)
            // console.warn(value);
            // this.generated_params = this.shared.merge_options(this.shared.merge_options(this.generated_params, value), formData))
            console.log(this.generated_params);
             this.authService.post('/api/update_candidate_details',  this.generated_params
            //   'interview_end': this.shared.convert_date_time_to_string(value.end_date, value.end_time),
            //   'interview_start': this.shared.convert_date_time_to_string(value.start_date, value.start_time),
            //   'send_mail_to_candidate': value.send_mail_to_candidate,
            //   'appointment_message': value.appointment_message,
            //   'interview_location': value.interview_location,
            //   'interviewers': value.interviewers,
            //   'stage_id': value.stage_id,
            //   'interview_reminder_unit': value.interview_reminder_unit,
            //   'interview_reminder': value.interview_reminder,
            //   'uploaded_resume_id': this.params['uploaded_resume_id']
            ).subscribe(
              res => {
                // console.log(res);
                this.uploaded_resume=res['uploaded_resume'];
                this.navbar.set_notification('Success','Candidate Details updated', res['notice']);
                this.showEditCandidate=false;
                this.clicked_save = false;
                this.nationality=res['nationality'];
                this.questionnaire_answers = res['questionnaire_answers']
                this.display_answers = res['display_answers']
                this.current_industry = res['current_industry']

                console.log(this.questionnaire_answers)
                for (let a of this.questionnaire_answers)  

                  {  
                    if(!this.shared.isEmpty(a['answer_of_candidate'])){
                      this.addAnswer(a['id'], a['type_of_question'], a['answer_of_candidate']['content'], a['answer_of_candidate']['selected_answer_options'].map((x,i,a)=>{return {label: x.answer_row_label, id: x.answer_row_id}}), a['answer_of_candidate']['selected_answer_options'].map((x,i,a)=>{return x.answer_row_label}), a['answer_rows'].map((x,i,a)=>{return {label: x.label, id: x.id}}), a['content'])
                    }
                  else{
                    this.addAnswer(a['id'], a['type_of_question'], '', [], [], a['answer_rows'].map((x,i,a)=>{return {label: x.label, id: x.id}}), a['content'])
                    }
                  }
                console.log(this.questions)
                // this.details.push(res['comment']);
              },
               err=> console.log(err)
            )
          // })
      
    }
    // this.modal.hide_modal('big');
  }
  submitTagsForm($ev, value: any) {
    console.log(value)
    $ev.preventDefault();
       let tags_array = this.shared.tags_form_control_to_string(value.tags)
       this.authService.post('/api/update_tags', this.shared.merge_options(this.generated_params, {'tags': tags_array})
      ).subscribe(
        res => {
          console.log(res);
          this.tags=res['tags']//.map((x,i,a) => {return x['tag']});
          console.log(this.tags)
          this.navbar.set_notification('Success','Tags updated', res['notice']);
          this.showEditTags=false;
        },
         err=> console.log(err)
      )
    
  }
  public requestAutocompleteNationality = (text: string): Observable<any> => {
    const url = `/api/nationalities_autocomplete?term=${text}`;
    return this.authService
        .get(url)
        .map(data => data);
  };
  set_temp_url(_url){
    this.modal.set_resume_temp_url(_url);
  }
  select_country(){
    // console.log(this.update_resume_details.controls['resume_current_country'].value)
    this.candidate_country_code = this.update_resume_details.controls['resume_current_country'].value
  }
  public requestAutocompleteCityName = (text: string): Observable<any> => {
    const url = `/api/city_names?term=${text}&country=`+ this.candidate_country_code;
    console.log(url);
    return this.authService
        .get(url)
        .map(data => data);
  };
  public requestAutocompletePostalCode = (text: string): Observable<any> => {
    const url = `/api/postal_code_autocomplete?term=${text}&country=`+ this.candidate_country_code;
    return this.authService
        .get(url)
        .map(data => data);
  };

  generate_resume_image(){
    let params = this.candidate.get_params_from_url(this.router.url);
    this.authService.post('/api/generate_image', params).subscribe(
      res=> {
        this.resume_temp_url = res['resume_url'];
        this.set_temp_url(this.resume_temp_url)
      },
      err=> console.log(err)
      )
  }
  generate_resume_photo(){
    let params = this.candidate.get_params_from_url(this.router.url);
    this.authService.post('/api/generate_resume_photo', params).subscribe(
      res=> {
        this.resume_photo_url = res['resume_image_url'];
        console.log(res)
        // this.set_temp_url(this.resume_temp_url)
      },
      err=> console.log(err)
      )
  }
  fileChangeListener2($event) {
        let image: any = new Image();
        let file: File = $event.target.files[0];
        let myReader: FileReader = new FileReader();
        let that = this;
        myReader.onloadend = function(loadEvent: any) {
            image.src = loadEvent.target.result;
            that.cropper2.setImage(image);
        };
        this.banner_file=file;
        console.log(image);
        console.log(file);
        console.log(myReader);

        myReader.readAsDataURL(file);
    }

     save_image(){
    // this.convert_b64_to_blob(this.data1.image)
        fetch(this.data2.image)
          .then((response) => {
            return response.blob();
          })
          .then((data) => {
            console.log(data);
            this.image_blob2=data;
            let formData = new FormData(); 
            formData.append('file', this.image_blob2, 'candidate_image'); 
            console.log(this.generated_params)
            this.generated_params['search_result_id']!=null ? formData.append('search_result_id', this.generated_params['search_result_id']) : '';
            this.generated_params['resume_report_id']!=null ? formData.append('resume_report_id', this.generated_params['resume_report_id']): '';
            this.generated_params['resume_email_id']!=null ? formData.append('resume_email_id', this.generated_params['resume_email_id']) : ''; 
            console.log(formData);
            //load spinner
            // this.modal.load_modal_by_name('spinner', {});
            this.loading=true
            //this.authService.post('/api/save_image_for_candidate', formData).subscribe(
            this.authService.post_html('/api/save_image_for_candidate', formData).subscribe(
              (val) => {
                console.log(val);
                // this.modal.hide_modal_by_name('spinner');
                this.loading=false
                // hide spinner
                // this.load_images();
                this.navbar.set_notification('Success','Notice', val['notice']);
                this.show_upload_image = !this.show_upload_image
                // setTimeout(function(){ location.reload(); }, 1000);
                this.generate_resume_photo();
              });

            return false; 
          });
    
    }
    delete_candidate(){
      swal({
          title: 'Are you sure?',
          text: 'Your will not be able to recover the candidate!',
          icon: 'warning',
          buttons: {
              cancel: {
                  text: 'No, cancel please!',
                  value: null,
                  visible: true,
                  className: "",
                  closeModal: true
              },
              confirm: {
                  text: 'Yes, delete it!',
                  value: true,
                  visible: true,
                  className: "bg-danger",
                  closeModal: true
              }
          }
      }).then((isConfirm) => {
          console.log(this.generated_params)
          if (isConfirm) {
                  this.authService.post('/api/delete_entire_candidate', this.generated_params).subscribe(
            res => {
              console.log(res);
              this.navbar.set_notification('Success', 'Success', res['notice']);
              // setTimeout(function(){ location.reload(); }, 1000);
              let a=this.router.url;
              if(a.includes('candidates/inbox'))
              {
                // this.shared.refresh(a.split('/candidates/inbox/view')[0] +'/candidates/inbox/view?'+ (a.includes('?') ? a.split('?')[1] : ''));
                this.router.navigate(['/candidates/inbox/view'], {queryParams: {'open_result': null}, queryParamsHandling: "merge" })
                setTimeout(() => {
                  this.shared.refresh(this.router.url)
                })
                // this.router.navigateByUrl('/loading', { skipLocationChange: true }).then(() => {
                  
                //   // Introduce a 3-second delay
                //   setTimeout(() => {
                //     // Retrieve current query parameters
                //     // const currentQueryParams = this.route.snapshot.queryParams;

                //     // Navigate to the new URL while merging the current query parameters
                //     this.router.navigate(['/candidates/inbox/view'], {
                //       queryParams: {  'open_result': null },
                //       queryParamsHandling: 'merge'
                //     });
                //   }, 3000);
                // });
              }
              if(a.includes('/jobs/candidates')){
                this.shared.refresh(a.split('/listview')[0] +'/listview?'+ a.split('?')[1]);
              }
              //this.router.navigate(['/candidates/inbox/view'])
            },
            err=> console.log(err)
            )
              
          } else {
              swal('Cancelled', 'Your Candidate shall stay', 'error');
          }
      });
    }
    delete_candidate_from_job(){
      swal({
          title: 'Are you sure?',
          text: 'Your will not be able to recover the candidate!',
          icon: 'warning',
          buttons: {
              cancel: {
                  text: 'No, cancel please!',
                  value: null,
                  visible: true,
                  className: "",
                  closeModal: true
              },
              confirm: {
                  text: 'Yes, delete it!',
                  value: true,
                  visible: true,
                  className: "bg-danger",
                  closeModal: true
              }
          }
      }).then((isConfirm) => {
          console.log(this.generated_params)
          if (isConfirm) {
                  this.authService.post('/api/delete_candidate_from_job', this.generated_params).subscribe(
            res => {
              console.log(res);
              this.navbar.set_notification('Success', 'Success', res['notice']);
              // setTimeout(function(){ location.reload(); }, 1000);
              let a=this.router.url;
              
              if(a.includes('/jobs/candidates')){
                this.shared.refresh(a.split('/listview')[0] +'/listview?'+ a.split('?')[1]);
              }
              //this.router.navigate(['/candidates/inbox/view'])
            },
            err=> console.log(err)
            )
              
          } else {
              swal('Cancelled', 'Your Candidate shall stay', 'error');
          }
      });
    }
    setRoundedMethod(value: boolean) {
        this.cropperSettings2.rounded = value;
    }

    cropped(bounds: Bounds) {
        console.log(bounds);
        // console.log(this.croppedImage);
        console.log(this.data2);
        // this.save();
    }
    imageCropped($ev){
        this.croppedImage = $ev.base64;
        // this.save();
    }
    get questions() {
      return this.update_resume_details.controls["questions"] as FormArray;
    }
    addAnswer(_id, _type, _answer_content, _answer_rows,_answer_rows_labels, _question_options, _question_content){
      console.log(_answer_rows)
      let answer =''
      if(_type==="radio"){
        if(_answer_rows.length ===0){
          console.log('1')
          answer = ""
        }
        else{
          console.log('2')
          answer = _answer_rows[0]['label']
        }
      }
      let questionForm = this.fb.group({
          question_id: [_id],
          question_options:[_question_options],
          question_type: [_type],
          answer_content: [_answer_content],
          answer_rows: [_answer_rows],
          answer_rows_labels: [_answer_rows_labels],
          question_content: [_question_content],
          answer: [_type === "radio" ? answer : _answer_rows]
          // answer: [_answer_rows]
      })
      console.log(questionForm)
      this.questions.push(questionForm)
    }
    deleteQuestion(i: number) {
      this.questions.removeAt(i);
    }
    // questionnaireCheckboxChanged(_bool){

    // }
    // public questionnaireCheckBox:boolean;

     questionnaireCheckboxChanged(event, id, option){
        // this.saveUsername = value;
        console.log(event.currentTarget.checked)
        console.log(id)
        console.log(option)
        console.log(this.questions.value)
        let as=this.questions.value
        for( let a of as){
          console.log(a)
          if(a?.question_id == id){
            console.log('entered 0')
            // let question_options= a.question_options
            let answer_rows = a.answer_rows
            console.log(option)
            // for( let question_option of question_options){
              if(event.currentTarget.checked){
                console.log("entered 1")
                console.log(option)
                answer_rows.push(option);
                break;
              }
              else{
                console.log("entered 2");
                console.log(option)
                let obj = answer_rows.find(o => o.id === option.id);

                console.log(obj);
                const index = answer_rows.indexOf(obj);
                const x = answer_rows.splice(index, 1);
                break;
              }
            // }
            console.log(answer_rows)
          }
        }
        // this.questions.patchValue([{question_id: id}, {answer_rows: [option]}])
        console.log(this.questions)
    }

    questionnaireRadioChanged(event, id, option){
        // this.saveUsername = value;
        console.log(event.currentTarget.checked)
        console.log(id)
        console.log(option)
        console.log(this.questions.value)
        let as=this.questions.value
        for( let a of as){
          console.log(a)
          if(a?.question_id == id){
            console.log('entered 0')
            // let question_options= a.question_options
            let answer_rows = a.answer_rows
            // answer_rows=[]
            console.log(option)
            // for( let question_option of question_options){
              if(event.currentTarget.checked){
                console.log("entered 1")
                console.log(option)
                answer_rows.pop();
                answer_rows.push(option);
                break;
              }
              else{
                console.log("entered 2");
                console.log(option)
                // let obj = answer_rows.find(o => o.id === option.id);

                // console.log(obj);
                // const index = answer_rows.indexOf(obj);
                // const x = answer_rows.splice(index, 1);
                break;
              }
            // }
            console.log(answer_rows)
          }
        }
        // this.questions.patchValue([{question_id: id}, {answer_rows: [option]}])
        console.log(this.questions)
    }
    add_tag(){
      this.modal.load_modal_by_name('add_tags', {});
    }
    removeTag(tag): void {
    
    // console.log(tag)
    // console.log(resume_id)
    let search_result_id = this.candidate.get_params_from_url(this.router.url)['search_result_id']
    console.log(search_result_id)
    this.authService.post('/api/bulk_untag', {'search_result_id': search_result_id, 'tags': [tag['tag']]}).subscribe(
      res=> {
        this.navbar.set_notification('Success','Notice', "Removed tags");

            for (let i = 0; i < this.tags.length; i++) {
              console.log(this.tags[i]['tag'])
              console.log(tag['tag'])
              if (this.tags[i]['tag'] === tag['tag']) {
                this.tags.splice(i, 1);
                break; // break the loop if tag is found and removed
              }
            }
      },
      err => console.log(err)
      )
  }

}

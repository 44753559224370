import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
const screenfull = require('screenfull');

import { UserblockService } from '../sidebar/userblock/userblock.service';
import { SettingsService } from '../../core/settings/settings.service';
import { MenuService } from '../../core/menu/menu.service';
import {NavbarService} from '../../layout/header/navbar.service'
// import { AngularTokenService } from 'angular-token';
import { AuthenticationService} from '../../core/authentication/authentication.service';
import { HttpHeaders, HttpClient} from '@angular/common/http' 
import { Subscription }   from 'rxjs';
import { Router} from '@angular/router';
import { ModalsService} from '../modals/modals.service';
import { AuthGuard} from "../../core/authentication/auth.guard";
import { SharedService} from "../../core/shared/shared.service";
import { ElectronService } from 'ngx-electron';
import { PLATFORM_ID , Inject} from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
// import { Ng2Cable, Broadcaster } from 'ng2-cable';
let win = undefined;
// import { ActionCableService, Channel } from 'angular2-actioncable';
import { environment} from '../../../environments/environment.prod'

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    providers: [AuthenticationService]
})
export class HeaderComponent implements OnInit {

    navCollapsed = true; // for horizontal layout
    menuItems = []; // for horizontal layout
    notifications: any;
    isNavSearchVisible: boolean;
    subscription: Subscription;
    subscription2: Subscription;
    subscription3: Subscription;
    subscription4: Subscription;
    current_page: any={};
    notification_count = 0;
    user_email = '';
    company_name = '';
    loggedIn=false;
    isElectron=false
    is_email_linked = true;
    bulk_emails_pending_today = 0;
    can_send_emails = false;
    ea_is_business_email_id = false;
    is_company_admin = false;
    @ViewChild('fsbutton', { static: true }) fsbutton;  // the fullscreen button

    constructor(
        public menu: MenuService, 
        public userblockService: UserblockService, 
        public settings: SettingsService,
        //private _tokenService: AngularTokenService, 
        public authService: AuthenticationService, 
        private http: HttpClient, 
        private navbar: NavbarService,
        private modal: ModalsService,
        private router: Router,
        private authGuard: AuthGuard,
        private shared: SharedService,
        private _electronService:ElectronService,
        @Inject(PLATFORM_ID) private platformId: any
        // private cableService: ActionCableService,
        // private ng2cable: Ng2Cable,
        // private broadcaster: Broadcaster

        ) {
        if(isPlatformBrowser(platformId))
            { win = (<any>window)}

        // show only a few items on demo
        this.menuItems = menu.getMenu().slice(0, 4); // for horizontal layout
        //if (authService.isLoggedIn()){
            // console.log("this");
           // this.update_notification_count()
           //  console.log(this._tokenService.tokenOptions.apiBase + '/api/get_most_recent_notifications_api');
           //  this.http.post(this._tokenService.tokenOptions.apiBase + '/api/get_most_recent_notifications_api', 
           // JSON.stringify(null), 
           // {headers:  new HttpHeaders({'Content-Type' : 'application/json'})}
           // ).subscribe(
           //      res =>      this.notifications = res,
           //                  //console.log(res),
           //      error =>    console.log(error)
           //  );
          
        
        //}
        this.subscription = navbar.current_page$.subscribe(
          res => {
            this.current_page = res;
        });
          // let access_token = localStorage.getItem("accessToken")
          // let client = localStorage.getItem("client")
          // let uid = localStorage.getItem("uid")
          // var wsUrl = 'ws://localhost:3000/cable?access-token=' + access_token + '&client=' + client+'&uid='+uid
        // this.ng2cable.subscribe(
        //     'ws://localhost:3000/cable?access-token=' +localStorage.getItem("accessToken")+ '&client=' + localStorage.getItem("client")+'&uid='+localStorage.getItem("uid")
        //     , 'NotificationChannel', {});
        // //   //By default event name is 'channel name'. But you can pass from backend field { action: 'MyEventName'}

        //   this.broadcaster.on<string>('NotificationChannel').subscribe(
        //     message => {
        //       console.log(message);
        //       this.navbar.set_notification('Success', message['title'], message['body'])
        //       this.update_notification_count();
        //     }
        //   );

    }

    ngOnInit() {
        if (this._electronService.isElectronApp)
            {
              console.log('loading electron')
              this.isElectron=true
            };
        // if(!this.authService.isLoggedIn()){this.router.navigate(['/account/login']);}
        this.isNavSearchVisible = false;
        this.loggedIn = this.authService.isLoggedIn();
        if(this.loggedIn===true){this.load_user();this.update_notification_count();this.loggedIn=true;}
        //         else{this.loggedIn=false}
        // this.http.get(environment.baseUrl+'/api/is_logged_in').subscribe(
        //     res=> {
        //         console.log(res)
        //         if(res['is_logged_in']===true){this.load_user();this.update_notification_count();this.loggedIn=true;}
        //         else{this.loggedIn=false}
        //     })
        // console.log(this.loggedIn)
        //this.load_user();
         this.get_sync_status_stats();
        if(this.shared.isBrowser()){
            var ua = window.navigator.userAgent;
            // if (ua.indexOf("MSIE ") > 0 || !!ua.match(/Trident.*rv\:11\./)) { // Not supported under IE
            //     this.fsbutton.nativeElement.style.display = 'none';
            // }

            // // Switch fullscreen icon indicator
            // const el = this.fsbutton.nativeElement.firstElementChild;
            // screenfull.on('change', () => {
            //     if (el)
            //         el.className = screenfull.isFullscreen ? 'fa fa-compress' : 'fa fa-expand';
            // });
            // if(this.loggedIn) { 
            //     this.load_user();
            //     this.update_notification_count();
            //     //     )
            //     // console.log(this.authService.currentUserData());
            //     // this.user_email = this.authService.currentUserData['email'];
            // } 
            }
        this.navbar.isLoggedIn$.subscribe(res=>{
            console.log(res)
            if ((res === true) || (res===false)){this.loggedIn = res};
            if(this.loggedIn){this.load_user(); this.update_notification_count()};
        })
        
    }
    ngOnDestroy() {
        // prevent memory leak when component destroyed
        if(this.shared.isBrowser()){
        this.subscription.unsubscribe();
        this.subscription2.unsubscribe();
        this.subscription3.unsubscribe();
        this.subscription4.unsubscribe();
        }
    }
    load_user(){
        this.load_notification_count() 
                // console.log(this.authService.currentAuthData['email']);
        this.authService.post('/api/get_current_user_profile_details', {}).subscribe(
            res=> {
                console.log(res);
                this.user_email = res['email'];
                this.company_name = res['company_name']
                this.is_email_linked = res['is_email_linked']
                this.bulk_emails_pending_today = res['no_of_bulk_emails_left_for_the_day']
                this.can_send_emails = res['can_user_send_bulk_emails']
                this.ea_is_business_email_id = res['ea_is_business_email_id']
                this.is_company_admin = res['is_admin']
            },
            err=>console.log(err)
            )
    }
    toggleUserBlock(event) {
        event.preventDefault();
        this.userblockService.toggleVisibility();
    }

    openNavSearch(event) {
        event.preventDefault();
        event.stopPropagation();
        this.setNavSearchVisible(true);
    }

    setNavSearchVisible(stat: boolean) {
        // console.log(stat);
        this.isNavSearchVisible = stat;
    }

    getNavSearchVisible() {
        return this.isNavSearchVisible;
    }

    toggleOffsidebar() {
        this.settings.toggleLayoutSetting('offsidebarOpen');
    }

    toggleCollapsedSideabar() {
        this.settings.toggleLayoutSetting('isCollapsed');
    }

    isCollapsedText() {
        return this.settings.getLayoutSetting('isCollapsedText');
    }

    toggleFullScreen(event) {
        if (screenfull.enabled) {
            screenfull.toggle();
        }
    }
    signout(){
         this.authService.logOut();
         this.navbar.set_is_logged_in(false);
         // this.router.navigate(['/account/login']);

    }
    isLoggedin(){
        return this.authService.isLoggedIn();
    }
    load_notification_count(){
        this.authService.get('/api/get_notification_count')
        .subscribe(
            res=> {
                // console.log(res);
                this.notification_count = res['notification_count']
            },
            // err=> console.log(err)
        )
    }
    show_notifications(){
        // this.modal.load_modal('notifications', 'medium', {});
        this.modal.load_modal_by_name('notifications', {})
    }
    load_notification(_id){
        // this.modal.load_modal('notification_alert', 'small', _id);
        // console.log(_id)
        this.modal.load_modal_by_name('notification_alert', _id)
    }
    clear_notifications(){
        this.authService.post('/api/mark_all_as_read', {})
        .subscribe(
            res => {
                // console.log(res);
                this.notification_count=0;
            },
            // err => console.log(err)
        )
    }
    update_notification_count(){
        if (this.loggedIn){
            this.subscription2 = this.authService.post('/api/get_most_recent_notifications_api', null).subscribe(
                res =>      {
                    this.notifications = res;
                    console.log(res);
                },
                // error => console.log(error)
            );
            // const channel: Channel = this.cableService
            //   .cable('wss://tobu.cloud/cable')
            //   .channel('NotificationChannel');
         
            // // Subscribe to incoming messages
            // console.log('subsctibing to notifications')
            // this.subscription3 = channel.received().subscribe(res => {
            //     console.log('received redis')
            //     // this.messageService.notify(message);
            //     // this.notifications = res['notifications']
            //     this.navbar.set_notification('Success','Notice', res['notification']);
            //     console.log(res)
            // });
            // this.subscription4 = channel.connected().subscribe(res => {
            //     console.log('connected redis')
            //     // this.messageService.notify(message);
            //     // this.notifications = res['notifications']
            //     console.log(res)
            // });
            // NotificationChannel.broadcast_to('notifications_channel', {room: 'Main', notification: _message})
        }
    }
    refresh_app(){
        if(this.isElectron){
            win.api.send("refreshApp")
        }
    }
    get_sync_status_stats(){
        if (this.loggedIn){
        this.authService.get('/api/get_sync_status_stats', {}).subscribe(
            res=> {
                console.log(res);
                this.navbar.set_sync_stats(res['stats']);
            },
            err=> {console.log(err)}
            )
        }
    }

}

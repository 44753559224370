import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl} from'@angular/forms';
import { JobsService } from '../../jobs/jobs.service'
import { ModalsService } from '../../../layout/modals/modals.service';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service';


@Component({
  selector: '[app-edit-stages]',
  templateUrl: './edit-stages.component.html',
  styleUrls: ['./edit-stages.component.scss']
})
export class EditStagesComponent implements OnInit {
  plo: any = {};
  uar: any = {};
  rcar: any= {};
  recruitment_stages:any;
  url_params:any;
  valForm = new FormGroup({
    all_stages: new FormArray([
      this.fb.group({
      	  stage_id: [''],
          stage: [''],
          stage_type: [''],
          can_delete_stage: ['']
      })
    ])
  });
  insertStageForm = new FormGroup({
          stage: new FormControl(),
          stage_type: new FormControl(),
          i: new FormControl()
  });
  stage_types = [];
  insert_stage= false;
  clicked_save =false;
  constructor(
  	private authService: AuthenticationService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private fb: FormBuilder,
    private jobs: JobsService,
    private modal: ModalsService,
    private shared: SharedService,
    private navbar: NavbarService,
  	) { }
  ngOnInit() {
    this.get_plo();
  }
  get_plo(){
    this.url_params = this.jobs.get_params_from_url(this.router.url);
    this.authService.post('/api/edit_stages', this.url_params).subscribe(
      res => {
          
          this.recruitment_stages = res['recruitment_stages'];
          this.stage_types = res['stage_types'];
          // this.valForm.patchValue({all_stages: new FormArray(this.all_stages())});
          this.valForm.setControl('all_stages', this.fb.array(this.all_stages() || []));
          // console.log(this.valForm.controls.all_stages)
          // console.log(res);
          console.log(this.valForm.get('all_stages')['controls'])
      }, error => {
          // console.log(error);
      }
    )
  }
  submitForm($ev, value: any) {
  //   $ev.preventDefault();
  //   // console.log(value);
  //   for (let c in this.valForm.controls) {
  //       this.valForm.controls[c].markAsTouched();
  //   }

  //   // if (this.valForm.valid) {
  //       this.clicked_save = true;
  //       this.authService.post(
  //         '/api/update_stage', this.shared.merge_options(value, this.url_params)
  //         ).subscribe(
  //         res => {
  //             // console.log(res);
  //             this.clicked_save =false;
  //             // this.router.navigate(['jobs/candidates/',res['job_role_id']]);// navigate to the created job
  //             this.navbar.set_notification('Success','Success', 'The stages have been updated');
  //             // this.modal.hide_modal('medium')
  //             this.modal.hide_modal_by_name('edit_stages');
  //             setTimeout(function(){ location.reload()  }, 1000);
  //         }, error => {
  //             // console.log(error);
  //         }
  //       )
  //   // }
  }
  edit_stage(i){
  	let stage_type =this.valForm.get('all_stages')['controls'][i]['value']['stage_type'];
  	let stage = this.valForm.get('all_stages')['controls'][i]['value']['stage'];
  	this.authService.post(
          '/api/update_stage', this.shared.merge_options({stage_type: stage_type, stage: stage, i: i}, this.url_params)
          ).subscribe(
          res => {
              // console.log(res);
              this.clicked_save =false;
              // this.router.navigate(['jobs/candidates/',res['job_role_id']]);// navigate to the created job
              this.navbar.set_notification('Success','Success', 'The stages have been updated');
              // this.modal.hide_modal('medium')
              // this.modal.hide_modal_by_name('edit_stages');
              // setTimeout(function(){ location.reload()  }, 1000);
              this.ngOnInit()
          }, error => {
              // console.log(error);
          }
        )
  }
  delete_stage(i){
  	let stage = this.valForm.get('all_stages')['controls'][i]['value']['stage'];
  	let stage_id = this.valForm.get('all_stages')['controls'][i]['value']['stage_id'];
  	console.log(stage)
  	this.authService.post(
          '/api/delete_stage', this.shared.merge_options({stage: stage, stage_id: stage_id}, this.url_params)
          ).subscribe(
          res => {
              // console.log(res);
              this.clicked_save =false;
              // this.router.navigate(['jobs/candidates/',res['job_role_id']]);// navigate to the created job
              this.navbar.set_notification('Success','Success', 'The stages have been updated');
              // this.modal.hide_modal('medium')
              this.modal.hide_modal_by_name('edit_stages');
              // setTimeout(function(){ location.reload()  }, 1000);
              this.shared.refresh(this.router.url);
          }, error => {
              // console.log(error);
          }
        )
  }
  submitInsertStageForm($ev, value: any) {
    $ev.preventDefault();
    // console.log(value);
    for (let c in this.insertStageForm.controls) {
        this.insertStageForm.controls[c].markAsTouched();
    }

    // if (this.valForm.valid) {
        this.clicked_save = true;
        this.authService.post(
          '/api/add_stage', this.shared.merge_options(value, this.url_params)
          ).subscribe(
          res => {
              // console.log(res);
              this.clicked_save =false;
              // this.router.navigate(['jobs/candidates/',res['job_role_id']]);// navigate to the created job
              this.navbar.set_notification('Success','Success', 'The stages have been updated');
              // this.modal.hide_modal('medium')
              this.modal.hide_modal_by_name('edit_stages');
              // setTimeout(function(){ location.reload()  }, 1000);
              this.shared.refresh(this.router.url);
          }, error => {
              // console.log(error);
          }
        )
    // }
  }
  // can_delete_stage(_stage, _index){
  // 	console.log(this.url_params)
  // 	this.authService.post('/api/can_delete_stage', this.shared.merge_options({stage: _stage, index: _index}, this.url_params) ).subscribe(
  // 		res => console.log(res),
  // 		err => console.log(err)
  // 		)
  // }
  private all_stages(): any {
  	console.log(this.recruitment_stages)
    // var as = {
    //       stage: [''],
    //       stage_type_id: [''],
    //       collaborators: ['']
    //   }
    var a =[]
    for (let rs of this.recruitment_stages) {
      a.push(this.fb.group({
      	  stage_id: [rs['id']],
          stage: [rs['name']],
          stage_type: [rs['stage_type']],
          can_delete_stage: [rs['can_delete_stage']]
      }))
    }

    return a;
  }
  add_stage(i){
  	this.insert_stage = true;
  	this.insertStageForm.patchValue({i:i});


  }
  
  
   

}

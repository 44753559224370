import { Component, OnInit , ViewChild} from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl} from'@angular/forms';
import { throwError, of, empty } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TagInputComponent as SourceTagInput } from 'ngx-chips';
import {ModalsService} from '../../../layout/modals/modals.service';
import {NavbarService} from '../../../layout/header/navbar.service';



// https://stackblitz.com/edit/ngx-chips-example-p8jsyz?file=app%2Fshared%2Ftag-input%2Ftag-input.component.ts
@Component({
  selector: '[app-invite-users]',
  templateUrl: './invite-users.component.html',
  styleUrls: ['./invite-users.component.scss']
})
export class InviteUsersComponent implements OnInit {
  @ViewChild('tagInput', {static: false})
  tagInput: SourceTagInput;
  team_mates =[];
  vendors = [];
  clients = [];
  pending_team_mates =[];
  pending_vendors = [];
  pending_clients = [];
  pending_recruiter_friends = []
  is_send_email_selected = false;
  invite_vendor_active=false
  invite_client_active=false
  invite_referral_active = false;
  user_roles_within_company:any;
  inviteTeamForm=this.fb.group({
    user_type: [''],
    email: [''],
    custom_message: [''],
    user_role: ['']
    
  });
  inviteVendorForm=this.fb.group({
    user_type: [''],
    email: [''],
    custom_message: ['']
    
  });
  inviteClientForm=this.fb.group({
    user_type: [''],
    email: [''],
    custom_message: ['']
    
  });
  inviteRecruiterFriendForm=this.fb.group({
    user_type: [''],
    email: [''],
    custom_message: ['']
    
  });
  // default_user_role_id:any;
  current_user_detail = {};
  clicked_save=false;
  // email_list = ['']
  public validators = [ this.must_be_email.bind(this) ];
  public errorMessages = {
    'must_be_email': 'Please be sure to use a valid email format'
  };

  public readonly onAddedFunc = this.beforeAdd.bind(this);

  private addFirstAttemptFailed = false;

  private must_be_email(control: FormControl) {        
    if (this.addFirstAttemptFailed && !this.validateEmail(control.value)) {
      return { "must_be_email": true };
    }
    return null;
  }
  constructor(
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private modal: ModalsService,
    private navbar: NavbarService
    ) { }

  ngOnInit() {
    this.authService.get('/api/user_roles_within_company').subscribe(
        res=> {
          this.user_roles_within_company = res['user_roles_within_company'];
          // this.default_user_role_id=res['default_id']
          this.inviteTeamForm.patchValue({
            user_role:  res['default_id']
          })
        },
        err=> {console.log(err)}
      )

    this.authService.get('/api/current_user_details').subscribe(
      res => {
        this.current_user_detail = res;
        this.inviteTeamForm.patchValue({
          user_type: 'teammate' ,
          custom_message: 'This is ' + this.current_user_detail['full_name']+'. Please follow the link below to start collaborating on Tobu: a system to manage our internal database of resumes.' 
        })
        this.inviteVendorForm.patchValue({
          user_type: 'vendor' ,
          custom_message: 'This is ' + this.current_user_detail['full_name'] +' from ' + this.current_user_detail['company_name'] +'. Please follow the link below to start collaborating on Tobu. I can now share jobs through Tobu and you can now send resumes through the system as well.' 
        })
        this.inviteClientForm.patchValue({
          user_type: 'client' ,
          custom_message: 'This is ' + this.current_user_detail['full_name'] +' from ' + this.current_user_detail['company_name'] +'. I am currently using Tobu to upload and search through my own resumes. In addition, Tobu automatically picks out the candidate details(contact details, locations etc.) from resumes and can be used to extract into excel sheets and cutting down on data entry as well. I recommend that you try Tobu by accessing the link below.' 
        })
        this.inviteRecruiterFriendForm.patchValue({
          user_type: 'recruiter_friend' ,
          custom_message: 'This is ' + this.current_user_detail['full_name'] +' from ' + this.current_user_detail['company_name'] +'. I am currently using Tobu to upload and search through my own resumes. In addition, Tobu automatically picks out the candidate details(contact details, locations etc.) from resumes and can be used to extract into excel sheets and cutting down on data entry as well. I recommend that you try Tobu by accessing the link below.'
        })
      }
    )
    this.refresh_invite_list();
  }
  invite_vendors(){
    this.invite_vendor_active=true;
    this.invite_client_active=false;
    this.invite_referral_active=false;
  }
  invite_clients(){
    this.invite_vendor_active=false;
    this.invite_client_active=true;
    this.invite_referral_active=false;
  }
  invite_referrals(){
    this.invite_vendor_active=false;
    this.invite_client_active=false;
    this.invite_referral_active=true;
  }
  refresh_invite_list(){
    this.authService.get('/api/get_invitation_details').subscribe(
      res => {
        console.log(res);
        this.team_mates = res['team_mates'];
        this.vendors = res['vendors'];
        this.clients = res['clients'];
        this.pending_team_mates =res['pending_team_mates'];
        this.pending_vendors = res['pending_vendors'];
        this.pending_clients = res['pending_clients'];
        this.pending_recruiter_friends = res['pending_recruiter_friends']
      },
      err => {
        console.log(err)
      }
    )
  }
  // onChangeUserType($ev){
  //   if ($ev.target.value === "teammate"){
  //     this.inviteUsersForm.patchValue({
  //       custom_message: 'This is ' + this.current_user_detail['full_name']+'. Please follow the link below to start collaborating on Tobu: a system to manage our internal database of resumes.' 
  //     })
  //   }
  //   else if ($ev.target.value === "vendor"){
  //     this.inviteUsersForm.patchValue({
  //       custom_message: 'This is ' + this.current_user_detail['full_name'] +' from ' + this.current_user_detail['company_name'] +'. Please follow the link below to start collaborating on Tobu. I can now share jobs through Tobu and you can now send resumes through the system as well.' 
  //     })
  //   }
  //   else if ($ev.target.value === "recruiter_friend"){
  //     this.inviteUsersForm.patchValue({
  //       custom_message: 'This is ' + this.current_user_detail['full_name'] +' from ' + this.current_user_detail['company_name'] +'. I am currently using Tobu to upload and search through my own resumes. In addition, Tobu automatically picks out the candidate details(contact details, locations etc.) from resumes and can be used to extract into excel sheets and cutting down on data entry as well. I recommend that you try Tobu by accessing the link below.' 
  //     })
  //   }
  // }
  submitInviteTeamForm($ev, value: any) {
        // console.log("submitted");
        $ev.preventDefault();
        for (let c in this.inviteTeamForm.controls) {
            this.inviteTeamForm.controls[c].markAsTouched();
        }
        // console.log(value);
        this.clicked_save=true;
          
            
            this.authService.post('/api/send_invitation', {
              emails: value['email'].map((x, i, a) => {return x.value}),
              custom_message: value.custom_message,
              access_role_id: value.user_role
            }).subscribe(
              res => {
                  // console.log(res);
                  this.clicked_save=false;
                  this.navbar.set_notification('Success','Sent invitation', 'The invitation has been sent');
                  this.modal.hide_modal_by_name('invite_users');
                  //refresh input
                  // this.router.navigate(['/account/login']);
              }, error => {
                  // console.log(error);
              }
            )
          
    }
    add_teammate(_email){
      this.authService.post('/api/send_invitation', {
              emails: [_email],
              custom_message: 'This is ' + this.current_user_detail['full_name']+'. Please follow the link below to start collaborating on Tobu: a system to manage our internal database of resumes.'
            }).subscribe(
              res => {
                  // console.log(res);
                  this.clicked_save=false;
                  this.navbar.set_notification('Success','Sent invitation', 'The invitation has been sent');
                  //refresh email list
                  this.refresh_invite_list();
                  // this.router.navigate(['/account/login']);
              }, error => {
                  // console.log(error);
              }
            )
    }

  submitInviteRecruiterFriendForm($ev, value: any) {
        // console.log("submitted");
        $ev.preventDefault();
        for (let c in this.inviteTeamForm.controls) {
            this.inviteTeamForm.controls[c].markAsTouched();
        }
        // console.log(value);
        this.clicked_save=true;
          

            this.authService.post('/api/send_referral_company_invitation', {
              emails: value['email'].map((x, i, a) => {return x.value}),
              custom_message: value.custom_message
            }).subscribe(
              res => {
                  // console.log(res);
                  this.clicked_save=false;
                  this.navbar.set_notification('Success','Sent invitation', 'The invitation has been sent');
                  this.modal.hide_modal_by_name('invite_users');
                  // this.router.navigate(['/account/login']);
              }, error => {
                  // console.log(error);
              }
            )
    }
    submitInviteClientForm($ev, value: any) {
        // console.log("submitted");
        $ev.preventDefault();
        for (let c in this.inviteTeamForm.controls) {
            this.inviteTeamForm.controls[c].markAsTouched();
        }
        // console.log(value);
        this.clicked_save=true;
            
            this.authService.post('/api/send_client_invitation', {
              emails: value['email'].map((x, i, a) => {return x.value}),
              custom_message: value.custom_message
            }).subscribe(
              res => {
                  // console.log(res);
                  this.clicked_save=false;
                  this.navbar.set_notification('Success','Sent invitation', 'The invitation has been sent');
                  this.modal.hide_modal_by_name('invite_users');
                  // this.router.navigate(['/account/login']);
              }, error => {
                  // console.log(error);
              }
            )
          
    }
    add_vendor(_email){
      this.authService.post('/api/send_agency_invitation', {
              emails: [_email],
              custom_message: 'This is ' + this.current_user_detail['full_name'] +' from ' + this.current_user_detail['company_name'] +'. Please follow the link below to start collaborating on Tobu. I can now share jobs through Tobu and you can now send resumes through the system as well.' 
            }).subscribe(
              res => {
                  // console.log(res);
                  this.clicked_save=false;
                  this.navbar.set_notification('Success','Sent invitation', 'The invitation has been sent');
                  //refresh email list
                  this.refresh_invite_list();
                  // this.router.navigate(['/account/login']);
              }, error => {
                  // console.log(error);
              }
            )
    }
    submitInviteVendorForm($ev, value: any) {
        // console.log("submitted");
        $ev.preventDefault();
        for (let c in this.inviteTeamForm.controls) {
            this.inviteTeamForm.controls[c].markAsTouched();
        }
        // console.log(value);
        this.clicked_save=true;
          
              
            this.authService.post('/api/send_agency_invitation', {
              emails: value['email'].map((x, i, a) => {return x.value}),
              custom_message: value.custom_message
            }).subscribe(
              res => {
                  // console.log(res);
                  this.clicked_save=false;
                  this.navbar.set_notification('Success','Sent invitation', 'The invitation has been sent');
                  this.modal.hide_modal_by_name('invite_users');
                  // this.router.navigate(['/account/login']);
              }, error => {
                  // console.log(error);
              }
            )
          
    }
    add_client(_email){
      this.authService.post('/api/send_client_invitation', {
              emails: [_email],
              custom_message: 'This is ' + this.current_user_detail['full_name'] +' from ' + this.current_user_detail['company_name'] +'. I am currently using Tobu to upload and search through my own resumes. In addition, Tobu automatically picks out the candidate details(contact details, locations etc.) from resumes and can be used to extract into excel sheets and cutting down on data entry as well. I recommend that you try Tobu by accessing the link below.' 
            }).subscribe(
              res => {
                  // console.log(res);
                  this.clicked_save=false;
                  this.navbar.set_notification('Success','Sent invitation', 'The invitation has been sent');
                  //refresh email list
                  this.refresh_invite_list();
                  // this.router.navigate(['/account/login']);
              }, error => {
                  // console.log(error);
              }
            )
    }
    remove_from_list(_email){
      //placeholder for remove from list api
      this.authService.post('/api/remove_email_from_invite_list', {'email': _email}).subscribe(
        res=>{
          console.log(res);
          this.navbar.set_notification('Success','Notice', res['notice']);
                  //refresh email list
          this.refresh_invite_list();
        },
        err => {
          console.log(err)
        }
      )
    }
  private beforeAdd(tag: string) {

    if (!this.validateEmail(tag)) {
      if (!this.addFirstAttemptFailed) {
        this.addFirstAttemptFailed = true;
        this.tagInput.setInputValue(tag);
      }
      return throwError(this.errorMessages['must_be_email']);
    }
    this.addFirstAttemptFailed = false;
    return of(tag);
  }
  private validateEmail(text: string) {
    // var EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/i;
    var EMAIL_REGEXP = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return (text && EMAIL_REGEXP.test(text));
  }
  send_email_changed($ev){

    this.is_send_email_selected = $ev.target.checked;

  }
  cancel_invite(_invite_id, _invite_type){
    this.authService.post('/api/cancel_invite', {'user_id': _invite_id, 'invite_type': _invite_type}).subscribe(
      res=> {
        console.log(res);
        this.navbar.set_notification('Success','Notice', res['notice']);
        //refresh email list
        this.refresh_invite_list();
      },
      err=> console.log(err)
    )
  }
  resend_invite(_invite_id, _invite_type){
    // this.modal.load_modal_by_name('spinner', {});
    this.authService.post('/api/resend_invite', {'user_id': _invite_id, 'invite_type': _invite_type}).subscribe(
      res=> {
        this.navbar.set_notification('Success','Notice', res['notice']);
        //refresh email list
        this.refresh_invite_list();
        // this.modal.hide_modal_by_name('spinner');
        console.log(res)
      },
      err=> console.log(err)
    )
  }
}

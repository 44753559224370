import { Component, OnInit , ViewChild, ElementRef} from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from'@angular/forms';
import { AuthenticationService } from '../../../../core/authentication/authentication.service'
import { SharedService } from '../../../../core/shared/shared.service';

@Component({
  selector: '[app-seniority]',
  templateUrl: './seniority.component.html',
  styleUrls: ['./seniority.component.scss']
})
export class SeniorityComponent implements OnInit {
  @ViewChild('updateSeniority', {static: false}) updateSeniority;
  bsValue = new Date();
  bsConfig = {
      containerClass: 'theme-angle'
  }
  updateSeniorityForm=this.fb.group({
	    start_date: ['', Validators.required],
	    end_date: ['', Validators.required],
	    date_unit: 'day'
	});
    barData: [{
		"label": "Resume Count",
		"color": "#1E973B",
	    "data": [
	        ["Senior", 20],
	        ["Entry", 13],
	        ["Mid", 85],
	        ["Executive", 29]
	    ]
	}];
    barOptions = {
        series: {
            bars: {
                align: 'center',
                lineWidth: 0,
                show: true,
                barWidth: 0.6,
                fill: 0.9
            }
        },
        grid: {
            borderColor: '#eee',
            borderWidth: 1,
            hoverable: true,
            backgroundColor: '#fcfcfc'
        },
        tooltip: true,
        tooltipOpts: {
            content: function(label, x, y) { return x + ' : ' + y; }
        },
        xaxis: {
            tickColor: '#fcfcfc',
            mode: 'categories'
        },
        yaxis: {
            // position: ($scope.app.layout.isRTL ? 'right' : 'left'),
            minTickSize:1,
            min: 0,
            tickColor: '#eee'
        },
        shadowSize: 0
    };
    lineData = [{
		"label": "exp1",
		"color": "#5ab1ef",
	    "data": [
	        ["6 Mar 2019", 20],
	        ["7 Mar 2019", 13],
	        ["8 Mar 2019", 85],
	        ["9 Mar 2019", 29]
	    ]
	},{
		"label": "exp2",
		"color": "#5314ef",
	    "data": [
	        ["6 Mar 2019", 22],
	        ["7 Mar 2019", 54],
	        ["8 Mar 2019", 45],
	        ["9 Mar 2019", 13]
	    ]
	}];
    lineOptions = {
        series: {
            lines: {
                show: true,
                fill: 0.01
            },
            points: {
                show: true,
                radius: 1
            }
        },
        grid: {
            borderColor: '#eee',
            borderWidth: 1,
            hoverable: true,
            backgroundColor: '#fcfcfc'
        },
        tooltip: true,
        tooltipOpts: {
            content: function(label, x, y) { return x + ' : ' + y; }
        },
        xaxis: {
            tickColor: '#eee',
            mode: "categories", 
      		// timeformat:"%y/%m/%d"
        },
        yaxis: {
            // position: ($scope.app.layout.isRTL ? 'right' : 'left'),
            minTickSize:1,
            min: 0,
            tickColor: '#eee'
        },
        shadowSize: 0
    };

  constructor(
  	private authService: AuthenticationService,
  	private fb: FormBuilder,
  	private shared: SharedService,) { }
  ngOnInit() {
  	var d = new Date();
 	d.setDate(d.getDate()-14000);
  	this.updateSeniorityForm.patchValue({
        start_date: d,
        end_date: new Date(),
    });
    setTimeout(()=>{
      this.updateSeniority.nativeElement.click();
    }, 2000)
  }
  choose_date_unit(val){
  	this.updateSeniorityForm.patchValue({
        date_unit: val 
      })
  }
  submitUpdateSeniorityForm($ev, value){
  	$ev.preventDefault();
  	this.authService.post('/api/update_seniority', {
      'end': this.shared.convert_date_to_string(value.end_date),
      'start': this.shared.convert_date_to_string(value.start_date),
      'date_unit': value.date_unit
    }).subscribe(
      res => {
      	console.log(res)
		  this.lineData = res['data'];
		  this.barData = [{
		    "label": "Resume Count",
		    "color": "#1E973B",
		    "data": res['totals']
		  }];
      },
      err=> console.log(err)
    )
  }
}
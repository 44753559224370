import { Component, OnDestroy } from '@angular/core';
import { Params, Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService} from '../../../../core/authentication/authentication.service'
import { Subscription }   from 'rxjs';
import {NavbarService} from '../../../../layout/header/navbar.service'
import { SharedService } from '../../../../core/shared/shared.service';
import {saveAs} from "file-saver";
import { CandidateService } from '../candidate.service';
import {ModalsService} from '../../../../layout/modals/modals.service'
const swal = require('sweetalert');

@Component({
  selector: '[app-viewresume]',
  templateUrl: './viewresume.component.html',
  styleUrls: ['./viewresume.component.scss']
})
export class ViewresumeComponent implements OnDestroy {

  plo: any ;
  rcar: any;
  uar: any;
  loading = true;
  resume_url: any;
  doc_url: any;
  uploaded_resume_id: string;
  generated_params ={};
  file_name =''
  resume_email:any;
  show_duplicates=false;
  // yt= '<%#= @uploaded_resume.resume_source.expiring_url(15)%>&embedded=true" width="100%" height="800" >'

  subscription: Subscription;
  sub1: Subscription;
  sub2: Subscription;
  sub3:Subscription;
  sub4:Subscription;
  sub5:Subscription;
  resume_temp_url='';
  resume_duplicates = [];
  resume_original_url = ''
  resume_duplicate_url = '';
  duplicate_resume_id = ''
  viewer_type = 'office'
  resume_content_type = ''
  resume_uploader ='';
  cv_source = '';
  received_on = '';
  constructor(
  	private authService: AuthenticationService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private navbar: NavbarService,
    private shared: SharedService,
    private candidate: CandidateService,
    private modal: ModalsService
    ) { }

  ngOnInit() {
  	this.get_restrictions()
  	this.get_plo();
    // this.modal.load_modal_by_name('candidate', {});
  }

  ngOnDestroy(){
    console.log('des sub view')
  	this.subscription.unsubscribe;
    this.sub1.unsubscribe;
    this.sub2.unsubscribe;
    this.sub3.unsubscribe;
    // this.sub4.unsubscribe;
    // this.sub5.unsubscribe;
    // this.modal.hide_modal_by_name('candidate');
  }

  get_plo(){
  	// var url_split = this.router.url.split('/');
  	// var candidate_id = url_split[url_split.length-2];
  	// console.log(candidate_id);
    // this.generated_params = this.candidate.get_params_from_url(this.router.url);
    // if(this.router.url.includes('inbox')){
  	 //  this.authService.post('/api/get_resume_view_for_resume', this.generated_params).subscribe(
    //     res => {
    //       this.plo =  res
    //       this.resume_url = '<iframe src="https://docs.google.com/viewer?url='+this.plo.resume_url+'&embedded=true" width="100%" height="800">';
    //       this.uploaded_resume_id = this.plo.uploaded_resume_id;
    //       this.file_name = res['file_name'];
    //       console.log(res);
    //     }, error => {
    //       console.log(error);
    //     }
    //   )
    // }
    // else{
    //   this.authService.post('/api/get_resume_view_for_resume_report', this.generated_params).subscribe(
    //     res => {
    //       this.plo =  res
    //       this.resume_url = '<iframe src="https://docs.google.com/viewer?url='+this.plo.resume_url+'&embedded=true" width="100%" height="800">';
    //       this.uploaded_resume_id = this.plo.uploaded_resume_id
    //       console.log(res);
    //     }, error => {
    //       console.log(error);
    //     }
    //   )
    // }
    // this.load_view();
    // this.sub1 = this.router.events.pipe(
    // filter(event => event instanceof NavigationEnd)  
    // ).subscribe((event: NavigationEnd) => {
    //   this.load_view();
    //   console.log(event.url);
    // });    
    this.generate_resume_iframe();
    this.sub1 = this.route.queryParams.subscribe(
      res=> {
        this.loading=true
        console.log(this.route.snapshot.queryParams)
        if(this.route.snapshot.queryParams['open_result']===undefined){this.sub1.unsubscribe()}
        else{
          if(res['notice']==='Access Denied')
          {
            this.router.navigate(['/candidate_inbox'])
          }
          this.generate_resume_iframe();
          this.load_view();
        }
      }
    )  
    // this.sub1 = this.route.params.subscribe(
    //   res=> {
    //     if(res['notice']==='Access Denied')
    //       {
    //         this.router.navigate(['/candidate_inbox'])
    //       }
    //     this.load_view();
    //   }
    // )    
  }

  load_view(){
    this.sub3 = this.modal.get_resume_temp_url()
    .subscribe(
      res=> {
        console.log(res);
        // this.resume_url = 'https://docs.google.com/viewer?url=' +res+'&embedded=true';
        this.resume_url = res
      },
      // err=> console.log(err)
    )
    this.sub2=this.fetch_view()
      .subscribe(
        res => {
          // this.plo =  res
          // this.resume_url = 'https://docs.google.com/viewer?url=' +res['resume_url']+'&embedded=true';
          this.uploaded_resume_id = res['uploaded_resume_id'];
          this.file_name = res['file_name'];
          this.resume_duplicates = res['resume_duplicates']
          this.cv_source = res['cv_source']
          this.resume_uploader = res['resume_uploader']
          this.received_on = res['received_on']
          if (res['resume_email']!=undefined && res['resume_email']!=null){this.resume_email=res['resume_email']}
          console.log(res);
          this.loading = false;
        }, error => {
          // console.log(error);
        }
      )
  }
  fetch_view(){
    this.generated_params = this.candidate.get_params_from_url(this.router.url);
    // console.log(this.generated_params)
    if(this.router.url.includes('inbox')){
      return this.authService.post('/api/get_resume_view_for_resume', this.generated_params)
    }
    else
    {
      return this.authService.post('/api/get_resume_view_for_resume_report', this.generated_params)
    }
  }
  get_restrictions(){
    this.subscription= this.shared.current_restrictions$.subscribe(
        res => {
          this.rcar = res["rcar"];
          this.uar = res["uar"]
        }
    );
  }
  download_resume(_file_name){
  	// console.log('downloading');
    console.log(this.uploaded_resume_id)
  	this.authService.downloadFile('/api/download_resume', {id: this.uploaded_resume_id}).subscribe(blob => {
            console.log(blob)
            saveAs(blob, _file_name);
        }, error => {
              // console.log(error);
        }
    )
  }
  open_duplicate(_id){
    this.resume_url =undefined;
    this.duplicate_resume_id = _id
    // this.authService.post('/api/get_duplicate_view_for_resume', {id: _id}).subscribe(
    this.authService.post('/api/get_duplicate_iframe_for_resume', {id: _id}).subscribe(
       res=> {
        this.resume_temp_url = res['resume_url'];
        this.set_temp_url(this.resume_temp_url);
        this.resume_duplicate_url = this.resume_temp_url
        console.log(res)
      },
      err=> console.log(err)
      )
  }
  mark_as_main_copy(_id){
    // this.shared.refresh(this.router.url);
    
    this.sub4 = this.authService.post('/api/mark_resume_as_main_copy', {id: _id}).subscribe(
       res=> {
        this.navbar.set_notification('Success','Notice', res['notice']);
        // this.ngOnInit();
        // setTimeout(function(){ location.reload(); }, 1000);
        // this.shared.refresh(this.router.url);
        this.get_plo();
        this.load_view();
        this.sub4.unsubscribe()
        console.log(res)
      },
      err=> console.log(err)
      )
  }
  delete_duplicate(_id){
    swal({
          title: 'Are you sure?',
          text: 'Your will not be able to recover the copy of the duplicate resume!',
          icon: 'warning',
          buttons: {
              cancel: {
                  text: 'No, cancel please!',
                  value: null,
                  visible: true,
                  className: "",
                  closeModal: true
              },
              confirm: {
                  text: 'Yes, delete it!',
                  value: true,
                  visible: true,
                  className: "bg-danger",
                  closeModal: true
              }
          }
      }).then((isConfirm) => {
          
          if (isConfirm) {
                this.sub5 = this.authService.post('/api/delete_duplicate_resume', {id: _id}).subscribe(
                  res=> {
                    
                    console.log(res);
                    this.navbar.set_notification('Success','Notice', res['notice']);
                    // this.ngOnInit()
                    // setTimeout(function(){ location.reload(); }, 1000);
                    // this.shared.refresh(this.router.url);
                    this.get_plo();
                    this.load_view();
                    this.sub5.unsubscribe()
                  },
                  err=> console.log(err)
                  )
              
          } else {
              swal('Cancelled', 'Your Resume copy was not deleted', 'error');
          }
      });

    
  }

  open_original_resume(){
    this.resume_temp_url = this.resume_original_url
    this.set_temp_url(this.resume_temp_url);
    this.duplicate_resume_id = ''
  }
  set_temp_url(_url){
    this.modal.set_resume_temp_url(_url);
  }
  generate_resume_image(){
    let params = this.candidate.get_params_from_url(this.router.url);
    this.authService.post('/api/generate_image', params).subscribe(
      res=> {
        this.resume_temp_url = res['resume_url'];
        this.set_temp_url(this.resume_temp_url);
        this.resume_original_url = this.resume_temp_url;
        console.log(this.resume_temp_url)
      },
      err=> console.log(err)
      )
  }

  generate_resume_iframe(){
    let params = this.candidate.get_params_from_url(this.router.url);
    this.authService.post('/api/generate_iframe', params).subscribe(
      res=> {
        console.log(res)
        this.resume_temp_url = res['resume_url'];
        this.resume_content_type = res ['content_type']
        // this.resume_temp_url = 'https://sgp1.digitaloceanspaces.com/tobu-temp/tobu_public_documents/dpa.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=HMSIRSM5XXYNKVXDES4R%2F20220403%2Fsgp1%2Fs3%2Faws4_request&X-Amz-Date=20220403T201408Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=bd0156db6c9dc42ca55da54019f08d3ce0d13c3505cb21eb090350485db13ef1';
        // this.resume_temp_url = 'https://sgp1.digitaloceanspaces.com/tobu-temp/uploaded_resumes/resume_sources/633/original/Mahipalreddy_M_185674_BA3_Core_java_developer_pune_shilpi.docx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=HMSIRSM5XXYNKVXDES4R%2F20220403%2Fsgp1%2Fs3%2Faws4_request&X-Amz-Date=20220403T201524Z&X-Amz-Expires=21600&X-Amz-SignedHeaders=host&X-Amz-Signature=8a4d04bdbaafd9bad22130b8f83623bcbfc478b8f9a8de9545b7881e9ab58ebf';
        if(this.resume_temp_url.toLowerCase().includes(".pdf") || this.resume_temp_url.toLowerCase().includes(".rtf")){this.viewer_type='google'}
        else if(this.resume_temp_url.toLowerCase().includes(".htm")){this.viewer_type='iframe'}
        else if(this.resume_temp_url.toLowerCase().includes(".doc") || this.resume_temp_url.toLowerCase().includes(".docx")) {this.viewer_type='office'}
        else if(this.resume_content_type.toLowerCase().includes("pdf") || this.resume_content_type.toLowerCase().includes("rtf")) {this.viewer_type='google'}
        else if(this.resume_content_type.toLowerCase().includes("htm")){this.viewer_type='iframe'}
        this.set_temp_url(this.resume_temp_url);
        this.resume_original_url = this.resume_temp_url;
        console.log(this.resume_temp_url)
      },
      err=> console.log(err)
      )
  }
  export_standardized_template(){
    this.modal.load_modal_by_name('export_standardized_template', {});
  }
  
}
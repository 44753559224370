import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl} from'@angular/forms';
import { JobsService } from '../../jobs/jobs.service'
import { ModalsService } from '../../../layout/modals/modals.service';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service';
import 'rxjs/add/operator/switchMap'; 
import 'rxjs/add/operator/map';

@Component({
  selector: '[app-candidate-collaborators]',
  templateUrl: './candidate-collaborators.component.html',
  styleUrls: ['./candidate-collaborators.component.scss']
})
export class CandidateCollaboratorsComponent implements OnInit {
  collaborators = [];
  current_collaborators =[];
    valForm = this.fb.group(
  {
    collaborator_ids: this.fb.array([]),
  })
  concat_params ={};
  clicked_save =false;
  constructor(
  	private authService: AuthenticationService,
  	private route: ActivatedRoute,
  	private router: Router,
  	private fb: FormBuilder,
    private jobs: JobsService,
    private modal: ModalsService,
    private shared: SharedService,
    private navbar: NavbarService
  	) { }

  ngOnInit() {

  	this.modal.candidate_collaborators_modal_id$.map(
  		params=> {
  			// console.log(params);
  			this.concat_params = params['params']
  			return params['params']
  		}
  	).switchMap(
  		res => {return this.authService.post('/api/load_candidate_collaborators_modal', res)}
  	).subscribe(
  		res => {
  			// console.log(res);
  			this.collaborators = res['all_possible_collaborators'];
  			this.current_collaborators = res['current_collaborators'];
  			// this.valForm.patchValue({
     //           collaborator_ids: this.current_collaborators
   		// 	})
   			this.valForm.setControl('collaborator_ids', this.fb.array(this.populate_collabs() || []));
  		},
  		// err => console.log(err)
  	)
  }
  onCheckChange($event){
    this.shared.onCheckChange($event, this.valForm ,'collaborator_ids');
  }
  submitForm($ev, value: any) {
    // console.log(value);
    $ev.preventDefault();
    for (let c in this.valForm.controls) {
        this.valForm.controls[c].markAsTouched();
    }

    if (this.valForm.valid) {
        this.clicked_save =true;
        this.authService.post(
          '/api/set_collaborators_for_candidate', this.shared.merge_options(value, this.concat_params)
          ).subscribe(
          res => {
              // console.log(res);
              this.navbar.set_notification('Success','Collaborators set', 'The collaborators have been updated');
              // this.modal.hide_modal('small');
              this.clicked_save =false;
          }, error => {
              // console.log(error);
          }
        )
    }
  }
  private populate_collabs(): any {
    var a =[]
    for (let c of this.current_collaborators) {
      a.push(c)
    }
    return a;
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ImageCropperComponent, CropperSettings, Bounds } from 'ngx-img-cropper';
// import { FileUploader , FileUploaderOptions} from 'ng2-file-upload';
import { AuthenticationService} from '../../../core/authentication/authentication.service';
import { NavbarService } from '../../../layout/header/navbar.service';
import { ModalsService } from '../../../layout/modals/modals.service'



@Component({
  selector: '[app-edit-logo]',
  templateUrl: './edit-logo.component.html',
  styleUrls: ['./edit-logo.component.scss']
})
export class EditLogoComponent implements OnInit {
	name: string;
    // data1: any;
    data2:any;
    // cropperSettings: CropperSettings;
    cropperSettings2: CropperSettings;
    croppedImage:any;
    banner_file: any;
    // image_blob:any;
    image_blob2:any;
    // banner_url: any; 
    logo_url: any;
    edit_logo = true;
    edit_banner = true;
    contents='';

  	// @ViewChild('cropper', undefined) cropper: ImageCropperComponent;
  	@ViewChild('cropper2', {static: false}) cropper2: ImageCropperComponent;

    constructor(
        private authService: AuthenticationService,
        private navbar: NavbarService,
        private modal: ModalsService,

        ) {
        this.name = 'Angular2';
        this.cropperSettings2 = new CropperSettings();

        this.cropperSettings2.noFileInput = true;

        this.cropperSettings2.width = 425;
        this.cropperSettings2.height = 425;

        this.cropperSettings2.croppedWidth = 425;
        this.cropperSettings2.croppedHeight = 425;

        this.cropperSettings2.canvasWidth = 425;
        this.cropperSettings2.canvasHeight = 425;

        this.cropperSettings2.minWidth = 106.25;
        this.cropperSettings2.minHeight = 106.25;

        this.cropperSettings2.cropperDrawSettings.strokeColor = 'rgba(0,0,0,.25)';
        this.cropperSettings2.cropperDrawSettings.strokeWidth = 2;

        this.cropperSettings2.rounded = false;

        this.data2 = {};
    }

  ngOnInit() {
  }
  fileChangeListener2($event) {
        let image: any = new Image();
        let file: File = $event.target.files[0];
        let myReader: FileReader = new FileReader();
        let that = this;
        myReader.onloadend = function(loadEvent: any) {
            image.src = loadEvent.target.result;
            that.cropper2.setImage(image);
        };
        this.banner_file=file;
        console.log(image);
        console.log(file);
        console.log(myReader);

        myReader.readAsDataURL(file);
    }

     save_logo(){
    // this.convert_b64_to_blob(this.data1.image)
        fetch(this.data2.image)
          .then((response) => {
            return response.blob();
          })
          .then((data) => {
            console.log(data);
            this.image_blob2=data;
            let formData = new FormData(); 
            formData.append('file', this.image_blob2, 'logo'); 
            console.log(formData);
            //load spinner
            // this.modal.load_modal_by_name('spinner', {});
            this.authService.post_html('/api/save_logo_for_recruitment_company', formData).subscribe(
              (val) => {
                console.log(val);
                // this.modal.hide_modal_by_name('spinner');
                // hide spinner
                // this.load_images();
                this.navbar.set_notification('Success','Notice', val['notice']);
                // setTimeout(function(){ location.reload(); }, 1000);
              });
            return false; 
          });
    
    }
    setRoundedMethod(value: boolean) {
        this.cropperSettings2.rounded = value;
    }

    cropped(bounds: Bounds) {
        console.log(bounds);
        // console.log(this.croppedImage);
        console.log(this.data2);
        // this.save();
    }
    imageCropped($ev){
        this.croppedImage = $ev.base64;
        // this.save();
    }

}

import { Component, OnInit , OnDestroy} from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params} from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators} from'@angular/forms';
import { ModalsService } from '../../../layout/modals/modals.service';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service'
import { Subscription } from 'rxjs';

@Component({
  selector: '[app-create-tag]',
  templateUrl: './create-tag.component.html',
  styleUrls: ['./create-tag.component.scss']
})
export class CreateTagComponent implements OnInit {
  constructor(
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private modal: ModalsService,
    private shared: SharedService,
    private navbar: NavbarService,
    ) { }
  clicked_save=false;
  tagForm = new FormGroup({
      tag_colour: new FormControl('#4285F4'), // Initial color
      tag: new FormControl('', Validators.required) // Initial value for tag_name
    });
  // tag_candidates = this.fb.group({
  //   tags: this.fb.array([], Validators.required)
  // });
  defaultColors: string[] = ['#E91E63', '#9C27B0', '#673AB7', '#3F51B5', '#339FF4', '#03A9F4', '#99D5FC', '#00BCD4', '#009688', '#43BF48', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#F5A623', '#FF9800', '#F44336'];

  ngOnInit() {
  }
  onSubmit() {
    this.clicked_save=true;
    console.log('Form Value:', this.tagForm.value);
    // Handle form submission
    this.authService.post('/api/create_tag', this.tagForm.value)
      .subscribe(
        res => {
          // console.log(res);
          this.clicked_save =false;
          this.navbar.set_notification('Success','Created Tag', 'The tag was successfully created');
          this.modal.hide_modal_by_name('create_tag')
        },
        // err => console.log(err)
      )
    
  }
}
import { Component, OnInit , ViewChild, ElementRef} from '@angular/core';
import { AuthenticationService} from '../../../core/authentication/authentication.service'
import {Router, ActivatedRoute, Params} from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ModalsService } from '../../../layout/modals/modals.service'
import { Observable} from 'rxjs';
import { SharedService } from '../../../core/shared/shared.service';
import { environment } from '../../../../environments/environment'

@Component({
  selector: '[app-search]',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
    @ViewChild('submitSearch', {static: false}) submitSearch;
    @ViewChild('submitEmailSearch', {static: false}) submitEmailSearch;
    @ViewChild('submitCandidateSearch', {static: false}) submitCandidateSearch;
    @ViewChild('submitEmailCampaignSearch', {static: false}) submitEmailCampaignSearch;
    @ViewChild('or_keywords', {static: false}) or_keywords;
    @ViewChild('and_keywords', {static: false}) and_keywords;
    @ViewChild('not_keywords', {static: false}) not_keywords;
    @ViewChild('keywordsElement') keywordsElement: ElementRef;
    @ViewChild('contactInfoElement') contactInfoElement: ElementRef;
    @ViewChild('candidateHistoryElement') candidateHistoryElement: ElementRef;
    @ViewChild('otherResumeInfoElement') otherResumeInfoElement: ElementRef;
    @ViewChild('tobuDataElement') tobuDataElement: ElementRef;
    @ViewChild('incomingEmailElement') incomingEmailElement: ElementRef;
    @ViewChild('tobuEmailCampaignsElement') tobuEmailCampaignsElement: ElementRef;

    scrollTo(elementRef: any): void {
      // elementRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
      if (elementRef === 'keywordsElement'){this.keywordsElement.nativeElement.scrollIntoView({behavior: 'smooth' })}
      if (elementRef === 'contactInfoElement'){this.contactInfoElement.nativeElement.scrollIntoView({behavior: 'smooth' })}
      if (elementRef === 'candidateHistoryElement'){this.candidateHistoryElement.nativeElement.scrollIntoView({behavior: 'smooth' })}
      if (elementRef === 'otherResumeInfoElement'){this.otherResumeInfoElement.nativeElement.scrollIntoView({behavior: 'smooth' })}
      if (elementRef === 'tobuDataElement'){this.tobuDataElement.nativeElement.scrollIntoView({behavior: 'smooth' })}
      if (elementRef === 'incomingEmailElement'){this.incomingEmailElement.nativeElement.scrollIntoView({behavior: 'smooth' })}
      if (elementRef === 'tobuEmailCampaignsElement'){this.tobuEmailCampaignsElement.nativeElement.scrollIntoView({behavior: 'smooth' })}

      
    }
    // and_keywords: any;
    // or_keywords:any;
    // not_keywords:any;
    // cv_sources:any;
    // tags:any;
    // tags_and:any;
    // postal_code:any;
    // boolean: any;
    // full_name: any;
    // school: any;
    // job_title: any;
    // location: any;
    // company: any;
    // location_anywhere_on_resume: any;
    // industry: any;
    // company_anywhere_on_resume: any;
    // school_anywhere_on_resume: any;
    // min_years_experience: any;
    // max_years_experience: any;
    // min_age: any;
    // max_age: any;
    // min_age_of_resume_years: any;
    // min_age_of_resume_months: any;
    // max_age_of_resume_years: any;
    // max_age_of_resume_months: any;
    // from_date: any;
    // to_date: any;
    // job_fair_id: any;
    // radius: any;
    // search_comments: any;
    // from_email: any;
    // to_email: any;
    // subject: any;
    // phone_number:any;
    // email:any;
    // search_type:any;
    // job_role_ids:any; //for job role search
  radiusValue: number = 5; // default value
  sliderRadiusOptions: any = {
    floor: 5,
    ceil: 100,
    // showTicks: true,
    stepsArray: [
      { value: 5, legend: '5 miles' },
      { value: 10, legend: '10 miles' },
      { value: 20, legend: '20 miles' },
      { value: 35, legend: '35 miles' },
      { value: 50, legend: '50 miles' },
      { value: 100, legend: '100 miles' }
    ],
    translate: (value: number): string => {
      return value + ' miles';
    }
  };
  // radiusValue: number = 10; // default value
  min_age_value =18
  max_age_value=75;
  ageSliderOptions: any = {
    floor: 18,
    ceil: 100,
    // showTicks: true,
    step: 1,
      translate: (value: number): string => {
        return value + ' years';
      }
  };
  min_work_ex =0
  max_work_ex=30;
  expSliderOptions: any = {
    floor: 0,
    ceil: 80,
    // showTicks: true,
    step: 1,
      translate: (value: number): string => {
        return value + ' years';
      }
  };
  min_age_of_resume =0
  max_age_of_resume=12;
  ageResumeSliderOptions: any = {
    floor: 0,
      ceil: 30 * 12, // 30 years in months
      // showTicks: true,
      stepsArray: this.generateStepsArray(),
      translate: (value: number): string => {
        if (value === 0) {
          return '0 months';
        } else if (value <= 12) {
          return value + ' month' + (value > 1 ? 's' : '');
        } else {
          return (value / 12) + ' years';
        }
      }
  };
   // Datepicker
    bsValue = new Date();
    maxDate = new Date();
    related_skills_and = []
    related_skills_or = []
    related_skills_not = []
    related_job_titles =[]
    currencies = [];
    salary_period_options = ['Annual', 'Monthly',  'Hourly'];
    methods_of_upload = ['All', 'Email', 'Tobu Drive', 'Web Upload', 'Career Page', 'Google Drive', 'One Drive', "Referral"]
    bsConfig = {
        containerClass: 'theme-angle'
    }
    candidate_country_code = '';
    countries = [];
    current_currency = {};
    current_salary_period: any;
    expected_currency = {};
    expected_salary_period: any;
    agencies_sharing_db = ['My Database'];
    // current_country: any;
    // Time picker
    mytime: Date = new Date();
    search_comments_checked = false;
    job_title_only_on_current_job_checked = false;
    avoid_related_titles_checked = false;
    show_only_resumes_without_contact_details_checked = false;
    not_from_location_checked = false;
    advanced_search_selected=false;
    no_campaigns_found = false;
    user_list=[]
  searchForm=this.fb.group({
    and_keywords: [''],
    or_keywords: [''],
    not_keywords: [''],
    boolean: [''],
    full_name: [''],
    school: [''],
    job_title: [''],
    country: [''],
    gender: [''],
    // current_location: [''],
    location: [''],
    company: [''],
    location_anywhere_on_resume: [''],
    industry: [''],
    company_anywhere_on_resume: [''],
    school_anywhere_on_resume: [''],
    min_years_experience: ['',  this.shared.validateNumber()],
    max_years_experience: ['',  this.shared.validateNumber()],
    min_age_of_candidate_range: ['',  this.shared.validateNumber()],
    max_age_of_candidate_range: ['',  this.shared.validateNumber()],
    min_salary: ['',  this.shared.validateNumber()],
    max_salary: ['',  this.shared.validateNumber()],
    salary_currency: [''],
    salary_period: [''],
    min_expected_salary: ['',  this.shared.validateNumber()],
    max_expected_salary: ['',  this.shared.validateNumber()],
    expected_salary_currency: [''],
    expected_salary_period: [''],
    min_age_of_resume_years: ['',  this.shared.validateNumber()],
    min_age_of_resume_months: ['',  this.shared.validateNumber()],
    max_age_of_resume_years: ['',  this.shared.validateNumber()],
    max_age_of_resume_months: ['',  this.shared.validateNumber()],
    from_date: [''],
    to_date: [''],
    cv_sources: [''],
    job_fair_id: [''],
    tags: [''],
    tags_and: [''],
    tags_not: [''],
    postal_code: [''],
    radius: [''],
    search_comments: [''],
    job_title_only_current: [''],
    from_email: [''],
    to_email: [''],
    subject: [''],
    job_role_ids: [''],
    phone_number: [''],
    email: [''],
    search_type: ['entire_database'],
    is_fresh: [''],
    entry_level: [''],
    mid_level: [''],
    senior_level: [''],
    executive_level: [''],
    received_date_range:[''],
    nationality: [''],
    not_nationality: [''],
    educational_qualification: [''],
    candidate_verification_status: [''],
    recruiter_verification_status: [''],
    email_from_date:  [''],
    email_to_date:  [''],
    inbox_type: ['All'],
    email_campaign_from_date: [''],
    email_campaign_to_date: [''],
    email_campaign_category: [''],
    email_campaign_name: [''],
    email_campaign_stats: [''],
    candidates_sent_to :[''],
    method_of_upload: ['All'],
    // method_of_upload_user_id: [{id: "All", text: "All"}],
    external_db_id: [''],
    email_domain_name:[''],
    email_job_title: [''],
    avoid_related_titles:[''],
    show_only_resumes_without_contact_details: [''],
    not_from_location: ['']
    // is_email_campaign_search: ['false']
    
  });
  searchCandidateForm=this.fb.group({
    full_name: [''],
    location: [''],
    phone_number: [''],
    email: [''],
    search_type: ['candidate_contact']
  })
  searchCampaignForm=this.fb.group({
    email_campaign_from_date: ['', Validators.required],
    email_campaign_to_date: ['', Validators.required],
    email_campaign_category: ['', Validators.required],
    email_campaign_name: ['', Validators.required],
    email_campaign_stats: [''],
    candidates_sent_to :['', Validators.required],
    search_type: ['email_campaign'],
    and_keywords: [''],
    or_keywords: [''],
    not_keywords: [''],
    boolean: [''],
    full_name: [''],
    school: [''],
    job_title: [''],
    country: [''],
    gender: [''],
    // current_location: [''],
    location: [''],
    company: [''],
    location_anywhere_on_resume: [''],
    industry: [''],
    company_anywhere_on_resume: [''],
    school_anywhere_on_resume: [''],
    min_years_experience: [''],
    max_years_experience: [''],
    min_age_of_candidate_range: [''],
    max_age_of_candidate_range: [''],
    min_salary: [''],
    max_salary: [''],
    salary_currency: [''],
    salary_period: [''],
    min_expected_salary: [''],
    max_expected_salary: [''],
    expected_salary_currency: [''],
    expected_salary_period: [''],
    min_age_of_resume_years: [''],
    min_age_of_resume_months: [''],
    max_age_of_resume_years: [''],
    max_age_of_resume_months: [''],
    from_date: [''],
    to_date: [''],
    cv_sources: [''],
    job_fair_id: [''],
    tags: [''],
    tags_and: [''],
    tags_not: [''],
    postal_code: [''],
    radius: [''],
    search_comments: [''],
    job_title_only_current: [''],
    from_email: [''],
    to_email: [''],
    subject: [''],
    job_role_ids: [''],
    phone_number: [''],
    email: [''],
    is_fresh: [''],
    entry_level: [''],
    mid_level: [''],
    senior_level: [''],
    executive_level: [''],
    received_date_range:[''],
    nationality: [''],
    not_nationality: [''],
    educational_qualification: [''],
    candidate_verification_status: [''],
    recruiter_verification_status: ['']
  })
  campaign_categories= [];
  campaign_names =[];
  campaign_stats = [];
  searchEmailForm = this.fb.group({
    and_keywords:  [''],
    or_keywords:  [''],
    not_keywords:  [''],
    boolean:  [''],
    inbox_type: ['All'],
    min_years_experience:  [''],
    max_years_experience:  [''],
    from_date:  [''],
    to_date:  [''],
    from_email:  [''],
    to_email:  [''],
    subject:  [''],
    search_type: ['email_search']
  });
  genders= [ 'Male', 'Female', 'Other'];
  inbox_types = ["All", "Resume Email", "Job Description", "Other Documents"]
  is_candidate_sent_to_true= true;
  job_board_job_titles=[]
  // searchForm = new FormGroup({
  //   and_keywords: new FormControl(),
  //   or_keywords: new FormControl(),
  //   not_keywords: new FormControl(),
  //   boolean: new FormControl(),
  //   full_name: new FormControl(),
  //   school: new FormControl(),
  //   job_title: new FormControl(),
  //   location: new FormControl(),
  //   company: new FormControl(),
  //   location_anywhere_on_resume: new FormControl(),
  //   industry: new FormControl(),
  //   company_anywhere_on_resume: new FormControl(),
  //   school_anywhere_on_resume: new FormControl(),
  //   min_years_experience: new FormControl(),
  //   max_years_experience: new FormControl(),
  //   min_age: new FormControl(),
  //   max_age: new FormControl(),
  //   min_age_of_resume_years: new FormControl(),
  //   min_age_of_resume_months: new FormControl(),
  //   max_age_of_resume_years: new FormControl(),
  //   max_age_of_resume_months: new FormControl(),
  //   from_date: new FormControl(),
  //   to_date: new FormControl(),
  //   cv_sources: new FormControl(),
  //   job_fair_id: new FormControl(),
  //   tags: new FormControl(),
  //   tags_and: new FormControl(),
  //   postal_code: new FormControl(),
  //   radius: new FormControl(),
  //   search_comments: new FormControl,
  //   from_email: new FormControl(),
  //   to_email: new FormControl(),
  //   subject: new FormControl(),
  //   job_role_ids: new FormControl(),
  //   phone_number: new FormControl(),
  //   email: new FormControl(),
  //   search_type: new FormControl()
  // });
  is_jobs_page=false;
  job_boards_hash = {};
  job_boards = []
  show_custom_job_title_option = false
  constructor(
  	private authService: AuthenticationService,
    private route: ActivatedRoute, 
  	private router: Router,
    private modal: ModalsService,
    private shared: SharedService,
    private fb: FormBuilder
  	) { 
  // Datepicker
        this.maxDate.setDate(this.maxDate.getDate() + 7);
        // this.bsRangeValue = [this.bsValue, this.maxDate];
      }


  ngOnInit() {
    // this.searchForm.patchValue({method_of_upload_user_id: {id: "All", text: "All"}})
    this.is_jobs_page=this.router.url.includes('jobs/candidates')
    console.log(this.is_jobs_page)
    this.get_salary_currency_options();
    this.get_campaign_search_parameters();
    this.get_search_job_board_parameters();
    this.get_user_list_for_search();
    //this is to auto-populate all search fields from the url
    this.route.queryParams.subscribe(queryparams => {
      // console.log(new Date(this.shared.string_null_check(queryparams['to_date'])));
      // console.log(this.shared.string_null_check(queryparams['to_date']));
      // console.log(queryparams['to_date']);
      // console.log(new Date(this.shared.string_null_check(queryparams['from_date'])));
      // console.log(this.shared.string_null_check(queryparams['from_date']));
      // console.log(queryparams['from_date'])
      console.log(queryparams['job_title_only_current'])
      // queryparams['job_title_only_current']  = (queryparams['job_title_only_current'] === undefined) ? true : undefined
      this.searchCandidateForm.patchValue({
        //and_keywords: [this.shared.tags_string_to_form_control(queryparams['and_keywords'])],
        //or_keywords: [this.shared.tags_string_to_form_control(queryparams['or_keywords'])],
        email: queryparams['email'],
        full_name: queryparams['full_name'],
        phone_number: queryparams['phone_number'],
        location: queryparams['location']
      });
      this.searchEmailForm.patchValue({
        and_keywords: this.shared.tags_string_to_form_control(queryparams['and_keywords']),
        or_keywords: this.shared.tags_string_to_form_control(queryparams['or_keywords']),
        not_keywords: this.shared.tags_string_to_form_control(queryparams['not_keywords']),
        boolean: queryparams['boolean'],
        min_years_experience: queryparams['min_years_experience'],
        max_years_experience: queryparams['max_years_experience'],
        from_date: (queryparams['from_date']===undefined || queryparams['from_date']===null || queryparams['from_date']==='') ? '': new Date(queryparams['from_date']),
        to_date: (queryparams['to_date']===undefined || queryparams['to_date']===null || queryparams['to_date']==='') ? '': new Date(queryparams['to_date']),
        from_email: queryparams['from_email'],
        to_email: queryparams['to_email'],
        subject: queryparams['subject'],
        inbox_type: (queryparams['inbox_type']===undefined || queryparams['inbox_type']===null || queryparams['inbox_type']==='') ? 'Resume Email': queryparams['inbox_type']
      });
      console.log(queryparams['location'])
      console.log(queryparams['and_keywords'])
      this.searchForm.patchValue({
        and_keywords: this.shared.tags_string_to_form_control(queryparams['and_keywords']),
        or_keywords: this.shared.tags_string_to_form_control(queryparams['or_keywords']),
        not_keywords: this.shared.tags_string_to_form_control(queryparams['not_keywords']),
        boolean: queryparams['boolean'],
        min_salary: queryparams['min_salary'],
        max_salary: queryparams['max_salary'],
        gender: queryparams['gender'],
        full_name: queryparams['full_name'],
        phone_number: queryparams['phone_number'],
        email: queryparams['email'],
        salary_currency: queryparams['salary_currency'],
        salary_period: queryparams['salary_period'],
        min_expected_salary: queryparams['min_expected_salary'],
        max_expected_salary: queryparams['max_expected_salary'],
        expected_salary_currency: queryparams['expected_salary_currency'],
        expected_salary_period: queryparams['expected_salary_period'],
        min_years_experience: queryparams['min_years_experience'],
        max_years_experience: queryparams['max_years_experience'],
        min_age_of_candidate_range: queryparams['min_age_of_candidate_range'],
        max_age_of_candidate_range: queryparams['max_age_of_candidate_range'],
        from_date: (queryparams['from_date']===undefined || queryparams['from_date']===null || queryparams['from_date']==='') ? '': new Date(queryparams['from_date']),
        to_date: (queryparams['to_date']===undefined || queryparams['to_date']===null || queryparams['to_date']==='') ? '': new Date(queryparams['to_date']),
        school: queryparams['school'],
        // job_title: [queryparams['job_title']],
        job_title: this.shared.tags_string_to_form_control(queryparams['job_title']),
        // location: queryparams['location'],
        location: this.shared.tags_string_to_form_control(queryparams['location']),
        country: queryparams['country'],
        company: queryparams['company'],
        location_anywhere_on_resume: queryparams['location_anywhere_on_resume'],
        company_anywhere_on_resume: queryparams['company_anywhere_on_resume'],
        min_age_of_resume_years: queryparams['min_age_of_resume_years'],
        min_age_of_resume_months: queryparams['min_age_of_resume_months'],
        max_age_of_resume_years: queryparams['max_age_of_resume_years'],
        max_age_of_resume_months: queryparams['max_age_of_resume_months'],
        cv_sources: this.shared.tags_string_to_form_control(queryparams['cv_sources']),
        tags: this.shared.tags_string_to_form_control(queryparams['tags']),
        tags_and: this.shared.tags_string_to_form_control(queryparams['tags_and']),
        tags_not: this.shared.tags_string_to_form_control(queryparams['tags_not']),
        postal_code: this.shared.tags_string_to_form_control(queryparams['postal_code']),
        radius: queryparams['radius'],
        search_comments: queryparams['search_comments']==='true',
        job_title_only_current: queryparams['job_title_only_current']==='true',
        // job_title_only_current: true,
        is_fresh: queryparams['is_fresh'],
        is_outdated: queryparams['is_outdated'],
        nationality: this.shared.tags_string_to_form_control(queryparams['nationality']),
        not_nationality: this.shared.tags_string_to_form_control(queryparams['not_nationality']),
        educational_qualification: this.shared.tags_string_to_form_control(queryparams['educational_qualification']),
        candidate_verification_status: queryparams['candidate_verification_status'],
        recruiter_verification_status: queryparams['recruiter_verification_status'],
        inbox_type: (queryparams['inbox_type']===undefined || queryparams['inbox_type']===null || queryparams['inbox_type']==='') ? 'All': queryparams['inbox_type'],
        email_campaign_from_date: (queryparams['email_campaign_from_date']===undefined || queryparams['email_campaign_from_date']===null || queryparams['email_campaign_from_date']==='') ? '': new Date(queryparams['email_campaign_from_date']),
        email_campaign_to_date: (queryparams['email_campaign_to_date']===undefined || queryparams['email_campaign_to_date']===null || queryparams['email_campaign_to_date']==='') ? '': new Date(queryparams['email_campaign_to_date']),
        email_campaign_category_type: queryparams['email_campaign_category'],
        email_campaign_category_name: queryparams['email_campaign_name'],
        email_campaign_stat: queryparams['email_campaign_stats'],
        candidates_sent_to: queryparams['candidates_sent_to'],
        method_of_upload: queryparams['method_of_upload'],
        // method_of_upload_user_id: queryparams['method_of_upload_user_id'],
        external_db_id: queryparams['external_db_id'],//(queryparams['external_db_id']===undefined || queryparams['external_db_id']===null || queryparams['external_db_id']==='') ? '0' : queryparams['external_db_id'], 
        email_domain_name: queryparams['email_domain_name'],
        email_job_title: queryparams['email_job_title'],
        avoid_related_titles: queryparams['avoid_related_titles']==='true',
        not_from_location: queryparams['not_from_location'],
        show_only_resumes_without_contact_details: queryparams['show_only_resumes_without_contact_details'],
        email_from_date: (queryparams['email_from_date']===undefined || queryparams['email_from_date']===null || queryparams['email_from_date']==='') ? '': new Date(queryparams['email_from_date']),
        email_to_date: (queryparams['email_to_date']===undefined || queryparams['email_to_date']===null || queryparams['email_to_date']==='') ? '': new Date(queryparams['email_to_date']),
        from_email: queryparams['from_email'],
        to_email: queryparams['to_email'],
        subject: queryparams['subject']
        // is_email_campaign_search: queryparams['is_email_campaign_search']
      });
      this.search_comments_checked = ((queryparams['search_comments'] === null || queryparams['search_comments'] === '' || queryparams['search_comments'] === 'false') ? false : queryparams['search_comments'])
      console.log(queryparams['job_title_only_current'])
      this.job_title_only_on_current_job_checked = ((queryparams['job_title_only_current'] === null || queryparams['job_title_only_current'] === '' || queryparams['job_title_only_current'] === 'false') ? false : queryparams['job_title_only_current'])
      console.log(this.job_title_only_on_current_job_checked)
      this.avoid_related_titles_checked = ((queryparams['avoid_related_titles'] === null || queryparams['avoid_related_titles'] === '' || queryparams['avoid_related_titles'] === 'false') ? false : queryparams['avoid_related_titles'])
      console.log((this.avoid_related_titles_checked))
      this.not_from_location_checked = ((queryparams['not_from_location'] === null || queryparams['not_from_location'] === '' || queryparams['not_from_location'] === 'false') ? false : queryparams['not_from_location'])
      this.show_only_resumes_without_contact_details_checked = ((queryparams['show_only_resumes_without_contact_details'] === null || queryparams['show_only_resumes_without_contact_details'] === '' || queryparams['show_only_resumes_without_contact_details'] === 'false') ? false : queryparams['show_only_resumes_without_contact_details'])
      this.min_age_value = queryparams['min_age_of_candidate_range']=== undefined ? 18 : queryparams['min_age_of_candidate_range'];
      this.max_age_value = queryparams['max_age_of_candidate_range']=== undefined ? 75 : queryparams['max_age_of_candidate_range'];
      this.radiusValue = queryparams['radius']=== undefined ? 5 : queryparams['radius'];
      this.min_work_ex = queryparams['min_years_experience']=== undefined ? 0 : queryparams['min_years_experience'];
      this.max_work_ex = queryparams['max_years_experience']=== undefined ? 30 : queryparams['max_years_experience'];
      this.min_age_of_resume = queryparams['min_age_of_resume_months']=== undefined ? 0 : queryparams['min_age_of_resume_months'];
      this.max_age_of_resume = queryparams['max_age_of_resume_months']=== undefined ? 12 : queryparams['max_age_of_resume_months'];
      
      // this.searchForm = this.fb.group({
      //   and_keywords: [this.shared.tags_string_to_form_control(queryparams['and_keywords'])],
      //   or_keywords: [this.shared.tags_string_to_form_control(queryparams['or_keywords'])],
      // });
      // this.and_keywords = this.shared.tags_string_to_form_control(queryparams['and_keywords']);
      // this.or_keywords = this.shared.tags_string_to_form_control(queryparams['or_keywords']);
      // this.not_keywords = this.shared.tags_string_to_form_control(queryparams['not_keywords']);
      // this.cv_sources = this.shared.tags_string_to_form_control(queryparams['not_keywords']);
      // this.tags = this.shared.tags_string_to_form_control(queryparams['tags']);
      // this.tags_and = this.shared.tags_string_to_form_control(queryparams['tags_and']);
      // this.postal_code = this.shared.tags_string_to_form_control(queryparams['postal_code']);
      // this.boolean= queryparams['boolean'];
      // this.full_name= queryparams['full_name'];
      // this.school= queryparams['school'];
      // this.job_title= queryparams['job_title'];
      // this.location= queryparams['location'];
      // this.company= queryparams['company'];
      // this.location_anywhere_on_resume= queryparams['location_anywhere_on_resume'];
      // this.industry= queryparams['industry'];
      // this.company_anywhere_on_resume= queryparams['company_anywhere_on_resume'];
      // this.school_anywhere_on_resume= queryparams['school_anywhere_on_resume'];
      // this.min_years_experience= queryparams['min_years_experience'];
      // this.max_years_experience= queryparams['max_years_experience'];
      // this.min_age= queryparams['min_age'];
      // this.max_age= queryparams['max_age'];
      // this.min_age_of_resume_years= queryparams['min_age_of_resume_years'];
      // this.min_age_of_resume_months= queryparams['min_age_of_resume_months'];
      // this.max_age_of_resume_years= queryparams['max_age_of_resume_years'];
      // this.max_age_of_resume_months= queryparams['max_age_of_resume_months'];
      // this.from_date= queryparams['from_date'];
      // this.to_date= queryparams['to_date'];
      // this.job_fair_id= queryparams['job_fair_id'];
      // this.radius= queryparams['radius'];
      // this.search_comments= queryparams['search_comments'];
      // this.from_email= queryparams['from_email'];
      // this.to_email= queryparams['to_email'];
      // this.subject= queryparams['subject'];
      // this.phone_number = queryparams['phone_number'];
      // this.email = queryparams['email'];
      // this.job_role_ids = [];
      // this.search_type = queryparams['search_type'];
      var d = new Date();
      d.setDate(d.getDate()-30);
      console.log(d)
      console.log(new Date())
      // console.log(this.searchCampaignForm.controls['campaign_from_date'].value)
      // if(queryparams['campaign_from_date']==='')
      // {
      //   this.searchCampaignForm.patchValue({
      //         campaign_from_date: d,
      //         campaign_to_date: new Date(),
      //     });
      // }
      this.searchCampaignForm.patchValue({
        email_campaign_from_date: (queryparams['email_campaign_from_date']===undefined || queryparams['email_campaign_from_date']===null || queryparams['email_campaign_from_date']==='') ? new Date(): new Date(queryparams['email_campaign_from_date']),
        email_campaign_to_date: (queryparams['email_campaign_to_date']===undefined || queryparams['email_campaign_to_date']===null || queryparams['email_campaign_to_date']==='') ? new Date(): new Date(queryparams['email_campaign_to_date']),
        email_campaign_category_type: queryparams['email_campaign_category'],
        email_campaign_category_name: queryparams['email_campaign_name'],
        email_campaign_stat: queryparams['email_campaign_stats'],
        candidates_sent_to: queryparams['candidates_sent_to'],
        and_keywords: this.shared.tags_string_to_form_control(queryparams['and_keywords']),
        or_keywords: this.shared.tags_string_to_form_control(queryparams['or_keywords']),
        not_keywords: this.shared.tags_string_to_form_control(queryparams['not_keywords']),
        boolean: queryparams['boolean'],
        min_salary: queryparams['min_salary'],
        max_salary: queryparams['max_salary'],
        gender: queryparams['gender'],
        salary_currency: queryparams['salary_currency'],
        salary_period: queryparams['salary_period'],
        min_expected_salary: queryparams['min_expected_salary'],
        max_expected_salary: queryparams['max_expected_salary'],
        expected_salary_currency: queryparams['expected_salary_currency'],
        expected_salary_period: queryparams['expected_salary_period'],
        min_years_experience: queryparams['min_years_experience'],
        max_years_experience: queryparams['max_years_experience'],
        from_date: (queryparams['from_date']===undefined || queryparams['from_date']===null || queryparams['from_date']==='') ? '': new Date(queryparams['from_date']),
        to_date: (queryparams['to_date']===undefined || queryparams['to_date']===null || queryparams['to_date']==='') ? '': new Date(queryparams['to_date']),
        school: queryparams['school'],
        // job_title: [queryparams['job_title']],
        job_title: this.shared.tags_string_to_form_control(queryparams['job_title']),
        // location: queryparams['location'],
        location: this.shared.tags_string_to_form_control(queryparams['location']),
        country: queryparams['country'],
        company: queryparams['company'],
        location_anywhere_on_resume: queryparams['location_anywhere_on_resume'],
        company_anywhere_on_resume: queryparams['company_anywhere_on_resume'],
        min_age_of_resume_years: queryparams['min_age_of_resume_years'],
        min_age_of_resume_months: queryparams['min_age_of_resume_months'],
        max_age_of_resume_years: queryparams['max_age_of_resume_years'],
        max_age_of_resume_months: queryparams['max_age_of_resume_months'],
        min_age_of_candidate_range: queryparams['min_age_of_candidate_range'],
        max_age_of_candidate_range: queryparams['max_age_of_candidate_range'],
        cv_sources: this.shared.tags_string_to_form_control(queryparams['cv_sources']),
        tags: this.shared.tags_string_to_form_control(queryparams['tags']),
        tags_and: this.shared.tags_string_to_form_control(queryparams['tags_and']),
        tags_not: this.shared.tags_string_to_form_control(queryparams['tags_not']),
        postal_code: this.shared.tags_string_to_form_control(queryparams['postal_code']),
        radius: queryparams['radius'],
        search_comments: queryparams['search_comments'],
        job_title_only_current: queryparams['job_title_only_current'],
        is_fresh: queryparams['is_fresh'],
        is_outdated: queryparams['is_outdated'],
        nationality:  this.shared.tags_string_to_form_control(queryparams['nationality']),
        not_nationality:  this.shared.tags_string_to_form_control(queryparams['not_nationality']),
        educational_qualification: this.shared.tags_string_to_form_control(queryparams['educational_qualification']),
        candidate_verification_status: queryparams['candidate_verification_status'],
        recruiter_verification_status: queryparams['recruiter_verification_status'],
        method_of_upload: queryparams['method_of_upload'],
        // method_of_upload_user_id: queryparams['method_of_upload_user_id'],
        avoid_related_titles: queryparams['avoid_related_titles'],
        show_only_resumes_without_contact_details: queryparams['show_only_resumes_without_contact_details'],
        not_from_location: queryparams['not_from_location']

      });
    });

  }
  //simulate pressing enter to ensure all keywords are in.
  enter_press(form_type){
    const event = new KeyboardEvent("keypress",{
    "key": "Enter"
    });
    setTimeout(()=>{
      document.dispatchEvent(event);
      if(form_type==='search_form')
      {
        this.reset_email_database_search();
        this.reset_email_compaign_search();
        this.reset_candidate_database_search();

        this.submitSearch.nativeElement.click();

      }
      else if(form_type==='search_email_form')
      {
        this.reset_email_compaign_search();
        this.reset_candidate_database_search();
        this.reset_entire_database_search();
        this.submitEmailSearch.nativeElement.click();
      }
      else if(form_type==='search_candidate_form')
      {
        this.reset_email_database_search();
        this.reset_email_compaign_search();
        // this.reset_candidate_database_search();
        this.reset_entire_database_search();
        this.submitCandidateSearch.nativeElement.click();
      }
      else if(form_type==='search_campaign_form')
      {
        this.reset_email_database_search();
        // this.reset_email_compaign_search();
        this.reset_candidate_database_search();
        this.reset_entire_database_search();
        this.submitEmailCampaignSearch.nativeElement.click();
      }
      // this.submitSearchForm(event, this.searchForm.value)
    }, 200)
    
  }
  submitSearchForm($ev, value: any) {
    console.log(value);
    console.log(this.shared.string_null_check(value.avoid_related_titles))
    $ev.preventDefault();
    if (this.searchForm.valid) {
      // console.warn($ev);
      // console.warn(value);
      // console.log(value.from_date);
      // console.log(this.shared.string_null_check(value.from_date));
      console.log('valid')
      this.router.navigate([], {queryParams:
      //this.authService.post(
         {

          'and_keywords': this.shared.tags_form_control_to_string(value.and_keywords),
          'or_keywords': this.shared.tags_form_control_to_string(value.or_keywords),//===null ? '' : value.or_keywords.map((x, i, a) => {return x.value}).join(','),
          'not_keywords': this.shared.tags_form_control_to_string(value.not_keywords),//===null ? '' : value.not_keywords.map((x, i, a) => {return x.value}).join(','),
          'boolean': this.shared.string_null_check(value.boolean),//=='undefined' ? '' : value.boolean ,
          // 'full_name': this.shared.string_null_check(value.full_name),
          'school': this.shared.string_null_check(value.school),
          // 'job_title': this.shared.string_null_check(value.job_title),
          'job_title': this.shared.tags_form_control_to_string(value.job_title),//===null ? '' : value.or_keywords.map((x, i, a) => {return x.value}).join(','),
          // 'location': this.shared.string_null_check(value.location),
          'gender': this.shared.string_null_check(value.gender),
          'location': this.shared.tags_form_control_to_string(value.location),
          'country': this.shared.string_null_check(value.country),
          'company': this.shared.string_null_check(value.company),
          'location_anywhere_on_resume': this.shared.string_null_check(value.location_anywhere_on_resume),
          'full_name': this.shared.string_null_check(value.full_name),
          'phone_number': this.shared.string_null_check(value.phone_number),
          'email': this.shared.string_null_check(value.email),
          // 'industry': this.shared.string_null_check(value.industry),
          'company_anywhere_on_resume': this.shared.string_null_check(value.company_anywhere_on_resume),
          // 'school_anywhere_on_resume': this.shared.string_null_check(value.school_anywhere_on_resume),
          'min_salary': this.shared.string_null_check(value.min_salary),
          'max_salary': this.shared.string_null_check(value.max_salary),
          'salary_currency': this.shared.string_null_check(value.salary_currency),
          'salary_period': this.shared.string_null_check(value.salary_period),
          'min_expected_salary': this.shared.string_null_check(value.min_expected_salary),
          'max_expected_salary': this.shared.string_null_check(value.max_expected_salary),
          'expected_salary_currency': this.shared.string_null_check(value.expected_salary_currency),
          'expected_salary_period': this.shared.string_null_check(value.expected_salary_period),
          'min_years_experience': this.shared.string_null_check(value.min_years_experience),
          'max_years_experience': this.shared.string_null_check(value.max_years_experience),
          'min_age_of_candidate_range': this.shared.string_null_check(value.min_age_of_candidate_range),
          'max_age_of_candidate_range': this.shared.string_null_check(value.max_age_of_candidate_range),
          'min_age_of_resume_years': this.shared.string_null_check(value.min_age_of_resume_years),
          'min_age_of_resume_months': this.shared.string_null_check(value.min_age_of_resume_months),
          'max_age_of_resume_years': this.shared.string_null_check(value.max_age_of_resume_years),
          'max_age_of_resume_months': this.shared.string_null_check(value.max_age_of_resume_months),
          'from_date': this.shared.convert_date_to_query_string(value.from_date),
          'to_date': this.shared.convert_date_to_query_string(value.to_date),
          'cv_sources': this.shared.tags_form_control_to_string(value.cv_sources),//===null ? '' : value.cv_sources.map((x, i, a) => {return x.value}).join(','),
          //'job_fair_id': value.job_fair_id,
          'tags': this.shared.tags_form_control_to_string(value.tags),//===null ? '' : value.tags.map((x, i, a) => {return x.value}).join(','),
          'tags_and': this.shared.tags_form_control_to_string(value.tags_and),//===null ? '' : value.tags_and.map((x, i, a) => {return x.value}).join(','),
          'tags_not': this.shared.tags_form_control_to_string(value.tags_not),//===null ? '' : value.tags_and.map((x, i, a) => {return x.value}).join(','),
          'postal_code': this.shared.tags_form_control_to_string(value.postal_code),//===null ? '' : value.postal_code.map((x, i, a) => {return x['value']}).join(','),
          'radius': value.radius === undefined ? '' : value.radius.toString(),//this.shared.string_null_check(value.radius.toString()),
          'search_comments': this.shared.string_null_check( (value.search_comments === true ||  value.search_comments === false) ? value.search_comments.toString() : value.search_comments ),
          'job_title_only_current': this.shared.string_null_check( (value.job_title_only_current === true ||  value.job_title_only_current === false) ? value.job_title_only_current.toString() : value.job_title_only_current ), 
          //'job_role_ids': this.shared.string_null_check(value.job_role_ids),
          'search_type': this.shared.string_null_check(value.search_type),
          'nationality': this.shared.tags_form_control_to_string(value.nationality),
          'not_nationality': this.shared.tags_form_control_to_string(value.not_nationality),
          'educational_qualification': this.shared.tags_form_control_to_string(value.educational_qualification),
          'candidate_verification_status': this.shared.string_null_check(value.candidate_verification_status),
          'recruiter_verification_status': this.shared.string_null_check(value.recruiter_verification_status),
          'email_from_date': this.shared.convert_date_to_query_string(value.email_from_date),
          'email_to_date': this.shared.convert_date_to_query_string(value.email_to_date),
          'from_email': this.shared.string_null_check(value.from_email),
          'to_email': this.shared.string_null_check(value.to_email),
          'subject': this.shared.string_null_check(value.subject),
          'sort_resumes_by': 'score',
          'email_campaign_from_date': this.shared.convert_date_to_query_string(value.email_campaign_from_date),
          'email_campaign_to_date': this.shared.convert_date_to_query_string(value.email_campaign_to_date),
          'email_campaign_category_type': this.shared.string_null_check(value.email_campaign_category),
          'email_campaign_category_name': this.shared.string_null_check(value.email_campaign_name),
          'email_campaign_stat': this.shared.string_null_check(value.email_campaign_stats),
          'candidates_sent_to': this.shared.string_null_check(value.candidates_sent_to),
          'method_of_upload': this.shared.string_null_check(value.method_of_upload),
          // 'method_of_upload_user_id': this.shared.string_null_check(value.method_of_upload_user_id.toString()),
          'external_db_id': this.shared.string_null_check(value.external_db_id.toString()),
          // 'is_email_campaign_search': this.shared.string_null_check(value.is_email_campaign_search)
          'email_domain_name':this.shared.string_null_check(value.email_domain_name),
          'email_job_title': this.shared.string_null_check(value.email_job_title),
          'avoid_related_titles': this.shared.string_null_check(value.avoid_related_titles.toString()),
          'show_only_resumes_without_contact_details': value.show_only_resumes_without_contact_details == null ? '' : value.show_only_resumes_without_contact_details.toString(),
          'not_from_location': value.not_from_location == null ? '' : value.not_from_location.toString()
          //stage: value.stage
        }, relativeTo : this.route
      });
    }
    console.log(value.external_db_id)
    // this.modal.hide_modal('big');
    this.modal.hide_modal_by_name('search')
  }
  submitCandidateSearchForm($ev, value: any) {
    $ev.preventDefault();
    if (this.searchForm.valid) {
      // console.warn($ev);
      // console.warn(value);
      // console.log(this.shared.string_null_check(value.boolean));
      this.router.navigate([], {queryParams:
      //this.authService.post(
         {
          'full_name': this.shared.string_null_check(value.full_name),
          'location': this.shared.string_null_check(value.location),
          'phone_number': this.shared.string_null_check(value.phone_number),
          'email': this.shared.string_null_check(value.email),
          'search_type': this.shared.string_null_check(value.search_type),
          'sort_resumes_by': 'score'
        }, relativeTo : this.route
      });
    }
    // this.modal.hide_modal('big');
    this.modal.hide_modal_by_name('search')
  }
  submitSearchCampaignForm($ev, value: any) {
    $ev.preventDefault();
    if (this.searchForm.valid) {
      // console.warn($ev);
      // console.warn(value);
      // console.log(this.shared.string_null_check(value.boolean));
      this.router.navigate([], {queryParams:
      //this.authService.post(
         {
          'email_campaign_from_date': this.shared.convert_date_to_query_string(value.email_campaign_from_date),
          'email_campaign_to_date': this.shared.convert_date_to_query_string(value.email_campaign_to_date),
          'email_campaign_category_type': this.shared.string_null_check(value.email_campaign_category),
          'email_campaign_category_name': this.shared.string_null_check(value.email_campaign_name),
          'email_campaign_stat': this.shared.string_null_check(value.email_campaign_stats),
          'candidates_sent_to': this.shared.string_null_check(value.candidates_sent_to),
          'search_type': 'email_campaign_search',
          'sort_resumes_by': 'score',
          'and_keywords': this.shared.tags_form_control_to_string(value.and_keywords),
          'or_keywords': this.shared.tags_form_control_to_string(value.or_keywords),//===null ? '' : value.or_keywords.map((x, i, a) => {return x.value}).join(','),
          'not_keywords': this.shared.tags_form_control_to_string(value.not_keywords),//===null ? '' : value.not_keywords.map((x, i, a) => {return x.value}).join(','),
          'boolean': this.shared.string_null_check(value.boolean),//=='undefined' ? '' : value.boolean ,
          // 'full_name': this.shared.string_null_check(value.full_name),
          'school': this.shared.string_null_check(value.school),
          'gender': this.shared.string_null_check(value.gender),
          // 'job_title': this.shared.string_null_check(value.job_title),
          'job_title': this.shared.tags_form_control_to_string(value.job_title),//===null ? '' : value.or_keywords.map((x, i, a) => {return x.value}).join(','),
          // 'location': this.shared.string_null_check(value.location),
          'location': this.shared.tags_form_control_to_string(value.location),
          'country': this.shared.string_null_check(value.country),
          'company': this.shared.string_null_check(value.company),
          'location_anywhere_on_resume': this.shared.string_null_check(value.location_anywhere_on_resume),
          // 'industry': this.shared.string_null_check(value.industry),
          'company_anywhere_on_resume': this.shared.string_null_check(value.company_anywhere_on_resume),
          // 'school_anywhere_on_resume': this.shared.string_null_check(value.school_anywhere_on_resume),
          'min_salary': this.shared.string_null_check(value.min_salary),
          'max_salary': this.shared.string_null_check(value.max_salary),
          'salary_currency': this.shared.string_null_check(value.salary_currency),
          'salary_period': this.shared.string_null_check(value.salary_period),
          'min_expected_salary': this.shared.string_null_check(value.min_expected_salary),
          'max_expected_salary': this.shared.string_null_check(value.max_expected_salary),
          'expected_salary_currency': this.shared.string_null_check(value.expected_salary_currency),
          'expected_salary_period': this.shared.string_null_check(value.expected_salary_period),
          'min_years_experience': this.shared.string_null_check(value.min_years_experience),
          'max_years_experience': this.shared.string_null_check(value.max_years_experience),
          'min_age_of_candidate_range': this.shared.string_null_check(value.min_age_of_candidate_range),
          'max_age_of_candidate_range': this.shared.string_null_check(value.max_age_of_candidate_range),
          'min_age_of_resume_years': this.shared.string_null_check(value.min_age_of_resume_years),
          'min_age_of_resume_months': this.shared.string_null_check(value.min_age_of_resume_months),
          'max_age_of_resume_years': this.shared.string_null_check(value.max_age_of_resume_years),
          'max_age_of_resume_months': this.shared.string_null_check(value.max_age_of_resume_months),
          'from_date': this.shared.convert_date_to_query_string(value.from_date),
          'to_date': this.shared.convert_date_to_query_string(value.to_date),
          'cv_sources': this.shared.tags_form_control_to_string(value.cv_sources),//===null ? '' : value.cv_sources.map((x, i, a) => {return x.value}).join(','),
          //'job_fair_id': value.job_fair_id,
          'tags': this.shared.tags_form_control_to_string(value.tags),//===null ? '' : value.tags.map((x, i, a) => {return x.value}).join(','),
          'tags_and': this.shared.tags_form_control_to_string(value.tags_and),//===null ? '' : value.tags_and.map((x, i, a) => {return x.value}).join(','),
          'tags_not': this.shared.tags_form_control_to_string(value.tags_not),//===null ? '' : value.tags_and.map((x, i, a) => {return x.value}).join(','),
          'postal_code': this.shared.tags_form_control_to_string(value.postal_code),//===null ? '' : value.postal_code.map((x, i, a) => {return x['value']}).join(','),
          'radius': this.shared.string_null_check(value.radius),
          'search_comments': this.shared.string_null_check( (value.search_comments === true ||  value.search_comments === false) ? value.search_comments.toString() : value.search_comments ),
          'job_title_only_current': this.shared.string_null_check( (value.job_title_only_current === true ||  value.job_title_only_current === false) ? value.job_title_only_current.toString() : value.job_title_only_current ),
          'nationality': this.shared.tags_form_control_to_string(value.nationality),
          'not_nationality': this.shared.tags_form_control_to_string(value.not_nationality),
          'educational_qualification': this.shared.tags_form_control_to_string(value.educational_qualification),
          'candidate_verification_status': this.shared.string_null_check(value.candidate_verification_status),
          'recruiter_verification_status': this.shared.string_null_check(value.recruiter_verification_status),
          'inbox_type': this.shared.string_null_check(value.inbox_type),
          'avoid_related_titles': this.shared.string_null_check(value.avoid_related_titles),
        }, relativeTo : this.route
      });
    }
    // this.modal.hide_modal('big');
    this.modal.hide_modal_by_name('search')
  }
  submitEmailSearchForm($ev, value: any) {
    $ev.preventDefault();
    if (this.searchForm.valid) {
      // console.warn($ev);
      // console.warn(value);
      // console.log(this.shared.string_null_check(value.boolean));
      this.router.navigate([], {queryParams:
      //this.authService.post(
         {
          'and_keywords': this.shared.tags_form_control_to_string(value.and_keywords),
          'or_keywords': this.shared.tags_form_control_to_string(value.or_keywords),//===null ? '' : value.or_keywords.map((x, i, a) => {return x.value}).join(','),
          'not_keywords': this.shared.tags_form_control_to_string(value.not_keywords),//===null ? '' : value.not_keywords.map((x, i, a) => {return x.value}).join(','),
          'boolean': this.shared.string_null_check(value.boolean),//=='undefined' ? '' : value.boolean ,
          'min_years_experience': this.shared.string_null_check(value.min_years_experience),
          'max_years_experience': this.shared.string_null_check(value.max_years_experience),
          'from_date': this.shared.convert_date_to_query_string(value.from_date),
          'to_date': this.shared.convert_date_to_query_string(value.to_date),
          'from_email': this.shared.string_null_check(value.from_email),
          'to_email': this.shared.string_null_check(value.to_email),
          'subject': this.shared.string_null_check(value.subject),
          'search_type': this.shared.string_null_check(value.search_type),
          'sort_resumes_by': 'score',
          'inbox_type': this.shared.string_null_check(value.inbox_type),
        }, relativeTo : this.route
      });
    }
    // this.modal.hide_modal('big');
    this.modal.hide_modal_by_name('search')
  }
  public requestAutocompleteTags = (text: string): Observable<any> => {
    const url = `/api/user_labels?q=${text}`;
    return this.authService
        .get(url)
        .map(data => data);
  };
  public requestAutocompleteNationality = (text: string): Observable<any> => {
    const url = `/api/nationalities_autocomplete?term=${text}`;
    return this.authService
        .get(url)
        .map(data => data);
  };
  public requestAutocompletePostalCode = (text: string): Observable<any> => {
    const url = `/api/postal_code_autocomplete?term=${text}&country=`+ this.candidate_country_code;
    return this.authService
        .get(url)
        .map(data => data);
  };
  public requestAutocompleteCvSource = (text: string): Observable<any> => {
    const url = `/api/company_sources?q=${text}`;
    return this.authService
        .get(url)
        .map(data => data);
  };
  public requestAutocompleteCollaborator = (text: string): Observable<any> => {
    const url = `/api/user_collaborators?q=${text}`;
    return this.authService
        .get(url)
        .map(data => data);
  };
  public requestAutocompleteCityName = (text: string): Observable<any> => {
    const url = `/api/city_names?term=${text}&country=`+ this.candidate_country_code;
    console.log(url);
    return this.authService
        .get(url)
        .map(data => data);
  };
  public requestAutocompleteKeyword = (text: string): Observable<any> => {
    const url = `/api/keywords?term=${text}`;
    console.log(url);
    return this.authService
        .get(url)
        .map(data => data);
  };
  public requestAutocompleteJobTitle = (text: string): Observable<any> => {
    const url = `/api/job_titles?term=${text}`;
    console.log(url);
    return this.authService
        .get(url)
        .map(data => data);
  };
  get_salary_currency_options(){
    this.authService.get('/api/get_salary_currency_options').subscribe(
      res=> {
        console.log(res);
        this.currencies=res['currencies'];
        this.countries = res ['countries'];
        this.candidate_country_code = res['current_country']['country_code']
        // this.current_country = res['current_country'];
        // if (this.candidate_country_code === ''){
        //   this.candidate_country_code=res['current_country']['country_code'];
        // }
        this.current_currency = res['current_currency'];
        this.current_salary_period = res['current_salary_period'];
        this.expected_currency = res['expected_currency'];
        this.expected_salary_period = res['expected_salary_period'];
        // this.agencies_sharing_db = 
        var a = res['agencies_sharing_db']
        a.push({id: 0, name: 'My Database'})
        this.agencies_sharing_db=a
        console.log(this.agencies_sharing_db)
        // this.agencies_sharing_db.push({id: 0, name: 'My Database'})
        // console.log(this.agencies_sharing_db)
        this.searchForm.patchValue({
          // 'current_country': this.candidate_country_code,
          'salary_currency': this.current_currency,
          'salary_period': this.current_salary_period,
          'expected_salary_currency': this.expected_currency,
          'expected_salary_period': this.expected_salary_period,
          'country': this.candidate_country_code,
          'external_db_id': '0'
        })
      },
      err=> console.log(err))
  }
  test(){
    console.log('autocomplete works')
  }
  select_country(){
    this.candidate_country_code = this.searchForm.controls['country'].value
  }
  select_search_comment($ev){
    console.log($ev.target.checked);
    this.searchForm.patchValue({
      'search_comments': $ev.target.checked
    })
    console.log(this.searchForm.controls['search_comments'].value)
  }
  select_job_title_only_current($ev){
    console.log($ev.target.checked);
    this.searchForm.patchValue({
      'job_title_only_current': $ev.target.checked
    })
    console.log(this.searchForm.controls['job_title_only_current'].value)
  }
  select_avoid_related_titles($ev){
    console.log($ev.target.checked);
    this.searchForm.patchValue({
      'avoid_related_titles': $ev.target.checked
    })
    console.log(this.searchForm.controls['avoid_related_titles'].value)
  }
  select_not_from_location($ev){
    console.log($ev.target.checked);
    this.searchForm.patchValue({
      'not_from_location': $ev.target.checked
    })
    console.log(this.searchForm.controls['not_from_location'].value)
  }
  select_show_only_resumes_without_contact_details($ev){
    console.log($ev.target.checked);
    this.searchForm.patchValue({
      'show_only_resumes_without_contact_details': $ev.target.checked
    })
    console.log(this.searchForm.controls['show_only_resumes_without_contact_details'].value)
  }

  get_campaign_search_parameters(){

    this.authService.get('/api/get_campaign_search_parameters').subscribe(
      res => {
        console.log(res);
        this.campaign_categories = res['category_types'];
        this.campaign_stats = res['campaign_stats']

      },
      err => console.log(err)
      )
  }
  select_category(){
    this.authService.post('/api/get_category_names_from_category_type', {
      'email_campaign_category_type': this.searchForm.controls['email_campaign_category'].value, 
      'email_campaign_to_date': this.shared.convert_date_to_query_string(this.searchForm.controls['email_campaign_to_date'].value),
      'email_campaign_from_date': this.shared.convert_date_to_query_string(this.searchForm.controls['email_campaign_from_date'].value)
  }).subscribe(
    res=> {
      console.log(res);
      this.campaign_names = res['category_names'];
      if(res['category_names'].length ===0){
        this.no_campaigns_found = true
      }
    },
    err=> console.log(err)
    )
  }

  select_campaign_name(){
    this.searchForm.patchValue({
      // is_email_campaign_search: 'true',
      search_type: 'email_campaign_search',
      candidates_sent_to: true
      })
  }

  set_candidate_sent_to(){
    let val = this.searchCampaignForm.controls['candidates_sent_to'].value;
    if (val=="yes"){
      this.is_candidate_sent_to_true = true
    }
    else if (val=="no"){
      this.is_candidate_sent_to_true = false
    }

  }
  reset_entire_database_search(){

      
      this.searchForm.patchValue({
        and_keywords:'',
        or_keywords: '',
        not_keywords: '',
        boolean: '',
        min_salary: '',
        max_salary: '',
        gender: '',
        salary_currency: '',
        salary_period: '',
        min_expected_salary: '',
        max_expected_salary: '',
        expected_salary_currency: '',
        expected_salary_period: '',
        min_years_experience: '',
        max_years_experience: '',
        from_date: '',
        to_date: '',
        school: '',
        // job_title: [queryparams['job_title']],
        phone_number: '',
        email: '',
        full_name: '',
        job_title: '',
        // location: queryparams['location'],
        location: '',
        country: '',
        company: '',
        location_anywhere_on_resume: '',
        company_anywhere_on_resume: '',
        min_age_of_resume_years: '',
        min_age_of_resume_months: '',
        max_age_of_resume_years: '',
        max_age_of_resume_months: '',
        min_age_of_candidate_range: '',
        max_age_of_candidate_range: '',
        cv_sources: '',
        tags: '',
        tags_and: '',
        tags_not: '',
        postal_code: '',
        radius: '',
        search_comments: '',
        job_title_only_current: '',
        is_fresh: '',
        is_outdated: '',
        nationality:  '',
        not_nationality:  '',
        educational_qualification: '',
        candidate_verification_status: '',
        recruiter_verification_status: '',
        email_from_date: '',
        email_to_date: '',
        from_email: '',
        to_email: '',
        subject: '',
        inbox_type: '',
        email_campaign_from_date: '',
        email_campaign_to_date: '',
        email_campaign_category_type: '',
        email_campaign_category_name: '',
        email_campaign_stat: '',
        candidates_sent_to: '',
        method_of_upload: '',
        // method_of_upload_user_id: '',
        is_email_campaign_search: '',
        external_db_id: '0',
        email_domain_name:'',
        email_job_title: '',
        avoid_related_titles: '',
        show_only_resumes_without_contact_details: '',
        not_from_location: ''
      });
      this.search_comments_checked = false
      this.job_title_only_on_current_job_checked=false
      this.avoid_related_titles_checked = false
      this.not_from_location_checked = false;
      this.show_only_resumes_without_contact_details_checked = false;
      
  }
  reset_email_database_search(){
    this.searchEmailForm.patchValue({
        and_keywords: '',
        or_keywords: '',
        not_keywords: '',
        boolean: '',
        min_years_experience: '',
        max_years_experience:'',
        from_date: '',
        to_date: '',
        from_email: '',
        to_email: '',
        subject: '',
        inbox_type: '',
    });
    
  }
  reset_candidate_database_search(){
    this.searchCandidateForm.patchValue({
        email: '',
        full_name: '',
        phone_number: '',
        location: ''
    });
    
  }
  reset_email_compaign_search(){
    this.searchCampaignForm.patchValue({
        email_campaign_from_date: '',
        email_campaign_to_date: '',
        email_campaign_category_type: '',
        email_campaign_category_name: '',
        email_campaign_stat: '',
        candidates_sent_to: '',
        and_keywords:'',
        or_keywords: '',
        not_keywords: '',
        boolean: '',
        min_salary: '',
        max_salary: '',
        gender: '',
        salary_currency: '',
        salary_period: '',
        min_expected_salary: '',
        max_expected_salary: '',
        expected_salary_currency: '',
        expected_salary_period: '',
        min_years_experience: '',
        max_years_experience: '',
        min_age_of_candidate_range: '',
        max_age_of_candidate_range: '',
        from_date: '',
        to_date: '',
        school: '',
        // job_title: [queryparams['job_title']],
        job_title: '',
        // location: queryparams['location'],
        location: '',
        country: '',
        company: '',
        location_anywhere_on_resume: '',
        company_anywhere_on_resume: '',
        min_age_of_resume_years: '',
        min_age_of_resume_months: '',
        max_age_of_resume_years: '',
        max_age_of_resume_months: '',
        cv_sources: '',
        tags: '',
        tags_and: '',
        tags_not: '',
        postal_code: '',
        radius: '',
        search_comments: '',
        job_title_only_current: '',
        is_fresh: '',
        is_outdated: '',
        nationality:  '',
        not_nationality:  '',
        educational_qualification: '',
        candidate_verification_status: '',
        recruiter_verification_status: '',
        avoid_related_titles: ''
      });
  }
  clear_form(){
    this.reset_email_database_search();
    this.reset_email_compaign_search();
    this.reset_candidate_database_search();
    this.reset_entire_database_search();
  }
  get_search_job_board_parameters()
  {
    this.authService.get('/api/get_search_job_board_parameters').subscribe(
      res => {
        console.log(res);
        this.job_boards = Object.keys(res['recent_auto_job_postings']);
        this.job_boards_hash = res['recent_auto_job_postings']
      },
      err => console.log(err)
      )
    
  }
  get_user_list_for_search(){
    this.authService.get('/api/get_user_list_for_search').subscribe(
      res => {
        console.log(res);
        this.user_list = [{ id: "All", text: "All" }, ...res['user_list'].map(user => ({ id: user[0], text: user[2] }))];
      },
      err => console.log(err)
      )
  }
  select_job_board(){
    console.log('selecting job board')
    let jb = this.searchForm.get('email_domain_name').value
    this.job_board_job_titles = this.job_boards_hash[jb]
    // console.log(this.job_board_job_titles)
  }
  tagAdded(_ev, _type) {
    console.log(_ev);
    this.get_related_skills(_type, _ev.value)
  }
  jtAdded(_ev){
    this.get_related_job_title(_ev.value)
  }

  get_related_skills(_type,_skill_name){
    this.authService.get('/api/get_related_keywords', {term: _skill_name}).subscribe(
      res=>{
        console.log(res)
        if(_type==='AND')
        {this.related_skills_and = res['related_keywords']}
        else if(_type==='OR')
        {this.related_skills_or = res['related_keywords']}
        else if(_type==='NOT')
        {this.related_skills_not = res['related_keywords']}
      },
      err=> console.log(err)
      )
  }
  add_keyword(_key, _type){
    // console.log(queryparams['and_keywords'])
    // console.log(this.shared.remove_value_from_array('1', ['1', '2', '3']))
    console.log(_key)
    console.log(_type)
    if(_type==='AND')
        {
          this.searchForm.patchValue({and_keywords: this.shared.tags_string_to_form_control(this.shared.tags_form_control_to_string(this.searchForm.get('and_keywords').value)+","+_key)})
          // console.log(this.shared.remove_value_from_array(_key, this.related_skills_and))
          this.related_skills_and =this.shared.remove_value_from_array(_key, this.related_skills_and)
        }
        else if(_type==='OR')
        {
          this.searchForm.patchValue({or_keywords: this.shared.tags_string_to_form_control(this.shared.tags_form_control_to_string(this.searchForm.get('or_keywords').value)+","+_key)})
          // console.log(this.shared.remove_value_from_array(_key, this.related_skills_or))
          this.related_skills_or =this.shared.remove_value_from_array(_key, this.related_skills_or)
        }
        else if(_type==='NOT')
        {
          this.searchForm.patchValue({not_keywords: this.shared.tags_string_to_form_control(this.shared.tags_form_control_to_string(this.searchForm.get('not_keywords').value)+","+_key)})
          // console.log(this.shared.remove_value_from_array(_key, this.related_skills_not))
          this.related_skills_not =this.shared.remove_value_from_array(_key, this.related_skills_not)
        }
    // this.searchForm.patchValue({
    //     and_keywords: this.shared.tags_string_to_form_control(queryparams['and_keywords']),
    //     or_keywords: this.shared.tags_string_to_form_control(queryparams['or_keywords']),
    //     not_keywords: this.shared.tags_string_to_form_control(queryparams['not_keywords']),
    //   })
  }
  get_related_job_title(_job_title_name){
    this.authService.get('/api/get_related_job_titles', {term: _job_title_name}).subscribe(
      res=>{
        console.log(res)
        this.related_job_titles = res['related_job_titles']
      },
      err=> console.log(err)
      )
  }
  add_job_title(_job_title_name){
    this.searchForm.patchValue({job_title: this.shared.tags_string_to_form_control(this.shared.tags_form_control_to_string(this.searchForm.get('job_title').value)+","+_job_title_name)})
          // console.log(this.shared.remove_value_from_array(_key, this.related_skills_and))
    this.related_job_titles =this.shared.remove_value_from_array(_job_title_name, this.related_job_titles)
  }
  onRadiusChange(newValue): void {
    console.log(newValue)
    this.searchForm.patchValue({radius: newValue})//.get('radius').setValue(newValue);
    console.log(this.shared.string_null_check(this.searchForm.get('radius')))
    console.log(this.searchForm.get('radius'))
  }
  onAgeChange(min_age_value, max_age_value){
    console.log(min_age_value)
    console.log(max_age_value)
    this.searchForm.patchValue({min_age_of_candidate_range: min_age_value.toString(), max_age_of_candidate_range: max_age_value.toString()})
  }
  onExpChange(min_work_ex, max_work_ex){
    console.log(min_work_ex)
    console.log(max_work_ex)
    this.searchForm.patchValue({min_years_experience: min_work_ex.toString(), max_years_experience: max_work_ex.toString()})
  }
  onAgeResumeChange(min_age_of_resume, max_age_of_resume){
    console.log(min_age_of_resume)
    console.log(max_age_of_resume)
    this.searchForm.patchValue({min_age_of_resume_months: min_age_of_resume.toString(), max_age_of_resume_months: max_age_of_resume.toString()})
  }
  generateStepsArray() {
    let stepsArray = [{ value: 0, legend: '0 months' }]; // Start from 0 months
    
    // Monthly steps for the first year
    for (let month = 1; month <= 12; month++) {
      stepsArray.push({ value: month, legend: month + ' month' + (month > 1 ? 's' : '') });
    }

    // Yearly steps from the second year to 30 years
    for (let year = 2; year <= 30; year++) {
      stepsArray.push({ value: year * 12, legend: year + ' years' });
    }

    return stepsArray;
  }

}

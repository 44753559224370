import { Component, OnInit , ViewChild, ElementRef} from '@angular/core';
import { AuthenticationService} from '../../../core/authentication/authentication.service'
import {Router, ActivatedRoute, Params} from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ModalsService } from '../../../layout/modals/modals.service'
import { Observable} from 'rxjs';
import { SharedService } from '../../../core/shared/shared.service';
import {saveAs} from "file-saver";
@Component({
  selector: '[app-export-appointments]',
  templateUrl: './export-appointments.component.html',
  styleUrls: ['./export-appointments.component.scss']
})
export class ExportAppointmentsComponent implements OnInit {

  constructor(
  	private authService: AuthenticationService,
    private route: ActivatedRoute, 
  	private router: Router,
    private modal: ModalsService,
    private shared: SharedService,
    private fb: FormBuilder
  	) { }
  exportAppointmentsForm=this.fb.group({
    from_date: [''],
    to_date: [''],
    job_role_id: ['']
  })
  bsValue = new Date();
  bsConfig = {
        containerClass: 'theme-angle'
    };
  job_roles = []
  ngOnInit() {
  	this.authService.get('/api/get_all_job_roles').subscribe(
  		res=> {
  			this.job_roles = res['job_roles']
  		},
  		err=> {}
  	)
  }
  submitExportAppointmentsForm($ev, _val){
  	  	this.authService.downloadFile('/api/download_appointment_calendar', _val).subscribe(
  		res=> {
  			this.modal.hide_modal_by_name('export_appointments');
  			saveAs(res, 'appointments.csv');
  			console.log(res)
  		},
  		err=> {}
  	)
  }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CareerService {

  constructor() { }

  get_params_from_url(_url){
    var url_split = _url.split('?')[0].split('/');
    // in case loaded from job list index
    if(url_split[url_split.length -1]==='apply' && url_split[url_split.length -3]!='job'){
      return {'job_role_id' : '0', 'company_id':url_split[url_split.length -2]}
    } 
    // else if(url_split[url_split.length -1]==='apply' && url_split[url_split.length -3]!='job'){
    //   return {'job_role_id' : url_split[url_split.length -2], 'company_id':url_split[url_split.length] -4};
    // }
    // else if(url_split[url_split.length -2]==='apply' && url_split[url_split.length -4]==='job'){
    //   return {'job_role_id' : url_split.length -3, 'company_id':url_split[url_split.length -5], 'email_sent_to_candidate': url_split.length-1};
    // }
    else{
      console.log('here')
      var i = url_split.findIndex(function(element) {
         return element === 'job';
       })
      var k = url_split.findIndex(function(element) {
         return element === 'confirm';
       })
      if(url_split[url_split.length -1]!='apply' && !_url.includes('confirm'))
      {
        //home career page
        if(url_split[url_split.length -2]==='career' || url_split[url_split.length -2]==='referral')
          {return { 'company_id':url_split[url_split.length -1] }; }
        else
          {
            var j = url_split.findIndex(function(element) {
               return element === 'apply';
             })
            return {'job_role_id' : url_split[i+1], 'company_id':url_split[i-1], 'email_sent_to_candidate': url_split[j+1] };
          }
      }
      else if (_url.includes('confirm')){
        return {'job_role_id' : url_split[i+1], 'company_id':url_split[i-1], 'confirmation_id': url_split[k+1]};
      }
      else
      {
        return {'job_role_id' : url_split[i+1], 'company_id':url_split[i-1]};
      }
    }
  }
}


import { Component, OnInit } from '@angular/core';
import { AuthenticationService} from '../core/authentication/authentication.service';
// import { ToasterService, ToasterConfig } from 'angular2-toaster/angular2-toaster';

import { ToastrService } from 'ngx-toastr';
import { NavbarService } from './header/navbar.service';
import { Router, ActivatedRoute } from "@angular/router";
import { SharedService} from '../core/shared/shared.service'
// declare var $: any;
declare const Tawk_API:any

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
	logged_in=true;
    // toasterConfig: any;
    // toasterconfig: ToasterConfig = new ToasterConfig({
    //       positionClass: 'toast-top-center',
    //       showCloseButton: true
    //   });
    // toaster = {
    //         type: 'success',
    //         title: 'Title',
    //         text: 'Message'
    //     };
    web_upload_count=0;
    tobu_drive_upload_count=0;
    email_upload_count=0;
    total_email_upload_count=0;
    hide_progress_bar=false;
    total_count =0;
    navbar_visible = true;
    tawk_visible = true;
    sync_complete: any;
    paid_customer = false;
    remaining_email_uploads = 0;
    sync_queued= false;
    check = true;
    pending_downloads = 0;
    constructor(
    	private authService: AuthenticationService,
        private navbar: NavbarService, 
        public toasterService: ToastrService,
        public router: Router, 
        public route: ActivatedRoute,
        private shared: SharedService
    	) {
    	}

    ngOnInit() {
        // console.log("isElectron")
        // console.log(this.shared.isElectron())
        this.logged_in = this.isLoggedin()
        this.navbar.isLoggedIn$.subscribe(
            res => {
                console.log(res)
                if((res === true) || (res===false))
                {this.logged_in = res}
            })
        this.navbar.notification$.subscribe(
            res=> {
                // this.toasterService.pop(res['type'], res['title'], res['message']);
                this.toasterService['success'](res['message'], res['title']);
            }
        )
        this.navbar.navbar_visible$.subscribe(
            res=> {this.navbar_visible = res}
        )
        // setTimeout(()=>{ this.tawk_check()}, 2500);
        
        // this.navbar.tawk_visible$.subscribe(
        //     res=> {
        //         console.log(res)
        //         console.log('home page?')
        //         console.log(this.router.url.includes('home'))
        //         this.tawk_visible = res && this.router.url.includes('home');

        //         if(this.tawk_visible){
        //             this.show_tawk();
        //         }
        //         else{this.hide_tawk();}
        //     }
        // )
        if(this.logged_in){
            this.refresh_upload_status();
        }
        // this.load_tawk();
        
    }
    // tawk_check(){
    //     if(this.router.url.includes('home')|| this.router.url.includes('pricing')){
    //         this.show_tawk();
    //             }
    //             else{this.hide_tawk();}
    // }
    isLoggedin(){
        return this.authService.isLoggedIn();
    }
    refresh_upload_status(){
        this.authService.get('/api/uploads_pending').subscribe(
        
            res => {
                console.log(res);
                this.web_upload_count=parseInt(res['web_upload_count'])
                this.tobu_drive_upload_count=parseInt(res['tobu_drive_upload_count']);
                this.email_upload_count=parseInt(res['email_upload_count']);
                this.total_email_upload_count=parseInt(res['total_email_upload_count']);
                this.total_count=this.web_upload_count+this.tobu_drive_upload_count+this.total_email_upload_count-this.email_upload_count
                this.sync_complete = res['sync_completed'];
                this.paid_customer=res['paid_customer'];
                this.remaining_email_uploads = res['remaining_email_uploads']
                this.sync_queued = res['sync_in_queue']
                this.pending_downloads = res['pending_downloads']
                setTimeout(()=>{ this.check_count(); }, 5000);
                
            },
            err => console.log(err)
        )
    }
    stop_check(){
        this.check=false
    }
    // hide_tawk(){
    //     console.log('hiding tawk')
    //     setTimeout(()=>{ Tawk_API.endChat(); Tawk_API.hideWidget() }, 3000);
    //     setTimeout(()=>{ Tawk_API.endChat(); Tawk_API.hideWidget() }, 8000);
    //     // };
    // }
    // show_tawk(){
    //     console.log('showing tawk')
    //     setTimeout(()=>{ Tawk_API.showWidget() }, 3000);
    // }
    // load_tawk(){
    //     var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    //     (function(){
    //     var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    //     s1.async=true;
    //     s1.src='https://embed.tawk.to/5b06b1ef8859f57bdc7b81bc/default';
    //     s1.charset='UTF-8';
    //     s1.setAttribute('crossorigin','*');
    //     s0.parentNode.insertBefore(s1,s0);
    //     })();
    // }
    check_count(){
        if(this.total_count>0 && this.check){
            this.refresh_upload_status()
        }
    }
    trial_sync_complete(){
        return (this.paid_customer==false && this.remaining_email_uploads==0 && this.total_email_upload_count>0)
    }
    
    
              


}

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient} from '@angular/common/http';
// import {AngularTokenService} from 'angular-token';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import {Router} from '@angular/router';
import { environment} from '../../../../environments/environment.prod'

@Injectable({
    providedIn: 'root'
})
export class LoggingService {
  constructor(
		// private tokenService: AngularTokenService,
		private router: Router,
		private http: HttpClient,
		private authService: AuthenticationService
		//private shared: SharedService
	){}
  logError(message: string, stack: string) {
  	let route = '/api/save_frontend_error_to_log'
    // Send errors to be saved here
    // The console.log is only for testing this example.
    let user_id=''
    if(this.authService.isLoggedIn()){
    	user_id=this.authService.currentAuthData()['uid'].toString()
    }
    // this.http.post(this.tokenService.tokenOptions.apiBase + route, 
    this.http.post(environment.baseUrl+ route, 
		   	{
          'error_message': message,
          'error_stacktrace': stack,
          'email': user_id
        }, 
           	{headers:  new HttpHeaders({'Content-Type' : 'application/json'})}
        	).subscribe(res => {
            // console.log(res); 
            // console.log('error logged')
          })
     // console.log('LoggingService: ' + message);
  }
}
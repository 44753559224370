// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
   // baseUrl: 'http://localhost:3000',
   // clientId: '4UzqF_fZmce_IF2mdtlp43hTl9y53M0rOAnPl2UCAdw',//local
  baseUrl: 'https://tobu.cloud',
  clientId: 'N8PImVklIEVN0iPvs5u0P912Iv_I6q0ljhmtYpxQWVo',//server,
  feURL: 'https://tobu.ai',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

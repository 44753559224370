import { Component, OnInit } from '@angular/core';
import { AuthenticationService} from '../../../core/authentication/authentication.service';
import { Params, Router, ActivatedRoute } from "@angular/router";
import { HttpHeaders,HttpClient} from '@angular/common/http';
// import { AngularTokenService } from 'angular-token';
import {environment} from '../../../../environments/environment.prod'

import {saveAs} from "file-saver";
@Component({
  selector: 'app-tobu-jks',
  templateUrl: './tobu-jks.component.html',
  styleUrls: ['./tobu-jks.component.scss']
})
export class TobuJksComponent implements OnInit {

  constructor(
    	private authService: AuthenticationService,
    	private router: Router,
      private http: HttpClient,
      // private tokenService: AngularTokenService,
      private route: ActivatedRoute
	  ) { }

  ngOnInit() {
  	// this.http.get(this.tokenService.tokenOptions.apiBase + '/api/get_tobu_jks', {responseType: 'blob'}).subscribe(
	  // 		blob => {
	  //           console.log(blob)
	  //           saveAs(blob, 'tobu.jks');
	  //           this.router.navigate['/']
	  //       }, error => {
	  //             console.log(error);
	  //       } 
	  // 	)
	  this.http.get(environment.baseUrl + '/api/get_tobu_jks').subscribe(
      // this.http.get(this.tokenService.tokenOptions.apiBase + '/api/get_tobu_jks').subscribe(
      res => {
        window.open(res['url']);
      },
      err=> console.log(err)
    )

  }

}
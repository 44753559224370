import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication/authentication.service'
import { ActivatedRoute, Router, Params} from '@angular/router';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { SharedService } from '../../../core/shared/shared.service';
import { NavbarService } from '../../../layout/header/navbar.service';
import { Observable} from 'rxjs';
import { ModalsService } from '../../../layout/modals/modals.service';
import { FileUploader , FileUploaderOptions} from 'ng2-file-upload';
import {saveAs} from "file-saver";
import { environment } from '../../../../environments/environment'
import {TokenService} from '../../../core/authentication/token.service';
const URL = environment.baseUrl+'/api/mass_create_clients';
let maxFileSize = 5*1024*1024
@Component({
  selector: '[app-create-client]',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.scss']
})
export class CreateClientComponent implements OnInit {

  plo: any = {};
  uar: any = {};
  rcar: any= {};
  form_data:any;
  collaborator_options: any;
  employment_type_categories: any;
  default_stages: any=[];
  default_statuses: any;
  // stageList: Array<string> = ['abc', 'bcd'];
  stage_types=[];
  listOne: Array<string> ;
  clicked_save =false;
  client_name_available = true;
  show_mass_create_clients=false;
  // interviewers = ['a','b','c'];

  recruiters=[];
  valForm = new FormGroup({
    client_name: new FormControl('',[Validators.required]),
    client_description: new FormControl(),
    client_account_manager_id: new FormControl(),
    // client_locations: new FormControl(),
    // client_contact_persons: new FormControl(),
    // client_emails: new FormControl(),
    // client_phone_nos: new FormControl(),
    client_contacts:  new FormArray([
      this.fb.group({
          client_contact_person: [''],
          client_email: [''],
          client_phone_no: [''],
          client_location: [''],
          client_designation: [''],
          client_department: ['']
      })
    ])

  });
  public uploader: FileUploader = new FileUploader({maxFileSize:maxFileSize, allowedMimeType: [ 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ], url: URL, itemAlias: 'mass_clients', autoUpload: true});
    public hasBaseDropZoneOver: boolean = false;
    // public hasAnotherDropZoneOver: boolean = false;

    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }
  // valForm2 = new FormArray([ {item:new FormConrol, color: new FormControl, tags: new FormArray[''] }])
  // collaborators = [];
  constructor(
  	private authService: AuthenticationService,
  	private route: ActivatedRoute,
  	private router: Router,
    private shared: SharedService,
    private fb:FormBuilder,
    private navbar: NavbarService,
    private tokenService2: TokenService,
    private modal: ModalsService

  	) { }

  ngOnInit() {
    // this.get_uar();
    this.load_recruiters();
    this.get_rcar();
    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.valForm.controls.client_name.valueChanges.subscribe(
      value => {
        this.client_exists(value)
      }
    );
  }
  
  get_uar(){
    this.authService.post('/api/get_user_access_rights_api', null).subscribe(
      res => {
          this.uar =  res;
          // console.log(res);
      }, error => {
          // console.log(error);
      }
    )
  }
  load_recruiters(){
    this.authService.get('/api/load_recruiters').subscribe(
      res=> {
        console.log(res)
        this.recruiters = res['recruiters'];
        this.upload_builder( res['company_id'], res['current_user_id']);
      },
      err=> {console.log(err)}
      )
  }
  get_rcar(){
    this.authService.post('/api/get_recruitment_company_access_rights_api', null).subscribe(
      res => {
          this.rcar =  res;
          console.log(res);
      }, error => {
          // console.log(error);
      }
    )
  }
  // onCheckSortChange($event, array_name){
  //   this.shared.onCheckSortChange($event, this.valForm, array_name);
  // }
  submitForm($ev, value: any) {
    $ev.preventDefault();
    console.log(value);
    for (let c in this.valForm.controls) {
        this.valForm.controls[c].markAsTouched();
    }

     if (this.valForm.valid) {
        this.clicked_save =true;
        this.authService.post(
          '/api/create_client', value
          // {
          //   client_name: value.client_name,
          //   client_description: value.client_description,
          //   client_locations: value.client_locations,
          //   client_contact_persons: value.client_contact_persons,
          //   client_emails: value.client_emails,
          //   client_phone_nos: value.client_phone_nos,
          //   job_role_title: value.job_role_title, 
          //   no_of_openings: value.no_of_openings, 
          //   job_locations: value.job_locations, 
          //   job_description: value.job_description, 
          //   required_qualifications: value.required_qualifications, 
          //   preferred_qualifications: value.preferred_qualifications, 
          //   required_skills: value.required_skills, 
          //   preferred_skills: value.preferred_skills, 
          //   preferred_industries: value.preferred_industries, 
          //   ctc: value.ctc, 
          //   min_years_of_experience: value.min_years_experience, 
          //   max_years_of_experience: value.max_years_experience, 
          //   interview_locations: value.interview_locations, 
          //   preferred_candidate_locations: value.preferred_candidate_locations,
          //   all_stages: value.all_stages
          //   // collaborators: value.collaborators,
          //   // stages: value.stages,
          //   // stage_type_ids: value.stage_type_ids,
          //   //stage: value.stage
          // }
          ).subscribe(
          res => {
              console.log(res);
              this.clicked_save =false;
              this.modal.hide_modal_by_name('create_client');
              this.navbar.set_notification('Success', 'Successfully Created Client', res['notice']);
              setTimeout(function(){ window.location.href = 'jobs/list?client_name='.concat(res['client_name']);  }, 3000);
          }, error => {
              // console.log(error);
          }
        )
     }
     else{
       this.navbar.set_notification('Warning', 'Please complete form', 'Please ensure all fields are filled')
     }
  }
  client_exists(_client){
    this.authService.post('/api/client_name_exists', {client_name: _client}).subscribe(
      res => {
        console.log(res);
        this.client_name_available = res['available']
        },
      err=> console.log(err)
      )
  }
  addContact() {
    const control = <FormArray>this.valForm.controls['client_contacts'];
    control.push(this.fb.group({
          client_contact_person: [''],
          client_email: [''],
          client_phone_no: [''],
          client_location: [''],
          client_designation: [''],
          client_department: ['']
      }));
    // console.log(control)
  }
  removeContact(i){
    const control = <FormArray>this.valForm.get('client_contacts');
    control.removeAt(i)
    // console.log(control[i]);
  }
  update_upload_parameters(){
      var uo: FileUploaderOptions = {};
      uo.headers = 
     [
       // {name: 'access-token', value : localStorage.getItem("accessToken")}, 
       // {name: 'client', value : localStorage.getItem("client")},
       // {name: 'expiry', value : localStorage.getItem("expiry")},
       // {name: 'token-type', value : localStorage.getItem("tokenType")},
       // {name: 'uid', value : localStorage.getItem("uid")}
       {name: 'access-token', value : localStorage.getItem("access_token")},
       {name: 'Authorization', value : 'Bearer '+ this.tokenService2.getToken()}, 
     ];
      // console.log(uo);
      
      this.uploader.setOptions(uo);
      // console.log(this.uploader);
  //    this.upload_builder(this.job_role.id , this.create_mass_upload_tags, "1" , this.create_mass_upload_cv_source)

    }
  upload_builder( _company_id,  _current_user_id){
    this.update_upload_parameters();
    var date:Date = new Date();
    var baseDate: Date = new Date(Date.UTC(1970, 0, 1));
    var timestamp = _current_user_id + (date.getTime() - baseDate.getTime()).toString();
    // console.log(this.cv_sources)
    //this.set_timestamp();
    this.uploader.onBuildItemForm = function(fileItem, form)
    { 
      
      //var timestamp ='9871239761'
      form.append('user_upload_timestamp', timestamp);
      form.append('file_count', this.queue.length);
      return {fileItem, form} 
    };
    this.uploader.onCompleteItem = (item:any, response:any, status:any, headers:any)=> {
      // console.log( item, status,response, headers);
      // var resp=JSON.parse(response);
      // console.log(response);
      // console.log(headers);
      if (headers['access-token']!== undefined && headers['access-token']!== ''){
        localStorage.setItem('accessToken', headers['access-token']);
        localStorage.setItem("expiry", headers['expiry']);
        this.update_upload_parameters()
      }
      
      // console.log(resp);  
    };
    this.uploader.onCompleteAll =()=> {
      this.navbar.set_notification('Success','Uploaded clients', 'It might take a minute for the new cliets to reflect');
      this.modal.hide_modal_by_name('upload');
      // setTimeout(function(){ location.reload(); }, 3000);
    }
  }
  get_sample_mass_client_upload(){
      this.authService.downloadFile('/api/get_sample_mass_client_upload', {}).subscribe(
        blob => {
              console.log(blob)
              saveAs(blob, 'sample_mass_client_upload.xlsx');
          }, error => {
                console.log(error);
          } 
      )
    }

}
